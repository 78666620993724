import { useState, useEffect, useReducer } from 'react'

const devTool = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
const isDevtoolsEnabled = process.env.ENABLE_DEVTOOLS === 'true'
let uniqueIndex = 0
const uniqueName = () => `Store-${++uniqueIndex}`

/** public interface for a devtool reducer
 * If devtools is not installed or options.enabled is false the react's reducer hook
 * will be used.
 */
export const useDevtoolReducer = (reducerFn, initialState, options = {}) => {
  // initialize name
  options.name = options.name || uniqueName()

  // define react's reducer
  const reactReducer = useReducer(reducerFn, initialState)
  // define devtool's reducer
  const [devToolStore] = useState(
    () => isDevtoolsEnabled && devTool && devTool(reducerFn, initialState, options)
  )
  const [devToolState, setDevToolState] = useState(devToolStore && devToolStore.getState())
  useEffect(() => {
    if (!devToolStore) return
    devToolStore.subscribe(() => {
      setDevToolState(devToolStore.getState())
    })
  }, [devToolStore])
  const devToolReducer = devToolStore && [devToolState, devToolStore.dispatch]

  // return the correct reducer
  return devToolStore ? devToolReducer : reactReducer
}

const storeManager = {}

export const useTest = () => {
  return storeManager
}
