import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'
import { categories, topics, subTopics } from '../RiwisCategories'
/**  defaultCategory and defaultTopic is defined by first item of the Array.
 *  Its use is to pre-select every category and topic for every question after the first question
 *  Therefore only the subTopic is choosable, this speeds up question creation and reduces wrong datasource selections
 */
const Item = ({
  item,
  onChange,
  handleItemDeleteClick,
  handleItemUpClick,
  handleItemDownClick,
  handleBreakCategoryLink,
  defaultCategory,
  defaultTopic,
  breakCategoryLink,
  index,
}) => {
  return (
    <div key={item.id} className="d-flex flex-column p-4" style={{ borderBottom: '1px solid lightgrey' }}>
      <div className="d-flex align-items-center mb-3">
        <div className="mr-5" style={{ width: '2rem' }}>
          Frage:{' '}
        </div>
        <div className="d-flex justify-content-between" style={{ flex: 1 }}>
          <input
            type="text"
            name="question"
            className="form-control"
            value={item.question || ''}
            onChange={(event) => onChange(item, event)}
          />
          <div className="pl-4">
            <ContextMenu
              item={item}
              handleItemDeleteClick={handleItemDeleteClick}
              handleItemUpClick={handleItemUpClick}
              handleItemDownClick={handleItemDownClick}
              handleBreakCategoryLink={handleBreakCategoryLink}
              breakCategoryLink={breakCategoryLink}
              index={index}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mr-5" style={{ width: '2rem' }}>
          Quelle:
        </div>
        <div className="d-flex" style={{ flex: 1 }}>
          <div className="col-sm-3 pl-0">
            <select
              disabled={defaultCategory && !breakCategoryLink}
              className="form-control"
              name="category"
              onChange={(event) => onChange(item, event)}
              value={item.category || 0}
            >
              {!item.category && <option value="">...bitte wählen</option>}
              {Object.values(categories).map((category) => (
                <option key={category.value} value={category.value}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-4">
            {}
            <select
              className="form-control"
              name="topic"
              disabled={!item.category || (defaultTopic && !breakCategoryLink)}
              onChange={(event) => onChange(item, event)}
              value={item.topic || 0}
            >
              <option value="">...bitte wählen</option>
              {item.category &&
                Object.values(topics[item.category || defaultCategory]).map((topic) => (
                  <option key={topic.value} value={topic.value}>
                    {topic.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-sm-4">
            <select
              className="form-control"
              name="subTopic"
              disabled={
                (!item.category && !item.subTopic) || (!subTopics[item.topic]?.length && !breakCategoryLink)
              }
              onChange={(event) => onChange(item, event)}
              value={item.subTopic ? item.subTopic : 0}
            >
              <option value="0">
                {!subTopics[item.topic]?.length ? 'keine Auswahl' : '...bitte wählen'}
              </option>
              {item.category &&
                item.topic &&
                item.topic !== '0' &&
                Object.values(subTopics[item.topic]).map((topic) => (
                  <option key={topic.value} value={topic.value}>
                    {topic.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

const ContextMenu = ({
  item,
  handleItemUpClick,
  handleItemDownClick,
  handleItemDeleteClick,
  handleBreakCategoryLink,
  breakCategoryLink,
  index,
}) => {
  return (
    <ul className="list-group">
      <li className="list-group-item p-0 text-break" key={item.id} id={item.id}>
        <div className="btn-group btn-group-sm float-right" role="group" aria-label="tool buttons">
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemUpClick(item.id)}
          >
            &uarr;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemDownClick(item.id)}
          >
            &darr;
          </button>
          {index === 0 && (
            <button type="button" className="btn btn-outline-secondary" onClick={handleBreakCategoryLink}>
              <FontAwesomeIcon icon={breakCategoryLink ? faUnlink : faLink} />
            </button>
          )}
          <button
            type="button"
            className="btn btn-outline-secondary text-danger"
            onClick={() => handleItemDeleteClick(item.id)}
          >
            &#x2718;
          </button>
        </div>
      </li>
    </ul>
  )
}

const buildFileMakerString = (datasource) => {
  const fmCategory = categories[datasource.category].fmName || ''
  let fmTopic = '_topic'
  let fmSubTopic = '_subTopic'
  if (datasource.topic) {
    fmTopic = '_' + topics[datasource.category][datasource.topic].fmName
  }
  if (datasource.subTopic) {
    if (datasource.category === 'md_retail_market' && datasource.topic === 'rent_city') {
      fmSubTopic =
        '_' + subTopics[datasource.topic].find((subTopic) => subTopic.value === datasource.subTopic).fmNameRM
    } else {
      fmSubTopic =
        '_' + subTopics[datasource.topic].find((subTopic) => subTopic.value === datasource.subTopic).fmName
    }
  }
  if (datasource.category === 'md_office_market' || datasource.category === 'md_logistics_market') {
    if (
      datasource.topic === 'turnover' ||
      datasource.topic === 'vacancy' ||
      datasource.topic === 'space_new_total'
    ) {
      return `${fmCategory}${fmTopic}`
    } else if (datasource.topic === 'yield' || datasource.topic === 'yield_logistics') {
      return `${fmCategory}${fmTopic}${fmSubTopic}`
    }
    return `${fmCategory}${fmSubTopic}${fmTopic}`
  }
  return `${fmCategory}${fmTopic}${fmSubTopic}`
}

export const RiwisBox = ({ datasources, questionnaireIndex, pageIndex, index }) => {
  const [breakCategoryLink, setBreakCategoryLink] = useState(false)
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const changeHandler = useCallback(
    (name, answers) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, [...answers], name)
    },
    [questionnaireIndex, pageIndex, setQuestionInput, index]
  )

  const handleNewClick = useCallback(() => {
    const newDatasources = [...datasources]
    let id = 0
    if (newDatasources.length) {
      id = newDatasources[newDatasources.length - 1].id + 1
      newDatasources.push({
        category: newDatasources[0].category,
        topic: newDatasources[0].topic,
        subTopic: '',
        id,
        question: '',
        fileMaker: '',
      })
    } else {
      newDatasources.push({ category: '', topic: '', subTopic: '', id, question: '', fileMaker: '' })
    }
    changeHandler('riwis_datasources', newDatasources)
  }, [datasources, changeHandler])

  const dataSourceChange = useCallback(
    (item, event) => {
      let newDatasources = [...datasources]
      const index = newDatasources.findIndex((datasource) => datasource.id === item.id)
      newDatasources[index][event.target.name] = event.target.value

      if (!breakCategoryLink || event.target.name === 'question') {
        newDatasources = newDatasources.map((datasource, index) => {
          const newDatasource = { ...datasource }
          if (index > 0 && !breakCategoryLink) {
            newDatasource.category = newDatasources[0].category
            newDatasource.topic = newDatasources[0].topic
            newDatasource.subTopic = datasource.subTopic
          }
          if (event.target.name === 'category' && !breakCategoryLink) {
            newDatasource.topic = ''
            newDatasource.subTopic = ''
          }
          if (event.target.name === 'topic') {
            newDatasource.subTopic = ''
          }
          newDatasource.fileMaker = buildFileMakerString(newDatasource)
          return newDatasource
        })
      } else {
        newDatasources[index].fileMaker = buildFileMakerString(newDatasources[index])
      }
      changeHandler('riwis_datasources', newDatasources)
    },
    [datasources, changeHandler, breakCategoryLink]
  )

  const handleItemDeleteClick = useCallback(
    (itemId) => {
      const newDatasources = [...datasources]
      const index = newDatasources.findIndex((datasource) => datasource.id === itemId)
      newDatasources.splice(index, 1)
      changeHandler('riwis_datasources', newDatasources)
    },
    [datasources, changeHandler]
  )

  const handleItemUpClick = useCallback(
    (itemId) => {
      const newDatasources = [...datasources]
      const index = newDatasources.findIndex((datasource) => datasource.id === itemId)
      if (index > 0) {
        const temp = newDatasources[index - 1]
        newDatasources[index - 1] = newDatasources[index]
        newDatasources[index] = temp
        changeHandler('riwis_datasources', newDatasources)
      }
    },
    [datasources, changeHandler]
  )

  const handleItemDownClick = useCallback(
    (itemId) => {
      const newDatasources = [...datasources]
      const index = newDatasources.findIndex((datasource) => datasource.id === itemId)
      if (index + 1 <= newDatasources.length - 1) {
        const temp = newDatasources[index + 1]
        newDatasources[index + 1] = newDatasources[index]
        newDatasources[index] = temp
        changeHandler('riwis_datasources', newDatasources)
      }
    },
    [datasources, changeHandler]
  )

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">Fragen</label>
      <div className="col-sm-10">
        <div className="border rounded p-3 bg-light">
          <ul className="list-group" style={{ maxHeight: 'calc(30vh)', overflowY: 'auto' }}>
            {datasources.map((datasource, index) => (
              <li key={datasource.id}>
                <Item
                  item={datasource}
                  onChange={dataSourceChange}
                  handleItemDeleteClick={handleItemDeleteClick}
                  handleItemUpClick={handleItemUpClick}
                  handleItemDownClick={handleItemDownClick}
                  handleBreakCategoryLink={() => setBreakCategoryLink(!breakCategoryLink)}
                  breakCategoryLink={breakCategoryLink}
                  defaultCategory={index > 0 ? datasources[0].category : null}
                  defaultTopic={index > 0 ? datasources[0].topic : null}
                  index={index}
                />
              </li>
            ))}
          </ul>

          <button
            type="button"
            className="btn-sm btn-outline-secondary mt-3 text-primary"
            onClick={handleNewClick}
          >
            <b>+</b>
          </button>
        </div>
      </div>
    </div>
  )
}
