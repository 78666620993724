import React from 'react'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { useSurveyDataStore } from './dataStore'
import { Question } from './Question'

/**
 * question.index is the actual index of the original questions array
 * the index of visibleQuestions is used to display the question number
 */

export const Questions = ({ questions, pageIndex }) => {
  const [{ selectedCity }] = useSurveyDataStore()
  return questions.map((question, index) => (
    <ErrorBoundary type={'question'} key={question.id} question={question} selectedCity={selectedCity}>
      <Question
        question={question}
        questionNumber={index + 1}
        questionIndex={question.index}
        questionsLength={questions.length}
        pageIndex={pageIndex}
      />
    </ErrorBoundary>
  ))
}
