import React, { useEffect, useState } from 'react'
import { useSurveyDataStore } from '../dataStore'
import { Button } from 'react-bootstrap'
import { RiwisSaveButton } from '../QuestionnaireBuilder/Components/RiwisButtons'
import { SaveButton } from '../QuestionnaireBuilder/Components/Buttons'
import { useUserDataStore } from '../../../stores/userStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const SuccessMessage = () => {
  const { t } = useTranslation()
  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap my-4">
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="text-success align-middle mr-3 mb-4"
        style={{ fontSize: '2rem' }}
      />
      <p className="text-center" style={{ fontSize: '1rem' }}>
        {t('survey.dismissModal.successMessage')}
      </p>
    </div>
  )
}

const FailureMessage = () => {
  const { t } = useTranslation()
  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap my-4">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className="text-danger align-middle mr-3 mb-4"
        style={{ fontSize: '2rem' }}
      />
      <p className="text-center" style={{ fontSize: '1rem' }}>
        {t('survey.dismissModal.failureMessage')}
      </p>
    </div>
  )
}

export const Dismiss = ({ onHide, navigateTo }) => {
  const [{ saveMessage, survey }] = useSurveyDataStore()
  const [{ user }] = useUserDataStore()
  const [disabledButton, setDisalbedButton] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (saveMessage.type === 'success') {
      setDisalbedButton(true)
      setTimeout(() => {
        setDisalbedButton(false)
        onHide(null, navigateTo)
      }, 2000)
    }
  }, [onHide, saveMessage, navigateTo])

  return (
    <div>
      <div className="header">
        {saveMessage.type === '' ? (
          <h2>{t('survey.dismissModal.header')}</h2>
        ) : saveMessage.type === 'success' ? (
          <h2>{t('survey.dismissModal.saveSuccess')}</h2>
        ) : (
          <h2>{t('survey.dismissModal.saveFailure')}</h2>
        )}
      </div>
      <div className="body">
        {saveMessage.type === '' ? (
          <>
            <div style={{ fontSize: '1rem' }}>{t('survey.dismissModal.quitQuestion')}</div>
            {user !== null ? (
              <p style={{ fontSize: '1rem' }} className="mt-3">
                {t('survey.dismissModal.saveAndResume')}
              </p>
            ) : (
              <p style={{ fontSize: '1rem' }} className="mt-3">
                {t('survey.dismissModal.saveAndQuit')}
              </p>
            )}
          </>
        ) : saveMessage.type === 'success' ? (
          <SuccessMessage />
        ) : (
          <FailureMessage />
        )}
      </div>
      <div className="d-flex justify-content-center justify-content-sm-between footer">
        {!disabledButton &&
          (survey.type === 'riwis' ? (
            <>
              <Button
                className="riwis-btn riwis-secondary my-3 mx-4"
                onClick={() => onHide(null, navigateTo)}
              >
                {t('survey.buttons.closeWithoutSaving')}
              </Button>
              <RiwisSaveButton
                earlyExit={true}
                className="my-3 mx-4"
                label={t('survey.buttons.closeWithSaving')}
              />
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={() => onHide(null, navigateTo)} className="my-3 mx-4">
                {t('survey.buttons.closeWithoutSaving')}
              </Button>
              <SaveButton
                earlyExit={true}
                label={t('survey.buttons.closeWithSaving')}
                className="my-3 mx-4"
                upperCase={false}
              />
            </>
          ))}
      </div>
    </div>
  )
}
