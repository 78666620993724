import { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Container } from '../../components/Container'
import { useSurveyDataStore } from './dataStore'
import { SurveyOpener } from './surveyOpener'
import { QuestionnaireBuilder } from './QuestionnaireBuilder'
import { RiwisOpener } from './RiwisOpener/RiwisOpener'
import { AuthorizationHandler } from '../../components/AuthorizationHandler'
import { NotFound } from '../ErrorPages/NotFound'
import { Expired } from '../ErrorPages/Expired'
import { useUserDataStore } from '../../stores/userStore'

export const Survey = () => {
  const [
    { dataIsLoading, survey, questionnaire, selectedCity, isAuthorized, surveyMessage },
    { fetchQuestionnaire, fetchSurvey, setSaveMessage, setSurveyMessage, setIsInSurvey },
  ] = useSurveyDataStore()
  const [, { setLanguage }] = useUserDataStore()
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(null)
  const { surveySlug } = useParams()
  const history = useHistory()

  const isSurveySwitching = useMemo(
    () => survey === null || surveySlug !== survey?.slug,
    [survey, surveySlug]
  )

  const handleCurrentQuestionaire = useCallback(
    (questionnaireId, navigateTo) => {
      setSaveMessage({ msg: '', type: '', error: '' })
      if (questionnaireId) {
        setCurrentQuestionnaireId(questionnaireId)
      } else {
        setCurrentQuestionnaireId(null)
        if (navigateTo) {
          setTimeout(() => {
            history.push(navigateTo)
          }, 0)
        }
      }
    },
    [setSaveMessage, history]
  )

  useEffect(() => {
    if (isSurveySwitching) {
      fetchSurvey(surveySlug)
    }
  }, [fetchSurvey, surveySlug, isSurveySwitching])

  useEffect(() => {
    if (currentQuestionnaireId) {
      if (survey.type === 'survey' || survey.type === 'bfQuarter') {
        fetchQuestionnaire(currentQuestionnaireId, survey.id)
      } else if (survey.type === 'riwis' && selectedCity) {
        fetchQuestionnaire(currentQuestionnaireId, survey.id, selectedCity.gac)
      }
    }
  }, [fetchQuestionnaire, currentQuestionnaireId, selectedCity, survey])

  // helper, needed for components outside of router (footer) to determine if a user is within a survey route a survey
  useEffect(() => {
    setIsInSurvey(true)
  }, [setIsInSurvey])

  // cleanup
  useEffect(() => {
    return function cleanup() {
      setSurveyMessage({ msg: '', err: '', type: '' })
      setIsInSurvey(false)
    }
  }, [setSurveyMessage, setIsInSurvey])

  useEffect(() => {
    if (survey?.language) {
      setLanguage(survey.language)
    }
  }, [survey, setLanguage])

  if (surveyMessage.err === 'expired') {
    return <Expired />
  }

  if (!isAuthorized) {
    return <AuthorizationHandler />
  } else if (surveyMessage.err === 'not_found') {
    return <NotFound />
  }

  if (dataIsLoading || isSurveySwitching || survey === null) {
    return <Container />
  }

  if (currentQuestionnaireId && questionnaire !== null) {
    return (
      <Container bgColor={survey.type === 'riwis' ? '#f0f0f0' : '#efedee'} surveyType={survey.type}>
        <QuestionnaireBuilder surveyId={survey.id} onHide={handleCurrentQuestionaire} />
      </Container>
    )
  }

  return (
    <Container
      customContainerStyle={
        survey?.settings?.introStyles[survey?.settings?.intro || 'default']?.customContainerStyle
      }
      wrapperStyle={survey?.settings?.introStyles[survey?.settings?.intro || 'default']?.wrapperStyle}
    >
      {survey.type === 'riwis' ? (
        <RiwisOpener survey={survey} handleCurrentQuestionaire={handleCurrentQuestionaire} />
      ) : (
        <SurveyOpener survey={survey} handleCurrentQuestionaire={handleCurrentQuestionaire} />
      )}
    </Container>
  )
}
