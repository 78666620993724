import React, { useMemo } from 'react'
import { useUserDataStore } from '../stores/userStore'
import bulwiengesaLogo from '../assets/img/bulwiengesa-logo.png'
import { useSurveyDataStore } from '../pages/Survey/dataStore'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const [{ user }] = useUserDataStore()
  const [{ supportContact, isInSurvey, dataIsLoading }] = useSurveyDataStore()

  const currentSupportContact = useMemo(() => {
    if (isInSurvey && !dataIsLoading) {
      // Support contact of survey
      return supportContact
    } else if (user) {
      return user.supportContact
    } else {
      return null
    }
  }, [supportContact, user, isInSurvey, dataIsLoading])

  const SupportContact = ({ children }) => {
    return <small>{children}</small>
  }

  SupportContact.Element = ({ children, showElement, lastElement = false }) => {
    return showElement ? (
      <span>
        {children} {lastElement ? '' : '|'}{' '}
      </span>
    ) : (
      <></>
    )
  }

  return (
    <footer className="text-secondary d-flex flex-column flex-sm-row justify-content-between align-items-center">
      <div className="col-md-2 text-center text-sm-left mt-3 mt-sm-0" style={{ maxWidth: '200px' }}>
        <small>
          <a href="https://www.bulwiengesa.de/de/impressum">Impressum</a>|
          <a href="https://www.bulwiengesa.de/de/datenschutzerklaerung">Datenschutz</a>
        </small>
      </div>
      <address className="m-0 col-md-8 text-center mt-3 mt-sm-0">
        {currentSupportContact !== null && (
          <SupportContact>
            <SupportContact.Element
              showElement={currentSupportContact?.first_name || currentSupportContact?.last_name}
            >
              {currentSupportContact?.first_name && currentSupportContact.first_name}{' '}
              {currentSupportContact?.last_name && currentSupportContact.last_name}
            </SupportContact.Element>
            <SupportContact.Element showElement={currentSupportContact?.street}>
              {currentSupportContact?.street}
            </SupportContact.Element>
            <SupportContact.Element showElement={currentSupportContact?.phone}>
              {currentSupportContact?.phone}
            </SupportContact.Element>
            <SupportContact.Element showElement={currentSupportContact?.zip || currentSupportContact?.city}>
              {currentSupportContact?.zip && currentSupportContact?.zip}{' '}
              {currentSupportContact?.city && currentSupportContact?.city}
            </SupportContact.Element>
            <SupportContact.Element lastElement={true} showElement={currentSupportContact?.email}>
              {currentSupportContact?.email}
            </SupportContact.Element>
          </SupportContact>
        )}
      </address>
      <div
        className="col-md-2 text-center text-sm-right mt-3 mt-sm-0"
        style={{ maxWidth: '200px', minWidth: '100px' }}
      >
        <div className="logoWrapper">
          <Link to={{ pathname: 'https://www.bulwiengesa.de/de' }} target="_blank">
            <img src={bulwiengesaLogo} alt="bulwiengesa AG logo" width="100%" />
          </Link>
        </div>
      </div>
    </footer>
  )
}
