import { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { Container } from '../../../components/Container'
import { useTokenAdminDataStore } from './dataStore'
import {
  Button,
  DropdownButton,
  Dropdown,
  Spinner,
  Accordion,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap'
import XLSX from 'xlsx'
import { Modal } from '../../../components/Modal'
import { AlertBox } from '../../../components/AlertBox'
import DatePicker from 'react-date-picker'
import { Mail } from './Mail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown, faAngleUp, faExclamationTriangle, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const getStatusIcon = (validUntil) => {
  const validUntilUnix = new Date(validUntil).getTime()
  const nowUnix = Date.now()
  const diff = validUntilUnix - nowUnix
  return diff > 0 ? (
    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
  ) : (
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
  )
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey))

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className="mail" onClick={decoratedOnClick}>
      {children}
      <FontAwesomeIcon icon={isCurrentEventKey ? faAngleUp : faAngleDown} className="ml-3" />
    </div>
  )
}

export const Token = () => {
  const [
    { dataIsLoading, tokensAreLoading, surveyList, userGroups, tokens, tokenMessage, mail },
    {
      fetchSurveyListAndUserGroups,
      fetchTokensAndMail,
      createTokens,
      expandTokenDatesAll,
      expandTokenDatesSelected,
      deleteAllTokens,
      deleteSelectedTokens,
      setTokens,
      createTokensSelected,
      setTokenMessage,
    },
  ] = useTokenAdminDataStore()
  const [selectedUserGroup, setSelectedUserGroup] = useState(0)
  const [selectedUserGroupLabel, setSelectedUserGroupLabel] = useState(null)
  const [selectedSurvey, setSelectedSurvey] = useState(0)
  const [selectedTokens, setSelectedTokens] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [usersWithoutToken, setUsersWithoutToken] = useState([])
  const [selectedUsersWithoutToken, setSelectedUsersWithoutToken] = useState([])
  const [extendDate, setExtendDate] = useState(new Date())
  const { t } = useTranslation()

  const handleUserGroupChange = useCallback((event) => {
    setSelectedUserGroupLabel(event.target.options[event.target.options.selectedIndex].textContent)
    setSelectedUserGroup(Number(event.target.value))
    setSelectedTokens([])
  }, [])

  const handleSurveyChange = useCallback((event) => {
    setSelectedSurvey(Number(event.target.value))
    setSelectedTokens([])
  }, [])

  const generateTokens = useCallback(() => {
    createTokens(selectedUserGroup, selectedSurvey)
    setUsersWithoutToken([])
  }, [selectedUserGroup, selectedSurvey, createTokens])

  const tokenAsXLSX = () => {
    let workbook = XLSX.utils.table_to_book(document.getElementById('tokenTable'))
    const fileName = 'tokens_' + selectedUserGroupLabel + '_' + new Date().toLocaleDateString() + '.xlsx'

    XLSX.writeFile(workbook, fileName)
  }

  const extendValidDateByAll = useCallback(
    (timespan) => {
      if (timespan) {
        expandTokenDatesAll(selectedUserGroup, selectedSurvey, timespan)
      } else {
        expandTokenDatesAll(selectedUserGroup, selectedSurvey, { date: extendDate })
      }
    },
    [expandTokenDatesAll, extendDate, selectedSurvey, selectedUserGroup]
  )

  const extendValidDateBySelected = useCallback(
    (timespan) => {
      const tokenIds = selectedTokens.map((token) => token.id)
      if (timespan) {
        expandTokenDatesSelected(selectedUserGroup, selectedSurvey, timespan, tokenIds)
      } else {
        expandTokenDatesSelected(setSelectedUserGroup, selectedSurvey, { date: extendDate }, tokenIds)
      }
    },
    [expandTokenDatesSelected, extendDate, selectedSurvey, selectedTokens, selectedUserGroup]
  )

  const handleCheckbox = useCallback(
    (event, token, all) => {
      let newSelectedTokens = [...selectedTokens]
      if (event.target.checked) {
        if (all) {
          newSelectedTokens = tokens
        } else {
          newSelectedTokens.push(token)
        }
      } else {
        if (all) {
          newSelectedTokens = []
        } else {
          const index = newSelectedTokens.findIndex((selectedToken) => selectedToken.id === token.id)
          newSelectedTokens.splice(index, 1)
        }
      }
      setSelectedTokens(newSelectedTokens)
    },
    [selectedTokens, tokens, setSelectedTokens]
  )

  const handleAddUserWithoutToken = useCallback(
    (event, userInfo, all) => {
      let newSelectedUsersWithoutToken = [...selectedUsersWithoutToken]
      if (event.target.checked) {
        if (all) {
          newSelectedUsersWithoutToken = usersWithoutToken.map((user) => user.userInfo.id)
        } else {
          newSelectedUsersWithoutToken.push(userInfo.id)
        }
      } else {
        if (all) {
          newSelectedUsersWithoutToken = []
        } else {
          const index = newSelectedUsersWithoutToken.indexOf(userInfo.id)
          newSelectedUsersWithoutToken.splice(index, 1)
        }
      }
      setSelectedUsersWithoutToken(newSelectedUsersWithoutToken)
    },
    [selectedUsersWithoutToken, usersWithoutToken, setSelectedUsersWithoutToken]
  )

  const handleDeleteAll = useCallback(() => {
    deleteAllTokens(selectedUserGroup, selectedSurvey)
    setSelectedTokens([])
  }, [deleteAllTokens, selectedSurvey, selectedUserGroup])

  const handleDeleteSelected = useCallback(() => {
    const tokenIds = selectedTokens.map((token) => token.id)
    deleteSelectedTokens(tokenIds)
    setSelectedTokens([])
  }, [deleteSelectedTokens, selectedTokens])

  const hideNewUsersModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleDateChange = useCallback((event) => {
    const date = new Date(event)
    setExtendDate(date)
  }, [])

  const createTokenForSelection = useCallback(
    (all) => {
      if (all) {
        createTokensSelected(
          selectedUserGroup,
          selectedSurvey,
          usersWithoutToken.map((user) => user.userInfo.id)
        )
      } else {
        createTokensSelected(selectedUserGroup, selectedSurvey, selectedUsersWithoutToken)
      }
      hideNewUsersModal(true)
    },
    [
      hideNewUsersModal,
      createTokensSelected,
      selectedUserGroup,
      selectedSurvey,
      usersWithoutToken,
      selectedUsersWithoutToken,
    ]
  )

  const generatedTokensExists = useMemo(() => {
    let generatedTokensExists = false
    if (tokens && selectedSurvey !== 0 && selectedUserGroup !== 0) {
      generatedTokensExists = !!tokens.filter((token) => token.state !== 'not created').length
    }
    return generatedTokensExists
  }, [tokens, selectedSurvey, selectedUserGroup])

  useEffect(() => {
    fetchSurveyListAndUserGroups()
  }, [fetchSurveyListAndUserGroups])

  useEffect(() => {
    if (parseInt(selectedSurvey) && parseInt(selectedUserGroup)) {
      fetchTokensAndMail(selectedUserGroup, selectedSurvey)
    }
  }, [fetchTokensAndMail, selectedSurvey, selectedUserGroup])

  useEffect(() => {
    if (tokens) {
      const usersWithoutToken = tokens.filter((token) => token.id === 0 && token.user !== null)
      if (usersWithoutToken.length && tokens.length !== usersWithoutToken.length) {
        setUsersWithoutToken(usersWithoutToken)
      } else {
        setUsersWithoutToken([])
      }
    }
  }, [tokens])

  useEffect(() => {
    if (!parseInt(selectedUserGroup) || !parseInt(selectedSurvey)) {
      setTokens(null)
      setUsersWithoutToken([])
    }
  }, [selectedUserGroup, selectedSurvey, setTokens])

  useEffect(() => {
    if (tokenMessage.type) {
      setTimeout(() => {
        setTokenMessage({ msg: '', type: '', err: '' })
      }, 3000)
    }
  })

  return (
    <Container>
      {dataIsLoading === false && surveyList && userGroups && (
        <>
          <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
            <h3>Token</h3>
            <AlertBox
              message={tokenMessage.msg}
              error={tokenMessage.err}
              type={tokenMessage.type}
              top={'-15px'}
            />
          </div>
          <hr />
          <div className="d-flex flex-row justify-content-start align-items-center">
            <label htmlFor="UserGruppe">Benutzer-Gruppe:</label>
            <select
              onChange={handleUserGroupChange}
              name="userGroup"
              id="userGroup"
              className="custom-select-sm ml-3"
            >
              <option value={0}>...wählen</option>
              {userGroups.map((userGroup) => (
                <option key={userGroup.id} value={userGroup.id}>
                  {userGroup.label}
                </option>
              ))}
            </select>
            <label htmlFor="survey" className="ml-5">
              Umfrage
            </label>
            <select onChange={handleSurveyChange} name="survey" id="" className="custom-select-sm ml-3">
              <option value={0}>...wählen</option>
              {surveyList.map((survey) => (
                <option key={survey.id} value={survey.id}>
                  {survey.title}
                </option>
              ))}
            </select>
            {selectedUserGroup !== 0 && selectedSurvey !== 0 && (
              <Button onClick={generateTokens} className="ml-5" variant="primary">
                {t('tokens.generate')}
              </Button>
            )}
          </div>
          <hr />

          {selectedSurvey !== 0 && selectedUserGroup !== 0 && generatedTokensExists ? (
            <>
              <Accordion defaultActiveKey="0" className="mt-5">
                <ContextAwareToggle eventKey="0">E-Mail Versand</ContextAwareToggle>
                <Accordion.Collapse eventKey="0">
                  <Mail
                    userGroupId={selectedUserGroup}
                    surveyId={selectedSurvey}
                    selectedTokens={selectedTokens}
                  />
                </Accordion.Collapse>
              </Accordion>

              <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                <Button onClick={tokenAsXLSX} variant="outline-primary">
                  <FontAwesomeIcon icon={faFileExcel} className="h5 m-0" />
                </Button>
                {usersWithoutToken.length > 0 && (
                  <Button variant="danger" onClick={() => setShowModal(true)}>
                    Neue Benutzer gefunden!
                  </Button>
                )}
                <DropdownButton
                  variant="outline-primary"
                  alignRight={true}
                  id="dropdown-basic-button"
                  title="Optionen"
                >
                  <DropdownButton
                    className="submenu"
                    variant="none"
                    drop="left"
                    title="Verlängern"
                    as={'div'}
                  >
                    <DropdownButton className="submenu" title="für Auswahl" drop="left" variant="none">
                      <Dropdown.Item onClick={() => extendValidDateBySelected({ weeks: 1 })}>
                        um eine Woche
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => extendValidDateBySelected({ weeks: 2 })}>
                        um zwei Wochen
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => extendValidDateBySelected({ months: 1 })}>
                        um einen Monat
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <div className="d-flex flex-column p-4">
                        <DatePicker value={extendDate} onChange={(event) => handleDateChange(event)} />
                        <Dropdown.Item>
                          <Button onClick={() => extendValidDateBySelected()}>Verlängern</Button>
                        </Dropdown.Item>
                      </div>
                    </DropdownButton>
                    <DropdownButton title="für Alle" drop="left" variant="none">
                      <Dropdown.Item onClick={() => extendValidDateByAll({ weeks: 1 })}>
                        um eine Woche
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => extendValidDateByAll({ weeks: 2 })}>
                        um zwei Wochen
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => extendValidDateByAll({ months: 1 })}>
                        um einen Monat
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <div className="d-flex flex-column p-4">
                        <DatePicker value={extendDate} onChange={(event) => handleDateChange(event)} />
                        <Dropdown.Item>
                          <Button onClick={() => extendValidDateByAll()}>Verlängern</Button>
                        </Dropdown.Item>
                      </div>
                    </DropdownButton>
                  </DropdownButton>
                  <DropdownButton className="submenu" variant="none" drop="left" title="Löschen">
                    <Dropdown.Item onClick={handleDeleteSelected}>Auswahl löschen</Dropdown.Item>
                    <Dropdown.Item onClick={handleDeleteAll}>Alle löschen</Dropdown.Item>
                  </DropdownButton>
                </DropdownButton>
              </div>
              <table className="table table-sm token" id="tokenTable">
                <colgroup>
                  <col span="1" />
                  <col span="1" />
                  <col span="1" />
                  <col span="1" />
                  <col span="1" />
                  <col span="1" />
                  <col span="1" style={{ width: '50px' }} />
                  <col span="1" />
                  <col span="1" />
                  <col span="1" />
                  <col span="1" />
                </colgroup>
                <thead className="bg-primary text-light">
                  <tr>
                    <th scope="col">
                      <input type="checkbox" onChange={(event) => handleCheckbox(event, null, true)} />
                    </th>
                    <th scope="col">Benutzername</th>
                    <th scope="col">Vorname</th>
                    <th scope="col">Nachname</th>
                    <th scope="col">Firma</th>
                    <th scope="col">Geschlecht</th>
                    <th scope="col">UUID</th>
                    <th scope="col">Topix ID</th>
                    <th scope="col">gültig bis</th>
                    <th>
                      <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    </th>
                    <th scope="col">beantwortet</th>
                  </tr>
                </thead>
                <tbody>
                  {tokensAreLoading === false && tokens?.length > 0 && selectedSurvey !== 0 ? (
                    tokens.map((token) => {
                      const selected = selectedTokens.some((selectedToken) => token.id === selectedToken.id)
                      const mailSentTimes = mail.token_ids?.filter((id) => token.id === id).length || ''
                      return (
                        token.id > 0 && (
                          <tr
                            key={token.id}
                            className={`${selected ? 'selected' : ''} ${
                              token.early_exit ? 'alert-warning' : ''
                            }
                            ${token.answer_count > 0 ? 'alert-success' : ''}
                            `}
                          >
                            <td>
                              <input
                                type="checkbox"
                                onChange={(event) => handleCheckbox(event, token)}
                                checked={selected}
                              />
                            </td>
                            <td>{token.userInfo.username}</td>
                            <td>{token.userInfo.first_name}</td>
                            <td>{token.userInfo.last_name}</td>
                            <td>{token.userInfo.company}</td>
                            <td>{token.userInfo.gender}</td>
                            <td>
                              <span
                              // style={{
                              //   overflow: 'hidden',
                              //   whiteSpace: 'nowrap',
                              //   textOverflow: 'ellipsis',
                              //   maxWidth: '10px',
                              // }}
                              >
                                {token.uuid}
                              </span>
                            </td>
                            <td>{token.userInfo.topix_id}</td>
                            <td>
                              {getStatusIcon(token.valid_until)}
                              {new Date(token.valid_until).toLocaleDateString()}
                            </td>
                            <td>{mailSentTimes}</td>
                            <td>
                              {token.early_exit ? `abgebrochen` : token.answer_count > 0 ? 'Ja' : 'Nein'}
                            </td>
                          </tr>
                        )
                      )
                    })
                  ) : tokensAreLoading ? (
                    <tr>
                      <td colSpan="9" align="middle" className="h2 text-muted">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden"></span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="9" align="middle" className="h2 text-muted">
                        {parseInt(selectedUserGroup) && parseInt(selectedSurvey)
                          ? 'Keine Token vorhanden'
                          : 'Bitte Benutzer-Gruppe und Umfragen wählen'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          ) : !tokensAreLoading ? (
            <div className="d-flex justify-content-center">
              <h2 className="text-muted">
                {selectedSurvey !== 0 && selectedUserGroup !== 0 && !generatedTokensExists
                  ? 'Bitte User-Tokens generieren'
                  : 'Bitte Benutzer-Gruppe und Umfragen wählen'}
              </h2>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
              </Spinner>
            </div>
          )}
          {showModal && (
            <Modal onHide={hideNewUsersModal} minWidth="700px">
              <div className="header">Neue Benutzer</div>
              <div className="body">
                <div>Für folgende Benutzer gibt es keine Tokens:</div>
                <table className="table table-sm token" id="tokenTable">
                  <thead className="bg-primary text-light">
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={(event) => handleAddUserWithoutToken(event, null, true)}
                        />
                      </th>
                      <th>Benutzername</th>
                      <th>Vorname</th>
                      <th>Nachname</th>
                      <th>Firma</th>
                      <th>Geschlecht</th>
                      <th>Topix ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersWithoutToken
                      .filter((user) => user.userInfo.id > 0)
                      .map((user) => {
                        const selected = selectedUsersWithoutToken.some((id) => user.userInfo.id === id)
                        return (
                          <tr key={user.userInfo.id} className={selected ? 'selected' : ''}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(event) => handleAddUserWithoutToken(event, user.userInfo)}
                                checked={selected}
                              />
                            </td>
                            <td>{user.userInfo.username}</td>
                            <td>{user.userInfo.first_name}</td>
                            <td>{user.userInfo.last_name}</td>
                            <td>{user.userInfo.company}</td>
                            <td>{user.userInfo.gender}</td>
                            <td>{user.userInfo.topix_id}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className="footer justify-content-between">
                <Button variant="secondary" onClick={hideNewUsersModal}>
                  Schließen
                </Button>
                <div>
                  <Button
                    className="mr-3"
                    variant="outline-primary"
                    onClick={() => createTokenForSelection(false)}
                  >
                    Auswahl hinzufügen
                  </Button>
                  <Button onClick={() => createTokenForSelection(true)}>Alle hinzufügen</Button>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </Container>
  )
}
