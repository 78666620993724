export const Placeholder = ({ showMap, datasources }) => {
  return (
    <div className="riwisSliderPlaceholder">
      {showMap === true && <div className="mapPlaceholder"></div>}
      {Object.entries(datasources).map(([key]) => {
        return (
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{ flex: '1' }}
            key={key}
          >
            <div className="riwisSliderContainer">
              <div className="riwisQuestionHeaderPlaceholder"></div>
              <div className="cardComparisonPlaceholer"></div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
