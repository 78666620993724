import { useEffect, useState, useMemo } from 'react'
import { Container } from '../../../components/Container'
import { Link } from 'react-router-dom'
import { useUsersAdminDataStore } from './dataStore'
import { Button, Spinner } from 'react-bootstrap'
import { Modal } from '../../../components/Modal'
import { Pagination } from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
export const UserList = () => {
  const [
    { dataIsLoading, userList, userSurveys, searchString },
    { fetchUserList, deleteUser, fetchUserSurvey, setUserSurveys, anonymizeUser, setSearchString },
  ] = useUsersAdminDataStore()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)

  const handleCloseWarning = () => {
    setUserSurveys(null)
    setUserToDelete(null)
    setShowDeleteModal(false)
  }
  const handleShowWarning = (user) => {
    fetchUserSurvey(user.id)
    setUserToDelete(user)
    setShowDeleteModal(true)
  }

  const handleDeleteUser = () => {
    deleteUser(userToDelete.id)
    handleCloseWarning()
  }

  const handleAnonymizeUser = () => {
    anonymizeUser(userToDelete.id)
    handleCloseWarning()
  }

  const handleSearchChange = (event) => {
    setSearchString(event.target.value)
  }

  const searchResults = useMemo(() => {
    if (!searchString.length) return []
    let valueRegex = null
    const splittedValueRegex = []
    const splittedValue = searchString.split(' ')
    try {
      valueRegex = new RegExp(searchString, 'gmi')
      if (splittedValue.length >= 2) {
        splittedValueRegex[0] = new RegExp(splittedValue[0], 'gmi')
        splittedValueRegex[1] = new RegExp(splittedValue[1], 'gmi')
      }
    } catch (e) {
      console.warn(e.message)
    }
    return userList.filter((user) => {
      if (
        (splittedValue.length > 1 &&
          user?.first_name?.match(splittedValueRegex[0]) &&
          splittedValue.length > 1 &&
          user?.last_name?.match(splittedValueRegex[1])) ||
        (splittedValue.length > 1 &&
          user?.first_name?.match(splittedValueRegex[1]) &&
          splittedValue.length > 1 &&
          user?.last_name?.match(splittedValueRegex[0]))
      ) {
        return true
      } else if (
        user?.username.match(valueRegex) ||
        user?.first_name?.match(valueRegex) ||
        user?.last_name?.match(valueRegex) ||
        user?.company?.match(valueRegex) ||
        user?.email?.match(valueRegex) ||
        user?.zip?.match(valueRegex) ||
        user?.city?.match(valueRegex) ||
        user?.id?.toString().match(valueRegex)
      ) {
        return true
      }
      return false
    })
  }, [searchString, userList])

  // create pages of userlist
  const userPages = useMemo(() => {
    const userPages = []
    const itemsPerPage = 50
    if (!searchString.length && userList) {
      const maxPages = Math.floor(userList.length / itemsPerPage)
      for (let i = 0; i < maxPages; i++) {
        userPages.push(userList.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage))
      }
    } else if (userList && searchString.length) {
      const maxPages = Math.floor(searchResults.length / itemsPerPage) || 1
      for (let i = 0; i < maxPages; i++) {
        userPages.push(searchResults.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage))
      }
    }
    return userPages
  }, [userList, searchString, searchResults])

  useEffect(() => {
    if (!userList) {
      fetchUserList()
    }
  }, [fetchUserList, userList])

  return (
    <Container>
      {dataIsLoading === false && userList && (
        <>
          <div className="row">
            <div className="col">
              <h3>User-Liste</h3>
            </div>
            <div className="col text-right">
              <Link to="/admin/user/0" className="btn btn-outline-primary">
                Neuer User
              </Link>
            </div>
          </div>
          <hr />
          <div className="form-group d-flex align-items-center mb-4 mt-4">
            <label className="p-0 m-0">Suche:</label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="text"
                name="last_name"
                onChange={(event) => handleSearchChange(event)}
                value={searchString}
              ></input>
            </div>
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Benutzername</th>
                <th scope="col">Nachname</th>
                <th scope="col">Vorname</th>
                <th scope="col">Firma</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            {userList?.length > 0 && (
              <tbody>
                {currentPage !== null &&
                  currentPage.map((user) => (
                    <tr key={user.id}>
                      <th scope="row">{user.id}</th>
                      <td>
                        <Link to={`/admin/user/${user.id}`}>{user.username}</Link>
                      </td>
                      <td>{user.last_name}</td>
                      <td>{user.first_name}</td>
                      <td>{user.company}</td>
                      <td>
                        <button className="btn btn-outline-none p-0" onClick={() => handleShowWarning(user)}>
                          <FontAwesomeIcon icon={faTrashAlt} className="trashCan" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
          <Pagination pages={userPages} pageChange={setCurrentPage} />
          {showDeleteModal === true && (
            <Modal onHide={handleCloseWarning} minWidth={'700px'}>
              <div className="header">Benutzer Löschen</div>
              <div className="body">
                <div>Möchten Sie den Benutzer {userToDelete?.username} löschen?</div>
                <div className="my-4 d-flex flex-column">
                  <b className="mb-4">
                    Anonymisieren: Die Kontaktdaten des Benutzers werden gelöscht. <br />
                    <FontAwesomeIcon icon={faArrowRight} /> Antworten bleiben erhalten.
                  </b>
                  <b className="text-danger">
                    Löschen: Der Benutzer wird vollständig gelöscht. <br />
                    <FontAwesomeIcon icon={faArrowRight} />
                    <span style={{ textDecoration: 'underline' }}>Alle Antworten werden gelöscht!</span>
                  </b>
                </div>
                {userSurveys !== null ? (
                  userSurveys.length > 0 ? (
                    <div>
                      <div>
                        <b>Der Benutzer hat an folgenden Umfragen teilgenommen: </b>
                      </div>
                      <table className="table table-sm mt-4">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Titel</th>
                            <th scope="col">Erstellungsdatum</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userSurveys.map((survey) => (
                            <tr key={survey.id}>
                              <th scope="row">{survey.id}</th>
                              <td>{survey.title}</td>
                              <td>{new Date(survey.create_date).toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <>
                      <div>Der Benutzer hat an keinen Umfragen teilgenommen</div>
                    </>
                  )
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="footer justify-content-between">
                <Button variant="secondary" onClick={handleCloseWarning}>
                  Abbrechen
                </Button>
                <div>
                  <Button variant="primary" onClick={handleAnonymizeUser}>
                    Anonymisieren
                  </Button>
                  <Button variant="danger" className="ml-3" onClick={handleDeleteUser}>
                    Löschen
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </Container>
  )
}
