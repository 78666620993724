import React, { useCallback, useMemo, useState } from 'react'
import { NoAnswerCheckbox } from '../QuestionnaireBuilder/Components/NoAnswerCheckbox'

export const Sum = ({ id, qid, answers, previousAnswer, noAnswer, value = {}, maxValue, unit, onChange }) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)

  const handleChange = useCallback(
    (event) => {
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }

      if (event.target.type === 'checkbox') {
        newValue.noAnswer = event.target.checked
      } else {
        const previousValue = newValue[event.target.name] || 0
        newValue[event.target.name] = event.target.value

        const sum = Object.values(newValue).reduce((acc, value) => acc + Number(value), 0)
        if (sum > maxValue) {
          newValue[event.target.name] = previousValue
        }
      }
      setLocalPreviousAnswer(null)
      onChange({ question_id: id, value: newValue, qid })
    },
    [value, onChange, id, qid, maxValue, localPreviousAnswer]
  )

  const inputValue = useMemo(() => {
    let inputValue = { ...value }
    if (!Object.keys(value).length) {
      if (localPreviousAnswer && localPreviousAnswer?.noAnswer) {
        inputValue = { noAnswer: localPreviousAnswer?.noAnswer || false }
      } else if (localPreviousAnswer && Object.keys(localPreviousAnswer).length) {
        inputValue = { ...localPreviousAnswer }
      }
    }
    return inputValue
  }, [value, localPreviousAnswer])

  const noAnswerValue = useMemo(() => {
    let checked = false
    if (typeof value.noAnswer !== 'undefined') {
      checked = value.noAnswer
    } else if (localPreviousAnswer && typeof localPreviousAnswer.noAnswer !== 'undefined') {
      checked = localPreviousAnswer.noAnswer
    }
    return checked
  }, [value, localPreviousAnswer])

  const sum = useMemo(() => {
    return inputValue.noAnswer
      ? '--'
      : Object.values(inputValue).reduce((accu, value) => accu + Number(value), 0)
  }, [inputValue])

  return (
    <div>
      <table className="sumTable">
        <thead></thead>
        <tbody>
          <tr>
            <th style={{ padding: '14px 0' }}>Summe</th>
            <td>
              <div className="input-group">
                <input
                  readOnly
                  className={
                    (Number(sum) === Number(maxValue) ? 'text-success' : 'text-danger') +
                    ' form-control col-12'
                  }
                  type="text"
                  id="sumUp"
                  value={sum + '/' + Number(maxValue) || ''}
                  aria-describedby="input-unit"
                ></input>
                <div className="input-group-append">
                  <span className="input-group-text" id="input-unit">
                    {unit}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ borderTop: '1px solid grey', padding: '6px' }}></td>
            <td style={{ borderTop: '1px solid grey', padding: '6px' }}></td>
          </tr>
          {answers.map((answer) => (
            <SumInput
              key={answer.id}
              answer={answer}
              id={id}
              handleChange={handleChange}
              value={inputValue}
              unit={unit}
            />
          ))}
        </tbody>
      </table>
      <NoAnswerCheckbox noAnswer={noAnswer} checked={noAnswerValue} questionId={id} onChange={handleChange} />
    </div>
  )
}

const SumInput = ({ answer, id, handleChange, value, unit }) => {
  const elementId = id + '_' + answer.id
  return (
    <tr key={elementId}>
      <th dangerouslySetInnerHTML={{ __html: answer.text }} style={{ padding: '14px 0' }}></th>
      <td>
        <div className="input-group flex-nowrap">
          <input
            className="form-control col-12"
            type="number"
            name={answer.id}
            id={elementId}
            onChange={handleChange}
            value={value[answer.id] || ''}
            aria-describedby="input-unit"
            min="0"
            disabled={value.noAnswer || false}
          ></input>
          <div className="input-group-append">
            <span className="input-group-text" id="input-unit">
              {unit}
            </span>
          </div>
        </div>
      </td>
    </tr>
  )
}
