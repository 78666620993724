import React from 'react'
import { ListBox } from './Components/ListBox'

const defaultContactData = [
  { id: 1, text: 'Nachname', required: true, dataType: 'text', line: 1 },
  { id: 2, text: 'Vorname', required: false, dataType: 'text', line: 1 },
  { id: 3, text: 'Firma', required: true, dataType: 'text', line: 2 },
  { id: 4, text: 'E-Mail', required: true, dataType: 'text', line: 3 },
  { id: 5, text: 'Telefon', required: false, dataType: 'text', line: 3 },
]

export const ContactForm = ({ question, questionnaireIndex, pageIndex, index }) => {
  return (
    <ListBox
      label={'Fragen'}
      name="questions_field"
      unit={question.unit}
      dataType={question.data_type}
      items={question.questions_field}
      initialItems={defaultContactData}
      index={index}
      pageIndex={pageIndex}
      questionnaireIndex={questionnaireIndex}
    />
  )
}
