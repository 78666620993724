import React, { useCallback } from 'react'
import { ListBox } from './Components/ListBox'
import { useSurveyEditorStateStore } from '../surveyEditorState'
import { maxId } from '../utils'
import { JSONTextarea } from '../JSONTextarea'

// determine current highest id with an array reducer function
// const maxId = (currentMax, current) => (current.id > currentMax.id ? current : currentMax)

export const Dropdown = ({ question, questionnaireIndex, pageIndex, index }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()
  const addDropdown = useCallback(() => {
    const newQuestionsField = [...question.questions_field]
    const currentMaxId = newQuestionsField.reduce(maxId, { id: 0 }).id
    const newDropdown = {
      id: currentMaxId + 1,
      items: [],
      text: '',
      style: {
        width: '100%',
      },
      noSelectionText: '',
      required: false,
      // id of another dropdown that is required to be answered to enable current dropdown
      requires: null,
    }
    newQuestionsField.push(newDropdown)
    setQuestionInput(questionnaireIndex, pageIndex, index, newQuestionsField, 'questions_field')
  }, [index, pageIndex, question.questions_field, questionnaireIndex, setQuestionInput])

  const onAddDropdownItem = useCallback(
    (questionnaireIndex, pageIndex, index, items, name) => {
      const newQuestionsField = [...question.questions_field]
      const editedIndex = newQuestionsField.findIndex((dropdown) => dropdown.id === Number(name))
      const dropdownToEdit = newQuestionsField.find((dropdown) => dropdown.id === Number(name))
      const newDropdown = { ...dropdownToEdit }
      newDropdown.items = [...items]
      newQuestionsField[editedIndex] = newDropdown

      setQuestionInput(questionnaireIndex, pageIndex, index, newQuestionsField, 'questions_field')
    },
    [question.questions_field, setQuestionInput]
  )

  const handleDropdownLabelChange = useCallback(
    (evt) => {
      const newQuestionsField = [...question.questions_field]
      const editedIndex = newQuestionsField.findIndex((dropdown) => dropdown.id === Number(evt.target.name))
      const newDropdown = { ...newQuestionsField[editedIndex] }
      newDropdown.text = evt.target.value
      newQuestionsField[editedIndex] = newDropdown
      setQuestionInput(questionnaireIndex, pageIndex, index, newQuestionsField, 'questions_field')
    },
    [index, pageIndex, question.questions_field, questionnaireIndex, setQuestionInput]
  )

  const handleJSONEdit = useCallback(
    (evt) => {
      const newQuestionsField = [...question.questions_field]
      const newDropdown = evt.target.value
      const editedIndex = newQuestionsField.findIndex((dropdown) => dropdown.id === evt.target.value.id)
      newQuestionsField[editedIndex] = newDropdown
      setQuestionInput(questionnaireIndex, pageIndex, index, newQuestionsField, 'questions_field')
    },
    [index, pageIndex, question.questions_field, questionnaireIndex, setQuestionInput]
  )

  return (
    <div stlye={{ display: 'flex', flexDirection: 'column' }}>
      {question.questions_field.map((dropdown) => (
        <div key={dropdown.id} stlye={{ display: 'flex', flexDirection: 'column' }}>
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">ID: {dropdown.id}</label>
            <div className="col-sm-10">
              <input
                name={dropdown.id}
                className="form-control"
                placeholder={`Dropdown #${dropdown.id} Label`}
                onChange={handleDropdownLabelChange}
                value={dropdown.text}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right"></label>
            <div className="col-sm-10">
              <JSONTextarea showWithinAccordion={true} json={dropdown} onChange={handleJSONEdit} />
            </div>
          </div>
          <ListBox
            name={dropdown.id}
            unit={question.unit}
            dataType={question.data_type}
            items={dropdown.items}
            initialItems={[]}
            index={index}
            pageIndex={pageIndex}
            questionnaireIndex={questionnaireIndex}
            customChangeHandler={onAddDropdownItem}
          />
        </div>
      ))}
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right"></label>
        <div className="col-sm-10">
          <button
            type="button"
            className="btn-sm btn-outline-secondary mt-3 text-primary"
            onClick={addDropdown}
          >
            <b>Dropdown hinzufügen</b>
          </button>
        </div>
      </div>
    </div>
  )
}

// question.question_field = [
//     dropdown_1 : {
// id:
//     values:    {
//             id: ...,
//             text: 'Jahr'
//             ....
//         }
//     },
//     dropdown_2 :[
//         {
//             id: ...,
//             text: 'Jahr'
//             ....
//         }
//     ]
// ]
