import React, { useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'

// importing the plugin js.
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/help'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/imagetools'
import '../../../../assets/js/tinymce/langs/de'

// // Content styles, including inline UI like fake cursors
// /* eslint import/no-webpack-loader-syntax: off */
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css'
// import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'

const inlineOptions = {
  branding: false,
  menubar: false,
  language: 'de',
  paste_as_text: true,
  // image_upload_url: '/api/upload/image',
  file_picker_types: 'image',
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste imagetools wordcount',
  ],
  toolbar:
    'undo redo | bold italic | | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
  content_css: '/css/custom.css',
  content_style: 'body { font-family: "Open Sans", Helvetica, sans-serif; }',
  init_instance_callback: (editor) => {
    // editor.setContent(this.props.content || '')
    // this.registerEventListeners(editor)
    // this.setState({
    //   editor,
    // })
  },
  file_picker_callback: function (callback, value, meta) {
    let input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.onchange = function () {
      let file = this.files[0]

      let reader = new FileReader()
      reader.onload = function () {
        // todo
        let id = 'blobid' + new Date().getTime()
        let blobCache = tinymce.activeEditor.editorUpload.blobCache
        let base64 = reader.result.split(',')[1]
        let blobInfo = blobCache.create(id, file, base64)
        blobCache.add(blobInfo)
        /* call the callback and populate the Title field with the file name */
        callback(blobInfo.blobUri(), { title: file.name })
      }
      reader.readAsDataURL(file)
    }

    input.click()
  },
}

const tinyOptions = {
  selector: '#surveyDescription',
  branding: false,
  menubar: false,
  language: 'de',
  // image_upload_url: '/api/upload/image',
  file_picker_types: 'image',
  file_picker_callback: function (callback, value, meta) {
    let input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.onchange = function () {
      let file = this.files[0]

      let reader = new FileReader()
      reader.onload = function () {
        // todo
        let id = 'blobid' + new Date().getTime()
        let blobCache = tinymce.activeEditor.editorUpload.blobCache
        let base64 = reader.result.split(',')[1]
        let blobInfo = blobCache.create(id, file, base64)
        blobCache.add(blobInfo)
        /* call the callback and populate the Title field with the file name */
        callback(blobInfo.blobUri(), { title: file.name })
      }
      reader.readAsDataURL(file)
    }

    input.click()
  },
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste imagetools wordcount',
  ],
  toolbar:
    'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code',
  content_css: '/css/custom.css',
  content_style: 'body { font-family: "Open Sans", Helvetica, sans-serif; }',
  // init_instance_callback: (editor) => {
  //   this.registerEventListeners(editor)
  //   this.setState({
  //     editor,
  //   })
  // },
}

export const TinyMCE = ({
  inlineMode = false,
  id,
  content,
  handleTinyMCEChange,
  name,
  toolbar,
  customButtons,
  convertUrls = false,
  relativeUrls = false,
  disabled,
}) => {
  const editorRef = useRef(null)
  const [options] = useState(() => {
    const options = inlineMode ? inlineOptions : tinyOptions
    options.toolbar = toolbar || options.toolbar
    options.relative_urls = relativeUrls
    options.convert_urls = convertUrls

    customButtons?.forEach((customButton) => {
      options.toolbar += `${customButton.pre} ${customButton.buttonName}`
    })
    // add custom buttons to toolbar
    options.setup = (editor) => {
      customButtons?.forEach((customButton) => {
        editor.ui.registry.addButton(customButton.buttonName, {
          text: customButton.text,
          onAction: (_) => customButton.onAction(editor),
        })
      })
    }
    return options
  })

  const handleInputChange = (value) => {
    handleTinyMCEChange({ value, name })
  }

  return (
    <Editor
      onInit={(evt, editor) => {
        if (inlineMode) {
          editor.targetElm.classList.add('form-control')
          editor.targetElm.classList.add('tinyMCEInline')
        }
        editorRef.current = editor
        return editorRef
      }}
      // initialValue={getValue}
      value={content}
      init={options}
      inline={inlineMode}
      id={'#' + id}
      onEditorChange={handleInputChange}
      disabled={disabled}
    />
  )
}
