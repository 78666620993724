import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { useEffect, useState } from 'react'

export const LeafletMap = ({ center, children }) => {
  return (
    <MapContainer
      style={{ height: '100%', width: '100%' }}
      center={center}
      zoom={13}
      zoomSnap={0.1}
      scrollWheelZoom={false}
      zoomAnimation={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        opacity={1}
        className="mapLowSaturation"
      />
      {children}
    </MapContainer>
  )
}

export const GeoJSON = ({ data, onEachFeature, style, zoomOut }) => {
  const map = useMap()
  const [layer] = useState(
    L.geoJSON(null, {
      onEachFeature,
      style,
    })
  )

  useEffect(() => {
    if ((Array.isArray(data) && data.length) || data?.type === 'Feature') {
      layer.clearLayers()
      layer.addData(data)
    }
  }, [data, layer])

  useEffect(() => {
    if (layer.getLayers().length) {
      map.addLayer(layer)
      map.fitBounds(layer.getBounds())
      if (zoomOut !== null && !isNaN(zoomOut) && zoomOut !== 0) {
        map.zoomOut(zoomOut)
      }
    }
  }, [layer, map, data, zoomOut])

  return <></>
}

// export const VectorTileLayer = ({ url }) => {
//   const [layer, setLayer] = useState(null)
//   const map = useMap()
//   useEffect(() => {
//     setLayer(
//       L.vectorGrid.protobuf(url, {
//         maxNativeZoom: 14,
//         credentials: 'include',
//         getFeatureId: (f) => f.properties.gid,
//       })
//     )
//   }, [url])

//   useEffect(() => {
//     if (layer) {
//       map.addLayer(layer)
//     }
//   }, [map, layer])

//   return <></>
// }
