import React, { useState, useCallback } from 'react'
import { generateId, findLastNonDeleted } from './utils'
import { Constraints } from './constraintBox'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import { TabContainer } from './TabContainer'
import { QuestionSwitch } from './QuestionSwitch'
import { FormRow, SurveySection } from './Components'
import { IdLabel } from '../../../../components/Labels'

const SingleQuestion = ({
  pageId,
  question,
  index,
  survey,
  questionnaireId,
  questionnaireIndex,
  pageIndex,
  assetClass,
  handleNoAnswerChange,
  handleCustomCheckboxChange,
  handleTinyMCEChange,
  handleConstraintsChange,
  handleInputChange,
  handleSettingsChange,
}) => {
  return (
    <div className="mt-4 flex-1">
      <div className="form-group row mb-0">
        <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
        <div className="col-sm-10">
          <IdLabel>ID: {question.id}</IdLabel>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
        <div className="col-sm-10">
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              name="is_optional"
              type="checkbox"
              readOnly
              checked={question?.is_optional || false}
            ></input>
            <label
              className="custom-control-label"
              onClick={() => handleCustomCheckboxChange(question.qid, question, 'is_optional')}
            >
              optional
            </label>
          </div>
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              name="show_question_number"
              type="checkbox"
              readOnly
              checked={question?.show_question_number ?? survey?.settings?.showQuestionNo ?? true}
            ></input>
            <label
              className="custom-control-label"
              onClick={() => handleCustomCheckboxChange(question.qid, question, 'show_question_number')}
            >
              Frage-Nr. anzeigen
            </label>
          </div>
          {survey.type === 'bfQuarter' && (
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                name="loadPreviousAnswers"
                id="loadPreviousAnswers"
                type="checkbox"
                readOnly
                checked={Boolean(question?.settings?.loadPreviousAnswers)}
              ></input>
              <label
                htmlFor="loadPreviousAnswers"
                name="loadPreviousAnswers"
                className="custom-control-label"
                onClick={(event) => handleSettingsChange(question.qid, question, event)}
              >
                vorherige Antworten laden
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">Frage</label>
        <div className="col-sm-10">
          <TinyMCE
            id={`questionLabel-${questionnaireId}-${pageId}-${question.id}`}
            name={'label'}
            content={question.label}
            inlineMode={true}
            handleTinyMCEChange={(event) => handleTinyMCEChange(question.qid, event)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">Beschreibung</label>
        <div className="col-sm-10">
          <TinyMCE
            id={`questionDescription-${questionnaireId}-${pageId}-${question.id}`}
            name={'description'}
            content={question.description}
            inlineMode={true}
            handleTinyMCEChange={(event) => handleTinyMCEChange(question.qid, event)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">Definition</label>
        <div className="col-sm-10">
          <TinyMCE
            id={`questionDefinition-${questionnaireId}-${pageId}-${question.id}`}
            name={'definition'}
            content={question.definition}
            inlineMode={true}
            handleTinyMCEChange={(event) => handleTinyMCEChange(question.qid, event)}
            toolbar={
              'undo redo | bold italic |  forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code | image'
            }
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">Bedingung</label>
        <div className="col py-0">
          <Constraints
            constraints={question.constraints || undefined}
            onChange={(constraints) => handleConstraintsChange(question.qid, constraints)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">Typ</label>
        <div className="col-sm-10">
          <select
            className="form-control"
            name="type"
            value={question.type || 'radio'}
            onChange={(event) => handleInputChange(question.qid, event)}
          >
            <option value="radio">Radio</option>
            <option value="radioInline">Radio (in Reihe)</option>
            <option value="radioMatrix">Radio (Matrix)</option>
            <option value="checkbox">Checkbox</option>
            <option value="checkboxMatrix">Checkbox (Matrix)</option>
            <option value="dropdown">Dropdown</option>
            <option value="text">Text</option>
            <option value="inputColumns">mehrere Texte</option>
            <option value="textarea">Textfeld</option>
            <option value="slider">Slider</option>
            {survey.type === 'riwis' && <option value="sliderRiwis">RIWIS Slider</option>}
            {survey.type === 'riwis' && <option value="radioMatrixRiwis">RIWIS Radio Matrix</option>}
            {survey.type === 'riwis' && <option value="radioInlineRiwis">RIWIS Radio (in Reihe)</option>}
            <option value="sum">Summen-Abfrage</option>
            <option value="contactForm">Kontaktformular</option>
          </select>
        </div>
      </div>
      <QuestionSwitch
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        assetClass={assetClass}
      />
      {survey.type !== 'riwis' && (
        <FormRow>
          <FormRow.Label>weitere Antwortmöglichkeit</FormRow.Label>
          <FormRow.Content>
            <div className="border rounded p-3 bg-light">
              <div className="custom-control custom-checkbox mb-4">
                <input
                  className="custom-control-input"
                  name="no_answer"
                  id="no_answer"
                  type="checkbox"
                  onChange={(event) => handleNoAnswerChange(question.qid, event)}
                  checked={!!question?.no_answer?.showNoAnswer || false}
                />
                <label className="custom-control-label" htmlFor="no_answer">
                  keine Antwort
                </label>
              </div>
              <input
                className="form-control"
                name="no_answer_title"
                value={question?.no_answer?.title || ''}
                disabled={!!question?.no_answer?.showNoAnswer === false}
                onChange={(event) => handleNoAnswerChange(question.qid, event)}
              ></input>
            </div>
          </FormRow.Content>
        </FormRow>
      )}
    </div>
  )
}

export const Question = ({
  questions,
  questionnaireId,
  questionnaireIndex,
  pageId,
  pageIndex,
  assetClass,
}) => {
  const [
    { survey, questionTab },
    { setQuestionInput, addQuestion, deleteQuestion, setQuestionTab, changeQuestionOrder },
  ] = useSurveyEditorStateStore()
  const [newId, setNewId] = useState(0)

  const handleInputChange = useCallback(
    (qid, event) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, event.target.value, event.target.name)
    },
    [pageIndex, questionnaireIndex, questions, setQuestionInput]
  )

  const handleTinyMCEChange = useCallback(
    (qid, event) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, event.value, event.name)
    },
    [pageIndex, questionnaireIndex, questions, setQuestionInput]
  )

  const handleConstraintsChange = useCallback(
    (qid, constraints) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, constraints, 'constraints')
    },
    [pageIndex, questionnaireIndex, questions, setQuestionInput]
  )

  const handleNoAnswerChange = useCallback(
    (qid, event) => {
      const index = questions.findIndex((question) => question.qid === qid)
      const question = questions[index]
      const newNoAnswerSettings = question.no_answer
        ? { ...question.no_answer }
        : { showNoAnswer: false, title: '', id: -1 }

      if (event.target.type === 'checkbox') {
        newNoAnswerSettings.showNoAnswer = event.target.checked
        if (event.target.checked && !newNoAnswerSettings.title) {
          newNoAnswerSettings.title = 'keine Antwort'
        }
      }

      if (event.target.type === 'text') {
        newNoAnswerSettings.title = event.target.value
      }

      setQuestionInput(questionnaireIndex, pageIndex, index, newNoAnswerSettings, 'no_answer')
    },
    [questions, setQuestionInput, questionnaireIndex, pageIndex]
  )

  const handleNewQuestionClick = useCallback(() => {
    const qid = generateId(6)
    const initialProperties = {
      id: newId,
      qid,
      is_optional: false,
      title: 'Titel 0',
      type: 'text',
      label: '',
      description: '',
      definition: '',
      possible_answers: [],
      questions_field: [],
      min_value: null,
      max_value: null,
      default_value: null,
      order: questions.length + 1,
      unit: '',
      step: 1,
      with_decimal: null,
      constraints: [],
      limit: null,
      riwis_datasources: [],
      opt_out: false,
      data_type: 'text',
      one_answer: false,
      matrix_answer_header: '',
      matrix_question_header: '',
      show_map: false,
      no_answer: null,
      show_question_number: null,
      settings: {
        loadPreviousAnswers: survey.type === 'bfQuarter',
      },
    }
    addQuestion(questionnaireIndex, pageIndex, initialProperties)
    setNewId(newId - 1)
    setQuestionTab(questions.length)
  }, [addQuestion, newId, pageIndex, questionnaireIndex, questions, setQuestionTab, survey])

  const handleDeleteQuestionClick = useCallback(
    (index) => {
      if (questionTab === index) {
        setQuestionTab(findLastNonDeleted(index, questions))
      } else if (questionTab - 1 > 0) {
        setQuestionTab(questionTab - 1)
      }
      deleteQuestion(questionnaireIndex, pageIndex, index, true, 'delete')
    },
    [questions, deleteQuestion, pageIndex, questionnaireIndex, questionTab, setQuestionTab]
  )

  const handleCustomCheckboxChange = useCallback(
    (qid, question, name) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, !question[name], name)
    },
    [pageIndex, questionnaireIndex, setQuestionInput, questions]
  )

  // TODO: move all available settings in to the settings object instead of having a column for each setting in the db
  const handleSettingsChange = useCallback(
    (qid, question, event) => {
      let key = event.target.name
      let value = event.target.value
      if (event.target.classList.contains('custom-control-label')) {
        const inputOfLabel = Array.from(event.target.parentNode.children).find(
          (element) => element.id === event.target.htmlFor
        )
        if (inputOfLabel) {
          key = inputOfLabel.name
          if (inputOfLabel.type === 'checkbox') {
            value = !inputOfLabel.checked
          }
        }
      }
      const index = questions.findIndex((question) => question.qid === qid)
      const settings = { ...question.settings }
      settings[key] = value
      setQuestionInput(questionnaireIndex, pageIndex, index, settings, 'settings')
    },
    [pageIndex, questionnaireIndex, setQuestionInput, questions]
  )

  const updateOrder = (questions) => {
    changeQuestionOrder(questionnaireIndex, pageIndex, questions)
  }

  return (
    <SurveySection>
      <div className="d-flex align-items-center">
        <h3>Fragen auf Seite {pageIndex + 1}</h3>
        <button type="button" className="btn-sm btn-outline-primary ml-5" onClick={handleNewQuestionClick}>
          <small>Neue Frage</small>
        </button>
      </div>
      <hr></hr>
      <div className="d-flex flex-row">
        {questions.length > 0 && (
          <div className="d-flex flex-column mr-5">
            <TabContainer
              tabs={questions}
              updateOrder={updateOrder}
              activeTab={questionTab}
              setActiveTab={setQuestionTab}
              handleDelete={handleDeleteQuestionClick}
              tabWidth={'160px'}
              flexDirection={'column'}
              type="question"
              itemType="questionTab"
              acceptedItemTypes={['questionTab']}
            />
          </div>
        )}
        {questions[questionTab] && !questions[questionTab]?.deleted ? (
          <SingleQuestion
            question={questions[questionTab]}
            index={questionTab}
            pageId={pageId}
            survey={survey}
            questionnaireId={questionnaireId}
            questionnaireIndex={questionnaireIndex}
            pageIndex={pageIndex}
            assetClass={assetClass}
            handleNoAnswerChange={handleNoAnswerChange}
            handleCustomCheckboxChange={handleCustomCheckboxChange}
            handleTinyMCEChange={handleTinyMCEChange}
            handleConstraintsChange={handleConstraintsChange}
            handleInputChange={handleInputChange}
            handleSettingsChange={handleSettingsChange}
          />
        ) : (
          ''
        )}
      </div>
    </SurveySection>
  )
}
