// prevents ios from zooming to input on inputs with font-sizes smaller than 16px

import { useEffect, useMemo } from 'react'

// const events = ['focus', 'blur']

export const usePreventIOSInputZoom = () => {
  const isIOS = useMemo(
    () =>
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),
    []
  )

  useEffect(() => {
    if (isIOS) {
      const viewportMeta = document.querySelector('meta[name="viewport"]')
      const value = `width=device-width, initial-scale=1, maximum-scale=1`
      viewportMeta.setAttribute('content', value)
    }
  }, [isIOS])

  // old approach - setting viewport maximum-scale only on focus - did not work
  // const listenerFunction = useCallback((event) => {
  //   const viewportMeta = document.querySelector('meta[name="viewport"]')
  //   const value = `width=device-width, initial-scale=1, maximum-scale=1`
  //   viewportMeta.setAttribute('content', value)
  // }, [])

  // useEffect(() => {
  //   if (!isIOS || true) {
  //     document.querySelectorAll('input, select, textarea').forEach((element) => {
  //       events.forEach((event) => element.addEventListener(event, listenerFunction))
  //     })

  //     return () => {
  //       document.querySelectorAll('input, select, textarea').forEach((element) => {
  //         events.forEach((event) => element.removeEventListener(event, listenerFunction))
  //       })
  //     }
  //   }
  // }, [listenerFunction, isIOS])
}
