import { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { useSurveyDataStore } from '../dataStore'
import bgagColors from '../../../config/bgag-colors.json'
import { LocationPin } from '../../../components/LocationPin'
import { CitySelectWidget } from './CitySelectWidget'
import { RiwisRegistrationIntro } from '../../Registration/Riwis/RiwisRegistrationIntro'

export const RiwisOpener = ({ survey, handleCurrentQuestionaire }) => {
  const [{ riwisCities, answeredCities, selectedCity }, { fetchRiwisCities }] = useSurveyDataStore()
  const [hideRiwisIntro, setHideRiwisIntro] = useState(() => {
    return document.cookie.includes('hideRiwisIntro2024=true')
  })
  const questionnaireContainerRef = useRef(null)

  const answers = useMemo(() => {
    const arr = []
    if (survey && survey.questionnaires)
      survey.questionnaires.forEach((questionnaire) => {
        if (questionnaire.pages)
          questionnaire.pages.forEach((page) => {
            if (page.questions)
              page.questions.forEach((question) => {
                arr.push(question.given_answer || { question_id: question.id, value: { value: '' } })
              })
          })
      })
    return arr
  }, [survey])

  const checkConstraints = useCallback(
    (constraints) => {
      return true
      // if (!constraints) return true

      // const constraint = constraints // shall be an array in future
      // const foundAnswer = answers.find((answer) => answer.qid === constraint.when)

      // const contains = (container, value) => {
      //   // eslint-disable-next-line
      //   return Array.isArray(container) ? container.some((item) => item == value) : container == value
      // }

      // if (foundAnswer) {
      //   return (
      //     foundAnswer.value && foundAnswer.value.value && contains(foundAnswer.value.value, constraint.has)
      //   )
      // } else {
      //   return false
      // }
    },
    [answers]
  )

  const renderSingleQuestionnaire = useCallback(
    (questionnaire, index) => {
      let answeredCityAsset = null
      if (answeredCities && selectedCity) {
        answeredCityAsset = answeredCities.find(
          (city) => city.generic_area_code === selectedCity.gac && questionnaire.id === city.questionnaire_id
        )
      }

      return (
        <div className="riwisQuestionnaireWidget" key={questionnaire.id}>
          <div className="d-flex flex-column justify-content-between flex-1">
            <div>
              <div className="questionnaireWidgetMeta">
                <div>Fragebogen {'#' + (index + 1)}</div>
                {/* <div>{numPagesText}</div> */}
              </div>
              <h4 className="text-primary mb-4">{questionnaire.title}</h4>
              <div
                className="questionnaireWidgetBody"
                dangerouslySetInnerHTML={{ __html: questionnaire.subtitle }}
              ></div>
            </div>
            <div className="d-flex flex-column align-items-center">
              {answeredCityAsset && answeredCityAsset?.answer_count !== null && (
                <h6 className="mb-3" style={{ color: '#002940' }}>
                  {answeredCityAsset?.answer_count} / {answeredCityAsset?.question_count} Fragen beantwortet
                </h6>
              )}
              <button
                onClick={() => {
                  handleCurrentQuestionaire(questionnaire.id)
                }}
                className="btn btn-light btn-white w-100"
              >
                {answeredCityAsset?.answer_count ? 'BEARBEITEN' : 'BEANTWORTEN'}
              </button>
            </div>
          </div>
        </div>
      )
    },
    [handleCurrentQuestionaire, selectedCity, answeredCities]
  )

  useEffect(() => {
    if (!riwisCities) {
      fetchRiwisCities(survey.id)
    }
  }, [riwisCities, fetchRiwisCities, survey])

  return !hideRiwisIntro ? (
    <RiwisRegistrationIntro setHideRiwisIntro={setHideRiwisIntro} />
  ) : riwisCities !== null && answeredCities ? (
    <div className={`openerWindow ${survey.type}`}>
      <div className="openerIntroductionBG">
        <div className="openerIntroductionRiwis openerIntroduction">
          <h1>{survey.title}</h1>
          <h2>{survey.subtitle}</h2>
          <div className="openerDescription" dangerouslySetInnerHTML={{ __html: survey.description }}></div>
        </div>
      </div>
      <div className="d-flex flex-wrap">
        <CitySelectWidget survey={survey} riwisCities={riwisCities} answeredCities={answeredCities} />
        {survey && survey.questionnaires && selectedCity && (
          <div className="d-flex flex-column" style={{ flex: '1', height: '100%' }}>
            <div className="cityNameHeader">{selectedCity.name}</div>
            <div id="qContainer" className="riwisQuestionnaires" ref={questionnaireContainerRef}>
              {survey.questionnaires.map(
                (q, index) => checkConstraints(q.constraints) && renderSingleQuestionnaire(q, index)
              )}
            </div>
          </div>
        )}
        {!selectedCity && (
          <div className="noCitySelectionContainer">
            <div className="content">
              <LocationPin selected={true} color={bgagColors['bgag-blue'].main} />
              <h4>Bitte wählen Sie eine Stadt</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  )
}
