import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import defaultBackground from '../../../assets/img/background.jpg'

const IntroBox = styled.div(({ theme, children, id, ...rest }) => ({ ...rest }))

const BackgroundImage = styled.div(({ theme, children, backgroundImage, id, ...rest }) => ({
  backgroundSize: 'cover',
  backgroundImage: `url(${backgroundImage || defaultBackground})`,
  ...rest,
}))

const Wrapper = styled.div(({ theme, children, id, ...rest }) => ({ ...rest }))

const Overlay = styled.div(({ theme, children, backgroundColor = 'rgba(0, 62, 113, 0.3)', id, ...rest }) => ({
  backgroundColor,
  ...rest,
}))

const Banner = styled.div(
  ({
    theme,
    children,
    display = 'flex',
    justifyContent = 'space-between',
    alignItems = 'center',
    flexWrap = 'wrap-reverse',
    backgroundColor = 'rgba(0, 62, 113, 0.85)',
    padding = '2rem',
    id,
    ...rest
  }) => ({
    display,
    justifyContent,
    alignItems,
    flexWrap,
    backgroundColor,
    padding,
    ...rest,
  })
)

const HeaderWrapper = styled.div(
  ({ theme, children, display = 'flex', flexDirection = 'column', marginRight = '1.5rem', id, ...rest }) => ({
    display,
    flexDirection,
    marginRight,
    ...rest,
  })
)

const Header = styled.h1(({ theme, children, fontSize = '1.25rem', color = 'white', id, ...rest }) => ({
  fontSize,
  color,
  ...rest,
}))

const SubHeader = styled.h1(
  ({ theme, children, fontSize = '1.25rem', fontWeight = 'lighter', color = 'white', id, ...rest }) => ({
    fontSize,
    fontWeight,
    color,
    ...rest,
  })
)

const Logo = styled.div(
  ({
    theme,
    children,
    width = '200px',
    minWidth = '100px',
    '@media(max-width: 576px)': mediaMaxWidth576 = { flex: '1', maxWidth: '150px', marginBottom: '1rem' },
    id,
    ...rest
  }) => ({
    width,
    minWidth,
    '@media(max-width: 576px)': mediaMaxWidth576,
    ...rest,
  })
)

Logo.Image = styled.img(({ theme, children, id, ...rest }) => ({ width: '100%', ...rest }))

const Description = styled.div(
  ({
    theme,
    children,
    padding = '2rem',
    '@media(max-width: 576px)': mediaMaxWidth576 = { padding: '1rem' },
    id,
    ...rest
  }) => ({
    padding,
    '@media(max-width: 576px)': mediaMaxWidth576,
    ...rest,
  })
)

Description.Box = styled('div')(
  ({
    theme,
    children,
    id,
    padding = '2rem',
    boxShadow = '0px 0px 4px 1px rgba(0, 0, 0, 0.4)',
    backgroundColor = 'rgba(255,255,255,0.9)',
    ...rest
  }) => ({
    padding,
    backgroundColor,
    boxShadow,
    ...rest,
  })
)

Description.Text = styled('div')(({ theme, children, id, ...rest }) => ({
  ...rest,
}))

Description.SurveyStartRow = styled('div')(
  ({ theme, children, id, display = 'flex', justifyContent = 'center', marginTop = '2rem', ...rest }) => ({
    marginTop,
    display,
    justifyContent,
    ...rest,
  })
)

Description.SurveyStartButton = styled('div')(
  ({
    theme,
    children,
    id,
    width = '200px',
    '@media(max-width: 576px)': mediaMaxWidth576 = { width: '100%' },
    ...rest
  }) => {
    return {
      width,
      '@media(max-width: 576px)': mediaMaxWidth576,
      ...rest,
    }
  }
)

export const DefaultIntro = forwardRef(({ survey, questionnaire = null, handleCurrentQuestionaire }, ref) => {
  const { t } = useTranslation()
  const {
    introBox,
    backgroundImage,
    wrapper,
    banner,
    headerWrapper,
    header,
    subHeader,
    logo,
    logoImage,
    overlay,
    description,
    descriptionBox,
    descriptionText,
    surveyStartRow,
    surveyStartButton,
  } = survey.settings.introStyles.default

  return (
    <IntroBox id="introBox" {...introBox}>
      <BackgroundImage id="backgroundImage" backgroundImage={survey.background_image} {...backgroundImage}>
        <Wrapper id="wrapper" {...wrapper}>
          <Banner id="banner" {...banner}>
            <HeaderWrapper id="headerWrapper" {...headerWrapper}>
              <Header id="header" {...header}>
                {survey.title}
              </Header>
              <SubHeader id="subHeader" {...subHeader}>
                {survey.subtitle}
              </SubHeader>
            </HeaderWrapper>
            {survey.logo && (
              <Logo id="logo" {...logo}>
                <Logo.Image id="logoImage" src={survey.logo} {...logoImage} />
              </Logo>
            )}
          </Banner>
          <Overlay id="overlay" {...overlay}>
            <Description id="description" {...description}>
              <Description.Box id="descriptionBox" {...descriptionBox}>
                <Description.Text
                  id="descriptionText"
                  dangerouslySetInnerHTML={{ __html: survey.description }}
                  {...descriptionText}
                />
                {questionnaire !== null && (
                  <Description.SurveyStartRow id="surveyStartRow" {...surveyStartRow}>
                    <Description.SurveyStartButton
                      ref={ref}
                      onClick={() => handleCurrentQuestionaire(questionnaire.id)}
                      id="start"
                      data-id="surveyStartButton"
                      className={
                        surveyStartButton?.className ? surveyStartButton.className : 'btn btn-primary'
                      }
                      {...surveyStartButton}
                    >
                      {t('survey.opener.answer')}
                    </Description.SurveyStartButton>
                  </Description.SurveyStartRow>
                )}
              </Description.Box>
            </Description>
          </Overlay>
        </Wrapper>
      </BackgroundImage>
    </IntroBox>
  )
})
