import React from 'react'
import { ListBox } from './Components/ListBox'
import { WithDecimal } from './Components/WithDecimal'
import { DataType } from './Components/DataType'
import { Unit } from './Components/Unit'
import { InputValue } from './Components/InputValue'

export const InputColumns = ({ question, questionnaireIndex, pageIndex, index }) => {
  return (
    <div>
      <ListBox
        label={'Fragen'}
        name="questions_field"
        unit={question.unit}
        dataType={question.data_type}
        items={question.questions_field}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <Unit question={question} index={index} pageIndex={pageIndex} questionnaireIndex={questionnaireIndex} />
      <InputValue
        label={'Min. Wert'}
        name={'min_value'}
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <InputValue
        label={'Max. Wert'}
        name={'max_value'}
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <InputValue
        label={'Default Wert'}
        name={'default_value'}
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <WithDecimal
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <DataType
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
    </div>
  )
}
