import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'
import { categories, matrixTopics, radioTopix } from '../RiwisCategories'

const getTopics = (questionType) => {
  if (questionType === 'radioMatrixRiwis') {
    return matrixTopics
  } else if (questionType === 'radioInlineRiwis') {
    return radioTopix
  } else {
    return null
  }
}

export const DatasourceSelectorRiwis = ({ question, questionnaireIndex, pageIndex, index, assetClass }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const matrixDatasourceChangeHandler = useCallback(
    (event) => {
      const value = [
        {
          id: 0,
          fileMaker: `${categories['md_' + assetClass].fmName}_${event.target.value}`,
        },
      ]
      setQuestionInput(questionnaireIndex, pageIndex, index, value, event.target.name)
    },
    [index, setQuestionInput, questionnaireIndex, pageIndex, assetClass]
  )

  return (
    <div className=" form-group row">
      <label className="col-sm-2 form-group-label text-right">Tabellenname</label>
      <div className="col-sm-10">
        <select
          className="form-control"
          disabled={!assetClass}
          name="riwis_datasources"
          value={
            question.riwis_datasources.length && assetClass
              ? question.riwis_datasources[0].fileMaker.split(categories['md_' + assetClass].fmName + '_')[1]
              : ''
          }
          onChange={(event) => matrixDatasourceChangeHandler(event)}
        >
          {assetClass ? (
            <>
              <option value="">Bitte wählen</option>
              {getTopics(question.type)['md_' + assetClass].map((topic) => (
                <option key={topic.fmName} value={topic.fmName}>
                  {topic.fmName}
                </option>
              ))}
            </>
          ) : (
            <option value="">Bitte Kategorie im Fragebogen wählen!</option>
          )}
        </select>
      </div>
    </div>
  )
}
