import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NoAnswerCheckbox } from '../QuestionnaireBuilder/Components/NoAnswerCheckbox'
export const Text = ({ id, qid, value = { text: '' }, previousAnswer, noAnswer, onChange }) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)
  const handleChange = useCallback(
    (event) => {
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }
      if (event.target.type === 'checkbox') {
        newValue.noAnswer = event.target.checked
      }
      if (event.target.type === 'text') {
        newValue.text = event.target.value
      }
      setLocalPreviousAnswer(null)
      onChange({ question_id: id, value: newValue, qid })
    },
    [onChange, id, qid, value, localPreviousAnswer]
  )

  const inputValue = useMemo(() => {
    let inputValue = ''
    if (value?.text) {
      inputValue = value?.text
    } else if (localPreviousAnswer && localPreviousAnswer?.text && !localPreviousAnswer.noAnswer) {
      inputValue = localPreviousAnswer.text
    }
    return inputValue
  }, [value, localPreviousAnswer])

  const noAnswerValue = useMemo(() => {
    let checked = false
    if (typeof value?.noAnswer !== 'undefined') {
      checked = value.noAnswer
    } else if (localPreviousAnswer && typeof localPreviousAnswer.noAnswer !== 'undefined') {
      checked = localPreviousAnswer.noAnswer
    }
    return checked
  }, [value, localPreviousAnswer])

  // converting text to new format
  useEffect(() => {
    if (typeof value === 'string') {
      const newValue = { text: value, noAnswer: false }
      onChange({ question_id: id, value: newValue, qid })
    }
  }, [onChange, id, qid, value])

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-12 col-md-5">
          <input
            type="text"
            className="form-control"
            name={id}
            value={inputValue}
            disabled={noAnswerValue}
            onChange={handleChange}
          ></input>
        </div>
      </div>
      <NoAnswerCheckbox noAnswer={noAnswer} onChange={handleChange} checked={noAnswerValue} questionId={id} />
    </div>
  )
}
