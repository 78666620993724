import React, { useCallback, useMemo, useState } from 'react'

export const RadioMatrix = ({
  id,
  qid,
  answers,
  previousAnswer,
  noAnswer,
  questions,
  oneAnswer,
  value = {},
  onChange,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)

  const handleChange = useCallback(
    (event) => {
      const questionName = id
      const [question, answer] = event.target.value.split('_')
      let newGivenAnswer = { ...value }

      newGivenAnswer[question] = answer
      if (oneAnswer) {
        newGivenAnswer = { [question]: answer }
      }

      // setGivenAnswer({ givenAnswer: newGivenAnswer })
      setLocalPreviousAnswer((prevState) => {
        const newState = { ...prevState }
        newState[question] = null
        return newState
      })
      onChange({ question_id: questionName, value: newGivenAnswer, qid })
    },
    [id, onChange, oneAnswer, qid, value]
  )

  const onScroll = useCallback((event) => {
    // todo
  }, [])

  const close = useCallback(() => {
    setSelectedQuestion(null)
  }, [])

  return (
    <div className="d-flex">
      {selectedQuestion !== null && (
        <MobileAnswersRadio
          id={id}
          answers={answers}
          questions={questions}
          selectedQuestion={selectedQuestion}
          value={value}
          close={close}
          changeHandler={handleChange}
          noAnswer={noAnswer}
          localPreviousAnswer={localPreviousAnswer}
        />
      )}
      <div onScroll={onScroll} className="matrixTableContainer">
        <table className="matrixTable">
          <TableHeader answers={answers} noAnswer={noAnswer} />
          <TableBody
            questions={questions}
            answers={answers}
            id={id}
            value={value}
            handleChange={handleChange}
            setSelectedQuestion={setSelectedQuestion}
            noAnswer={noAnswer}
            localPreviousAnswer={localPreviousAnswer}
          />
        </table>
      </div>
      {/* <div
          className={this.state.scrollEndX ? '' : 'tableBlur'}
          style={{ width: '1px', backgroundColor: 'white' }}
        ></div> */}
    </div>
  )
}

const TableHeader = ({ answers, noAnswer }) => {
  const preparedAnswers = useMemo(() => {
    const preparedAnswers = [...answers]
    if (noAnswer && noAnswer.showNoAnswer) {
      preparedAnswers.push({ id: noAnswer.id, text: noAnswer.title })
    }
    return preparedAnswers
  }, [answers, noAnswer])

  return (
    <thead>
      <tr>
        <th scope="col">{/* <div className="theadBlur"></div> */}</th>
        {preparedAnswers.map((answer) => {
          return <th key={answer.id} dangerouslySetInnerHTML={{ __html: answer.text }}></th>
        })}
      </tr>
    </thead>
  )
}

const TableBody = ({
  questions,
  answers,
  id,
  value,
  handleChange,
  setSelectedQuestion,
  noAnswer,
  localPreviousAnswer,
}) => {
  const preparedAnswers = useMemo(() => {
    return questions.reduce((acc, question) => {
      acc[question.id] = answers.map((answer) => {
        let checked = false
        if (value?.[question.id]) {
          checked = parseInt(value[question.id]) === answer.id
        } else if (localPreviousAnswer?.[question.id]) {
          checked = parseInt(localPreviousAnswer[question.id]) === answer.id
        } else {
          checked = false
        }

        return {
          ...answer,
          elementName: id + '_' + question.id,
          elementValue: question.id + '_' + answer.id,
          elementId: id + '_' + question.id + '_' + answer.id,
          checked,
        }
      })

      if (noAnswer && noAnswer.showNoAnswer) {
        if (typeof acc[question.id] === 'undefined') {
          acc[question.id] = []
        }

        let checked = false
        if (value?.[question.id]) {
          checked = parseInt(value[question.id]) === noAnswer.id
        } else if (localPreviousAnswer?.[question.id]) {
          checked = parseInt(localPreviousAnswer[question.id]) === noAnswer.id
        } else {
          checked = false
        }

        acc[question.id].push({
          elementName: id + '_' + question.id,
          elementValue: question.id + '_' + noAnswer.id,
          elementId: id + '_' + question.id + '_' + noAnswer.id,
          checked,
          type: 'noAnswer',
          id: noAnswer.id,
        })
      }

      return acc
    }, {})
  }, [answers, value, id, questions, noAnswer, localPreviousAnswer])

  return (
    <tbody>
      {questions.map((question) => {
        return (
          <tr style={{ verticalAlign: 'baseline' }} key={question.id}>
            <th key={question.id} scope="row" dangerouslySetInnerHTML={{ __html: question.text }}></th>

            <td className="matrixDropdown">
              <MobileDopdownButton
                question={question}
                answers={answers}
                value={value}
                setSelectedQuestion={setSelectedQuestion}
              />
            </td>

            {preparedAnswers[question.id].map((answer) => {
              return (
                <td
                  key={answer.id}
                  style={{
                    borderLeft: answer?.type === 'noAnswer' ? '1px solid lightgrey' : '',
                  }}
                >
                  {
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        type="radio"
                        name={answer.elementName}
                        id={answer.elementId}
                        value={answer.elementValue}
                        onChange={handleChange}
                        checked={answer.checked}
                      ></input>
                      <label className="custom-control-label" htmlFor={answer.elementId}></label>
                    </div>
                  }
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

const MobileDopdownButton = ({ question, answers, value, setSelectedQuestion }) => {
  const answer = answers.find((answer) => parseInt(value[question.id]) === answer.id)
  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn mainBtn"
        key={question.id}
        onClick={() => setSelectedQuestion(question.id)}
      >
        {answer ? answer.text : 'Antwort auswählen'}
      </button>
      <button
        type="button"
        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
        onClick={() => setSelectedQuestion(question.id)}
      ></button>
    </div>
  )
}

const MobileAnswersRadio = ({
  questions,
  selectedQuestion,
  close,
  answers,
  id,
  value,
  changeHandler,
  noAnswer,
  localPreviousAnswer,
}) => {
  const [question] = useState(() => questions.find((question) => question.id === selectedQuestion))

  const handleClose = useCallback(
    (event) => {
      if (typeof close === 'function') {
        close()
      }
    },
    [close]
  )

  const handleMobileAnswerWindowClose = useCallback(
    (event) => {
      if (event.target.className === 'mobileAnswerWindow') {
        handleClose()
      }
    },
    [handleClose]
  )

  const preparedAnswers = useMemo(() => {
    const preparedAnswers = answers.map((answer) => {
      let checked = false
      if (value?.[question.id]) {
        checked = parseInt(value[question.id]) === answer.id
      } else if (localPreviousAnswer?.[question.id]) {
        checked = parseInt(localPreviousAnswer[question.id]) === answer.id
      } else {
        checked = false
      }
      return {
        ...answer,
        elementName: id + '_' + question.id + '_m',
        elementValue: question.id + '_' + answer.id,
        elementId: id + '_' + question.id + '_' + answer.id + '_m',
        checked,
      }
    })

    if (noAnswer && noAnswer.showNoAnswer) {
      let checked = false
      if (value?.[question.id]) {
        checked = parseInt(value[question.id]) === noAnswer.id
      } else if (localPreviousAnswer?.[question.id]) {
        checked = parseInt(localPreviousAnswer[question.id]) === noAnswer.id
      } else {
        checked = false
      }

      preparedAnswers.push({
        elementName: id + '_' + question.id + '_m',
        elementValue: question.id + '_' + noAnswer.id,
        elementId: id + '_' + question.id + '_' + noAnswer.id + '_m',
        checked,
        type: 'noAnswer',
        id: noAnswer.id,
        text: noAnswer.title,
      })
    }
    return preparedAnswers
  }, [answers, question, value, id, noAnswer, localPreviousAnswer])

  return (
    <div className="mobileAnswerWindow" onClick={handleMobileAnswerWindowClose}>
      <div className="mobileAnswerContainer">
        <ul className="mobileAnswerList">
          <li className="closeBtn d-flex justify-space-between">
            <div className="w-100">{question.text}</div>
            <div className="crossContainer" onClick={close}>
              <div className="crossBarOne">
                <div className="crossBarTwo"></div>
              </div>
            </div>
          </li>
          {preparedAnswers.map((answer) => {
            return (
              <li key={answer.id} className="d-flex align-items-center">
                <div className="custom-control custom-radio">
                  <input
                    onChange={changeHandler}
                    className="custom-control-input"
                    type="radio"
                    name={answer.elementName}
                    id={answer.elementId}
                    value={answer.elementValue}
                    checked={answer.checked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={answer.elementId}
                    dangerouslySetInnerHTML={{ __html: answer.text }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
