import React from 'react'
import { ListBox } from './Components/ListBox'
import { DatasourceSelectorRiwis } from './Components/DatasourceSelectorRiwis'

export const RadioInlineRiwis = ({ question, index, questionnaireIndex, pageIndex, assetClass }) => {
  return (
    <div>
      <DatasourceSelectorRiwis
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
        assetClass={assetClass}
      />
      <ListBox
        label={'Antworten'}
        name="possible_answers"
        items={question.possible_answers}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
    </div>
  )
}
