import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../surveyEditorState'
import { ListBox } from './Components/ListBox'

export const Checkbox = ({ question, index, pageIndex, questionnaireIndex }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const onChange = useCallback(
    (event) => {
      let { value, name } = event.target
      if (typeof value === 'string' && !isNaN(Number(value))) {
        value = Number(value)
      }
      setQuestionInput(questionnaireIndex, pageIndex, index, value, name)
    },
    [setQuestionInput, pageIndex, index, questionnaireIndex]
  )

  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 form-group-label text-right">Zu vergebende Antworten</label>
        <div className="col-sm-2">
          <div className="flex">
            <input
              className="form-control"
              type="number"
              name="limit"
              value={question.limit || 0}
              onChange={onChange}
              max={question.possible_answers.length}
              min={0}
            ></input>
            <div className="text-secondary ml-4">0 = deaktiviert</div>
          </div>
        </div>
      </div>

      <ListBox
        label={'Antworten'}
        name="possible_answers"
        items={question.possible_answers}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
    </>
  )
}
