import React, { useEffect, useState, useCallback } from 'react'

const isWithinSection = (index, sectionRange) => {
  if (index >= sectionRange[0] && index < sectionRange[1]) {
    return true
  }
  return false
}

export const Pagination = ({ pages, pageChange, sectionSize = 7 }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [sectionRange, setSectionRange] = useState([0, sectionSize])

  const handlePageChange = useCallback(
    (index) => {
      setCurrentPage(index)
      if (index >= 0 && index < Math.ceil(sectionSize / 2)) {
        setSectionRange([0, sectionSize])
      } else if (index + 1 === sectionRange[1]) {
        setSectionRange([
          sectionRange[0] + sectionSize - Math.ceil(sectionSize / 2),
          sectionRange[1] + Math.floor(sectionSize / 2),
        ])
      } else if (index === sectionRange[0]) {
        setSectionRange([
          sectionRange[0] - Math.floor(sectionSize / 2),
          sectionRange[1] - Math.floor(sectionSize / 2),
        ])
      } else if (index + 1 === pages.length) {
        setSectionRange([pages.length - sectionSize, pages.length])
      }
    },
    [pages, sectionRange, sectionSize]
  )

  const shiftRange = (direction) => {
    if (direction === 'up') {
      setSectionRange([
        sectionRange[0] + sectionSize - Math.ceil(sectionSize / 2),
        sectionRange[1] + Math.floor(sectionSize / 2),
      ])
      setCurrentPage(sectionRange[0] + sectionSize - 1)
    } else if (direction === 'down') {
      setSectionRange([
        sectionRange[0] - Math.floor(sectionSize / 2),
        sectionRange[1] - Math.floor(sectionSize / 2),
      ])
      setCurrentPage(sectionRange[1] - sectionSize)
    }
  }

  useEffect(() => {
    if (pages[currentPage]) {
      pageChange(pages[currentPage])
    } else {
      setCurrentPage(0)
    }
  }, [currentPage, pages, pageChange])

  return (
    <div className="d-flex align-items-baseline justify-content-center mt-5">
      {currentPage >= sectionSize - 1 && (
        <>
          <div
            className="doubleArrow"
            onClick={() => {
              shiftRange('down')
            }}
          >
            &#171;
          </div>
          <div className="d-flex">
            <div className="pagination" onClick={() => handlePageChange(0)}>
              {1}
            </div>
            <div className="px-4">...</div>
          </div>
        </>
      )}
      {pages.map((_, index) => {
        if (isWithinSection(index, sectionRange)) {
          return (
            <div
              key={index}
              className={(currentPage === index ? 'selected' : '') + ' pagination'}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </div>
          )
        }
        return null
      })}
      {!(pages.length >= sectionRange[0] && pages.length <= sectionRange[1]) && (
        <>
          <div className="d-flex">
            <div className="px-4">...</div>
            <div className="pagination" onClick={() => handlePageChange(pages.length - 1)}>
              {pages.length}
            </div>
          </div>
          <div
            className="doubleArrow"
            onClick={() => {
              shiftRange('up')
            }}
          >
            &#187;
          </div>
        </>
      )}
    </div>
  )
}
