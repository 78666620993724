import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

export const OneAnswer = ({ question, questionnaireIndex, pageIndex, index }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const handleCustomCheckboxChange = useCallback(
    (question, name) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, !question[name], name)
    },
    [pageIndex, questionnaireIndex, index, setQuestionInput]
  )

  return (
    <div className=" form-group row">
      <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
      <div className="col-sm-10">
        <div className="custom-control custom-checkbox">
          <input
            className="custom-control-input"
            name="one_answer"
            type="checkbox"
            readOnly
            checked={question.one_answer || false}
          ></input>
          <label
            className="custom-control-label"
            onClick={() => handleCustomCheckboxChange(question.qid, question, 'one_answer')}
          >
            Nur eine Antwort in der gesamten Matrix
          </label>
        </div>
      </div>
    </div>
  )
}
