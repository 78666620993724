import React, { useState, useEffect, useCallback } from 'react'
import { AlertBox } from '../../../../components/AlertBox'
import { ModalSelectBox } from './modalSelectBox'
import { UserSelectBox } from '../../../../components/UserSelectBox'
import { Questionnaire } from './questionnaire'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import DatePicker from 'react-date-picker'
import { Dropdown, Button, ButtonGroup, DropdownButton } from 'react-bootstrap'
import { TemplateSave } from './TemplateSave'
import { TemplateLoad } from './TemplateLoad'
import { useParams, useHistory } from 'react-router-dom'
import { JSONTextarea, LayoutSettingEditor } from './JSONTextarea'
import { SurveyOpener } from '../../../Survey/surveyOpener'
import { Modal } from '../../../../components/Modal'
import { FormRow, SurveySection } from './Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import slugify from 'slugify'

const parseTime = (date) => {
  date = new Date(date) || new Date()
  const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  return `${hours}:${minutes}`
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const Survey = () => {
  const [
    { dataIsLoading, survey, userGroups, saveMessage, editTemplate, template },
    { initSurvey, setSurveyInput, saveSurvey, setSaveMessage, saveTemplate, setSurvey },
  ] = useSurveyEditorStateStore()
  const [fileErrors, setFileErrors] = useState({})
  const [showTemplateSave, setShowTemplateSave] = useState(false)
  const [showTemplateLoad, setShowTemplateLoad] = useState(false)
  const [updateContent, setUpdateContent] = useState(false)
  const { surveyId } = useParams()
  const history = useHistory()
  const [showPreview, setShowPreview] = useState(false)

  const handleSave = useCallback(() => {
    saveSurvey(survey)
  }, [saveSurvey, survey])

  const handleTemplateSave = () => {
    saveTemplate(template.title, survey, template.id)
  }
  const handleUserGroupsChange = (event) => {
    const usergroups = event.map((usergroup) => usergroup.id)
    setSurveyInput(usergroups, 'usergroups')
  }
  const handleSupportContactChange = (supportContact) => {
    setSurveyInput(supportContact, 'support_contact')
  }
  const handleInputChange = (event) => {
    if (event?.target?.type === 'checkbox') {
      setSurveyInput(event.target.checked, event.target.name)
    } else {
      setSurveyInput(event.target.value, event.target.name)
    }
  }

  const handleSlugChange = useCallback(
    (event) => {
      const value = slugify(event.target.value, { lower: true, trim: false, remove: /[*+~.()'"!:@]/g })
      setSurveyInput(value, event.target.name)
    },
    [setSurveyInput]
  )

  const handleSettingsChange = useCallback(
    (event) => {
      const newSettings = { ...survey.settings }
      if (event?.target?.type === 'checkbox') {
        newSettings[event.target.name] = event.target.checked
        setSurveyInput(newSettings, 'settings')
      } else {
        newSettings[event.target.name] = event.target.value
        setSurveyInput(newSettings, 'settings')
      }
    },
    [survey, setSurveyInput]
  )

  const handleTinyMCEChange = (event) => {
    setSurveyInput(event.value, event.name)
  }

  const updateTinyMCEContent = (bool) => {
    setUpdateContent(bool)
  }

  // const handleColorReset = (value, property) => {
  //   setSurveyInput(value, property)
  // }

  const handleDateChange = (event, name) => {
    setSurveyInput(new Date(event).toISOString(), name)
  }

  const handleTimeChange = (event) => {
    const date = event.target.name === 'from' ? survey.from : survey.to
    const [hours, minutes] = event.target.value.split(':')
    let time = new Date(date)
    time.setHours(parseInt(hours))
    time.setMinutes(parseInt(minutes), 0, 0)
    setSurveyInput(new Date(time).toISOString(), event.target.name)
  }

  const handleFileUpload = (event, type) => {
    const newFileErrors = { ...fileErrors }
    newFileErrors[type] = []
    if (event.target.files[0]) {
      if (event.target.files[0].size > 2 * 1024 * 1024) {
        newFileErrors[type].push('Bild muss kleiner als 2mb sein')
      }
      if (event.target.files[0].type.split('/')[0] !== 'image') {
        newFileErrors[type].push('Die Datei muss ein Bild sein')
      }
      if (newFileErrors[type].length) {
        setFileErrors(newFileErrors)
      } else {
        setFileErrors(newFileErrors)
        toBase64(event.target.files[0]).then((base64Image) => {
          setSurveyInput(base64Image, type)
        })
      }
    }
  }

  const handleImageDelete = (type) => {
    const newFileErrors = { ...fileErrors }
    newFileErrors[type] = []
    setFileErrors(newFileErrors)
    setSurveyInput('', type)
  }

  useEffect(() => {
    initSurvey(surveyId)
    return function cleanup() {
      setSurvey(null)
    }
  }, [initSurvey, surveyId, setSurvey])

  useEffect(() => {
    if (saveMessage.msg.length) {
      setTimeout(() => {
        setSaveMessage({ msg: '', error: '', type: '' })
      }, 3000)
    }
  }, [saveMessage, setSaveMessage])

  // redirect to survey with proper id, after creation
  useEffect(() => {
    if (surveyId === '0' && survey && survey.id !== 0) {
      history.push({ pathname: `/admin/survey/${survey.id}` })
    }
  }, [survey, surveyId, history])

  const keyboardEventListener = useCallback(
    (event) => {
      if (event.code === 'KeyS' && event.ctrlKey) {
        handleSave()
      }
    },
    [handleSave]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyboardEventListener)

    return () => document.removeEventListener('keydown', keyboardEventListener)
  }, [keyboardEventListener])

  return (
    dataIsLoading === false && (
      <>
        <div className="sticky-top pt-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
          <div className="d-flex">
            <h3 className="mr-5 text-nowrap">
              {survey?.id === 0 && !survey.title ? 'Neue Umfrage' : survey.title}
            </h3>
            {editTemplate ? (
              <button className="btn-sm btn-primary text-nowrap" onClick={handleTemplateSave}>
                Vorlage speichern
              </button>
            ) : (
              <button className="btn-sm btn-primary" onClick={handleSave}>
                Speichern
              </button>
            )}
            <div id="#alert" className="d-flex justify-content-end w-100 mr-4">
              <AlertBox type={saveMessage.type} message={saveMessage.msg} error={saveMessage.error} />
            </div>
            <DropdownButton id="dropdown-basic-button" title="Aktionen">
              <Dropdown.Item onClick={() => setShowTemplateSave(true)}>als Vorlage speichern</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowTemplateLoad(true)} disabled={survey.id}>
                Vorlage laden
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <hr className="mb-5"></hr>
        </div>

        <SurveySection>
          <div className="form-group row align-items-center my-5">
            <label className="col-sm-2 h5 m-0 text-primary text-right">Umfragetyp</label>
            <div className="col">
              <Dropdown as={ButtonGroup}>
                <Button style={{ width: '10rem' }} variant="outline-primary">
                  {survey.type === 'survey'
                    ? 'SurveyDesk'
                    : survey.type === 'bfQuarter'
                    ? 'BF Quartalsbarometer'
                    : 'RIWIS'}
                </Button>

                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                <Dropdown.Menu align="right">
                  <Dropdown.Item onClick={() => setSurveyInput('survey', 'type')}>SurveyDesk</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSurveyInput('bfQuarter', 'type')}>
                    BF Quartalsbarometer
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSurveyInput('riwis', 'type')}>RIWIS</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="form-group row align-items-center my-5">
            <label className="col-sm-2 h5 m-0 text-primary text-right">Sprache</label>
            <div className="col">
              <Dropdown as={ButtonGroup}>
                <Button style={{ width: '10rem' }} variant="outline-primary">
                  {survey.language === 'de' || !survey.language ? 'Deutsch' : 'Englisch'}
                </Button>

                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                <Dropdown.Menu align="right">
                  <Dropdown.Item onClick={() => setSurveyInput('de', 'language')}>Deutsch</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSurveyInput('en', 'language')}>Englisch</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {survey.type === 'riwis' && (
            <FormRow className="align-items-center">
              <FormRow.Label className="col-sm-2 h5 m-0 text-primary text-right">
                Jahr der Datenquellen
              </FormRow.Label>
              <div className="col-sm-2">
                <input
                  id="datasource_year"
                  className="form-control"
                  type="text"
                  name="datasource_year"
                  value={survey.datasource_year || ''}
                  onChange={handleInputChange}
                />
              </div>
            </FormRow>
          )}

          {survey.type === 'bfQuarter' && (
            <FormRow className="align-items-center">
              <FormRow.Label className="col-sm-2 h5 m-0 text-primary text-right">
                Jahr / Quartal
              </FormRow.Label>
              <div className="col-sm-2">
                <input
                  id="datasource_year"
                  className="form-control"
                  type="text"
                  name="datasource_year"
                  value={survey.datasource_year || ''}
                  onChange={handleInputChange}
                />
              </div>

              <Dropdown as={ButtonGroup} style={{ width: '70px' }}>
                <Button style={{ width: '10rem' }} variant="outline-primary">
                  {survey.quarter}
                </Button>
                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                <Dropdown.Menu align="right">
                  <Dropdown.Item onClick={() => setSurveyInput(1, 'quarter')}>1</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSurveyInput(2, 'quarter')}>2</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSurveyInput(3, 'quarter')}>3</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSurveyInput(4, 'quarter')}>4</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </FormRow>
          )}

          <FormRow className="align-items-center">
            <FormRow.Label className="h5 m-0 text-primary">Status</FormRow.Label>
            <div className="col">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="radioDraft"
                  type="radio"
                  name="status"
                  value="draft"
                  onChange={handleInputChange}
                  checked={survey.status === 'draft' ? 'checked' : ''}
                ></input>
                <label className="custom-control-label" htmlFor="radioDraft">
                  Entwurf
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="radioPublished"
                  type="radio"
                  name="status"
                  value="published"
                  onChange={handleInputChange}
                  checked={survey.status === 'published' ? 'checked' : ''}
                ></input>
                <label className="custom-control-label" htmlFor="radioPublished">
                  Veröffentlicht
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="radioArchived"
                  type="radio"
                  name="status"
                  value="archived"
                  onChange={handleInputChange}
                  checked={survey.status === 'archived' ? 'checked' : ''}
                ></input>
                <label className="custom-control-label" htmlFor="radioArchived">
                  Archiv
                </label>
              </div>
            </div>
          </FormRow>

          <FormRow className="align-items-center">
            <FormRow.Label className="h5 m-0 text-primary">Laufzeit</FormRow.Label>
            <div className="col-sm form-inline">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                </div>
                <input
                  type="time"
                  name="from"
                  className="timePicker"
                  value={parseTime(survey.from)}
                  onChange={handleTimeChange}
                />
                <DatePicker
                  value={new Date(survey.from)}
                  onChange={(event) => handleDateChange(event, 'from')}
                />
              </div>

              <label className="col-form-label mx-sm-4">bis</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                </div>
                <input
                  type="time"
                  name="to"
                  className="timePicker"
                  value={parseTime(survey.to)}
                  onChange={handleTimeChange}
                />
                <DatePicker value={new Date(survey.to)} onChange={(event) => handleDateChange(event, 'to')} />
              </div>
            </div>
          </FormRow>

          <FormRow className="align-items-center">
            <FormRow.Label className="h5 text-primary">Sichtbarkeit für</FormRow.Label>
            <div className="col">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  type="radio"
                  id="radioTypeOpen"
                  name="type_of_survey"
                  value="open"
                  onChange={handleInputChange}
                  checked={survey.type_of_survey === 'open'}
                ></input>
                <label className="custom-control-label" htmlFor="radioTypeOpen">
                  Jeden
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  type="radio"
                  id="radioTypeClosed"
                  name="type_of_survey"
                  value="closed"
                  onChange={handleInputChange}
                  checked={survey.type_of_survey === 'closed' || survey.type_of_survey == null}
                ></input>
                <label className="custom-control-label" htmlFor="radioTypeClosed">
                  Gruppen
                </label>
              </div>
            </div>
          </FormRow>

          <FormRow className="align-items-center">
            <FormRow.Label className="h5 text-primary">User-Gruppen</FormRow.Label>
            <FormRow.Content>
              <ModalSelectBox
                items={userGroups}
                selectedItemIds={survey.usergroups}
                changeHandler={handleUserGroupsChange}
              />
            </FormRow.Content>
          </FormRow>

          <FormRow className="align-items-center">
            <FormRow.Label className="h5 text-primary">Ansprechpartner</FormRow.Label>
            <FormRow.Content>
              <UserSelectBox
                support_contact={survey.support_contact || {}}
                changeHandler={handleSupportContactChange}
              ></UserSelectBox>
            </FormRow.Content>
          </FormRow>
        </SurveySection>

        <SurveySection>
          <div className="d-flex align-items-center">
            <h3>Startseite</h3>
          </div>
          <hr></hr>
          <FormRow>
            <FormRow.Label>URL</FormRow.Label>
            <FormRow.Content>
              <input
                className="form-control"
                type="text"
                name="slug"
                onChange={handleSlugChange}
                value={survey.slug || slugify(survey.title, { remove: /[*+~.()'"!:@]/g, lower: true })}
              ></input>
            </FormRow.Content>
          </FormRow>
          <FormRow>
            <FormRow.Label>Titel</FormRow.Label>
            <FormRow.Content>
              <input
                className="form-control"
                type="text"
                name="title"
                onChange={handleInputChange}
                value={survey.title || ''}
              ></input>
            </FormRow.Content>
          </FormRow>
          <FormRow>
            <FormRow.Label>Untertitel</FormRow.Label>
            <FormRow.Content>
              <input
                className="form-control"
                type="text"
                name="subtitle"
                onChange={handleInputChange}
                value={survey.subtitle || ''}
              ></input>
            </FormRow.Content>
          </FormRow>
          <FormRow>
            <FormRow.Label>Beschreibung</FormRow.Label>
            <FormRow.Content>
              <TinyMCE
                inlineMode={false}
                id={'surveyDescription'}
                content={survey.description}
                updateContent={updateContent}
                onUpdatedContent={updateTinyMCEContent}
                name={'description'}
                handleTinyMCEChange={handleTinyMCEChange}
              />
            </FormRow.Content>
          </FormRow>
          {(survey.type === 'survey' || survey.type === 'bfQuarter') && (
            <FormRow>
              <FormRow.Label htmlFor="fileUploader">Hintergrundbild (Beschreibung)</FormRow.Label>
              <FormRow.Content className="col-sm-10">
                {survey.background_image ? (
                  <div style={{ width: '200px' }}>
                    <img style={{ width: '100%' }} src={survey.background_image} alt="background_image" />
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => handleImageDelete('background_image')}
                    >
                      Hintergrundbild löschen
                    </button>
                  </div>
                ) : (
                  <div>
                    <input
                      type="file"
                      name="logo"
                      id="fileUploader"
                      onChange={(event) => handleFileUpload(event, 'background_image')}
                    />
                    {fileErrors['background_image'] &&
                      fileErrors['background_image'].map((error) => <p className="text-danger">{error}</p>)}
                  </div>
                )}
              </FormRow.Content>
            </FormRow>
          )}

          {(survey.type === 'survey' || survey.type === 'bfQuarter') && (
            <FormRow>
              <FormRow.Label htmlFor="advancedStyling">Erweitertes Styling</FormRow.Label>
              <FormRow.Content id="advancedStyling">
                <LayoutSettingEditor
                  introStyles={survey?.settings?.introStyles}
                  name="introStyles"
                  onChange={handleSettingsChange}
                  layout={survey?.settings?.intro}
                />
              </FormRow.Content>
            </FormRow>
          )}

          <FormRow>
            <FormRow.Label htmlFor="progress">Layout</FormRow.Label>
            <FormRow.Content>
              <div className="col">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="default"
                    type="radio"
                    name="intro"
                    value="default"
                    onChange={handleSettingsChange}
                    checked={survey.settings?.intro === 'default' || !survey.settings?.intro ? 'checked' : ''}
                  ></input>
                  <label className="custom-control-label" htmlFor="default">
                    Standard
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="custom"
                    type="radio"
                    name="intro"
                    value="custom"
                    onChange={handleSettingsChange}
                    checked={survey.settings?.intro === 'custom' ? 'checked' : ''}
                  ></input>
                  <label className="custom-control-label" htmlFor="custom">
                    Benutzerdefiniert
                  </label>
                </div>
              </div>
            </FormRow.Content>
          </FormRow>

          {(survey.type === 'survey' || survey.type === 'bfQuarter') && (
            <FormRow>
              <FormRow.Label htmlFor="preview">Vorschau</FormRow.Label>
              <FormRow.Content id="preview">
                <button id="preview" className="btn btn-primary mt-3" onClick={() => setShowPreview(true)}>
                  <FontAwesomeIcon icon={faEye} /> Anzeigen
                </button>
                {showPreview && (
                  <Modal
                    isPreview={true}
                    onHide={() => setShowPreview(!showPreview)}
                    maxWidth={'65rem'}
                    overflowY="auto"
                  >
                    <div className="d-flex align-items-center">
                      <h3>Vorschau</h3>
                    </div>
                    <hr></hr>
                    <SurveyOpener survey={survey} />
                  </Modal>
                )}
              </FormRow.Content>
            </FormRow>
          )}
          {/* TODO: Generate custom styling  */}
          {/* <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="primaryColor">
            Primärfarbe
          </label>
          <div className="col-sm-10">
            <input
              type="color"
              name="primaryColor"
              id="primaryColor"
              value={survey.primaryColor}
              onChange={handleInputChange}
            />
            {survey.primaryColor !== '#003e71' && (
              <button className="btn ml-2" onClick={() => handleColorReset('#003e71', 'primaryColor')}>
                <i className="fas fa-undo-alt"></i>
              </button>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="secondaryColor">
            Sekundärfarbe
          </label>
          <div className="col-sm-10">
            <input
              type="color"
              name="secondaryColor"
              id="secondaryColor"
              value={survey.secondaryColor}
              onChange={handleInputChange}
            />
            {survey.secondaryColor !== '#7c7e7d' && (
              <button className="btn ml-2" onClick={() => handleColorReset('#7c7e7d', 'secondaryColor')}>
                <i className="fas fa-undo-alt"></i>
              </button>
            )}
          </div>
        </div> */}
          <FormRow>
            <FormRow.Label htmlFor="fileUploader">Logo</FormRow.Label>
            <FormRow.Content>
              {survey.logo ? (
                <div>
                  <div style={{ width: '200px' }}>
                    <img style={{ width: '100%' }} src={survey.logo} alt="logo" />
                    <button className="btn btn-primary mt-3" onClick={() => handleImageDelete('logo')}>
                      Logo löschen
                    </button>
                  </div>
                  <div className="custom-control custom-checkbox mb-3 mt-5">
                    <input
                      className="custom-control-input"
                      name="useLogoInNav"
                      id="useLogoInNav"
                      type="checkbox"
                      onChange={handleSettingsChange}
                      checked={survey.settings?.useLogoInNav || false}
                    />
                    <label className="custom-control-label" htmlFor="useLogoInNav">
                      Logo in Navigationsleiste verwenden
                    </label>
                  </div>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    name="logo"
                    id="fileUploader"
                    onChange={(event) => handleFileUpload(event, 'logo')}
                  />
                  {fileErrors['logo'] &&
                    fileErrors['logo'].map((error) => <p className="text-danger">{error}</p>)}
                </div>
              )}
            </FormRow.Content>
          </FormRow>
          <FormRow>
            <FormRow.Label>Allgemeine Einstellungen</FormRow.Label>
            <FormRow.Content>
              <div className="custom-control custom-checkbox mb-4">
                <input
                  className="custom-control-input"
                  name="showStar"
                  id="showStar"
                  type="checkbox"
                  onChange={handleSettingsChange}
                  checked={survey.settings?.showStar || false}
                />
                <label className="custom-control-label" htmlFor="showStar">
                  Zeige * bei nicht-optionalen Fragen
                </label>
              </div>
              <div className="custom-control custom-checkbox mb-4">
                <input
                  className="custom-control-input"
                  name="showPageNo"
                  id="showPageNo"
                  type="checkbox"
                  onChange={handleSettingsChange}
                  checked={survey.settings?.showPageNo || false}
                />
                <label className="custom-control-label" htmlFor="showPageNo">
                  Seitenzahl im Titel
                </label>
              </div>
              <div className="custom-control custom-checkbox mb-4">
                <input
                  className="custom-control-input"
                  name="showQuestionNo"
                  id="showQuestionNo"
                  type="checkbox"
                  onChange={handleSettingsChange}
                  checked={survey.settings?.showQuestionNo || false}
                />
                <label className="custom-control-label" htmlFor="showQuestionNo">
                  Frage-Nr. im Titel
                </label>
              </div>
            </FormRow.Content>
          </FormRow>
          <FormRow>
            <FormRow.Label htmlFor="progress">Fortschritts-Anzeige</FormRow.Label>
            <FormRow.Content>
              <div className="col">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="numberOfPages"
                    type="radio"
                    name="progress"
                    value="numberOfPages"
                    onChange={handleSettingsChange}
                    checked={
                      survey?.settings?.progress === 'numberOfPages' || !survey?.settings?.progress
                        ? 'checked'
                        : ''
                    }
                  ></input>
                  <label className="custom-control-label" htmlFor="numberOfPages">
                    Seitenanzahl
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="progressBar"
                    type="radio"
                    name="progress"
                    value="progressBar"
                    onChange={handleSettingsChange}
                    checked={survey?.settings?.progress === 'progressBar' ? 'checked' : ''}
                  ></input>
                  <label className="custom-control-label" htmlFor="progressBar">
                    Fortschrittsbalken
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="pageIndicator"
                    type="radio"
                    name="progress"
                    value="pageIndicator"
                    onChange={handleSettingsChange}
                    checked={survey?.settings?.progress === 'pageIndicator' ? 'checked' : ''}
                  ></input>
                  <label className="custom-control-label" htmlFor="pageIndicator">
                    Seitenindikator
                  </label>
                </div>
              </div>
            </FormRow.Content>
          </FormRow>
        </SurveySection>
        <TemplateSave showTemplateSave={showTemplateSave} onHide={() => setShowTemplateSave(false)} />
        <TemplateLoad
          showTemplateLoad={showTemplateLoad}
          onHide={() => setShowTemplateLoad(false)}
          onLoaded={updateTinyMCEContent}
        />
        <Questionnaire questionnaires={survey.questionnaires} />
      </>
    )
  )
}
