import React, { useState, useCallback } from 'react'
import { Container } from '../../../components/Container'
import { AlertBox } from '../../../components/AlertBox'
import { useRegistrationDataStore } from '../dataStore'
import { RegistrationFeedback } from '../RegistrationFeedback'
import { Link } from 'react-router-dom'

const formValidation = (userInfo) => {
  const missingInput = {
    email: false,
    emailNotValid: false,
    first_name: false,
    last_name: false,
    password: false,
    password_repeat: false,
    gender: false,
    minLength: false,
    company: false,
    privacy: false,
  }
  if (!userInfo.email) {
    missingInput.email = true
  }

  if (!userInfo.email.match('@')) {
    missingInput.emailNotValid = true
  }

  if (!userInfo.password) {
    missingInput.password = true
  }

  if (userInfo.password !== userInfo.password_repeat) {
    missingInput.password = true
    missingInput.password_repeat = true
  }

  if (!userInfo.gender) {
    missingInput.gender = true
  }

  if (userInfo.password.length < 8) {
    missingInput.minLength = true
  }

  if (!userInfo.company) {
    missingInput.company = true
  }

  if (!userInfo.first_name) {
    missingInput.first_name = true
  }

  if (!userInfo.last_name) {
    missingInput.last_name = true
  }

  if (!userInfo.privacy) {
    missingInput.privacy = true
  }

  return missingInput
}

export const RiwisRegistrationFormular = () => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    password_repeat: '',
    first_name: '',
    last_name: '',
    gender: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    mobile: '',
    company: '',
    privacy: false,
    list_as_partner: false,
  })
  const [missingUserInput, setMissingUserInput] = useState(null)
  const [{ registrationMessage }, { registerUser }] = useRegistrationDataStore()

  const handleInputChange = useCallback(
    (event) => {
      const newUserInfo = { ...userInfo }
      if (event.target.type === 'checkbox') {
        newUserInfo[event.target.name] = event.target.checked
      } else {
        newUserInfo[event.target.name] = event.target.value
      }
      setUserInfo(newUserInfo)
    },
    [userInfo]
  )

  const handleRegistration = useCallback(() => {
    const missingInput = formValidation(userInfo)
    if (Object.values(missingInput).some((value) => value === true)) {
      setMissingUserInput(missingInput)
    } else {
      setMissingUserInput(null)
      registerUser(userInfo, true)
    }
  }, [registerUser, userInfo])

  return (
    <Container>
      {registrationMessage.type === 'success' ? (
        <RegistrationFeedback message={registrationMessage} />
      ) : (
        <div className="registrationFormular">
          <div className="row">
            <div className="col-6 text-left text-md-right my-5 px-5">
              <h1 className="display-5">Registrierung</h1>
            </div>
          </div>
          <div className="mt-4 col-md-9 col-sm-12">
            {registrationMessage && registrationMessage.type === 'failed' && (
              <div className="form-group row">
                <label
                  htmlFor="email"
                  className={
                    (missingUserInput && missingUserInput.email ? 'text-danger' : '') +
                    ' col text-md-right text-sm-left'
                  }
                ></label>
                <div className="col-sm-12 col-md-8">
                  <AlertBox
                    className="my"
                    variant="danger"
                    error={registrationMessage.err}
                    type={registrationMessage.type}
                    position={'relative'}
                  />
                </div>
              </div>
            )}
            <div style={{ marginBottom: '2rem' }}>
              <div className="form-group row">
                <label
                  htmlFor="email"
                  className={
                    (missingUserInput && missingUserInput.email ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  E-Mail*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {missingUserInput && missingUserInput.emailNotValid && (
                <div className="form-group row">
                  <label className="col d-none d-md-block">&nbsp;</label>
                  <div className="col-md col-sm-12 text-danger">E-Mail Addresse ungültig</div>
                </div>
              )}

              <div className="form-group row">
                <label
                  htmlFor="password"
                  className={
                    (missingUserInput && missingUserInput.password ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  Passwort*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                  <div
                    className={
                      (missingUserInput && missingUserInput.minLength ? 'text-danger' : '') + ' mt-2'
                    }
                    style={{ fontSize: '0.75rem' }}
                  >
                    (mindestens 8 Zeichen)
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="password_repeat"
                  className={
                    (missingUserInput && missingUserInput.password_repeat ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  Passwort wiederholen*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="password"
                    name="password_repeat"
                    id="password_repeat"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {missingUserInput && missingUserInput.password && missingUserInput.password_repeat && (
                <div className="form-group row">
                  <label className="col d-none d-md-block">&nbsp;</label>
                  <div className="col text-danger">Passwörter stimmen nicht überein</div>
                </div>
              )}
            </div>

            <div style={{ marginBottom: '2rem' }}>
              <div className="form-group row">
                <label
                  htmlFor="company"
                  className={
                    (missingUserInput && missingUserInput.company ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  Firma*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginBottom: '2rem' }}>
              <div className="form-group row">
                <label
                  htmlFor="first_name"
                  className={
                    (missingUserInput && missingUserInput.first_name ? 'text-danger' : '') +
                    ' col text-md-right text-sm-left'
                  }
                >
                  Vorname*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="last_name"
                  className={
                    (missingUserInput && missingUserInput.last_name ? 'text-danger' : '') +
                    ' col text-md-right text-sm-left'
                  }
                >
                  Nachname*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="gender"
                  className={
                    (missingUserInput && missingUserInput.gender ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  Geschlecht*
                </label>
                <div className="col-sm-12 col-md">
                  <select className="form-control" name="gender" onChange={handleInputChange}>
                    <option value="">bitte wählen</option>
                    <option value="W">weiblich</option>
                    <option value="M">männlich</option>
                    <option value="D">divers</option>
                  </select>
                </div>
              </div>
            </div>

            <div style={{ marginBottom: '2rem' }}>
              <div className="form-group row">
                <label htmlFor="street" className="col col-form-label text-md-right text-sm-left">
                  Straße
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="street"
                    id="street"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="zip" className="col col-form-label text-md-right text-sm-left">
                  PLZ
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="zip"
                    id="zip"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="city" className="col col-form-label text-md-right text-sm-left">
                  Ort
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="phone" className="col col-form-label text-md-right text-sm-left">
                Tel.
              </label>
              <div className="col-sm-12 col-md">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col text-md-right text-sm-left">Als Datenpartner listen</label>
              <div className="col-sm-12 col-md">
                <div className="d-flex mb-2">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="list_as_partner"
                      id="list_as_partner"
                      checked={userInfo.list_as_partner}
                      onChange={handleInputChange}
                    ></input>
                    <label className="custom-control-label" htmlFor="list_as_partner">
                      Zustimmung
                    </label>
                  </div>
                </div>
                <div>
                  Ja ich möchte als Datenpartner auf der Website{' '}
                  <Link to={{ pathname: 'https://www.riwis.de' }} target="_blank">
                    www.riwis.de
                  </Link>{' '}
                  als Marktexpert*in aufgelistet werden
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label
                className={
                  (missingUserInput && missingUserInput.privacy ? 'text-danger' : '') +
                  ' col text-md-right text-sm-left'
                }
              >
                Datenschutzhinweis*
              </label>
              <div className="col-sm-12 col-md">
                <div className="d-flex mb-2">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="privacy"
                      id="privacy"
                      checked={userInfo.privacy}
                      onChange={handleInputChange}
                    ></input>
                    <label className="custom-control-label" htmlFor="privacy">
                      Einwilligung
                    </label>
                  </div>
                </div>
                Ich habe die{' '}
                <Link
                  to={{ pathname: 'https://www2.bulwiengesa.de/mde/pdf/datenschutzhinweis.pdf' }}
                  target="_blank"
                >
                  Datenschutzerklärung
                </Link>{' '}
                gelesen und akzeptiert. Ich erteile die in der Datenschutzerklärung aufgeführten
                Einwilligungen in die Verarbeitung und Nutzung meiner Daten.
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-12 col-md text-right my-3">
                <button
                  className="riwis-btn riwis-primary"
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                  onClick={handleRegistration}
                >
                  Registrieren
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}
