import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { generateId } from './utils'
import { useSurveyEditorStateStore } from './surveyEditorState'

const Options = ({ options }) => {
  if (Array.isArray(options)) {
    return options.map((option) => (
      <option
        key={`${option.questionnaireId}-${option.pageId}-${option.qid}`}
        value={option.qid}
        dangerouslySetInnerHTML={{ __html: option.text }}
      ></option>
    ))
  } else if (typeof options === 'object') {
    return Object.entries(options).map(([groupTitle, options]) => (
      <optgroup key={groupTitle} label={groupTitle}>
        {options.map((option) => (
          <option
            key={`${option.questionnaireId}-${option.pageId}-${option.qid}`}
            value={option.qid}
            dangerouslySetInnerHTML={{ __html: option.text }}
          ></option>
        ))}
      </optgroup>
    ))
  }
}

const Operators = ({ operator, onChange }) => {
  return (
    <select value={operator || 'and'} onChange={onChange}>
      <option value="and">und</option>
      <option value="or">oder</option>
      {/* <option value="nand">und nicht</option>
      <option value="nor">oder nicht</option> */}
    </select>
  )
}

export const ConstraintBox = ({ selected, changeHandler, options, index, constraintsLength }) => {
  const [when, setWhen] = useState(selected?.when ?? `intern-${index}`)
  const [has, setHas] = useState(selected?.has ?? `intern-${index}`)
  const [operator, setOperator] = useState(selected.operator)

  const handleWhenChange = useCallback(
    (event) => {
      const { value } = event.target
      setWhen(event.target.value)
      if (event.target.value === `intern-${index}`) {
        setHas(event.target.value)
        changeHandler({ type: 'display', when: value, has: value, cid: selected.cid, operator }, index, false)
      }
    },
    [changeHandler, index, operator, selected.cid]
  )

  const handleHasChange = useCallback(
    (event) => {
      let has = event.target.value
      if (typeof has === 'string' && !isNaN(Number(has))) {
        has = Number(has)
      }
      setHas(has)
      if (typeof changeHandler === 'function') {
        changeHandler({ type: 'display', when, has: has, cid: selected.cid, operator }, index, false)
      }
    },
    [changeHandler, when, index, selected, operator]
  )

  const handleOperatorChange = useCallback(
    (event) => {
      setOperator(event.target.value)
      if (typeof changeHandler === 'function') {
        changeHandler(
          { type: 'display', when, has: parseInt(has), cid: selected.cid, operator: event.target.value },
          index,
          false
        )
      }
    },
    [changeHandler, has, index, selected, when]
  )

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation()
      changeHandler(null, index, true)
    },
    [changeHandler, index]
  )

  const constraint = useMemo(() => {
    if (Array.isArray(options)) {
      return when && when !== `intern-${index}` && options.find((option) => option.qid === when)
    } else if (typeof options === 'object') {
      return (
        when &&
        when !== `intern-${index}` &&
        Object.values(options).reduce((obj, optionGroup) => {
          const found = optionGroup.find((c) => c.qid === when)
          if (found) {
            obj = found
          }
          return obj
        }, null)
      )
    }
  }, [options, when, index])

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <span>sichtbar wenn</span>
        <select
          value={when}
          onChange={handleWhenChange}
          style={{
            width: '20%',
            maxWidth: '20%',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
          }}
        >
          <option key="0" value={`intern-${index}`}>
            &mdash; Frage wählen
          </option>
          <Options options={options} />
        </select>
        <span>hat</span>
        <select
          className="mx-3"
          value={has}
          onChange={handleHasChange}
          style={{ width: '20%', maxWidth: '20%' }}
        >
          <option key="0" value={`intern-${index}`}>
            &mdash; Antwort wählen
          </option>
          {
            // this.state.when && this.state.when !== 'intern-0' && this.state.when.has.map(has => <option key={has.id} value={has.id}>{has.text}</option>)
            constraint &&
              constraint.has.map((has) => (
                <option key={has.id} value={has.id}>
                  {has.text || has.title}
                </option>
              ))
          }
        </select>
        <FontAwesomeIcon
          icon={faTrashAlt}
          size="sm"
          className="ml-3 trashCan btn btn-outline-secondary btn-sm"
          onClick={handleDelete}
        />
      </div>
      {constraintsLength > 1 && constraintsLength - 1 !== index && (
        <div className="mb-4">
          <Operators operator={operator} onChange={handleOperatorChange} />
        </div>
      )}
    </div>
  )
}

export const Constraints = ({ constraints = [], crossPagesAlwaysOn = false, onChange }) => {
  const [{ survey, questionnaireTab, pageTab }] = useSurveyEditorStateStore()
  const [crossPages, setCrossPages] = useState(crossPagesAlwaysOn)

  const handleChange = useCallback(
    (constraint, index, deleted) => {
      let newConstraints = [...constraints]
      newConstraints[index] = constraint

      if (deleted) {
        newConstraints.splice(index, 1)
        if (index !== 0) {
          newConstraints[index - 1].operator = null
        }
      }
      if (typeof onChange === 'function') {
        onChange(newConstraints)
      }
    },
    [constraints, onChange]
  )

  const addConstraint = useCallback(() => {
    const newConstraints = [...constraints]
    if (newConstraints.length > 0 && newConstraints[newConstraints.length - 1].operator === null) {
      newConstraints[newConstraints.length - 1].operator = 'and'
    }
    newConstraints.push({ cid: generateId(4) })
    if (typeof onChange === 'function') {
      onChange(newConstraints)
    }
  }, [constraints, onChange])

  const handleCrossPages = useCallback((event) => {
    setCrossPages(event.target.checked)
  }, [])

  const [crossPageOptions, currentPageOptions] = useMemo(() => {
    const crossPageOptions = {}
    let currentPageOptions = []
    if (questionnaireTab >= 0) {
      if (!Array.isArray(survey.questionnaires[questionnaireTab].pages)) {
        return []
      }
      survey.questionnaires[questionnaireTab].pages.forEach((page, index) => {
        const groupTitle = `Seite ${index + 1}`
        if (page.questions) {
          const options = page.questions
            .map((q) => {
              const has = []
              if (['sum', 'inputColumns', 'contactForm', 'dropdown'].includes(q.type)) {
                has.push(...q.questions_field)
              } else {
                has.push(...q.possible_answers)
              }
              if (q.no_answer) {
                has.push(q.no_answer)
              }
              return {
                id: q.id,
                qid: q.qid,
                text: q.label,
                has: has,
                pageId: page.id,
                questionnaireId: survey.questionnaires[questionnaireTab].qnid,
                noAnswer: q.no_answer,
              }
            })
            .filter((item) => item !== undefined && item.has)

          if (pageTab === index) {
            currentPageOptions = options
          }

          crossPageOptions[groupTitle] = options
        }
      })
    }

    return [crossPageOptions, currentPageOptions]
  }, [survey, questionnaireTab, pageTab])

  // determine if set up conditions are cross page selections
  useEffect(() => {
    if (constraints.length > 0 && Object.keys(crossPageOptions).length > 0) {
      let isCrossPage = false
      const pageOptionsValues = Object.values(crossPageOptions)
      for (let i = 0; i < pageOptionsValues.length; i++) {
        for (const constraint of constraints) {
          if (pageOptionsValues[i].some((option) => option.qid === constraint.when)) {
            if (i !== pageTab) {
              isCrossPage = true
              break
            } else {
              isCrossPage = false
            }
          }
        }
        if (isCrossPage === true) {
          break
        }
      }
      setCrossPages(isCrossPage)
    }
  }, [constraints, crossPageOptions, pageTab])

  return (
    <div>
      {constraints.length > 0 && (
        <div>
          {crossPagesAlwaysOn === false && (
            <div>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  name="crossPages"
                  id="crossPages"
                  type="checkbox"
                  onChange={handleCrossPages}
                  checked={crossPages}
                />
                <label className="custom-control-label" htmlFor="crossPages">
                  Seitenübergreifend
                </label>
              </div>
            </div>
          )}
          {constraints.map((constraint, index) => (
            <ConstraintBox
              key={constraint?.cid}
              changeHandler={handleChange}
              options={crossPages ? crossPageOptions : currentPageOptions}
              selected={constraint}
              index={index}
              constraintsLength={constraints.length}
            />
          ))}
        </div>
      )}
      <div>
        <button type="button" className="btn-sm btn-outline-secondary text-primary" onClick={addConstraint}>
          <b>+</b>
        </button>
      </div>
    </div>
  )
}
