import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react'
import ScrollArrow from '../../assets/img/arrow-down.svg'
// import { checkConstraints } from './QuestionnaireBuilder/helper'
import { useTranslation } from 'react-i18next'
import { CustomCode } from './Custom'
import { Intro } from './SurveyIntros'

export const SurveyOpener = ({ survey, handleCurrentQuestionaire }) => {
  const initRender = useRef(true)
  const [scrollArrowOpacity, setScrollArrowOpacity] = useState(1)
  const [showScrollArrow, setShowScrollArrow] = useState(true)
  const startButtonRef = useRef(null)
  const scrollToArrow = useRef(null)
  const { t } = useTranslation()

  const handleScroll = useCallback(() => {
    if (scrollToArrow.current) {
      const arrowTop = scrollToArrow.current.getBoundingClientRect().top
      const arrowHeight = scrollToArrow.current.getBoundingClientRect().height
      const containerTop = startButtonRef.current.getBoundingClientRect().top
      if (containerTop - arrowHeight <= arrowTop) {
        const diff = containerTop - arrowTop
        const opacity = (diff * 100) / arrowHeight / 100
        setScrollArrowOpacity(opacity)
      } else {
        setScrollArrowOpacity(1)
      }
      if (containerTop <= arrowTop) {
        if (showScrollArrow) {
          setShowScrollArrow(false)
        }
      } else {
        if (!showScrollArrow) {
          setShowScrollArrow(true)
        }
      }
    }
  }, [showScrollArrow])

  // const answers = useMemo(() => {
  //   const arr = []
  //   if (survey && survey.questionnaires)
  //     survey.questionnaires.forEach((questionnaire) => {
  //       if (questionnaire.pages)
  //         questionnaire.pages.forEach((page) => {
  //           if (page.questions)
  //             page.questions.forEach((question) => {
  //               arr.push(question.given_answer || { question_id: question.id, value: { value: '' } })
  //             })
  //         })
  //     })
  //   return arr
  // }, [survey])

  const constraintsFullfilled = useCallback((constraints, questionnaire) => {
    return true
    // todo fix questionnaire constraints -> answers of user needed

    // if (!constraints || constraints?.length === 0) return true
    // console.log(questionnaire)

    // // checkConstraints(constraints)

    // const constraint = constraints // shall be an array in future
    // const foundAnswer = answers.find((answer) => answer.qid === constraint.when)

    // const contains = (container, value) => {
    //   // eslint-disable-next-line
    //   return Array.isArray(container) ? container.some((item) => item == value) : container == value
    // }

    // if (foundAnswer) {
    //   return (
    //     foundAnswer.value && foundAnswer.value.value && contains(foundAnswer.value.value, constraint.has)
    //   )
    // } else {
    //   return false
    // }
  }, [])

  useLayoutEffect(() => {
    if (initRender.current === true) {
      initRender.current = false
      if (startButtonRef.current) {
        const containerTop = startButtonRef.current.getBoundingClientRect().top
        if (containerTop <= window.innerHeight) {
          setShowScrollArrow(false)
        }
      }
    }
  }, [])

  const renderSingleQuestionnaire = useCallback(
    (questionnaire, index) => {
      const numPages = questionnaire.pages ? questionnaire.pages.length : 0
      // eslint-disable-next-line
      const numPagesText = numPages + (numPages === 0 || numPages >= 2 ? ' Seiten' : ' Seite')

      return (
        <div className="questionnaireWidget" key={questionnaire.id}>
          <div className="d-flex flex-column justify-content-between flex-1">
            <div>
              <div className="questionnaireWidgetMeta">
                <div>
                  {t('survey.opener.questionnaire')} {'#' + (index + 1)}
                </div>
                {/* <div>{numPagesText}</div> */}
              </div>
              <h4 className="text-primary mb-4">{questionnaire.title}</h4>
              <div
                className="questionnaireWidgetBody"
                dangerouslySetInnerHTML={{ __html: questionnaire.subtitle }}
              ></div>
            </div>
            <button
              disabled={typeof handleCurrentQuestionaire !== 'function'}
              onClick={() => handleCurrentQuestionaire(questionnaire.id)}
              className="btn btn-light btn-white"
            >
              {t('survey.opener.answer')}
            </button>
          </div>
        </div>
      )
    },
    [handleCurrentQuestionaire, t]
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div className={`openerWindow ${survey.type}`}>
      <Intro
        survey={survey}
        questionnaire={survey?.questionnaires?.length === 1 ? survey.questionnaires[0] : null}
        handleCurrentQuestionaire={handleCurrentQuestionaire}
        ref={startButtonRef}
      />

      <CustomCode name={survey.settings.customCode} />

      {
        survey && survey.questionnaires?.length > 1 && (
          <div id="start" className="questionnaireContainer" ref={startButtonRef}>
            <QuestionnaireWidget
              questionnaires={survey.questionnaires}
              handleCurrentQuestionaire={handleCurrentQuestionaire}
            />
          </div>
        )
        // (q, index) => constraintsFullfilled(q.constraints, q) && renderSingleQuestionnaire(q, index)
      }
      <div
        className={(showScrollArrow ? 'visible' : 'invisible') + ' scrollToArrow'}
        style={{ opacity: scrollArrowOpacity }}
        ref={scrollToArrow}
      >
        <a href="#start">
          <img width="100%" alt="scrollArrow" src={ScrollArrow}></img>
        </a>
      </div>
    </div>
  )
}

const QuestionnaireWidget = ({ questionnaires, handleCurrentQuestionaire }) => {
  const { t } = useTranslation()
  if (questionnaires.length > 1) {
    return questionnaires.map((questionnaire) => (
      <div className="questionnaireWidget" key={questionnaire.id}>
        <div className="d-flex flex-column justify-content-between flex-1">
          <div>
            <div className="questionnaireWidgetMeta">
              <div>
                {t('survey.opener.questionnaire')} {'#' + 1}
              </div>
              {/* <div>{numPagesText}</div> */}
            </div>
            <h4 className="text-primary mb-4">{questionnaire.title}</h4>
            <div
              className="questionnaireWidgetBody"
              dangerouslySetInnerHTML={{ __html: questionnaire.subtitle }}
            ></div>
          </div>
          <button
            disabled={typeof handleCurrentQuestionaire !== 'function'}
            onClick={() => handleCurrentQuestionaire(questionnaire.id)}
            className="btn btn-light btn-white"
          >
            {t('survey.opener.answer')}
          </button>
        </div>
      </div>
    ))
  } else {
    return null
  }
}
