import { Auth, Registration } from '../../helper/api'
import createStore from '../../lib/flux-store'

const initialState = {
  dataIsLoading: false,
  registrationMessage: { msg: '', type: '', err: '' },
  redirectTo: null,
  registeredUser: null,
}

const actions = {
  registerUser: (userInfo, isRiwisUser) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Registration.register(userInfo, isRiwisUser)
      .then((res) => {
        dispatch({ type: 'setDataIsLoading', payload: false })
        if (res.data.msg === 'failed') {
          dispatch({
            type: 'setRegistrationMessage',
            registrationMessage: { type: 'failed', err: res.data.error, msg: '' },
          })
        } else {
          dispatch({
            type: 'setRegistrationMessage',
            registrationMessage: { type: 'success', err: '', msg: '' },
          })
        }
      })
      .catch(() => {
        dispatch({
          type: 'setRegistrationMessage',
          registrationMessage: { type: 'failed', err: 'Registrierung fehlgeschlagen.', msg: '' },
        })
      })
  },
  verifyUser: (token) => (dispatch) => {
    Registration.verification(token).then((res) => {
      if (res.data.msg === 'failed') {
        dispatch({
          type: 'setRegistrationMessage',
          registrationMessage: { type: 'failed', err: res.data.error, msg: '' },
        })
      } else {
        dispatch({
          type: 'setRegistrationMessage',
          registrationMessage: { type: 'success', err: '', msg: '' },
        })
        const token = res.data.uuid
        if (token) {
          Auth.token(token).then((res) => {
            if (res.data.msg === 'failed') {
              dispatch({
                type: 'setRegistrationMessage',
                registrationMessage: { msg: 'token error', type: 'failed', err: res.data.error },
              })
            } else {
              dispatch({ type: 'loginUserToken', payload: res.data })
            }
          })
        }
      }
    })
  },
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setRegistrationMessage: (state, { registrationMessage }) => {
    return { ...state, registrationMessage }
  },
  loginUserToken: (state, { payload }) => {
    return { ...state, registeredUser: payload.user, redirectTo: payload.redirectTo }
  },
}

export const [RegistrationDataContext, RegistrationDataProvider, useRegistrationDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'RegistrationDataStore'
)
