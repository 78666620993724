import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSurveyDataStore } from '../../dataStore'

const OuterBar = styled('div')({
  borderRadius: '0.75rem',
  width: '100%',
  height: '9px',
  overflow: 'hidden',
  border: '1px solid lightgrey',
  padding: '1px',
})

const InnerBar = styled('div')({
  width: ({ width }) => width,
  borderRadius: '0.75rem',
  height: '100%',
  backgroundColor: '#003e71',
})

const BarWrapper = styled('div')({
  width: '200px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '0 1rem',
  marginTop: '0.5rem',
})

const PageBubble = ({ id, isSelected }) => {
  return <span id={id} className={(isSelected ? 'bg-primary' : 'bg-secondary') + ' indicatorItem'}></span>
}

const PageIndicator = ({ pageCount, pageNo }) => {
  const pages = useMemo(() => {
    const pages = new Array(pageCount - 1)
    pages.fill(0)
    return pages.map((_, index) => ({ id: index, isSelected: index === pageNo }))
  }, [pageCount, pageNo])

  return (
    <div>
      <div className="desktopPageIndicator">
        {pages.map(({ id, isSelected }) => (
          <PageBubble key={id} id={id} isSelected={isSelected} />
        ))}
      </div>
      <div className="mobilePageIndicator">{pageNo + 1 + '/' + pages.length}</div>
    </div>
  )
}

const NumberOfPages = ({ pageCount, pageNo }) => {
  const pages = useMemo(() => {
    const pages = new Array(pageCount - 1)
    pages.fill(0)
    return pages.map((_, index) => ({ id: index, isSelected: index === pageNo }))
  }, [pageCount, pageNo])

  return (
    <div>
      <div className="desktopPageIndicator">
        <div className="pagination">{pageNo + 1 + '/' + pages.length}</div>
      </div>
      <div className="mobilePageIndicator">{pageNo + 1 + '/' + pages.length}</div>
    </div>
  )
}

const Bar = ({ progress }) => {
  return (
    <BarWrapper>
      <OuterBar>
        <InnerBar width={progress} />
      </OuterBar>
      <span>{progress}</span>
    </BarWrapper>
  )
}

const ProgressBar = () => {
  const [{ pages }] = useSurveyDataStore()

  const progress = useMemo(() => {
    let questionCount = 0
    let answerdQuestionCount = 0
    pages.forEach((page) => {
      page.questions.forEach((question) => {
        if (!question.is_optional) {
          questionCount++
          const value = question?.given_answer?.value?.value
          if (value) {
            if ((typeof value === 'string' && value.length) || typeof value === 'number') {
              answerdQuestionCount++
            } else if (Array.isArray(value) && value.length) {
              answerdQuestionCount++
            } else if (
              !Array.isArray(value) &&
              typeof value === 'object' &&
              Object.values(value).length === question.questions_field.length
            ) {
              answerdQuestionCount++
            } else if (
              question.type === 'slider' &&
              (value?.sliderValue !== question?.default_value || value?.noAnswer === true)
            ) {
              answerdQuestionCount++
            } else if (typeof value === 'object' && value?.noAnswer === true) {
              answerdQuestionCount++
            }
          }
        }
      })
    })
    return (answerdQuestionCount / questionCount) * 100
  }, [pages])

  if (!pages) {
    return null
  }
  return <Bar progress={Math.ceil(progress) + '%'} />
}

export const Progress = ({ type = 'numberOfPages', pageCount, pageNo }) => {
  if (type === 'numberOfPages') {
    return <NumberOfPages pageCount={pageCount} pageNo={pageNo} />
  } else if (type === 'progressBar') {
    return <ProgressBar />
  } else if (type === 'pageIndicator') {
    return <PageIndicator pageCount={pageCount} pageNo={pageNo} />
  }
}
