export const categories = {
  md_office_market: { value: 'md_office_market', name: 'Büro', fmName: 'b' },
  md_residential_market: { value: 'md_residential_market', name: 'Wohnen', fmName: 'w' },
  md_logistics_market: { value: 'md_logistics_market', name: 'Industrie', fmName: 'g' },
  md_retail_market: { value: 'md_retail_market', name: 'Handel', fmName: 'e' },
}

export const topics = {
  md_office_market: {
    rent_city: {
      value: 'rent_city',
      name: 'City (Innenstadtbürolage)',
      fmName: 'city',
    },
    rent_city_fringe: {
      value: 'rent_city_fringe',
      name: 'Cityrand',
      fmName: 'cityrand',
    },
    rent_office_agglomeration: {
      value: 'rent_office_agglomeration',
      name: 'Bürozentrum',
      fmName: 'bz',
    },
    rent_suburb: {
      value: 'rent_suburb',
      name: 'Peripherie',
      fmName: 'peripherie',
    },
    vacancy: {
      value: 'vacancy',
      name: 'Leerstand',
      fmName: 'le',
    },
    turnover: {
      value: 'turnover',
      name: 'Flächenumsatz',
      fmName: 'fu',
    },
    yield: {
      value: 'yield',
      name: 'Vervielfacher',
      fmName: 'vvf',
    },
    space_new_total: {
      value: 'space_new_total',
      name: 'Neuzugang',
      fmName: 'neuzugang_ges',
    },
  },
  md_residential_market: {
    price_condo_new: {
      value: 'price_condo_new',
      name: 'Neubaupreise Eigentumswohnung',
      fmName: 'etwneu',
    },
    price_condo_existing: {
      value: 'price_condo_existing',
      name: 'Bestandspreise Eigentumswohnung',
      fmName: 'etwbest',
    },
    rent_new: {
      value: 'rent_new',
      name: 'Neubaumiete Wohnungen',
      fmName: 'mieteneu',
    },
    rent_existing: {
      value: 'rent_existing',
      name: 'Bestandsmiete Wohnungen',
      fmName: 'mietebest',
    },
    multiplier_multi_family: {
      value: 'multiplier_multi_family',
      name: 'Vervielfacher für Mehrfamilienhäuser',
      fmName: 'mfh_vvf',
    },
    price_plot_multi_family: {
      value: 'price_plot_multi_family',
      name: 'Preise für Grundstücke für Mehrfamilienhäuser',
      fmName: 'grundstmfh',
    },
    price_detached_house: {
      value: 'price_detached_house',
      name: 'Preise für Einfamilienhäuser',
      fmName: 'efh',
    },
    price_plot_detached_house: {
      value: 'price_plot_detached_house',
      name: 'Preise für Grundstücke für Einfamilienhäuser',
      fmName: 'grundstefh',
    },
    price_row_hs_new: {
      value: 'price_row_hs_new',
      name: 'Neubaupreise für Reihenhäuser',
      fmName: 'rhneu',
    },
    price_row_hs_existing: {
      value: 'price_row_hs_existing',
      name: 'Bestandspreise für Reihenhäuser',
      fmName: 'rhbest',
    },
  },
  md_logistics_market: {
    rent_logistics: {
      value: 'rent_logistics',
      name: 'Miete Logistik- und Lagerflächen',
      fmName: 'lager',
    },
    rent_service: {
      value: 'rent_service',
      name: 'Miete Serviceflächen',
      fmName: 'service',
    },
    price_plot_commercial: {
      value: 'price_plot_commercial',
      name: 'Kaufpreis für Gewerbegrundstück',
      fmName: 'grund',
    },
    yield_logistics: {
      value: 'yield_logistics',
      name: 'VVF Lagerhallen',
      fmName: 'vvf_lagerhalle',
    },
  },
  md_retail_market: {
    rent_city: {
      value: 'rent_city',
      name: 'Miete Einzelhandel in 1a Lagen',
      fmName: 'miete',
    },
    rent_1b: {
      value: 'rent_1b',
      name: 'Miete Einzelhandel in 1b lagen',
      fmName: 'miete',
    },
    rent_secondary: {
      value: 'rent_secondary',
      name: 'Miete Einzelhandel in Stadtteillagen',
      fmName: 'miete',
    },
    yield: {
      value: 'yield',
      name: 'VVF Einzelhandel',
      fmName: 'vvf',
    },
  },
}

export const subTopics = {
  // office market
  rent_city: [
    { value: 'max', name: 'Spitzenmiete', fmName: 'miete_s', fmNameRM: 's_1a' },
    { value: 'average', name: 'Durchschnittsmiete', fmName: 'miete_d', fmNameRM: 'd_1a' },
    { value: 'max_large', name: 'Spitzenmiete (groß)', fmNameRM: 'gr_s_1a' },
    { value: 'average_large', name: 'Durchscnittsmiete (groß)', fmNameRM: 'gr_d_1a' },
  ],
  rent_city_fringe: [
    { value: 'max', name: 'Spitzenmiete', fmName: 'miete_s' },
    { value: 'average', name: 'Durchschnittsmiete', fmName: 'miete_d' },
  ],
  rent_office_agglomeration: [
    { value: 'max', name: 'Spitzenmiete', fmName: 'miete_s' },
    { value: 'average', name: 'Durchschnittsmiete', fmName: 'miete_d' },
  ],
  rent_suburb: [
    { value: 'max', name: 'Spitzenmiete', fmName: 'miete_s' },
    { value: 'average', name: 'Durchschnittsmiete', fmName: 'miete_d' },
  ],
  vacancy: [],
  turnover: [],
  // residential market
  price_condo_new: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  price_condo_existing: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  rent_new: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  rent_existing: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  multiplier_multi_family: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  price_plot_multi_family: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  price_detached_house: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  price_plot_detached_house: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  price_row_hs_new: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  price_row_hs_existing: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  // logistics market
  rent_logistics: [
    { value: 'max', name: 'Maximum', fmName: 'miete_s' },
    { value: 'average', name: 'Durchschnitt', fmName: 'miete_d' },
  ],
  rent_service: [
    { value: 'max', name: 'Maximum', fmName: 'miete_s' },
    { value: 'average', name: 'Durchschnitt', fmName: 'miete_d' },
  ],
  price_plot_commercial: [
    { value: 'max', name: 'Maximum', fmName: 'max' },
    { value: 'min', name: 'Minimum', fmName: 'min' },
    { value: 'average', name: 'Durchschnitt', fmName: 'durch' },
  ],
  yield_logistics: [
    { value: 'min', name: 'VVF Spitze', fmName: 'max' },
    { value: 'average', name: 'VVF Durchschnitt', fmName: 'durch' },
  ],
  // retail_market (1a is rent_city)
  rent_1b: [
    { value: 'max', name: 'Spitze', fmName: 's_1b' },
    { value: 'max_large', name: 'Spitze (groß)', fmName: 'gr_s_1b' },
  ],
  rent_secondary: [
    { value: 'max', name: 'Spitze', fmName: 's_nebenlage' },
    { value: 'average', name: 'Durchschnitt', fmName: 'd_nebenlage' },
    { value: 'max_large', name: 'Spitze (groß)', fmName: 'gr_s_nebenlage' },
    { value: 'average_large', name: 'Durchschnitt (groß)', fmName: 'gr_d_nebenlage' },
  ],
  yield: [
    { value: 'decentral', name: 'Dezentral', fmName: 'dezentral' },
    { value: 'central', name: 'Zentral', fmName: 'zentral' },
  ],
  space_new_total: [],
}

export const matrixTopics = {
  md_office_market: [
    { fmName: 'zentral_neu_marktsituation' },
    { fmName: 'dezentral_neu_marktsituation' },
    { fmName: 'zentral_bestand_marktsituation' },
    { fmName: 'dezentral_bestand_marktsituation' },
  ],
  md_residential_market: [
    { fmName: 'mietebest_marktsituation' },
    { fmName: 'mieteneu_marktsituation' },
    { fmName: 'etwbest_marktsituation' },
    { fmName: 'etwneu_marktsituation' },
    { fmName: 'haeuser_marktsituation' },
  ],
  md_logistics_market: [
    { fmName: 'neubau_marktsituation' },
    { fmName: 'bestand_gut_marktsituation' },
    { fmName: 'bestand_einfach_marktsituation' },
  ],
  md_retail_market: [
    { fmName: '1a_groß_marktsituation' },
    { fmName: '1b_groß_marktsituation' },
    { fmName: 'stadtteil_groß_marktsituation' },
    { fmName: '1a_klein_marktsituation' },
    { fmName: '1b_klein_marktsituation' },
    { fmName: 'stadtteil_klein_marktsituation' },
  ],
}

export const radioTopix = {
  md_office_market: [],
  md_residential_market: [{ fmName: 'leerstand_qualitativ' }],
  md_logistics_market: [{ fmName: 'leerstand_qualitativ' }],
  md_retail_market: [],
}

export const commentCategories = {
  b_bemerkung_mieten: [
    'b_miete_s_city',
    'b_miete_d_city',
    'b_miete_s_cityrand',
    'b_miete_d_cityrand',
    'b_miete_s_bz',
    'b_miete_d_bz',
    'b_miete_s_peripherie',
    'b_miete_d_peripherie',
  ],
  b_bemerkung_vvf: ['b_vvf_zentral', 'b_vvf_dezentral'],
  b_marktsituation_bemerkung: [
    'b_zentral_neu_marktsituation',
    'b_dezentral_neu_marktsituation',
    'b_zentral_bestand_marktsituation',
    'b_dezentral_bestand_marktsituation',
  ],
  b_bemerkung_le: ['b_le'],
  b_fu_vertraege: ['b_fu'],
  bu_neuzugang_ges_nennung: ['b_neuzugang_ges'],
  g_bemerkung_mieten: ['g_miete_s_lager', 'g_miete_d_lager', 'g_miete_s_service', 'g_miete_d_service'],
  ge_vvf_bemerkung: ['g_vvf_lagerhalle_max', 'g_vvf_lagerhalle_durch'],
  g_grund_bemerkung: ['g_max_grund', 'g_durch_grund', 'g_min_grund'],
  g_marktsituation_bemerkung: [
    'g_neubau_marktsituation',
    'g_bestand_gut_marktsituation',
    'g_bestand_einfach_marktsituation',
  ],
  g_leerstand_bemerkung: ['g_leerstand_qualitativ'],
  eh_miete_gr_bemerkung: [
    'e_miete_s_1a',
    'e_miete_d_1a',
    'e_miete_s_1b',
    'e_miete_s_nebenlage',
    'e_miete_d_nebenlage',
    'e_miete_gr_s_nebenlage',
    'e_miete_gr_d_nebenlage',
    'e_miete_gr_s_1a',
    'e_miete_gr_d_1a',
    'e_miete_gr_s_1b',
  ],
  eh_vvf_bemerkung: ['e_vvf_zentral', 'e_vvf_dezentral'],
  eh_marktsituation_bemerkung: [
    'e_1a_klein_marktsituation',
    'e_1b_klein_marktsituation',
    'e_stadtteil_klein_marktsituation',
    'e_1a_groß_marktsituation',
    'e_1b_groß_marktsituation',
    'e_stadtteil_groß_marktsituation',
  ],
  w_bemerkung_wohnen_etw: [
    'w_etwneu_max',
    'w_etwneu_durch',
    'w_etwneu_min',
    'w_etwbest_max',
    'w_etwbest_durch',
    'w_etwbest_min',
  ],
  w_bemerkung_wohnen_miet: [
    'w_mieteneu_max',
    'w_mieteneu_durch',
    'w_mieteneu_min',
    'w_mietebest_max',
    'w_mietebest_durch',
    'w_mietebest_min',
  ],
  wo_bemerkung_wohnen_vvf: ['w_mfh_vvf_max', 'w_mfh_vvf_durch', 'w_mfh_vvf_min'],
  w_bemerkung_wohnen: [
    'w_efh_max',
    'w_efh_durch',
    'w_efh_min',
    'w_rhneu_max',
    'w_rhneu_durch',
    'w_rhneu_min',
    'w_rhbest_max',
    'w_rhbest_durch',
    'w_rhbest_min',
  ],
  w_bemerkung_wohnen_grundst: [
    'w_grundstmfh_max',
    'w_grundstmfh_durch',
    'w_grundstmfh_min',
    'w_grundstefh_max',
    'w_grundstefh_durch',
    'w_grundstefh_min',
  ],
  wo_marktsituation_bemerkung: [
    'w_etwneu_marktsituation',
    'w_etwbest_marktsituation',
    'w_mieteneu_marktsituation',
    'w_mietebest_marktsituation',
    'w_haeuser_marktsituation',
  ],
  w_leerstand_qualitativ_bemerkung: ['w_leerstand_qualitativ'],
}
