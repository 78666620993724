import { useEffect, Suspense } from 'react'
import './lib/i18n'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Navigation } from './components/Navigation'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Landingpage } from './pages/Landingpage/Landingpage'
import { LandingpageDataProvider } from './pages/Landingpage/dataStore'
import { SurveyAdminDataProvider } from './pages/Admin/Survey/dataStore'
import { ReportingDataProvider } from './pages/Admin/Reporting/dataStore'
import { UsersAdminDataProvider } from './pages/Admin/Users/dataStore'
import { CookieAlert } from './components/CookieAlert'
import { useUserDataStore } from './stores/userStore'
import { Footer } from './components/Footer'
import { SurveyList } from './pages/Admin/Survey/SurveyList'
import { EditSurvey } from './pages/Admin/Survey/edit/EditSurvey'
import { Reporting } from './pages/Admin/Reporting/index'
import { UserList } from './pages/Admin/Users/UserList'
import { User } from './pages/Admin/Users/User'
import { UserGroupsAdminDataProvider } from './pages/Admin/UserGroups/dataStore'
import { UserGroupList } from './pages/Admin/UserGroups/UserGroupList'
import { UserGroup } from './pages/Admin/UserGroups/UserGroup'
import { Token } from './pages/Admin/Token/Token'
import { Account } from './pages/User/Account'

import { TokenAdminDataProvider } from './pages/Admin/Token/dataStore'
import { SurveyDataProvider } from './pages/Survey/dataStore'
import { Survey } from './pages/Survey'
import { TokenLogin } from './pages/Token/Token'
import { RiwisRegistration } from './pages/Registration/Riwis/index'
import { RegistrationDataProvider } from './pages/Registration/dataStore'
// import { Account } from './pages/User/Account'
import { EmailVerification } from './pages/Registration/EmailVerification'
import { ResetPassword } from './pages/User/ResetPassword'
import { NotFound } from './pages/ErrorPages/NotFound'
import { useTranslation } from 'react-i18next'
import { usePreventIOSInputZoom } from './hooks/usePreventIOSInputZoom'

function App() {
  usePreventIOSInputZoom()
  const [{ user, language }, { authenticateUser }] = useUserDataStore()
  const { i18n } = useTranslation()

  // try to re-authenticate if session-cookie exists (e.g. on page reload)
  useEffect(() => {
    if (!user) {
      authenticateUser()
    }
  }, [user, authenticateUser])

  useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language)
    }
  }, [language, i18n])

  return (
    <Router>
      <ErrorBoundary user={user}>
        <LandingpageDataProvider>
          <SurveyAdminDataProvider>
            <ReportingDataProvider>
              <UsersAdminDataProvider>
                <UserGroupsAdminDataProvider>
                  <TokenAdminDataProvider>
                    <SurveyDataProvider>
                      <RegistrationDataProvider>
                        <div className="App">
                          <Navigation />
                          <CookieAlert />
                          <Suspense fallback={<div></div>}>
                            <Switch>
                              <Route exact path="/" component={Landingpage} />
                              <Route path="/token/:token" component={TokenLogin} />
                              <Route exact path="/account/:userId" component={Account} />
                              <Route exact path="/admin/surveys/:filter" component={SurveyList} />
                              <Route exact path="/admin/survey/:surveyId" component={EditSurvey} />
                              <Route path="/admin/reporting/" component={Reporting} />
                              <Route path="/admin/users/" component={UserList} />
                              <Route path="/admin/user/:userId" component={User} />
                              <Route path="/admin/usergroups/" component={UserGroupList} />
                              <Route path="/admin/usergroup/:userGroupId" component={UserGroup} />
                              <Route path="/admin/token" component={Token} />
                              <Route exact path="/survey/:surveySlug" component={Survey} />
                              <Route path="/registration/riwis" component={RiwisRegistration} />
                              <Route path="/registration/activation/:token" component={EmailVerification} />
                              <Route path="/resetPassword/:token" component={ResetPassword}>
                                {user ? <Redirect to="/" /> : <ResetPassword />}
                              </Route>
                              <Route component={NotFound} />
                            </Switch>
                          </Suspense>
                          <Footer />
                        </div>
                      </RegistrationDataProvider>
                    </SurveyDataProvider>
                  </TokenAdminDataProvider>
                </UserGroupsAdminDataProvider>
              </UsersAdminDataProvider>
            </ReportingDataProvider>
          </SurveyAdminDataProvider>
        </LandingpageDataProvider>
      </ErrorBoundary>
    </Router>
  )
}

export default App
