import React, { useCallback, useEffect, useState } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

export const InputValue = ({ question, pageIndex, questionnaireIndex, index, name, label }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()
  const [localValue, setLocalValue] = useState(() =>
    question?.with_decimal === true ? question?.[name] : question?.[name]?.replace(/\..*/, '') || ''
  )
  const [localIndex, setLocalIndex] = useState(index)

  const handleNumberInput = useCallback(
    (event, question) => {
      if (question.data_type === 'number') {
        if (
          (question.with_decimal && isNaN(event.target.value.replace(',', '.'))) ||
          isNaN(event.target.value.replace('.', '_'))
        ) {
          return
        }
      }

      let value = event.target.value.replace(',', '.')
      value = value === '' ? null : value
      setLocalValue(value)
      setQuestionInput(questionnaireIndex, pageIndex, index, value, event.target.name)
    },
    [pageIndex, questionnaireIndex, setQuestionInput, index]
  )

  // update numbers on tab switch
  useEffect(() => {
    if (localIndex !== index) {
      setLocalValue(
        question?.with_decimal === true ? question?.[name] : question?.[name]?.replace(/\..*/, '') || ''
      )
      setLocalIndex(index)
    }
  }, [index, localIndex, question, name])

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">{label}</label>
      <div className="col-sm-10">
        <input
          className="form-control"
          type="text"
          name={name}
          onChange={(event) => handleNumberInput(event, question)}
          value={localValue}
        ></input>
      </div>
    </div>
  )
}
