import React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import styled from 'styled-components'

const Overlay = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})

const ImageTitlesContainer = styled('div')({
  display: 'flex',
  justifyContent: ({ justifyContent }) => justifyContent || 'start',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
})

const ImageTitle = styled('div')({
  fontSize: '0.875rem',
  color: ({ color }) => color || 'black',
  backgroundColor: ({ backgroundColor }) => backgroundColor || 'white',
  padding: '0.5rem',
  // borderRadius: '0.5rem',
  boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 40%)',
  margin: '2rem',
})

export const CompareImage = ({
  image,
  alt,
  title,
  justifyTitle = 'flex-start',
  backgroundColor = 'rgba(255,255,255,0.85)',
  titleColor = 'black',
}) => {
  return (
    <Overlay>
      <ImageTitlesContainer justifyContent={justifyTitle}>
        <ImageTitle backgroundColor={backgroundColor} color={titleColor}>
          {title}
        </ImageTitle>
      </ImageTitlesContainer>
      <ReactCompareSliderImage src={image} alt={alt} />
    </Overlay>
  )
}

export const ImageCompareSlider = ({ ImageOne, ImageTwo }) => {
  return <ReactCompareSlider itemOne={ImageOne} itemTwo={ImageTwo} />
}
