export const NoAnswerCheckbox = ({ noAnswer, checked, onChange, questionId }) => {
  if (!questionId) {
    console.warn('questionId for NoAnswerCheckbox is required!')
  }

  if (!noAnswer || !noAnswer.showNoAnswer) {
    return null
  }

  return (
    <div className="custom-control custom-checkbox mb-2 mt-5">
      <input
        className="custom-control-input"
        type="checkbox"
        name={'noAnswer'}
        id={`${questionId}_${noAnswer.id}`}
        onChange={onChange}
        checked={checked || false}
      ></input>
      <label
        className="custom-control-label"
        // style={{ fontWeight: 'normal', fontSize: '14px' }}
        htmlFor={`${questionId}_${noAnswer.id}`}
      >
        {noAnswer.title}
      </label>
    </div>
  )
}
