import { Container } from '../../components/Container'

export const NotFound = () => {
  return (
    <Container>
      <div className="d-flex flex-column align-items-center">
        <div style={{ fontSize: '6rem' }}>404</div>
        <h2>Diese Seite konnte nicht gefunden werden!</h2>
      </div>
    </Container>
  )
}
