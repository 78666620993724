import createStore from '../../../lib/flux-store'
import { UserGroup } from '../../../helper/api'

const initialState = {
  dataIsLoading: true,
  saveMessage: { msg: '', error: '', type: '' },
  userGroupList: null,
  userGroup: null,
  userGroupMessage: { msg: '', error: '', type: '' },
}

const actions = {
  setSaveMessage: (saveMessage) => ({ saveMessage }),
  fetchUserGroupList: () => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    UserGroup.getAll().then((res) => {
      dispatch({ type: 'setUserGroupList', userGroupList: res.data.userGroups })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchUserGroup: (userGroupId) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    UserGroup.getById(userGroupId).then((res) => {
      dispatch({ type: 'setUserGroup', userGroup: res.data.userGroup })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  saveUserGroup: (userGroup, userGroupId) => (dispatch) => {
    UserGroup.saveUserGroup(userGroup, userGroupId)
      .then((res) => {
        if (res.data.msg === 'failed') {
          dispatch({
            type: 'setSaveMessage',
            saveMessage: { msg: 'Fehler', type: 'failed', error: JSON.stringify(res.data.error) },
          })
        } else {
          dispatch({ type: 'setUserGroup', userGroup: res.data.userGroup })
          dispatch({
            type: 'setSaveMessage',
            saveMessage: { msg: 'Gruppe gespeichert', type: 'success', error: '' },
          })
        }
      })
      .catch((err) =>
        dispatch({ type: 'setSaveMessage', saveMessage: { msg: 'Fehler', type: 'failed', error: err } })
      )
  },
  editUserGroup: (value, property) => ({ value, property }),
  setUserGroupMessage: (userGroupMessage) => ({ userGroupMessage }),
  deleteUserGroup: (userGroupId) => (dispatch) => {
    UserGroup.deleteById(userGroupId).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setUserGroupMessage',
          userGroupMessage: { msg: '', err: JSON.stringify(res.data.error), type: 'failed' },
        })
      } else {
        dispatch({
          type: 'setUserGroupMessage',
          userGroupMessage: { msg: 'Benutzer-Gruppe gelöscht', err: '', type: 'success' },
        })
        dispatch({ type: 'setUserGroupList', userGroupList: res.data.userGroups })
      }
    })
  },
  deleteUsers: (userGroupId, userIds) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    UserGroup.deleteUsersInUserGroup(userGroupId, userIds).then((res) => {
      dispatch({ type: 'setDataIsLoading', payload: false })
      if (res.data.error) {
        dispatch({
          type: 'setSaveMessage',
          saveMessage: { msg: '', type: 'failed', error: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({
          type: 'setSaveMessage',
          saveMessage: { msg: 'Benutzer gelöscht', type: 'success', error: '' },
        })
        dispatch({ type: 'setUserGroup', userGroup: res.data.userGroup })
      }
    })
  },
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setSaveMessage: (state, { saveMessage }) => {
    return { ...state, saveMessage }
  },
  setUserGroupList: (state, { userGroupList }) => {
    return { ...state, userGroupList }
  },
  setUserGroup: (state, { userGroup }) => {
    return { ...state, userGroup }
  },
  saveUserGroup: (state, { payload }) => {
    return { ...state, userGroup: payload.userGroup, saveMessage: payload.saveMessage }
  },
  editUserGroup: (state, { value, property }) => {
    const newUserGroup = { ...state.userGroup }
    newUserGroup[property] = value
    return { ...state, userGroup: newUserGroup }
  },
  setNewUserGroupId: (state, { userGroupId }) => {
    return { ...state, newUserGroupId: userGroupId }
  },
  setUserGroupMessage: (state, { userGroupMessage }) => {
    return { ...state, userGroupMessage }
  },
}

export const [UserGroupsAdminDataContext, UserGroupsAdminDataProvider, useUserGroupsAdminDataStore] =
  createStore(reducer, actions, initialState, undefined, 'UserGroupsAdminDataStore')
