import { Redirect, Route, Switch } from 'react-router'
import { useUserDataStore } from '../../../stores/userStore'
import { RiwisRegistrationFormular } from './RiwisRegistrationFormular'
import { RiwisRegistrationIntro } from './RiwisRegistrationIntro'

export const RiwisRegistration = () => {
  const [{ user }] = useUserDataStore()
  return (
    <Switch>
      <Route exact path="/registration/riwis">
        <Redirect to="/registration/riwis/intro" />
      </Route>
      <Route path="/registration/riwis/intro" component={RiwisRegistrationIntro} />
      <Route path="/registration/riwis/formular">
        {user ? <Redirect to="/" /> : <RiwisRegistrationFormular />}
      </Route>
    </Switch>
  )
}
