import createStore from '../../../lib/flux-store'
import { Survey, UserGroup, Token } from '../../../helper/api'

const initialState = {
  dataIsLoading: true,
  tokensAreLoading: false,
  surveyList: null,
  userGroups: null,
  tokens: null,
  tokenMessage: { msg: '', error: '', type: '' },
  mail: { mail_content: '', subject: '' },
}

const actions = {
  fetchSurveyListAndUserGroups: () => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Promise.all([Survey.getAll(), UserGroup.getAll()]).then((res) => {
      const [surveyData, userGroupsData] = res
      const payload = {
        surveyList: surveyData.data,
        userGroups: userGroupsData.data.userGroups,
      }
      dispatch({ type: 'setSurveyListAndUserGroups', payload })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchTokensAndMail: (userGroupId, surveyId) => (dispatch) => {
    dispatch({ type: 'setTokensAreLoading', payload: true })
    Token.getByUserGroupIdAndSurveyId(userGroupId, surveyId).then((res) => {
      dispatch({ type: 'setTokens', tokens: res.data.tokens })
      dispatch({ type: 'setMail', mail: res.data.mail || initialState.mail })
      dispatch({ type: 'setTokensAreLoading', payload: false })
    })
  },
  createTokens: (userGroupId, surveyId) => (dispatch) => {
    dispatch({ type: 'setTokensAreLoading', payload: true })
    Token.createTokensAll(userGroupId, surveyId).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({ type: 'addTokens', tokens: res.data.tokens })
        dispatch({ type: 'setTokensAreLoading', payload: false })
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'Tokens erstellt', type: 'success', err: '' },
        })
      }
    })
  },
  createTokensSelected: (userGroupId, surveyId, userIds) => (dispatch) => {
    dispatch({ type: 'setTokensAreLoading', payload: true })
    Token.createTokensSelected(userGroupId, surveyId, userIds).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({ type: 'addTokens', tokens: res.data.tokens, userIds })
        dispatch({ type: 'setTokensAreLoading', payload: false })
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'Tokens für Auswahl erstellt', type: 'success', err: '' },
        })
      }
    })
  },
  deleteAllTokens: (userGroupId, surveyId) => (dispatch) => {
    dispatch({ type: 'setTokensAreLoading', payload: true })
    Token.deleteAll(userGroupId, surveyId).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({ type: 'setTokens', tokens: res.data.tokens })
        dispatch({ type: 'setTokensAreLoading', payload: false })
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'Alle Tokens erfolgreich gelöscht', type: 'success', err: '' },
        })
      }
    })
  },
  deleteSelectedTokens: (tokenIds) => (dispatch) => {
    Token.deleteSelected(tokenIds).then((res) => {
      dispatch({
        type: 'setTokenMessage',
        tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
      })
      if (res.data.error) {
      } else {
        dispatch({ type: 'filterTokens', removedTokens: res.data.tokens })
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'Ausgewählte Tokens erfolgreich gelöscht', type: 'success', err: '' },
        })
      }
    })
  },
  expandTokenDatesAll: (userGroupId, surveyId, timespan) => (dispatch) => {
    Token.extendValidDateAll(userGroupId, surveyId, timespan).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({ type: 'setTokens', tokens: res.data.tokens })
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'Alle Tokens erfolgreich verlängert', type: 'success', err: '' },
        })
      }
    })
  },
  expandTokenDatesSelected: (userGroupId, surveyId, timespan, tokenIds) => (dispatch) => {
    Token.extendValidDateSelected(userGroupId, surveyId, timespan, tokenIds).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({ type: 'setSelectedTokens', updatedTokens: res.data.tokens })
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'Ausgewählte Tokens erfolgreich verlängert', type: 'success', err: '' },
        })
      }
    })
  },
  setTokens: (tokens) => ({ tokens }),
  setTokenMessage: (tokenMessage) => ({ tokenMessage }),
  setMail: (mail) => ({ mail }),
  saveMail: (userGroupId, surveyId, mail) => (dispatch) => {
    Token.saveMail(userGroupId, surveyId, mail).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: 'E-Mail gespeichert', type: 'success', err: '' },
        })
      }
    })
  },
  sendMail: (userGroupId, surveyId, mail, tokens) => (dispatch) => {
    Token.sendMail(userGroupId, surveyId, mail, tokens).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setTokenMessage',
          tokenMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
        })
      } else {
        dispatch({ type: 'setMail', mail: res.data.mail })
        if (res.data.mailErrors.length) {
          dispatch({
            type: 'setTokenMessage',
            tokenMessage: {
              msg: 'Mail-Versand fehlgeschlagen für:',
              type: 'failed',
              err: res.data.mailErrors.map((error) => error.recipient),
            },
          })
        } else {
          dispatch({
            type: 'setTokenMessage',
            tokenMessage: { msg: 'E-Mail Versand erfolgreich!', type: 'success', err: '' },
          })
        }
      }
    })
  },
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setTokensAreLoading: (state, { payload }) => {
    return { ...state, tokensAreLoading: payload }
  },
  setSurveyListAndUserGroups: (state, { payload }) => {
    return { ...state, surveyList: payload.surveyList, userGroups: payload.userGroups }
  },
  setTokens: (state, { tokens }) => {
    return { ...state, tokens }
  },
  filterTokens: (state, { removedTokens }) => {
    let newTokens = [...state.tokens]
    newTokens = newTokens.filter((token) => {
      return !removedTokens.some((removedToken) => removedToken.id === token.id)
    })
    return { ...state, tokens: newTokens }
  },
  setSelectedTokens: (state, { updatedTokens }) => {
    let newTokens = [...state.tokens]
    newTokens = newTokens.map(
      (token) => updatedTokens.find((updatedToken) => updatedToken.id === token.id) || token
    )
    return { ...state, tokens: newTokens }
  },
  addTokens: (state, { tokens, userIds }) => {
    const oldTokens = state.tokens || []
    let filteredTokens = oldTokens
    if (userIds) {
      filteredTokens = oldTokens.filter(
        (token) => !userIds.some((userId) => token.userInfo.id === userId && token.id === 0)
      )
    } else {
      filteredTokens = oldTokens.filter(
        (oldToken) =>
          !tokens.some(
            (token) => token.userInfo.id === oldToken.userInfo.id && (token.id === 0 || oldToken.id === 0)
          )
      )
    }
    const newTokens = [...filteredTokens, ...tokens]
    return { ...state, tokens: newTokens }
  },
  setTokenMessage: (state, { tokenMessage }) => {
    return { ...state, tokenMessage }
  },
  setMail: (state, { mail }) => {
    return { ...state, mail }
  },
}

export const [TokenAdminDataContext, TokenAdminDataProvider, useTokenAdminDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'TokenAdminDataStore'
)
