import React from 'react'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSurveyDataStore } from '../../dataStore'
import { getMissingInputs, getQuestionPerPageObject } from '../helper'
import { RiwisCloseButton, RiwisSaveButton } from './RiwisButtons'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

export const PrevButton = () => {
  const [
    { survey, pageNumber, pages, questionsPerPage },
    { setMissingInputs, setPageNumber, setQuestionsPerPage },
  ] = useSurveyDataStore()
  const { t } = useTranslation()

  const handlePrevButton = () => {
    setMissingInputs([])
    setPageNumber(pageNumber - 1)
    const newQuestionsPerPage = [...questionsPerPage]
    newQuestionsPerPage.pop()
    setQuestionsPerPage(newQuestionsPerPage)
  }

  const prevVisibility = pageNumber < 1 ? 'hidden' : 'visible'
  if (pages?.length + 1 === pageNumber) {
    return null
  }

  return (
    <button
      type="button"
      className={survey.type === 'riwis' ? 'riwis-btn riwis-secondary' : 'btn btn-primary text-uppercase'}
      style={{ visibility: prevVisibility }}
      onClick={handlePrevButton}
    >
      <div className="d-flex">
        <span className="mr-4">
          <FontAwesomeIcon icon={faPlay} flip="horizontal" />
        </span>
        {t('survey.buttons.prevButton')}
      </div>
    </button>
  )
}

export const NextButton = ({ onHide, setNextRiwisQuestionnaire }) => {
  const [
    { survey, questionnaire, pages, isSaving, pageNumber, questionsPerPage },
    { setPageNumber, setMissingInputs, setQuestionsPerPage },
  ] = useSurveyDataStore()

  const { t } = useTranslation()

  const handleNextButton = () => {
    const missingInputs = getMissingInputs(pageNumber, pages)
    setMissingInputs(missingInputs)
    if (!missingInputs.length) {
      setPageNumber(pageNumber + 1)
      setQuestionsPerPage(getQuestionPerPageObject(pages, pageNumber, questionsPerPage))
    }
  }

  if (pages?.length + 1 === pageNumber) {
    return survey.type === 'riwis' ? (
      <RiwisCloseButton
        isSaving={isSaving}
        questionnaire={questionnaire}
        setNextRiwisQuestionnaire={setNextRiwisQuestionnaire}
        onHide={onHide}
      />
    ) : (
      <CloseButton onHide={onHide} isSaving={isSaving} surveyType={survey.type} />
    )
  } else if (pages?.length === pageNumber) {
    if (survey.type === 'riwis') {
      return <RiwisSaveButton label={t('survey.buttons.finishButton')} />
    } else {
      return <SaveButton label={t('survey.buttons.finishButton')} />
    }
  } else {
    return (
      <button
        type="button"
        className={survey.type === 'riwis' ? 'riwis-btn riwis-primary' : 'btn btn-primary text-uppercase'}
        onClick={() => handleNextButton()}
      >
        <div className="d-flex">
          {t('survey.buttons.nextButton')}
          <span className="ml-4">
            <FontAwesomeIcon icon={faPlay} />
          </span>
        </div>
      </button>
    )
  }
}

const CloseButton = ({ isSaving, onHide }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* workaround for space-between*/}
      <div></div>
      <button
        type="button"
        onClick={() => onHide(null)}
        className="btn btn-primary text-uppercase"
        disabled={isSaving}
      >
        {t('survey.buttons.closeButton')}
      </button>
    </>
  )
}

export const SaveButton = ({ label, earlyExit = false, className, upperCase = true }) => {
  const [{ questionnaire, survey, pageNumber, pages, isSaving }, { saveAnswers }] = useSurveyDataStore()
  const nextPage = earlyExit ? pageNumber : pageNumber + 1
  return (
    <button
      type="button"
      className={`btn btn-primary ${className} ${upperCase && 'text-uppercase'}`}
      disabled={isSaving}
      onClick={() =>
        saveAnswers({
          nextPage,
          questionnaire,
          surveyId: survey.id,
          earlyExit: earlyExit ? pageNumber + 1 : null,
          quarter: survey.quarter,
          datasourceYear: survey.datasource_year,
          visiblePages: pages,
        })
      }
    >
      {isSaving ? (
        <SavingState />
      ) : (
        <span>
          {label}
          <span className="ml-4">
            <FontAwesomeIcon icon={faPlay} />
          </span>
        </span>
      )}
    </button>
  )
}

export const SavingState = () => {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ width: 80 }}>
      &nbsp;
      <Spinner animation="border" variant="light" size="sm" />
      &nbsp;
    </div>
  )
}
