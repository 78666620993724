//-------------------------------------------------------
//             ListBox defines default props
//             at the end of this file
//-------------------------------------------------------
import React, { useState, useCallback, useEffect } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'
import { JSONTextarea } from '../../JSONTextarea'
import { PropertyLabel } from '../../../../../../components/Labels'
import { maxId } from '../../utils'

const InputEdit = ({
  type = 'edit',
  item,
  items,
  handleUpdateItem,
  handleKeyPressForUpdate,
  handleKeyPressForCancel,
}) => {
  const [message, setMessage] = useState(null)
  const onChange = useCallback(
    (evt) => {
      const prevId = item.id
      const newId = Number(evt.target.value.id)
      if (prevId !== newId) {
        if (items.some(({ id }) => id !== item.id && id === newId)) {
          setMessage({ text: 'ID bereits vergeben!', type: 'error' })
          return
        }
      }
      setMessage(null)
      handleUpdateItem(evt, false)
    },
    [handleUpdateItem, items, item]
  )
  if (type === 'code') {
    return (
      <JSONTextarea
        width="100%"
        json={item}
        rows={8}
        id={item.id}
        name={type}
        onChange={onChange}
        onKeyDown={handleKeyPressForCancel}
        message={message}
      />
    )
  }
  return (
    <input
      autoFocus={true}
      type="text"
      id={item.id}
      className="form-input w-100"
      name={type}
      defaultValue={type === 'edit' ? item.text : ''}
      onChange={onChange}
      onKeyDown={handleKeyPressForUpdate}
    ></input>
  )
}

const RenderSingleItem = ({
  item,
  items,
  globalUnit,
  globalDataType,
  selectedItemId,
  setSelectItemId,
  changeHandler,
  editType,
  setEditType,
}) => {
  const className = 'list-group-item p-2 pl-3 text-break' + (item.isSelected ? ' active' : '')

  const handleUpdateItem = useCallback(
    (event, closeAfterChange = true) => {
      let newItems = [...items]
      const index = newItems.findIndex((item) => item.id === parseInt(event.target.id))

      if (event.target.name === 'edit') {
        newItems[index].text = event.target.value
      } else if (event.target.name === 'code') {
        newItems[index] = event.target.value
      }

      newItems = newItems.map((item) => {
        return { ...item }
      })

      if (closeAfterChange) {
        setSelectItemId(null)
        setEditType(null)
      }

      changeHandler(newItems)
    },
    [changeHandler, items, setEditType, setSelectItemId]
  )

  // used for JSONTextarea
  const handleKeyPressForCancel = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        setSelectItemId(null)
        setEditType(null)
      }
    },
    [setSelectItemId, setEditType]
  )

  const handleKeyPressForUpdate = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        setSelectItemId(null)
        setEditType(null)
      }
      if (event.keyCode === 13) handleUpdateItem(event)
    },
    [handleUpdateItem, setSelectItemId, setEditType]
  )

  const handleItemDeleteClick = useCallback(
    (itemId) => {
      let newItems = [...items.filter((item) => item.id !== itemId)]
      newItems = newItems.map((item) => {
        return { id: item.id, text: item.text }
      })
      changeHandler(newItems)
    },
    [changeHandler, items]
  )

  const handleItemEditClick = useCallback(() => {
    const newItems = [...items]
    changeHandler(newItems)
  }, [changeHandler, items])

  const handleItemUpClick = useCallback(
    (itemId) => {
      let newItems = [...items]
      const index = newItems.findIndex((item) => item.id === itemId)
      const elem = newItems.splice(index, 1)[0]
      newItems.splice(index - 1, 0, elem)

      changeHandler(newItems)
    },
    [changeHandler, items]
  )

  const handleItemDownClick = useCallback(
    (itemId) => {
      let newItems = [...items]
      const index = newItems.findIndex((item) => item.id === itemId)
      const elem = newItems.splice(index, 1)[0]
      newItems.splice(index + 1, 0, elem)

      changeHandler(newItems)
    },
    [changeHandler, items]
  )

  const handleItemClick = useCallback(
    (itemId, action) => {
      if (itemId === selectedItemId && editType === action) {
        setSelectItemId(null)
        setEditType(null)
        return
      }
      setSelectItemId(itemId)
      if (action === 'up') {
        handleItemUpClick(itemId)
      } else if (action === 'down') {
        handleItemDownClick(itemId)
      } else if (action === 'edit' || action === 'code') {
        handleItemEditClick(itemId)
        setEditType(action)
      } else if (action === 'delete') {
        handleItemDeleteClick(itemId)
      }
    },
    [
      handleItemDeleteClick,
      handleItemDownClick,
      handleItemEditClick,
      handleItemUpClick,
      setSelectItemId,
      selectedItemId,
      setEditType,
      editType,
    ]
  )

  return (
    <li className={className} id={item.id}>
      <div className="d-flex justify-content-between">
        {selectedItemId === item.id && editType ? (
          <InputEdit
            item={item}
            items={items}
            type={editType}
            handleUpdateItem={handleUpdateItem}
            handleKeyPressForUpdate={handleKeyPressForUpdate}
            handleKeyPressForCancel={handleKeyPressForCancel}
          />
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <PropertyLabel>ID: {item.id}</PropertyLabel>
              {typeof globalUnit !== 'undefined' && (
                <PropertyLabel>unit: {item.unit || globalUnit || ''}</PropertyLabel>
              )}
              {typeof globalDataType !== 'undefined' && (
                <PropertyLabel>dataType: {item.dataType || globalDataType || ''}</PropertyLabel>
              )}
              {typeof item.required !== 'undefined' && (
                <PropertyLabel>required: {item.required === true ? 'true' : 'false'}</PropertyLabel>
              )}
              {typeof item.line !== 'undefined' && <PropertyLabel>line: {item.line}</PropertyLabel>}
            </div>
            <div>{item.text}</div>
          </div>
        )}

        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="tool buttons"
          style={{ height: '100%' }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemClick(item.id, 'up')}
          >
            &uarr;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemClick(item.id, 'down')}
          >
            &darr;
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary text-primary ${
              selectedItemId === item.id && editType === 'edit' ? 'active' : ''
            }`}
            onClick={() => handleItemClick(item.id, 'edit')}
          >
            &#x270E;
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary text-primary ${
              selectedItemId === item.id && editType === 'code' ? 'active' : ''
            }`}
            onClick={() => handleItemClick(item.id, 'code')}
          >
            <span style={{ whiteSpace: 'nowrap' }}>{'{}'}</span>
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary text-danger"
            onClick={() => handleItemClick(item.id, 'delete')}
          >
            &#x2718;
          </button>
        </div>
      </div>
    </li>
  )
}

export const ListBox = ({
  items,
  initialItems,
  name,
  questionnaireIndex,
  pageIndex,
  index,
  label,
  unit,
  dataType,
  customChangeHandler,
}) => {
  const [selectedItemId, setSelectItemId] = useState(null)
  const [editType, setEditType] = useState(null)
  const [showNewInput, setShowNewInput] = useState(false)
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const changeHandler = useCallback(
    (items) => {
      if (typeof customChangeHandler === 'function') {
        customChangeHandler(questionnaireIndex, pageIndex, index, [...items], name)
      } else {
        setQuestionInput(questionnaireIndex, pageIndex, index, [...items], name)
      }
    },
    [questionnaireIndex, pageIndex, setQuestionInput, index, name, customChangeHandler]
  )

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) handleAddItem(event)
  }

  const handleNewClick = () => {
    setShowNewInput(true)
  }

  const handleAddItem = useCallback(
    (event) => {
      if (event.target.value === '') {
        return
      }

      let newItems = [...items]

      newItems.push({
        id: newItems.reduce(maxId, { id: 0 }).id + 1,
        text: event.target.value,
        unit: null,
        dataType: null,
      })

      changeHandler(newItems)

      event.target.value = ''
    },
    [items, changeHandler]
  )

  useEffect(() => {
    if ((!items || !items?.length) && initialItems?.length) {
      changeHandler(initialItems)
    }
  }, [items, initialItems, changeHandler])

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">{label}</label>
      <div className="col-sm-10">
        <div className="border rounded p-3 bg-light">
          <ul className="list-group" style={{ maxHeight: 'calc(30vh)', overflowY: 'auto' }}>
            {items.map((item) => (
              <RenderSingleItem
                key={item.id}
                items={items}
                item={item}
                globalUnit={unit}
                globalDataType={dataType}
                selectedItemId={selectedItemId}
                setSelectItemId={setSelectItemId}
                editType={editType}
                setEditType={setEditType}
                changeHandler={changeHandler}
              />
            ))}
          </ul>

          {showNewInput ? (
            <input
              type="text"
              autoFocus={true}
              className="form-control mt-2 w-100"
              name="newAnswer"
              onBlur={handleAddItem}
              onKeyDown={handleKeyPress}
            ></input>
          ) : (
            ''
          )}

          <button
            type="button"
            className="btn-sm btn-outline-secondary mt-3 text-primary"
            onClick={handleNewClick}
          >
            <b>+</b>
          </button>
        </div>
      </div>
    </div>
  )
}
