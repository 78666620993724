import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container } from '../../../components/Container'
import { UserSelectBox } from '../../../components/UserSelectBox'
import { useUsersAdminDataStore } from './dataStore'
import { AlertBox } from '../../../components/AlertBox'

export const User = () => {
  const [{ dataIsLoading, user, saveMessage }, { fetchUser, editUser, saveUser, setSaveMessage, setUser }] =
    useUsersAdminDataStore()
  const [createNewPassword, setCreateNewPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { userId } = useParams()
  const history = useHistory()

  const handleInputChange = (event) => {
    if (event.target.type === 'checkbox') {
      editUser(event.target.checked, event.target.name)
    } else {
      editUser(event.target.value, event.target.name)
    }
  }
  const handleNewPassword = () => {
    setCreateNewPassword(true)
  }

  const handleSuppportContactChange = (supportContact) => {
    editUser(supportContact, 'support_contact')
  }

  const handleSaveUser = () => {
    saveUser(user)
  }

  useEffect(() => {
    fetchUser(userId)
    return function cleanup() {
      setUser(null)
    }
  }, [fetchUser, userId, setUser])

  useEffect(() => {
    if (saveMessage?.msg?.length) {
      setTimeout(() => {
        setSaveMessage({ msg: '', error: '', type: '' })
      }, 5000)
    }
  }, [saveMessage, setSaveMessage])

  useEffect(() => {
    if (userId === '0' && user && user.id !== 0) {
      history.push({ pathname: `/admin/user/${user.id}` })
    }
  }, [user, userId, history])

  return (
    <Container>
      {dataIsLoading === false && user && (
        <>
          <div className="sticky-top pt-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
            <div className="d-flex justify-space-between">
              <div className="d-flex">
                <h3 className="mr-5">Benutzer Bearbeiten</h3>
                <button onClick={handleSaveUser} className="btn-sm btn-primary">
                  Speichern
                </button>
              </div>
              <div id="#alert" className="d-flex justify-content-end">
                <AlertBox type={saveMessage.type} message={saveMessage.msg} error={saveMessage.error} />
              </div>
            </div>
            <hr className="mb-5" />
          </div>
          <div>
            <div className="d-flex justify-content-end mb-5">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="isAdmin"
                  id="isAdmin"
                  className="custom-control-input"
                  checked={user.isAdmin || false}
                  onChange={handleInputChange}
                />
                <label htmlFor="isAdmin" className="custom-control-label">
                  Ist Admin
                </label>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="username" className="col-sm-2 col-form-label text-right">
                Benutzer-Name
              </label>
              <div className="col">
                <input
                  onChange={handleInputChange}
                  type="text"
                  name="username"
                  id="username"
                  className="form-control"
                  value={user.username || ''}
                  disabled={user.id}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="topix_id" className="col-sm-2 col-form-label text-right">
                Topix ID
              </label>
              <div className="col-sm-2">
                <input
                  onChange={handleInputChange}
                  type="text"
                  name="topix_id"
                  id="topix_id"
                  className="form-control"
                  value={user.topix_id || ''}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="password" className="col-sm-2 col-form-label text-right">
                Passwort
              </label>
              {user.id !== 0 && !createNewPassword && (
                <div className="col-sm-8" id="passwordInputButtonLine">
                  <button className="btn btn-sm btn-primary" type="button" onClick={handleNewPassword}>
                    Neues Passwort
                  </button>
                </div>
              )}
              {(user.id === 0 || createNewPassword) && (
                <>
                  <div className="col-sm-8">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="form-control"
                      onChange={handleInputChange}
                      value={user.password || ''}
                    />
                  </div>
                  <div className="col-sm-2">
                    <div className="d-flex justify-content-end">
                      <div className="custom-control custom-checkbox">
                        <input
                          onClick={(event) => setShowPassword(event.target.checked)}
                          type="checkbox"
                          className="custom-control-input"
                          id="showPassword"
                        />
                        <label htmlFor="showPassword" className="custom-control-label">
                          Passwort anzeigen
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="first_name" className="col-sm-2 col-form-label text-right">
                Vorname
              </label>
              <div className="col">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="form-control"
                  value={user.first_name || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="last_name" className="col-sm-2 col-form-label text-right">
                Nachname
              </label>
              <div className="col">
                <input
                  type="text"
                  name="last_name"
                  className="form-control"
                  value={user.last_name || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="gender" className="col-sm-2 col-form-label text-right">
                Geschlecht
              </label>
              <div className="col-2">
                <select
                  className="form-control"
                  name="gender"
                  onChange={handleInputChange}
                  value={user?.gender?.toUpperCase() || 'W'}
                >
                  <option value="W">weiblich</option>
                  <option value="M">männlich</option>
                  <option value="D">divers</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="company" className="col-sm-2 col-form-label text-right">
                Firma
              </label>
              <div className="col">
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="form-control"
                  value={user.company || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="street" className="col-sm-2 col-form-label text-right">
                Straße
              </label>
              <div className="col">
                <input
                  type="text"
                  name="street"
                  id="street"
                  className="form-control"
                  value={user.street || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="zip" className="col-sm-2 col-form-label text-right">
                PLZ
              </label>
              <div className="col">
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  className="form-control"
                  value={user.zip || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="city" className="col-sm-2 col-form-label text-right">
                Ort
              </label>
              <div className="col">
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="form-control"
                  value={user.city || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="phone" className="col-sm-2 col-form-label text-right">
                Tel
              </label>
              <div className="col">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  value={user.phone || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="mobile" className="col-sm-2 col-form-label text-right">
                Mobil
              </label>
              <div className="col">
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  className="form-control"
                  value={user.mobile || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="email" className="col-sm-2 col-form-label text-right">
                E-Mail
              </label>
              <div className="col">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  value={user.email || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="list_as_partner" className="col-sm-2 col-form-label text-right">
                Als Partner listen
              </label>
              <div className="col d-flex align-items-center">
                <input
                  type="checkbox"
                  name="list_as_partner"
                  id="list_as_partner"
                  className=""
                  checked={user.list_as_partner || false}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {user.isAdmin === true && (
              <div className="form-group row">
                <label htmlFor="support_contact" className="col-sm-2 col-form-label text-right">
                  Ansprechpartner
                </label>
                <div className="col">
                  <UserSelectBox
                    support_contact={user.support_contact}
                    changeHandler={handleSuppportContactChange}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  )
}
