import { useCallback, useRef, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useReportingDataStore } from './dataStore'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import XLSX from 'xlsx'
import { Link } from 'react-router-dom'

const chartOptions = {
  chart: {
    borderColor: 'lightgray',
    borderWidth: 1,
    height: 400,
    type: 'scatter',
  },
  title: {},
  plotOptions: {
    series: {
      animation: false,
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    formatter: function () {
      const date = new Date(this.x).toLocaleDateString('de-DE')
      return `<div style="display: flex; flex-direction: column">
      <div>Uhrzeit: ${getTimeFromDecimal(this.y)}</div>
      <div>Datum: ${date}</div>
      </div>`
    },
    useHTML: true,
  },
  xAxis: {
    title: {
      text: 'Antworten',
    },
    type: 'datetime',
    labels: {
      formatter: function () {
        return Highcharts.dateFormat('%e. %b', this.value)
      },
      step: 1,
    },
  },
  yAxis: {
    title: {
      text: 'Uhrzeit',
    },
  },
}

const getTimeFromDecimal = (decimalNumber) => {
  let [integral, fraction] = decimalNumber.toString().split('.')
  if (isNaN(fraction)) {
    fraction = '0'
  }
  integral = integral.length === 1 ? '0' + integral : integral
  fraction = (Number('0.' + fraction) * 60).toFixed(0)
  fraction = fraction.toString().length === 1 ? '0' + fraction : fraction
  return `${integral}:${fraction}`
}

const addDays = (days, currentDate) => {
  const date = new Date(currentDate)
  date.setDate(date.getDate() + days)
  return date
}

const getTimeline = (startDate, endDate) => {
  let currentDate = startDate
  const timeline = []
  while (currentDate <= endDate) {
    timeline.push(currentDate)
    currentDate = addDays(1, currentDate)
  }
  return timeline
}

export const Monitor = () => {
  const [{ report }] = useReportingDataStore()
  const [options, setOptions] = useState(chartOptions)
  const tableRef = useRef()

  const generateXLSX = useCallback(() => {
    const book = XLSX.utils.table_to_book(document.getElementById('monitorTable'))
    XLSX.writeFile(book, 'monitor_' + report.currentSurvey + '.xlsx')
  }, [report])

  useEffect(() => {
    if (report) {
      setOptions((prevOptions) => {
        const fromDate = new Date(report.survey.from)
        const toDate = new Date(report.survey.to)
        const timeline = getTimeline(fromDate, toDate)
        const data = []
        timeline.forEach((date) => {
          const filtered = report.answersByDate
            .filter(
              ({ answer_date }) =>
                new Date(answer_date).toLocaleDateString('de-DE') === date.toLocaleDateString('de-DE')
            )
            .reduce((arr, { answer_date }) => {
              const answerDate = new Date(answer_date)
              date.setHours(0, 0, 0, 0)
              arr.push([date.getTime(), answerDate.getHours() + answerDate.getMinutes() / 60])
              return arr
            }, [])
          data.push(...filtered)
        })

        const newOptions = { ...prevOptions }
        newOptions.title.text = `${report.currentSurvey} (${fromDate.toLocaleDateString(
          'de-DE'
        )} - ${toDate.toLocaleDateString('de-DE')})`
        newOptions.series = [
          {
            name: 'Teilnehmer',
            data: data,
            color: 'rgba(119, 152, 191, .5)',
          },
        ]

        return newOptions
      })
    }
  }, [report])

  if (!report) {
    return <div></div>
  }

  return (
    <div>
      <HighchartsReact options={options} highcharts={Highcharts} />
      <button className="btn btn-outline-primary" onClick={generateXLSX}>
        <FontAwesomeIcon icon={faFileExcel} className="h3 m-0" />
      </button>
      <table ref={tableRef} className="table table-sm table-bordered" id="monitorTable">
        <thead className="bg-primary text-light">
          <tr>
            <th>Teilnehmer</th>
            <th>Fragebogen ID</th>
            <th>Datum</th>
            <th>Uhrzeit</th>
          </tr>
        </thead>
        <tbody>
          {report.answersByDate.map(({ participant, username, answer_date, questionnaire_id }) => {
            const answerDate = new Date(answer_date)
            return (
              <tr key={participant + '-' + answer_date + '-' + questionnaire_id}>
                <td>
                  <Link to={`/admin/user/${participant}`}> {username}</Link>
                </td>
                <td>{questionnaire_id}</td>
                <td>{answerDate.toISOString().split('T')[0]}</td>
                <td>{answerDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
