import axios from 'axios'
import { surveyDeskApiUrl } from '../config/config'

const backend = axios.create({
  baseURL: surveyDeskApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const Auth = {
  login: (credentials) => backend.post('/login', credentials),
  logout: () => backend.get('/logout'),
  authenticate: () => backend.get('/authentication'),
  token: (token) => backend.get(`/token/${token}`),
}

export const Survey = {
  getAll: () => backend.get('/survey/all'),
  getAllByUserId: (userId) => backend.get(`/survey/all/${userId}`),
  getById: (surveyId) => backend.get(`/survey/id/${surveyId}`),
  save: (survey) => backend.post('/survey/save', survey),
  getBySlug: (surveySlug) => backend.get(`/survey/slug/${surveySlug}`),
  deleteById: (surveyId) => backend.delete(`/survey/delete/${surveyId}`),
  updateStatus: (surveyId, status) => backend.post(`/survey/updateStatus/${surveyId}`, { status }),
}

export const Questionnaire = {
  getById: (questionnaireId, surveyId = null) =>
    backend.post(`/questionnaire/id/${questionnaireId}`, { surveyId }),
  // gac is used by answer where clause for answers of riwis surveys
  getByIdAndGac: (questionnaireId, surveyId, gac) =>
    backend.post(`/questionnaire/id/${questionnaireId}`, { gac, surveyId }),
  saveAnswers: ({
    answers,
    surveyId,
    questionnaireId,
    gac,
    answeredCityId,
    questionCount,
    answerCount,
    earlyExit,
    datasourceYear,
    quarter,
  }) =>
    backend.post('questionnaire/answers', {
      answers,
      surveyId,
      questionnaireId,
      gac,
      answeredCityId,
      questionCount,
      answerCount,
      earlyExit,
      datasourceYear,
      quarter,
    }),
}

export const UserGroup = {
  getAll: () => backend.get('/usergroup/all'),
  getById: (userGroupId) => backend.get(`/usergroup/id/${userGroupId}`),
  deleteById: (userGroupId) => backend.delete(`/usergroup/id/${userGroupId}`),
  saveUserGroup: (userGroup, userGroupId) => backend.post(`/usergroup/id/${userGroupId}`, userGroup),
  uploadUsers: (users) => backend.post('/usergroup/userupload/', users),
  deleteUsersInUserGroup: (userGroupId, userIds) =>
    backend.post(`/usergroup/id/${userGroupId}/users`, { userIds }),
}

export const Landingpage = {
  getOngoingSurveys: () => backend.post('/landingpage/surveys'),
}

export const Reporting = {
  getReport: (surveyId) => backend.get(`/reporting/id/${surveyId}`),
}

export const User = {
  getAll: () => backend.get('/user/all'),
  findByName: (searchQuery) => backend.get(`/user/find/${searchQuery}`),
  getById: (userId) => backend.get(`/user/id/${userId}`),
  createUser: (user) => backend.post('/user/id/0', { user }),
  updateUser: (user, userId) => backend.post(`/user/id/${userId}`, { user }),
  deleteUser: (userId) => backend.delete(`/user/id/${userId}`),
  anonymizeUser: (userId) => backend.post(`/user/anonymize/${userId}`),
  editUser: (user) => backend.post('/user/account/edit', { user }),
  requestResetPassword: (email) => backend.post('/user/account/requestResetPassword', { email }),
  changePassword: (password, token) => backend.post('/user/account/changePassword', { password, token }),
  setListAsPartner: (listAsPartner) => backend.get(`/user/listAsPartner?value=${listAsPartner}`),
}

export const Token = {
  createTokensAll: (userGroupId, surveyId) => backend.post(`/token/all`, { userGroupId, surveyId }),
  createTokensSelected: (userGroupId, surveyId, userIds) =>
    backend.post('/token/selected', { userGroupId, surveyId, userIds }),
  getByUserGroupIdAndSurveyId: (userGroupId, surveyId) => backend.get(`/token/${userGroupId}/${surveyId}`),
  extendValidDateAll: (userGroupId, surveyId, timespan) =>
    backend.post(`/token/${userGroupId}/${surveyId}/extend/all`, { timespan }),
  extendValidDateSelected: (userGroupId, surveyId, timespan, tokenIds) =>
    backend.post(`/token/${userGroupId}/${surveyId}/extend/selected`, { timespan, tokenIds }),
  deleteAll: (userGroupId, surveyId) => backend.post(`/token/delete/all`, { userGroupId, surveyId }),
  deleteSelected: (tokenIds) => backend.post(`/token/delete/selected`, { tokenIds }),
  getMail: (userGroupId, surveyId) => backend.get(`/token/${userGroupId}/${surveyId}/getMail`),
  saveMail: (userGroupId, surveyId, mail) =>
    backend.post(`/token/${userGroupId}/${surveyId}/saveMail`, { mail }),
  sendMail: (userGroupId, surveyId, mail, tokens) =>
    backend.post(`/token/${userGroupId}/${surveyId}/sendMail`, { mail, tokens }),
}

export const Template = {
  getList: () => backend.get('/template/list'),
  getById: (templateId) => backend.get(`/template/id/${templateId}`),
  saveTemplate: (title, template, id) => backend.post(`/template/save/`, { title, template, id }),
}

export const Riwis = {
  getCities: () => backend.get('/riwis/cities'),
  getData: (datasources, gac, year, surveyId) =>
    backend.post('/riwis/data', { datasources, gac, year, surveyId }),
  getAnswerdCitiesBySurvey: (surveyId) => backend.get(`/riwis/answeredCities?surveyId=${surveyId}`),
  getGeometryByGacAndAreaType: (gac, areaType, marketCategory, surveyId) =>
    backend.post('/riwis/geometry', { gac, areaType, marketCategory, surveyId }),
  getKgs12ByGeometry: (geometry) => backend.post('/riwis/kgs12', { geometry }),
}

export const Registration = {
  register: (userInfo, isRiwisUser = false) => backend.post('/registration', { userInfo, isRiwisUser }),
  verification: (token) => backend.post('/registration/activation', { token }),
  requestVerificationMail: (email) => backend.post('/registration/requestVerification', { email }),
}

export const Logs = {
  saveErrorLog: (report) => backend.post('/logs/error', { report }),
}
