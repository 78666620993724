import React from 'react'
import { useSurveyDataStore } from '../../dataStore'
import { Page } from '../../Page'
import { RiwisThanks, Thanks, Fail, RiwisFinish, Finish } from './Endings'

export const NextPage = ({ onHide, waitForNext }) => {
  const [{ survey, pages, isSaving, saveMessage, questionnaireIsFetching, pageNumber }] = useSurveyDataStore()

  if (pages.length + 1 === pageNumber) {
    if (isSaving === false && saveMessage.type === 'success') {
      if (survey.type === 'riwis') {
        return <RiwisThanks />
      } else {
        return <Thanks />
      }
    } else {
      return <Fail />
    }
  } else if (pages.length === pageNumber) {
    return survey.type === 'riwis' ? <RiwisFinish /> : <Finish />
  } else if (!questionnaireIsFetching && !waitForNext && pages.length) {
    return <Page page={pages[pageNumber]} onHide={onHide} />
  }
  return <div></div>
}
