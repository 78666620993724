import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useCallback, useEffect } from 'react'
import { useSurveyDataStore } from '../../dataStore'
import { TextEnum } from '../../QuestionTypes/RiwisSlider/mapHelper'
import { useTranslation } from 'react-i18next'
import { SavingState } from './Buttons'

export const RiwisCloseButton = ({ isSaving, onHide }) => {
  const [
    { answeredCities, questionnaire, survey, selectedCity },
    { setPageNumber, setNextRiwisQuestionnaire },
  ] = useSurveyDataStore()

  const { t } = useTranslation()

  const nextRiwisQuestionnaire = useMemo(() => {
    if (survey.type === 'riwis') {
      let current = questionnaire
      const available = survey.questionnaires
      const currentIndex = available.findIndex((questionnaire) => questionnaire.id === current.id)
      let nextIndex = currentIndex < available.length - 1 ? currentIndex + 1 : 0
      let nextQuestionnaire = null
      while (!nextQuestionnaire) {
        const id = available[nextIndex].id
        if (
          !answeredCities?.some(
            (answeredCity) =>
              answeredCity.generic_area_code === selectedCity.gac &&
              answeredCity.questionnaires.some((questionnaire) => {
                return questionnaire.id === id && questionnaire.answerCount > 0
              })
          )
        ) {
          nextQuestionnaire = available[nextIndex]
        } else if (currentIndex === nextIndex) {
          nextQuestionnaire = current
        } else {
          if (nextIndex + 1 < available.length) {
            nextIndex++
          } else {
            nextIndex = 0
          }
        }
      }
      return nextQuestionnaire
    }
    return null
  }, [answeredCities, questionnaire, selectedCity, survey])

  useEffect(() => {
    if (nextRiwisQuestionnaire) {
      setNextRiwisQuestionnaire(nextRiwisQuestionnaire)
    }
  }, [nextRiwisQuestionnaire, setNextRiwisQuestionnaire])

  const goToNextRiwisQuestionnaire = useCallback(() => {
    setPageNumber(0)
    onHide(nextRiwisQuestionnaire.id)
  }, [nextRiwisQuestionnaire, onHide, setPageNumber])

  if (nextRiwisQuestionnaire.id !== questionnaire.id) {
    return (
      <>
        <button
          type="button"
          onClick={() => onHide(null)}
          className="riwis-btn riwis-secondary"
          disabled={isSaving}
        >
          {t('survey.buttons.closeButton')}
        </button>
        <button
          type="button"
          className="riwis-btn riwis-primary"
          disabled={isSaving}
          onClick={goToNextRiwisQuestionnaire}
        >
          {TextEnum[nextRiwisQuestionnaire.asset_class]}{' '}
          <span className="ml-4">
            <FontAwesomeIcon icon={faPlay} />
          </span>
        </button>
      </>
    )
  } else {
    return (
      <>
        {/* workaround for space-between*/}
        <div></div>
        <button
          type="button"
          onClick={() => onHide(null)}
          className="riwis-btn riwis-primary"
          disabled={isSaving}
        >
          {t('survey.buttons.closeButton')}
        </button>
      </>
    )
  }
}

export const RiwisSaveButton = ({ label, earlyExit = false, className }) => {
  const [
    { questionnaire, survey, selectedCity, answeredCities, pageNumber, pages, isSaving },
    { saveAnswers },
  ] = useSurveyDataStore()
  const nextPage = earlyExit ? pageNumber : pageNumber + 1
  const answeredQuestionnaires = useMemo(
    () => answeredCities?.find((city) => city.generic_area_code === selectedCity.gac)?.questionnaires,
    [answeredCities, selectedCity]
  )
  const answeredCityId = useMemo(() => {
    return (
      answeredQuestionnaires?.find((aQuestionnaire) => aQuestionnaire.id === questionnaire.id)?.resId ?? 0
    )
  }, [answeredQuestionnaires, questionnaire])

  return (
    <button
      type="button"
      className={'riwis-btn riwis-primary ' + className}
      disabled={isSaving}
      onClick={() =>
        saveAnswers({
          nextPage,
          questionnaire,
          surveyId: survey.id,
          gac: selectedCity.gac,
          answeredCityId: answeredCityId,
          earlyExit: earlyExit ? pageNumber + 1 : null,
          datasourceYear: survey.datasource_year,
          visiblePages: pages,
        })
      }
    >
      {isSaving ? (
        <SavingState />
      ) : (
        <>
          {label}
          <span className="ml-4">
            <FontAwesomeIcon icon={faPlay} />
          </span>
        </>
      )}
    </button>
  )
}
