import React from 'react'
import { ReactComponent as LocationPinOutline } from '../assets/icons/Location-Pin.svg'
import { ReactComponent as LocationPinFill } from '../assets/icons/Location-Pin 2.svg'
export const LocationPin = ({ selected, color }) => {
  //   return <img className="mr-3" width="24" src={pin} alt="" style={{ fill: color }} />
  return (
    <div className="mr-3" style={{ width: '24px' }}>
      {selected ? <LocationPinFill fill={color} /> : <LocationPinOutline fill={color} />}
    </div>
  )
}
