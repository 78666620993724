import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

export const Unit = ({ question, questionnaireIndex, pageIndex, index }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const handleInputChange = useCallback(
    (event) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, event.target.value, event.target.name)
    },
    [pageIndex, questionnaireIndex, index, setQuestionInput]
  )

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">Einheit</label>
      <div className="col-sm-10">
        <input
          className="form-control"
          type="text"
          name="unit"
          onChange={(event) => handleInputChange(event)}
          value={question.unit || ''}
        ></input>
      </div>
    </div>
  )
}
