import { useCallback, useEffect, useState, useMemo } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { useUserDataStore } from '../stores/userStore'
import { useSurveyDataStore } from '../pages/Survey/dataStore'
import { LoginBox } from './NavigationContent/LoginBox'
import { ResendVerification } from './NavigationContent/ResendVerification'
import { ForgotPassword } from './NavigationContent/ForgotPassword'
import Logo from '../assets/img/riwis_logo_color.svg'
import { useTranslation } from 'react-i18next'

const LanguageSwitch = () => {
  const [{ language }, { setLanguage }] = useUserDataStore()
  const { t } = useTranslation()

  const handleLanguageChange = useCallback(
    (event) => {
      setLanguage(event.target.value)
    },
    [setLanguage]
  )

  return (
    <ul className="navbar-nav align-items-center">
      <li className="nav-item dropdown">
        <div
          id="languageDropdown"
          href="#"
          className="nav-link dropdown-toggle btn text-white"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="notranslate">{language?.toUpperCase()}</span>
        </div>
        <div
          className="dropdown-menu"
          aria-labelledby="languageDropdown"
          style={{ zIndex: 1100, minWidth: 'auto' }}
        >
          <button onClick={handleLanguageChange} className="dropdown-item notranslate" value="de">
            {t('navigation.german')}
          </button>
          <button onClick={handleLanguageChange} className="dropdown-item notranslate" value="en">
            {t('navigation.english')}
          </button>
        </div>
      </li>
    </ul>
  )
}

export const Navigation = () => {
  const [
    { user, redirectTo, showLoginModal },
    { logoutUser, setLoginMessage, setRequestResetPasswordMessage, setShowLoginModal },
  ] = useUserDataStore()
  const [{ survey, questionnaire, surveyMessage }] = useSurveyDataStore()
  const { t } = useTranslation()

  const [show, setShow] = useState(false)
  const [riwisMode, setRiwisMode] = useState(false)

  const [resendTo, setResendTo] = useState(null)
  const [forgotPassword, setForgotPassword] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const history = useHistory()
  const location = useLocation()

  const handleLogout = useCallback(() => {
    logoutUser()
  }, [logoutUser])

  const logo = useMemo(() => {
    let logo = ''
    if (survey && survey.logo && survey.settings?.useLogoInNav && location.pathname.includes('/survey/')) {
      logo = survey.logo
    }
    if (riwisMode) {
      logo = Logo
    }
    return logo
  }, [location.pathname, riwisMode, survey])

  const NavbarNotLoggedIn = useCallback(() => {
    return (
      <nav
        className={
          (riwisMode ? 'bg-riwis' : 'bg-primary') +
          ' navbar navbar-dark navbar-expand-md p-0 justify-content-between'
        }
      >
        <button
          onClick={handleShow}
          className="btn btn-outline-light py-3 ml-3 mr-auto"
          data-toggle="modal"
          data-target="#loginModal"
        >
          {t('navigation.login')}
        </button>

        <LanguageSwitch />

        <NavbarSlope backgroundClass={riwisMode ? 'riwis-slope' : ''} logo={logo} />
      </nav>
    )
  }, [riwisMode, t, logo])

  const NavbarLoggedIn = useCallback(() => {
    return (
      <nav className={(riwisMode ? 'bg-riwis' : 'bg-primary') + ' navbar navbar-dark navbar-expand-md p-0'}>
        <button className="navbar-toggler ml-3" type="button" data-toggle="collapse" data-target=".dual-nav">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse dual-nav order-1 order-md-0">
          <ul className={`navbar-nav align-items-center ${!user.isAdmin ? 'mr-auto' : ''}`}>
            <li className="nav-item dropdown">
              <div
                id="userDropdown"
                href="#"
                className="nav-link dropdown-toggle btn text-white"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {user.username}
              </div>
              <div className="dropdown-menu" aria-labelledby="userDropdown" style={{ zIndex: 1100 }}>
                <Link className="nav-link" to={`/account/${user.id}`} style={{ padding: 0 }}>
                  <button className="dropdown-item">{t('navigation.account')}</button>
                </Link>
                <button onClick={handleLogout} href="" className="dropdown-item">
                  {t('navigation.logout')}
                </button>
              </div>
            </li>
          </ul>
          {user.isAdmin === true && (
            <ul className="navbar-nav align-items-center ml-5 mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/admin/surveys/all">
                  {t('navigation.surveys')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/reporting">
                  {t('navigation.evaluation')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/token">
                  {t('navigation.token')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/usergroups">
                  {t('navigation.usergroups')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/users">
                  {t('navigation.users')}
                </Link>
              </li>
            </ul>
          )}
          <LanguageSwitch />
        </div>
        <NavbarSlope
          questionnaire={questionnaire}
          backgroundClass={riwisMode ? 'riwis-slope' : ''}
          logo={logo}
        />
      </nav>
    )
  }, [riwisMode, user, t, handleLogout, questionnaire, logo])

  const NavbarSlope = useCallback(({ backgroundClass, logo, questionnaire }) => {
    return (
      <div className="navbar-brand order-0 order-md-2 p-0 m-0">
        <div className="brandWrapper order-sm-last">
          <div className={`${backgroundClass} slope`}>
            <div className="logoWrapper notranslate">
              {/* {questionnaire ? (
                logo ? (
                  <img className="p-2 logo" src={logo} alt="logo" width="100%" />
                ) : (
                  'SurveyDesk'
                )
              ) : ( */}
              <Link to="/">
                {logo ? <img className="p-2 logo" src={logo} alt="logo" width="100%" /> : 'SurveyDesk'}
              </Link>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    )
  }, [])

  useEffect(() => {
    if (showLoginModal) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [showLoginModal])

  useEffect(() => {
    if (!show) {
      setLoginMessage({ msg: '', type: '', err: '' })
      setRequestResetPasswordMessage({ msg: '', type: '', err: '' })
      setResendTo(null)
      setForgotPassword(null)
      setShowLoginModal(false)
    }
  }, [show, setLoginMessage, setRequestResetPasswordMessage, setShowLoginModal])

  useEffect(() => {
    if (!user && redirectTo) {
      history.push(redirectTo)
    }
  }, [user, redirectTo, history])

  useEffect(() => {
    if (
      (location &&
        location?.pathname.match(/^\/survey\//) &&
        ((survey && survey.type === 'riwis') || surveyMessage?.msg === 'showRiwisRegistration')) ||
      location?.pathname.match(/^\/registration\/riwis/)
    ) {
      setRiwisMode(true)
    } else {
      setRiwisMode(false)
    }
  }, [location, survey, surveyMessage])

  return (
    <>
      {!user?.id ? <NavbarNotLoggedIn /> : <NavbarLoggedIn />}

      {/* Login Modal */}
      <Modal
        contentClassName="loginWindow"
        style={{ fontFamily: riwisMode && 'Maven Pro' }}
        show={show}
        onHide={handleClose}
      >
        <div className={(riwisMode ? 'riwis' : '') + ' d-flex justify-content-start align-items-center pt-5'}>
          <div className="loginBarOne"></div>
          <h2 className="text-primary text-nowrap mx-3">
            {riwisMode ? <img width="100" height="100%" src={Logo} alt="logo" /> : 'SurveyDesk'}
          </h2>
          <div className="loginBarTwo"></div>
        </div>
        <Modal.Body className="loginBody">
          {!resendTo ? (
            !forgotPassword ? (
              <LoginBox
                handleResendVerificationMail={setResendTo}
                handleForgotPassword={setForgotPassword}
                handleClose={handleClose}
                riwisMode={riwisMode}
              />
            ) : (
              <ForgotPassword handleClose={handleClose} riwisMode={riwisMode} />
            )
          ) : (
            <ResendVerification handleClose={handleClose} email={resendTo} riwisMode={riwisMode} />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
