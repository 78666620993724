import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { default as RCSlider } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { NoAnswerCheckbox } from '../QuestionnaireBuilder/Components/NoAnswerCheckbox'

const railAndTrackStyle = {
  backgroundColor: '#efedee',
}

const handleStyle = {
  height: 16,
  width: 16,
  border: '1px solid #003e71',
  right: 'auto',
  position: 'relative',
  borderRadius: '100%',
  transform: 'translateX(-50%) translateY(-6px)',
  zIndex: 100,
}

const disabledBadgeStyle = {
  backgroundColor: '#ced4da',
  color: 'grey',
}

const CustomSliderHandle = ({ position, dragging, disabled }) => {
  const left = position + '%'
  const boxShadow = dragging ? '0 0 6px #003e71' : 'none'
  const backgroundColor = disabled ? 'grey' : dragging ? 'white' : '#003e71'
  const borderWidth = disabled ? 0 : dragging ? '3px' : '1px'
  const cursor = dragging ? 'grabbing' : 'grab'

  const style = useMemo(() => {
    return {
      ...handleStyle,
      boxShadow,
      left,
      backgroundColor,
      borderWidth,
      cursor,
    }
  }, [boxShadow, left, backgroundColor, borderWidth, cursor])
  return <div style={style}></div>
}

export const Slider = ({ id, qid, options, value = {}, previousAnswer, noAnswer, onChange }) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)

  const handleSliderChange = useCallback(
    (sliderValue) => {
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }

      if (sliderValue < Number(options.min_value)) {
        sliderValue = options.min_value
      }
      if (sliderValue > Number(options.max_value)) {
        sliderValue = options.max_value
      }
      newValue.sliderValue = sliderValue

      setLocalPreviousAnswer(null)
      onChange({ question_id: id, value: newValue, qid })
    },
    [id, qid, onChange, options, value, localPreviousAnswer]
  )

  const handleNoAnswerChange = useCallback(
    (event) => {
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }
      newValue.noAnswer = event.target.checked
      setLocalPreviousAnswer(null)
      onChange({ question_id: id, value: newValue, qid })
    },
    [value, onChange, qid, id, localPreviousAnswer]
  )

  const step = useMemo(() => {
    if (Number(options.step) === 0) {
      return 1
    }
    return Number(options.step)
  }, [options.step])

  const sliderValue = useMemo(() => {
    let sliderValue = options.default_value
    if (typeof value?.sliderValue !== 'undefined') {
      sliderValue = value.sliderValue
    } else if (
      localPreviousAnswer &&
      typeof localPreviousAnswer?.sliderValue !== 'undefined' &&
      !localPreviousAnswer.noAnswer
    ) {
      sliderValue = localPreviousAnswer.sliderValue
    }
    return sliderValue
  }, [value, localPreviousAnswer, options])

  const noAnswerValue = useMemo(() => {
    let checked = false
    if (typeof value.noAnswer !== 'undefined') {
      checked = value.noAnswer
    } else if (localPreviousAnswer && typeof localPreviousAnswer.noAnswer !== 'undefined') {
      checked = localPreviousAnswer.noAnswer
    }
    return checked
  }, [value, localPreviousAnswer])

  const customizeHandle = useCallback(({ offset, dragging, disabled, ...props }) => {
    return <CustomSliderHandle position={offset} dragging={dragging} disabled={disabled} />
  }, [])

  // converting slider value to new format
  useEffect(() => {
    if (typeof value === 'string' || typeof value === 'number') {
      onChange({ sliderValue: value, noAnswer: false })
    }
  }, [onChange, value])

  return (
    <div>
      <div className="d-flex align-items-center">
        <SliderInput
          disabled={noAnswerValue}
          options={options}
          handleChange={handleSliderChange}
          inputValue={sliderValue}
        />
        <h5 className="mr-4">
          <span className="badge badge-primary" style={value.noAnswer ? disabledBadgeStyle : null}>
            {Number(options.min_value).toLocaleString() + ' ' + (options.unit || '')}
          </span>
        </h5>
        <div style={{ width: '33%' }}>
          <RCSlider
            defaultValue={Number(options.default_value)}
            value={sliderValue}
            min={Number(options.min_value)}
            max={Number(options.max_value)}
            step={step}
            trackStyle={railAndTrackStyle}
            railStyle={railAndTrackStyle}
            handle={customizeHandle}
            onChange={handleSliderChange}
            disabled={noAnswerValue}
          />
        </div>
        <h5 className="ml-4">
          <span className="badge badge-primary" style={value.noAnswer ? disabledBadgeStyle : null}>
            {Number(options.max_value).toLocaleString() + ' ' + (options.unit || '')}
          </span>
        </h5>
      </div>
      <NoAnswerCheckbox
        noAnswer={noAnswer}
        onChange={handleNoAnswerChange}
        checked={noAnswerValue}
        questionId={id}
      />
    </div>
  )
}

const SliderInput = ({ options, handleChange, inputValue, disabled }) => {
  const handleNumberInput = useCallback(
    (event) => {
      let maxDecimal = 0
      if (options.with_decimal) {
        maxDecimal = options.step.toString().split('.')[1].length
      }
      if (maxDecimal > 0 && event.target.value.toString().split('.')[1]?.length > maxDecimal) {
        event.target.value = Number(event.target.value).toFixed(maxDecimal).toString()
      }
      handleChange(event.target.value)
    },
    [handleChange, options]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (!options.with_decimal && (event.code === 'Period' || event.code === 'Comma')) {
        event.preventDefault()
      }
    },
    [options]
  )

  if (options.unit) {
    return (
      <div className="input-group mr-4" style={{ width: '10rem' }}>
        <div className="input-group-prepend">
          <span className="input-group-text" id="valueUnit">
            {options.unit}
          </span>
        </div>
        <input
          className="form-control mr-4 numberInput"
          type="number"
          name="sliderValue"
          size="1"
          min={options.min_value}
          max={options.max_value}
          step={options.step}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleNumberInput}
          style={{ width: '3rem' }}
          aria-describedby="valueUnit"
          disabled={disabled}
        ></input>
      </div>
    )
  }

  return (
    <input
      className="form-control mr-4 numberInput"
      type="number"
      name="sliderValue"
      size="1"
      min={options.min_value}
      max={options.max_value}
      step={options.step}
      value={inputValue}
      onKeyDown={handleKeyDown}
      onChange={handleNumberInput}
      style={{ width: '3rem' }}
      aria-describedby="valueUnit"
      disabled={disabled}
    ></input>
  )
}
