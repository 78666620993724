const resolveConditions = (conditions, requiredAnswer, previousAnswer = null, operatorType) => {
  if (conditions.length === 0) {
    return true
  }
  let conditionsFulfilled = null
  const givenAnswer = requiredAnswer.given_answer?.value?.value || previousAnswer || null

  for (const constraint of conditions) {
    if (Array.isArray(givenAnswer)) {
      // eslint-disable-next-line
      conditionsFulfilled = givenAnswer.some((item) => item == constraint.has)
    } else if (typeof givenAnswer === 'object' && givenAnswer !== null) {
      // eslint-disable-next-line
      conditionsFulfilled = Object.entries(givenAnswer).reduce((bool, [key, answers]) => {
        if (requiredAnswer.type === 'radioMatrix') {
          // eslint-disable-next-line
          if (answers == constraint.has) {
            bool = true
          }
        } else if (Array.isArray(answers)) {
          // eslint-disable-next-line
          if (answers.some((item) => item == constraint.has)) {
            bool = true
          }
        } else {
          if (
            requiredAnswer.type === 'text' ||
            requiredAnswer.type === 'inputColumns' ||
            requiredAnswer.type === 'textarea' ||
            requiredAnswer.type === 'slider' ||
            requiredAnswer.type === 'sum'
          ) {
            // -1 means noAnswer
            if (constraint.has === -1) {
              bool = givenAnswer?.noAnswer || false
            }

            if (!isNaN(Number(key)) && Number(key) === constraint.has && answers) {
              bool = true
            }
          } else if (!isNaN(Number(key)) && Number(key) === constraint.has && answers) {
            bool = true
          }
        }
        return bool
      }, false)
    } else {
      // eslint-disable-next-line
      conditionsFulfilled = givenAnswer == constraint.has
    }
    if (operatorType === 'or' && conditionsFulfilled === true) {
      return true
    }
    if (operatorType === 'and' && conditionsFulfilled === false) {
      return false
    }
  }
  return conditionsFulfilled
}

export const checkConstraints = (constraints, questionnaire, surveyType, surveyYear, surveyQuarter) => {
  if (!constraints) return true
  if (!Array.isArray(constraints) && (!constraints.when || !constraints.has)) return true
  if (Array.isArray(constraints) && constraints.length === 0) return true

  const requiredAnswers = questionnaire.pages.reduce((arr, page) => {
    const filteredQuestions = page.questions
      ? page.questions.filter((question) => {
          return constraints.some(
            (constraint) =>
              constraint.when === question.qid && constraint.has !== null && constraint.has !== undefined
          )
        }, [])
      : []
    if (filteredQuestions.length) {
      arr.push(...filteredQuestions)
    }
    return arr
  }, [])

  const checkConditions = (requiredAnswers, constraints) => {
    let conditionsFulfilled = false
    for (const requiredAnswer of requiredAnswers) {
      const filteredConstraints = constraints.filter(
        (constraint) =>
          constraint.when === requiredAnswer.qid && constraint.has !== null && constraint.has !== undefined
      )

      const currentOperator = filteredConstraints[filteredConstraints.length - 1]?.operator ?? null

      const { orConditions, andConditions } = filteredConstraints.reduce(
        (obj, constraint, index) => {
          if (
            constraint?.operator === 'or' ||
            (index > 0 && !constraint?.operator && filteredConstraints[index - 1].operator === 'or')
          ) {
            obj.orConditions.push(constraint)
          } else if (
            constraint?.operator === 'and' ||
            (index > 0 && !constraint?.operator && filteredConstraints[index - 1].operator === 'and')
          ) {
            obj.andConditions.push(constraint)
          } else if (!constraint.operator) {
            obj.andConditions.push(constraint)
          }
          return obj
        },
        { orConditions: [], andConditions: [] }
      )

      const previousAnswer = findLatestPreviousAnswer(
        requiredAnswer.previous_answers,
        surveyType,
        surveyYear,
        surveyQuarter
      )

      conditionsFulfilled =
        resolveConditions(orConditions, requiredAnswer, previousAnswer, 'or') &&
        resolveConditions(andConditions, requiredAnswer, previousAnswer, 'and')

      if (currentOperator === 'or' && conditionsFulfilled === true) {
        return true
      }

      if (currentOperator === 'or' && conditionsFulfilled === false) {
        continue
      }

      if (!conditionsFulfilled) {
        return false
      }
    }
    return true
  }

  if (requiredAnswers.length) {
    return checkConditions(requiredAnswers, constraints)
  } else {
    return true
  }
}

export const getMissingInputs = (pageNo, pages) => {
  if (pageNo < 0 || pageNo === null) return []
  if (pages.length === pageNo) return []
  // even if no questions are defined, the questionnaire shall go further
  //
  if (!pages[pageNo]?.questions) return []

  // workaround
  const missingInputSubIds = {}

  let missingInputs = pages[pageNo]?.questions.filter((question) => {
    if (question.is_optional) return false
    const currentValue = question?.given_answer?.value?.value
    const previousAnswers = question?.previous_answers
    if (previousAnswers && !question?.given_answer) return false
    if (typeof currentValue === 'undefined') {
      return true
    }

    if (currentValue?.optOut === true || currentValue.noAnswer === true) return false
    if (Array.isArray(currentValue)) return currentValue.length <= 0
    if (question.type === 'radioMatrix' || question.type === 'checkboxMatrix') {
      return (
        !question?.given_answer ||
        question?.questions_field.length !== Object.keys(question?.given_answer?.value?.value).length ||
        Object.values(question?.given_answer?.value?.value).some((value) => value.length === 0)
      )
    }
    if (question.type === 'sum')
      return (
        Object.values(currentValue).reduce((accu, value) => accu + Number(value), 0) !==
        Number(question?.max_value)
      )
    if (question.type === 'text' || question.type === 'textarea') {
      if (!currentValue.text) {
        return true
      }
    }

    if (question.type === 'inputColumns' || question.type === 'contactForm' || question.type === 'dropdown') {
      const requireSubIds = question.questions_field.some((pAnswer) => pAnswer?.required === true)
      if (requireSubIds) {
        missingInputSubIds[question.id] = []
        return !question.questions_field.reduce((fullfilled, pAnswer) => {
          const givenAnswer = currentValue[pAnswer.id]
          if (
            pAnswer.required &&
            (givenAnswer === '' || givenAnswer === null || typeof givenAnswer === 'undefined')
          ) {
            fullfilled = false
            missingInputSubIds[question.id].push(pAnswer.id)
          }

          return fullfilled
        }, true)
      } else {
        return !Object.values(currentValue).some((value) => value.length)
      }
    }
    if (typeof currentValue === 'object') return Object.keys(currentValue).length <= 0
    return currentValue === ''
  })

  missingInputs = missingInputs.map((question) => {
    if (missingInputSubIds[question.id] && missingInputSubIds[question.id].length) {
      return { [question.id]: missingInputSubIds[question.id] }
    }
    return question.id
  })

  return missingInputs
}

export const getQuestionPerPageObject = (pages, pageNumber, questionsPerPage) => {
  const visibleQuestions = pages[pageNumber]?.questions?.length
  const questionObject = {
    pageNumber: pageNumber,
    questionCount: visibleQuestions,
    accumulatedQuestions:
      pageNumber === 0
        ? visibleQuestions
        : visibleQuestions + questionsPerPage[pageNumber - 1].accumulatedQuestions,
  }

  const questionPerPageItems = [...questionsPerPage]

  if (!questionsPerPage[pageNumber]) {
    questionPerPageItems.push(questionObject)
  } else {
    questionPerPageItems[pageNumber] = questionObject
  }

  return questionPerPageItems
}

export const getAnswersFromQuestionnaire = (questionnaire, visiblePages) => {
  const answers = []
  visiblePages.forEach((visiblePage) => {
    const page = questionnaire.pages[visiblePage.index]
    if (page.questions) {
      visiblePage.questions.forEach((visibleQuestion) => {
        const question = page.questions[visibleQuestion.index]
        if (question.given_answer && Object.values(question.given_answer).length) {
          const givenAnswer = { ...question.given_answer, qid: question.qid }
          answers.push(givenAnswer)
        }
      })
    }
  })
  return { answers, questionCount: null, answerCount: null }
}

export const getRiwisAnswersFromQuestionnaire = (questionnaire, gac, visiblePages) => {
  const answers = []
  let questionCount = 0
  visiblePages.forEach((visiblePage) => {
    const page = questionnaire.pages[visiblePage.index]
    if (page.questions) {
      visiblePage.questions.forEach((visibleQuestion) => {
        const question = page.questions[visibleQuestion.index]
        questionCount++
        if (question.given_answer && Object.values(question.given_answer).length) {
          question.given_answer.generic_area_code = gac
          if (question.given_answer.value.value || question.given_answer.value.value.note) {
            const givenAnswer = { ...question.given_answer, qid: question.qid }
            answers.push(givenAnswer)
          } else {
            questionCount--
          }
        }
      })
    }
  })
  return { answers, questionCount, answerCount: answers.length }
}

export const findLatestPreviousAnswer = (previousAnswers, surveyType, surveyYear, surveyQuarter) => {
  if (!previousAnswers || !Object.keys(previousAnswers).length) {
    return null
  }
  if (surveyType === 'bfQuarter') {
    let latestYear = surveyYear
    let latestQuarter = surveyQuarter
    while (!previousAnswers[latestYear]) {
      latestYear--
      // saftey exit
      if (latestYear < 2000) {
        break
      }
    }
    if (!previousAnswers[latestYear] || !Object.keys(previousAnswers[latestYear]).length) {
      return null
    }
    let emergencyBreak = 20
    let i = 0
    while (!previousAnswers[latestYear][latestQuarter]) {
      if (latestQuarter - 1 < 1) {
        latestQuarter = 4
      } else {
        latestQuarter--
        i++
      }
      if (i === emergencyBreak) {
        console.warn('Iteration reached maximum, returning null')
        return null
      }
    }
    return previousAnswers[latestYear][latestQuarter]?.value
  }
  // TODO: for riwis surveys
  return previousAnswers || {}
}
