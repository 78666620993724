import createStore from '../../lib/flux-store'
import { Landingpage } from '../../helper/api'

const initialState = {
  dataIsLoading: true,
  surveys: null,
}

const actions = {
  fetchSurveys: () => (dispatch) => {
    Landingpage.getOngoingSurveys().then((res) => {
      dispatch({ type: 'fetchSurveys', payload: res.data.surveys })
    })
  },
}

const reducer = {
  fetchSurveys: (state, { payload }) => {
    return { ...state, surveys: payload }
  },
}

export const [LandingpageDataContext, LandingpageDataProvider, useLandingpageDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'LandingpageDataStore'
)
