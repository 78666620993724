import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

const Enum = {
  matrix_answer_header: 'Antwort-Überschrift',
  matrix_question_header: 'Frage-Überschrift',
}

export const MatrixHeader = ({ question, questionnaireIndex, pageIndex, index, header }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()
  const handleInputChange = useCallback(
    (event) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, event.target.value, event.target.name)
    },
    [pageIndex, questionnaireIndex, index, setQuestionInput]
  )

  return (
    <div className=" form-group row">
      <label className="col-sm-2 form-group-label text-right">{Enum[header]}</label>
      <div className="col-sm-10">
        <input
          className="form-control"
          name={header}
          type="text"
          value={question[header] || ''}
          onChange={(event) => handleInputChange(event)}
        />
      </div>
    </div>
  )
}
// header options:
// matrix_answer_header
// matrix_question_header
