import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Pane } from 'react-leaflet'
import { LeafletMap, GeoJSON } from '../../../../components/LeafletMap'
import { useSurveyDataStore } from '../../dataStore'
import { AreaEnum, TopicToQualityEnum, wohnlagen, eh_lagen, eh_lagen_name } from './mapHelper'
import { multiPolygon } from '@turf/helpers'

const peripheryStyle = {
  fillColor: '#005b9a',
  fillOpacity: 0.4,
  stroke: false,
}

const peripheryStrikedOut = {
  fillColor: 'red',
  fillOpacity: 0.8,
  stroke: false,
}

const geojsonMarkerOptions = {
  radius: 8,
  fillColor: '#005b9a',
  color: '#005b9a',
  weight: 2,
  opacity: 1,
  fillOpacity: 0.4,
}

const boundStyleOnPeriphery = {
  fill: false,
  stroke: true,
  weight: 2,
  opacity: 1,
  color: '#005b9a',
}

const boundStyleBlack = {
  fill: false,
  stroke: true,
  color: 'black',
  weight: 1,
}

const boundsStyle = {
  color: '#000000',
  fillColor: '#000000',
  weight: 1,
  opacity: 1,
  fillOpacity: 0.2,
}

const kgs12Style = {
  radius: 8,
  fillColor: '#ff1100',
  color: '#005b9a',
  weight: 1,
  opacity: 0,
  fillOpacity: 0.2,
}

const doSkip = (areaType) => {
  if (areaType === 'logistics') {
    return true
  }
  return false
}

const onEachFeatureKgs12 = (feature, layer) => {
  layer.setStyle({
    fillColor: wohnlagen[feature.properties.quality],
    fillOpacity: 0.7,
    color: 'white',
    opacity: 1,
  })
}

const getCombinedGeometry = (features) => {
  return features.reduce((geometry, feature) => {
    const coordinates = feature?.geometry?.coordinates || []
    geometry.push(...coordinates)
    return geometry
  }, [])
}

export const RiwisMap = ({ question }) => {
  const [areaType] = useState(() => AreaEnum[question.riwis_datasources[0].category])
  const [topic] = useState(question.riwis_datasources[0].topic)
  const [error, setError] = useState(false)
  const [
    { riwisGeometry, riwisBounds, selectedCity, riwisKgs12, survey },
    { fetchRiwisGeometry, fetchRiwisKgs12, setRiwisKgs12 },
  ] = useSurveyDataStore()

  const renderSelfGeneratedSuburbs = topic === 'rent_suburb'

  const onEachFeature = useCallback(
    (feature, layer) => {
      if (feature.properties.name) {
        if (areaType === 'retailArea') {
          layer.bindTooltip('Zentrale Lage')
        } else if (feature.properties.name === 'peripheryArea') {
          layer.bindTooltip('Peripherie', { sticky: true })
        } else {
          layer.bindTooltip(feature.properties.name)
        }
        if (areaType === 'retailArea') {
          layer.setStyle({
            fillColor: eh_lagen[feature.properties.quality],
            fillOpacity: 0.5,
          })
        }
      } else if (!feature.properties.name && areaType === 'retailArea') {
        layer.bindTooltip(eh_lagen_name[feature.properties.quality])
        layer.setStyle({
          fillColor: eh_lagen[feature.properties.quality],
          fillOpacity: 0.5,
        })
      }
    },
    [areaType]
  )

  const peripheryGeometry = useMemo(() => {
    if (!riwisGeometry[areaType]) {
      return
    }
    const cityBoundFeatures = [
      riwisBounds?.[areaType]?.features.find((feature) => Number(feature.properties.type) < 45),
    ].filter((feature) => typeof feature !== 'undefined')

    if (!cityBoundFeatures.length) {
      return
    }

    const cityBoundGeometry = getCombinedGeometry(cityBoundFeatures)
    const areaFeatures =
      riwisGeometry[areaType]?.features?.filter(
        (feature) => !TopicToQualityEnum.rent_suburb.includes(feature.properties.quality)
      ) || []

    let areaGeometry = getCombinedGeometry(areaFeatures)
    const combinedGeoJson = multiPolygon([...cityBoundGeometry, ...areaGeometry])

    combinedGeoJson.properties.name = 'peripheryArea'

    return combinedGeoJson
  }, [riwisBounds, riwisGeometry, areaType])

  // const boundGeometryAsPeriphery = useMemo(() => {
  //   const newBoundGeoJson = { ...riwisBounds?.[areaType] }
  //   // newBoundGeoJson.features = [...newBoundGeoJson.features]
  //   console.log(newBoundGeoJson)
  // })

  const filteredGeometry = useMemo(() => {
    if (!riwisGeometry[areaType] || !riwisBounds[areaType]) {
      return null
    }
    if (!Object.keys(riwisGeometry[areaType]).length && !Object.keys(!riwisBounds[areaType]).length) {
      setError(true)
      return null
    }
    const centroidX = riwisBounds[areaType].features[0].properties.centroidX
    const centroidY = riwisBounds[areaType].features[0].properties.centroidY
    riwisGeometry[areaType].center = [centroidY, centroidX]
    if (riwisGeometry[areaType]) {
      if (TopicToQualityEnum[topic] && areaType === 'officeArea') {
        const featureCollection = {
          center: riwisGeometry[areaType].center,
          type: 'FeatureCollection',
          features: [],
        }
        const quality = TopicToQualityEnum[topic]

        if (topic === 'rent_suburb') {
          featureCollection.features = riwisGeometry[areaType].features.filter(
            (feature) => !TopicToQualityEnum.rent_suburb.includes(feature.properties.quality)
          )
        } else {
          featureCollection.features = riwisGeometry[areaType].features.filter((feature) => {
            return quality.some((quality) => quality === feature.properties.quality)
          })
        }

        // filter periphery geometry and city is not type A
        // if (selectedCity.marketCategory !== 'A' && quality[0] >= 40) {
        //   featureCollection.features = riwisGeometry[areaType].features
        // } else {
        //   featureCollection.features = riwisGeometry[areaType].features.filter((feature) => {
        //     return quality.some((quality) => quality === feature.properties.quality)
        //   })
        // }
        // featureCollection.features = featureCollection.features.filter((feature) =>
        //   [].includes(feature.properties.quality)
        // )
        return featureCollection
      } else if (areaType === 'retailArea') {
        // retailArea filter for center, 1a and 1b locations
        riwisGeometry[areaType].features = riwisGeometry[areaType].features
          .filter((feature) => feature.properties.quality <= 30)
          .sort((a, b) => a.properties.quality - b.properties.quality)
        return riwisGeometry[areaType]
      }
    }
    if (areaType === 'residentialArea') {
      return riwisBounds[areaType]
    }
    return riwisGeometry[areaType]
  }, [riwisGeometry, areaType, topic, riwisBounds])

  useEffect(() => {
    if (areaType && !riwisGeometry[areaType] && !doSkip(areaType, selectedCity.marketCategory)) {
      fetchRiwisGeometry(selectedCity.gac, areaType, selectedCity.marketCategory, question, survey.id)
    }
  }, [riwisGeometry, selectedCity, fetchRiwisGeometry, question, areaType, survey.id])

  useEffect(() => {
    if (!riwisKgs12 && riwisBounds[areaType] && areaType === 'residentialArea') {
      fetchRiwisKgs12(riwisBounds[areaType].features[0].geometry)
    }
  }, [riwisBounds, areaType, fetchRiwisKgs12, riwisKgs12])

  useEffect(() => {
    if (riwisKgs12 && areaType !== 'residentialArea') {
      // delete kgs12 layer if not used, else it will overlay
      setRiwisKgs12(null)
    }
  }, [riwisKgs12, areaType, setRiwisKgs12])

  return filteredGeometry ? (
    <div className="riwisMap">
      <LeafletMap center={filteredGeometry.center}>
        <Pane name="geometry" style={{ zIndex: 700 }}>
          {renderSelfGeneratedSuburbs ? (
            <>
              <GeoJSON
                data={riwisBounds[areaType].features}
                style={boundStyleOnPeriphery}
                onEachFeature={onEachFeature}
              />
              <GeoJSON data={filteredGeometry.features} style={peripheryStrikedOut} zoomOut={0} />
              <GeoJSON data={peripheryGeometry} onEachFeature={onEachFeature} style={peripheryStyle} />
            </>
          ) : (
            <>
              <GeoJSON
                data={riwisBounds[areaType].features}
                style={topic === 'yield' ? boundStyleOnPeriphery : boundStyleBlack}
              />
              {topic !== 'yield' && (
                <GeoJSON
                  data={filteredGeometry.features}
                  onEachFeature={onEachFeature}
                  style={areaType === 'residentialArea' ? boundsStyle : geojsonMarkerOptions}
                  zoomOut={topic === 'rent_suburb' ? 0 : 0.5}
                />
              )}
            </>
          )}
        </Pane>
        <Pane name="kgs12" style={{ zIndex: 600 }}>
          {riwisKgs12 !== null && (
            <GeoJSON data={riwisKgs12.features} onEachFeature={onEachFeatureKgs12} style={kgs12Style} />
          )}
        </Pane>
      </LeafletMap>
    </div>
  ) : doSkip(areaType) ? (
    <div></div>
  ) : (
    <div
      style={{ backgroundColor: error ? '#f0f0f0' : '' }}
      className="riwisMap d-flex justify-content-center align-items-center"
    >
      {error === true && (
        <div style={{ fontSize: '1rem' }}>Leider kann derzeit keine Karte angezeigt werden</div>
      )}
    </div>
  )
}
