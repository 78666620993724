import { forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const InputWrapper = styled('div')({
  display: 'flex',
  flex: (props) => props.flex || 1,
  alignItems: (props) => props.alignItems || 'center',
  flexDirection: (props) => props.flexDirection || 'row',
  marginRight: '1rem',
  '@media(max-width: 425px)': {
    minWidth: '100%',
  },
  marginBottom: '1rem',
})

const Label = styled('label')({
  flex: 1,
  marginBottom: (props) => props.marginBottom || 0,
  marginRight: '8px',
  '@media(max-width: 425px)': {
    minWidth: '40%',
    flex: 0,
  },
  '@media(min-width: 1024px)': {
    minWidth: '25%',
    flex: 0,
  },
})

const InputGroup = styled('div')({
  flex: 1,
  '@media(min-width: 1024px)': {
    maxWidth: (props) => props.maxWidth1024,
  },
})

const TooltipText = styled('span')({
  borderBottom: '1px dotted black',
})

const TooltipContentText = styled('div')(
  ({
    theme,
    children,
    id,
    textAlign = 'left',
    fontSize = '0.725rem',
    maxWidth = '300px',
    padding = '0.5rem',
    ...rest
  }) => ({
    padding,
    maxWidth,
    textAlign,
    fontSize,
    ...rest,
  })
)

const TooltipContent = forwardRef(({ text, contentStyle, ...props }, ref) => {
  return (
    <Tooltip ref={ref} id="button-tooltip" {...props}>
      <TooltipContentText style={contentStyle} dangerouslySetInnerHTML={{ __html: text }} />
    </Tooltip>
  )
})

export const Input = ({
  name,
  id,
  value,
  onChange,
  type = 'text',
  dataType,
  unit,
  decimal = false,
  disabled,
  style,
  min,
  max,
  label,
  hint,
  inlineLabel = true,
  notFullfilled,
  required,
}) => {
  const [localValue, setLocalValue] = useState(value)
  const onLocalChange = useCallback(
    (event) => {
      if (typeof onChange === 'function') {
        let inputValue = event.target.value
        if (dataType === 'number') {
          if (
            (decimal && isNaN(event.target.value.replace(',', '.'))) ||
            isNaN(event.target.value.replace('.', '_'))
          ) {
            return
          }

          if (min !== '' && min !== null && typeof min !== 'undefined' && Number(inputValue) < min) {
            inputValue = decimal ? min : parseInt(min)
          }
          if (max !== '' && max !== null && typeof max !== 'undefined' && Number(inputValue) > max) {
            inputValue = decimal ? max : parseInt(max)
          }
        }
        event.target.value = inputValue
        setLocalValue(inputValue)
        onChange(event)
      }
    },
    [onChange, dataType, min, max, decimal]
  )
  return (
    <InputWrapper
      flexDirection={inlineLabel ? 'row' : 'column'}
      alignItems={inlineLabel ? 'center' : 'flex-start'}
      style={style}
    >
      {label !== null && (
        <>
          <Label
            htmlFor={id}
            marginBottom={inlineLabel ? 0 : '0.375rem'}
            className={(notFullfilled ? 'missingInputColor' : '') + ' mr-1 warning'}
          >
            {required === true && (
              <span style={{ fontSize: '0.6125rem', verticalAlign: 'top' }} className="star">
                *{' '}
              </span>
            )}
            {hint?.text ? (
              <>
                {hint.sign ? label : ''}
                <OverlayTrigger
                  placement="top"
                  overlay={<TooltipContent contentStyle={hint?.style} text={hint?.text} />}
                >
                  <TooltipText>
                    {hint?.sign ? <span dangerouslySetInnerHTML={{ __html: hint?.sign }} /> : label}
                  </TooltipText>
                </OverlayTrigger>
              </>
            ) : (
              label
            )}
          </Label>
        </>
      )}
      <InputGroup className="input-group" maxWidth1024={inlineLabel ? '33%' : '100%'}>
        <input
          value={localValue}
          id={id}
          name={name}
          className="form-control"
          type={type}
          disabled={disabled}
          onChange={onLocalChange}
        />
        {unit && unit !== 'none' && (
          <div className="input-group-append">
            <span className="input-group-text" id="input-unit">
              {unit}
            </span>
          </div>
        )}
      </InputGroup>
    </InputWrapper>
  )
}
