import styled from 'styled-components'

export const SurveySection = styled.div`
  margin: 2rem 0;
`

export const FormRow = ({ children, className, ...props }) => {
  return (
    <div className={`${className} form-group row my-5`} {...props}>
      {children}
    </div>
  )
}

FormRow.Label = ({ children, htmlFor, className, ...props }) => {
  return (
    <label className={`${className} col-sm-2 text-right`} htmlFor={htmlFor} {...props}>
      {children}
    </label>
  )
}

FormRow.Content = ({ children, ...props }) => {
  return (
    <div className="col-sm-10" {...props}>
      {children}
    </div>
  )
}
