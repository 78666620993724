import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Checkbox = ({ id, qid, value = [], answers, previousAnswer, limit, onChange, noAnswer }) => {
  const { t } = useTranslation()
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)

  const handleChange = useCallback(
    (event) => {
      let newGivenAnswer = [...value]
      if (localPreviousAnswer !== null && !newGivenAnswer.length) {
        newGivenAnswer = [...localPreviousAnswer]
      }

      if (event.target.name === noAnswer?.id.toString()) {
        if (newGivenAnswer.includes(noAnswer?.id.toString())) {
          newGivenAnswer = []
        } else {
          newGivenAnswer = [noAnswer?.id.toString()]
        }
      } else {
        if (event.target.checked && !newGivenAnswer.some((item) => item === event.target.name)) {
          newGivenAnswer.push(event.target.name)
        } else if (!event.target.checked) {
          newGivenAnswer = newGivenAnswer.filter((item) => item !== event.target.name)
        }
        // remove noAnswer value if checked
        const noAnswerIndex = newGivenAnswer.indexOf(noAnswer?.id.toString())
        if (noAnswerIndex !== -1) {
          newGivenAnswer.splice(noAnswerIndex, 1)
        }
      }

      if (!limit || limit - newGivenAnswer.length >= 0) {
        if (typeof onChange === 'function') {
          setLocalPreviousAnswer(null)
          onChange({ question_id: id, value: newGivenAnswer, qid })
        }
      }
    },
    [onChange, id, qid, value, limit, noAnswer, localPreviousAnswer]
  )

  const checkboxes = useMemo(() => {
    const preparedAnswers = answers.map((answer) => {
      const name = '' + answer.id
      let checked = false
      if (value && value.length) {
        checked = value.includes(name)
      } else if (localPreviousAnswer && localPreviousAnswer.length) {
        checked = localPreviousAnswer.includes(name)
      }
      const elementId = id + '_' + answer.id
      return { name, checked, elementId, text: answer.text }
    })

    if (noAnswer && noAnswer.showNoAnswer) {
      let checked = false
      if (value && value.length) {
        checked = value.includes(noAnswer.id.toString())
      } else if (localPreviousAnswer && localPreviousAnswer.length) {
        checked = localPreviousAnswer.includes(noAnswer.id.toString())
      }

      preparedAnswers.push({
        name: noAnswer.id.toString(),
        checked,
        elementId: id + '_' + noAnswer.id,
        text: noAnswer.title,
        type: 'noAnswer',
      })
    }
    return preparedAnswers
  }, [answers, value, id, noAnswer, localPreviousAnswer])

  const limitReached = useMemo(() => {
    if (!limit) {
      return false
    }
    return limit - value.length > 0 ? false : true
  }, [limit, value])

  return (
    <div>
      {limit && (
        <div className="mb-3">
          {t('survey.questions.checkbox.remainingAnswers')} {limit - value.length}
        </div>
      )}
      {checkboxes.map(({ name, checked, elementId, text }) => {
        return (
          <div className="custom-control custom-checkbox mb-3" key={elementId}>
            <input
              className="custom-control-input"
              type="checkbox"
              name={name}
              id={elementId}
              onChange={handleChange}
              checked={checked}
              disabled={!value && limitReached}
            ></input>
            <label
              className="custom-control-label checkboxLabel"
              htmlFor={elementId}
              dangerouslySetInnerHTML={{ __html: text }}
            ></label>
          </div>
        )
      })}
    </div>
  )
}
