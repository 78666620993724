import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArray } from 'highcharts'
import React from 'react'

const parseError = (error) => {
  if (typeof error === 'string') {
    return error
  }
  if (error.detail) {
    return error.detail
  }
  if (error.message) {
    return error.message
  }

  return JSON.stringify(error)
}

export const AlertBox = ({ type, message, error, position, right, top }) => {
  const renderList = (errors) => (
    <ul style={{ color: 'black' }} className="mt-3 p-1">
      {errors.map((error, index) => (
        <li key={index}>{parseError(error)}</li>
      ))}
    </ul>
  )

  return (
    type?.length > 0 && (
      <div
        className={(type === 'success' ? 'success' : 'warning') + ' alertBox'}
        style={{ position: position ? position : 'absolute', top: top || 0, right: right || 0, zIndex: 100 }}
      >
        <div className="d-flex align-items-center">
          {type === 'success' ? (
            <FontAwesomeIcon icon={faCheck} className="mr-3 icon success" />
          ) : (
            <FontAwesomeIcon icon={faExclamation} className="mr-3 icon" />
          )}
          <div>{message ? message : ''}</div>
          <div>{error ? (isArray(error) ? renderList(error) : parseError(error)) : ''}</div>
        </div>
      </div>
    )
  )
}
