import React from 'react'
import { ListBox } from './Components/ListBox'
import { DatasourceSelectorRiwis } from './Components/DatasourceSelectorRiwis'
import { MatrixHeader } from './Components/MatrixHeader'
import { OneAnswer } from './Components/OneAnswer'

export const RadioMatrixRiwis = ({ question, questionnaireIndex, pageIndex, index, assetClass }) => {
  return (
    <div>
      <OneAnswer
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <DatasourceSelectorRiwis
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
        assetClass={assetClass}
      />
      <MatrixHeader
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
        header={'matrix_answer_header'}
      />
      <ListBox
        name="possible_answers"
        label={'Antworten'}
        items={question.possible_answers}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <MatrixHeader
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
        header={'matrix_question_header'}
      />
      <ListBox
        label={'Frage-Feld'}
        name="questions_field"
        items={question.questions_field}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
    </div>
  )
}
