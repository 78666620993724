import React, { useCallback, useMemo, useState } from 'react'

export const CheckboxMatrix = ({
  id,
  qid,
  answers,
  previousAnswer,
  noAnswer,
  questions,
  value = {},
  onChange,
}) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const handleChange = useCallback(
    (event) => {
      const questionName = event.target.name.split('_')[0]
      const [question, answer] = event.target.value.split('_')
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }

      if (!newValue[question]) {
        newValue[question] = []
      }

      if (Number(answer) === Number(noAnswer.id)) {
        if (event.target.checked) {
          newValue[question] = [answer]
        } else {
          newValue[question] = []
        }
      } else {
        if (event.target.checked && !newValue[question].some((item) => Number(item) === Number(answer))) {
          newValue[question].push(answer)
        } else if (!event.target.checked) {
          newValue[question] = newValue[question].filter((item) => Number(item) !== Number(answer))
        }
        const noAnswerIndex = newValue[question].indexOf(noAnswer?.id.toString())
        if (noAnswerIndex !== -1) {
          newValue[question].splice(noAnswerIndex, 1)
        }
      }

      setLocalPreviousAnswer(null)
      onChange({ question_id: questionName, value: newValue, qid })
    },
    [value, onChange, qid, noAnswer, localPreviousAnswer]
  )

  const close = useCallback(() => {
    setSelectedQuestion(null)
  }, [])

  return (
    <div className="d-flex">
      {selectedQuestion !== null && (
        <MobileAnswersCheckbox
          id={id}
          answers={answers}
          noAnswer={noAnswer}
          questions={questions}
          selectedQuestion={selectedQuestion}
          value={value}
          close={close}
          handleChange={handleChange}
          localPreviousAnswer={localPreviousAnswer}
        />
      )}
      <div className="matrixTableContainer">
        <table className="matrixTable">
          <TableHeader answers={answers} noAnswer={noAnswer} />
          <TableBody
            questions={questions}
            answers={answers}
            noAnswer={noAnswer}
            id={id}
            value={value}
            handleChange={handleChange}
            setSelectedQuestion={setSelectedQuestion}
            localPreviousAnswer={localPreviousAnswer}
          />
        </table>
      </div>
    </div>
  )
}

const TableHeader = ({ answers, noAnswer }) => {
  const preparedAnswers = useMemo(() => {
    const preparedAnswers = [...answers]
    if (noAnswer && noAnswer.showNoAnswer) {
      preparedAnswers.push({ id: noAnswer.id, text: noAnswer.title })
    }
    return preparedAnswers
  }, [answers, noAnswer])
  return (
    <thead>
      <tr>
        <th scope="col"></th>
        {preparedAnswers.map((answer) => {
          return <th key={answer.id} dangerouslySetInnerHTML={{ __html: answer.text }}></th>
        })}
      </tr>
    </thead>
  )
}

const TableBody = ({
  questions,
  answers,
  noAnswer,
  id,
  value,
  handleChange,
  setSelectedQuestion,
  localPreviousAnswer,
}) => {
  const preparedAnswers = useMemo(() => {
    const preparedAnswers = questions.reduce((acc, question) => {
      acc[question.id] = answers.map((answer) => {
        const elementName = id + '_' + question.id
        const elementId = id + '_' + question.id + '_' + answer.id
        const elementValue = question.id + '_' + answer.id
        let checked = false
        if (value?.[question.id]?.length) {
          checked = value[question.id].includes(answer.id.toString())
        } else if (localPreviousAnswer?.[question.id]?.length) {
          checked = localPreviousAnswer[question.id].includes(answer.id.toString())
        }
        return {
          ...answer,
          elementName,
          elementId,
          elementValue,
          checked,
        }
      })

      if (noAnswer && noAnswer?.showNoAnswer) {
        let checked = false
        if (value?.[question.id]?.length) {
          checked = value[question.id].includes(noAnswer.id.toString())
        } else if (localPreviousAnswer?.[question.id]?.length) {
          checked = localPreviousAnswer[question.id].includes(noAnswer.id.toString())
        }
        acc[question.id].push({
          id: noAnswer.id,
          elementName: id + '_' + question.id,
          elementId: id + '_' + question.id + '_' + noAnswer.id,
          elementValue: question.id + '_' + noAnswer.id,
          checked,
          text: noAnswer.title,
          type: 'noAnswer',
        })
      }

      return acc
    }, {})

    return preparedAnswers
  }, [answers, id, questions, value, noAnswer, localPreviousAnswer])

  return (
    <tbody>
      {questions.map((question) => {
        return (
          <tr style={{ verticalAlign: 'baseline' }} key={question.id}>
            <th key={question.id} scope="row" dangerouslySetInnerHTML={{ __html: question.text }}></th>

            <td className="matrixDropdown">
              <MobileDropdownButton
                question={question}
                answers={answers}
                value={value}
                setSelectedQuestion={setSelectedQuestion}
              />
            </td>

            {preparedAnswers[question.id].map((answer) => {
              return (
                <td
                  key={answer.id}
                  style={{
                    borderLeft: answer?.type === 'noAnswer' ? '1px solid lightgrey' : '',
                  }}
                >
                  {
                    <div className="custom-control custom-checkbox" key={answer.elementId}>
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name={answer.elementName}
                        id={answer.elementId}
                        value={answer.elementValue}
                        onChange={handleChange}
                        checked={answer.checked}
                      ></input>
                      <label className="custom-control-label" htmlFor={answer.elementId}></label>
                    </div>
                  }
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

const MobileDropdownButton = ({ question, answers, value, setSelectedQuestion }) => {
  const preparedAnswers = useMemo(() => {
    return answers.filter(
      (answer) =>
        value[question.id] && value[question.id].find((givenAnswers) => parseInt(givenAnswers) === answer.id)
    )
  }, [answers, value, question])

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn mainBtn"
        key={question.id}
        onClick={() => setSelectedQuestion(question.id)}
      >
        {preparedAnswers.length
          ? preparedAnswers.map((answer, index) => (
              <span key={answer.id}>
                {answer.text}
                {index < answer.length - 1 && ', '}
              </span>
            ))
          : 'Antwort auswählen'}
      </button>
      <button
        type="button"
        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
        onClick={() => setSelectedQuestion(question.id)}
      ></button>
    </div>
  )
}

const MobileAnswersCheckbox = ({
  questions,
  selectedQuestion,
  close,
  value,
  answers,
  id,
  handleChange,
  noAnswer,
  localPreviousAnswer,
}) => {
  const [question] = useState(() => questions.find((question) => question.id === selectedQuestion))

  const preparedAnswers = useMemo(() => {
    const preparedAnswers = answers.map((answer) => {
      const elementName = id + '_' + question.id
      const elementId = id + '_' + question.id + '_' + answer.id
      const elementValue = question.id + '_' + answer.id
      let checked = false
      if (value?.[question.id]?.length) {
        checked = value[question.id].includes(answer.id.toString())
      } else if (localPreviousAnswer?.[question.id]?.length) {
        checked = localPreviousAnswer[question.id].includes(answer.id.toString())
      }
      return {
        ...answer,
        elementName,
        elementId,
        elementValue,
        checked,
      }
    })

    if (noAnswer && noAnswer?.showNoAnswer) {
      let checked = false
      if (value?.[question.id]?.length) {
        checked = value[question.id].includes(noAnswer.id.toString())
      } else if (localPreviousAnswer?.[question.id]?.length) {
        checked = localPreviousAnswer[question.id].includes(noAnswer.id.toString())
      }
      preparedAnswers.push({
        elementName: id + '_' + question.id,
        elementId: id + '_' + question.id + '_' + noAnswer.id,
        elementValue: question.id + '_' + noAnswer.id,
        checked,
        text: noAnswer.title,
        type: 'noAnswer',
      })
    }

    return preparedAnswers
  }, [answers, id, value, question, noAnswer, localPreviousAnswer])

  const handleClose = useCallback(() => {
    if (typeof close === 'function') {
      close()
    }
  }, [close])

  const handleCloseMobileAnswerWindow = useCallback(
    (event) => {
      if (event.target.className === 'mobileAnswerWindow') {
        handleClose()
      }
    },
    [handleClose]
  )

  return (
    <div className="mobileAnswerWindow" onClick={handleCloseMobileAnswerWindow}>
      <div className="mobileAnswerContainer">
        <ul className="mobileAnswerList">
          <li className="closeBtn d-flex justify-content-end">
            <div className="crossContainer" onClick={handleClose}>
              <div className="crossBarOne">
                <div className="crossBarTwo"></div>
              </div>
            </div>
          </li>
          {preparedAnswers.map((answer) => {
            return (
              <li key={answer.id} value={answer.elementValue} className="d-flex align-items-center">
                <div className="custom-control custom-checkbox">
                  <input
                    onChange={handleChange}
                    className="custom-control-input"
                    type="checkbox"
                    name={answer.elementName}
                    id={answer.elementId}
                    value={answer.elementValue}
                    checked={answer.checked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={answer.elementId}
                    dangerouslySetInnerHTML={{ __html: answer.text }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
