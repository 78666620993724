import createStore from '../../../lib/flux-store'
import { Survey, User } from '../../../helper/api'

const initialState = {
  dataIsLoading: true,
  saveMessage: { msg: '', error: '', type: '' },
  userList: null,
  user: null,
  userSurveys: null,
  searchString: '',
}

const actions = {
  setSaveMessage: (saveMessage) => ({ saveMessage }),
  fetchUserList: () => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    User.getAll().then((res) => {
      dispatch({ type: 'setUserList', userList: res.data })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchUser: (userId) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    User.getById(userId).then((res) => {
      dispatch({ type: 'setUser', user: res.data.user })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchUserSurvey: (userId) => (dispatch) => {
    Survey.getAllByUserId(userId).then((res) => {
      dispatch({ type: 'setUserSurveys', userSurveys: res.data.surveys })
    })
  },
  deleteUser: (userId) => (dispatch) => {
    User.deleteUser(userId).then((res) => {
      dispatch({ type: 'setUserList', userList: res.data })
    })
  },
  anonymizeUser: (userId) => (dispatch) => {
    User.anonymizeUser(userId).then((res) => {
      dispatch({ type: 'setUserList', userList: res.data })
    })
  },
  editUser: (value, property) => ({ value, property }),
  saveUser: (user) => (dispatch) => {
    User.updateUser(user, user.id)
      .then((res) => {
        if (res.data.user) {
          dispatch({ type: 'setUser', user: res.data.user })
          dispatch({
            type: 'setSaveMessage',
            saveMessage: { msg: 'Benutzer gespeichert', type: 'success', error: '' },
          })
        } else {
          dispatch({
            type: 'setSaveMessage',
            saveMessage: { msg: res.data.msg, error: JSON.stringify(res.data.error), type: 'failed' },
          })
        }
      })
      .catch((err) =>
        dispatch({ type: 'setSaveMessage', saveMessage: { msg: 'Fehler', type: 'failed', error: err } })
      )
  },
  setUserSurveys: (userSurveys) => ({ userSurveys }),
  setSearchString: (searchString) => ({ searchString }),
  setUser: (user) => ({ user }),
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setSaveMessage: (state, { saveMessage }) => {
    return { ...state, saveMessage }
  },
  setUserList: (state, { userList }) => {
    return { ...state, userList }
  },
  setUser: (state, { user }) => {
    return { ...state, user }
  },
  editUser: (state, { value, property }) => {
    const newUser = { ...state.user }
    newUser[property] = value
    return { ...state, user: newUser }
  },
  saveUser: (state, { payload }) => {
    return { ...state, user: payload.user, saveMessage: payload.saveMessage }
  },
  setUserSurveys: (state, { userSurveys }) => {
    return { ...state, userSurveys }
  },
  setSearchString: (state, { searchString }) => {
    return { ...state, searchString }
  },
}

export const [UsersAdminDataContext, UsersAdminDataProvider, useUsersAdminDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'UsersAdminDataStore'
)
