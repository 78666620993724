import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Logs } from '../helper/api'

const ErrorMessage = ({ type }) => {
  if (type === 'question') {
    return (
      <>
        <div className="errorBoundaryContainer">
          <div style={{ position: 'relative' }}>
            <div className="text-center background question">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <div style={{ position: 'relative' }}>
              <div style={{ fontSize: '1rem' }}>
                <div>Leider kann diese Frage aufgrund eines Fehlers nicht angezeigt werden.</div>
                <div>Sie können die Umfrage jedoch wie gewohnt fortsetzen.</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  }

  return (
    <div className="errorBoundaryContainer">
      <div style={{ position: 'relative' }}>
        <div className="text-center background">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        <div style={{ position: 'relative' }}>
          <h1 className="mb-5">App Crash</h1>
          <div style={{ fontSize: '1rem' }}>
            <div>Leider ist ein Fehler aufgetreten und die Anwendung ist abgestürzt.</div>
            <div>Bitte laden Sie die Seite neu!</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const { user, children, ...rest } = this.props
    const details = {
      ...rest,
    }
    const report = {
      userId: user?.id,
      timestamp: new Date(),
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      location: window.location.pathname,
      userAgent: window.navigator.userAgent,
      appId: 'survey-desk',
      details,
    }
    Logs.saveErrorLog(report)
      .then((res) => {
        if (res.data.msg === 'success') {
          console.log('Error report has been submitted')
        } else {
          console.warn('Error report submit failed', res.data.err)
        }
      })
      .catch((err) => console.warn(err))
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorMessage type={this.props.type} />
    }

    return this.props.children
  }
}
