import React from 'react'
import { CompareImage, ImageCompareSlider } from '../CustomComponents/ImageCompareSlider'
import newImage from '../../../../assets/img/new.png'
import original from '../../../../assets/img/original.png'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Header = styled.h1(({ theme, children, fontSize = '1.25rem', color = 'black', ...rest }) => ({
  fontSize,
  color,
  '@media(max-width: 576px)': {
    padding: '1rem',
  },
  ...rest,
}))

const ImageOne = () => {
  const { t } = useTranslation()

  return (
    <CompareImage
      image={newImage}
      alt={t('custom.UnionInvestment.new')}
      title={t('custom.UnionInvestment.new')}
    />
  )
}

const ImageTwo = () => {
  const { t } = useTranslation()

  return (
    <CompareImage
      image={original}
      alt={t('custom.UnionInvestment.original')}
      title={t('custom.UnionInvestment.original')}
      justifyTitle={'flex-end'}
    />
  )
}

export const UnionInvestment = () => {
  const { t } = useTranslation()
  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Header marginBottom={'1rem'}>{t('custom.UnionInvestment.header')}</Header>
      <ImageCompareSlider ImageOne={<ImageOne />} ImageTwo={<ImageTwo />} />
    </div>
  )
}
