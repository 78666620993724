import React, { useEffect, useMemo, useState } from 'react'
import { useSurveyDataStore } from '../dataStore'
import { Progress } from './Components/Progress'
import { NextPage } from './Components/NextPage'
import { NextButton, PrevButton } from './Components/Buttons'
import { checkConstraints } from './helper'
import { Prompt } from 'react-router-dom'
import { Dismiss } from '../ModalWindows/Dismiss'
import { Modal } from '../../../components/Modal'

export const QuestionnaireBuilder = ({ onHide }) => {
  const [{ survey, questionnaire, pageNumber, pages }, { setInitialState, setPages }] = useSurveyDataStore()

  const [showDismiss, setShowDismiss] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)

  // const [showScrollIndicatorTop, setShowScrollIndicatorTop] = useState(false)
  // const [showScrollIndicatorBottom, setShowScrollInidicatorBottom] = useState(false)
  // const [scrolledBottom] = useState(false)

  const visiblePages = useMemo(() => {
    if (!questionnaire.pages.length) {
      return []
    }
    return questionnaire.pages.reduce((pagesArr, page, index) => {
      if (Array.isArray(page.questions) && page.questions.length) {
        const questions = page.questions.reduce((questionsArr, question, index) => {
          if (
            checkConstraints(
              question.constraints,
              questionnaire,
              survey.type,
              survey.datasource_year,
              survey.quarter
            )
          ) {
            questionsArr.push({ ...question, index })
          }
          return questionsArr
        }, [])
        if (questions.length) {
          const newPage = { ...page, index }
          newPage.questions = questions
          pagesArr.push(newPage)
        }
      }
      return pagesArr
    }, [])
  }, [questionnaire, survey])

  useEffect(() => {
    setPages(visiblePages)
  }, [visiblePages, setPages])

  useEffect(() => {
    return function cleanup() {
      setInitialState()
    }
  }, [setInitialState])

  if (questionnaire === null) {
    return <h1>Keine Fragen zu beantworten!</h1>
  } else if (pages === null) {
    return null
  } else {
    return (
      <>
        <Prompt
          when={true}
          message={(event) => {
            setShowDismiss(true)
            setNavigateTo(event.pathname)
            return false
          }}
        />
        <div className="questionnaireWindow">
          <NextPage onHide={onHide} />
          <div className="d-flex justify-content-between align-items-center pageNavigation">
            <PrevButton />
            {pageNumber < questionnaire.pages.length && (
              <Progress pageCount={pages?.length + 1} pageNo={pageNumber} type={survey?.settings?.progress} />
            )}
            <NextButton onHide={onHide} />
          </div>
        </div>
        {showDismiss === true && (
          <Modal onHide={setShowDismiss} minWidth={'500px'}>
            <Dismiss onHide={onHide} navigateTo={navigateTo} />
          </Modal>
        )}
      </>
    )
  }
}
