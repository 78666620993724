import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSurveyAdminDataStore } from './dataStore'
import { Container } from '../../../components/Container'
import { Modal } from '../../../components/Modal'
import { Button, Dropdown, Nav } from 'react-bootstrap'
import { AlertBox } from '../../../components/AlertBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </span>
))

export const SurveyList = () => {
  const [
    { surveyList, dataIsLoading, surveyListMessage },
    { fetchSurveyList, deleteSurvey, updateStatus, setSurveyListMessage },
  ] = useSurveyAdminDataStore()
  const [modal, setShowModal] = useState(false)
  const [surveyToDelete, setSurveyToDelete] = useState(null)
  const [idCheck, setIdCheck] = useState(null)
  const { filter } = useParams()

  const handleDeleteSurvey = useCallback(() => {
    setShowModal(false)
    deleteSurvey(surveyToDelete.id)
    setSurveyToDelete(null)
    setIdCheck(null)
  }, [deleteSurvey, surveyToDelete])

  const hideModal = useCallback(() => {
    setShowModal(false)
    setSurveyToDelete(null)
    setIdCheck(null)
  }, [])

  const showModal = useCallback((survey) => {
    setShowModal(true)
    setSurveyToDelete(survey)
  }, [])

  useEffect(() => {
    fetchSurveyList()
  }, [fetchSurveyList])

  useEffect(() => {
    if (surveyListMessage.type) {
      setTimeout(() => {
        setSurveyListMessage({ msg: '', type: '', err: '' })
      }, 3000)
    }
  })

  return (
    <Container>
      {dataIsLoading === false && (
        <>
          <div className="row">
            <div className="col">
              <h3>Umfragen</h3>
            </div>
            <div className="col text-right">
              <Link to="/admin/survey/0" className="btn btn-outline-primary">
                Neue Umfrage
              </Link>
            </div>
            <AlertBox
              type={surveyListMessage.type}
              message={surveyListMessage.msg}
              error={surveyListMessage.error}
              top={'70px'}
              right={'25%'}
            />
          </div>
          <hr />
          <Nav variant="pills" className="mt-5" defaultActiveKey="/admin/surveys/all">
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={filter === 'all'}>
                <Link style={{ color: filter === 'all' ? 'white' : 'black' }} to="/admin/surveys/all">
                  Alle
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={filter === 'published'}>
                <Link
                  style={{ color: filter === 'published' ? 'white' : 'black' }}
                  to="/admin/surveys/published"
                >
                  Veröffentlicht
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={filter === 'draft'}>
                <Link style={{ color: filter === 'draft' ? 'white' : 'black' }} to="/admin/surveys/draft">
                  Entwürfe
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={filter === 'archived'}>
                <Link
                  style={{ color: filter === 'archived' ? 'white' : 'black' }}
                  to="/admin/surveys/archived"
                >
                  Archiviert
                </Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <table className="table surveyList">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Titel</th>
                <th scope="col">Von</th>
                <th scope="col">Bis</th>
                <th scope="col">Art</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {surveyList &&
                surveyList.map(
                  (survey) =>
                    (survey.status === filter || filter === 'all') && (
                      <tr key={survey.id}>
                        <th scope="row">{survey.id}</th>
                        <td>
                          <Link to={`/admin/survey/${survey.id}`}>
                            {survey.title} {survey.subtitle && `- ${survey.subtitle}`}
                          </Link>
                        </td>
                        <td>{new Date(survey.from).toLocaleDateString()}</td>
                        <td>{new Date(survey.to).toLocaleDateString()}</td>
                        <td>{survey.type_of_survey}</td>
                        <td>{survey.status}</td>
                        <td className="subMenu">
                          <Dropdown alignRight={true}>
                            <Dropdown.Toggle as={CustomToggle}>
                              <FontAwesomeIcon icon={faEllipsisV} className="ml-3 cursorPointer" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => updateStatus(survey.id, 'archived')}>
                                Archivieren
                              </Dropdown.Item>
                              <Dropdown.Item className="delete" onClick={() => showModal(survey)}>
                                Löschen
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
          {modal && (
            <Modal maxWidth={'600px'} onHide={hideModal} minWidth={'450px'}>
              <div className="p-3">
                <div className="header">Umfrage löschen?</div>
                <div className="body">
                  <p>
                    <b>{surveyToDelete.title}</b> wirklich löschen?
                  </p>
                  <div>Zusätzlich werden folgende Inhalte gelöscht:</div>
                  <ul>
                    <li>
                      <b>Alle Fragebögen der Umfrage</b>
                    </li>
                    <li>
                      <b>Alle Seiten des Fragebogens</b>
                    </li>
                    <li>
                      <b>Alle Fragen der Seite</b>
                    </li>
                    <li>
                      <b>Antworten zu den Fragen</b>
                    </li>
                  </ul>
                  <div className="d-flex flex-column">
                    <div>
                      Geben Sie die ID <b>{surveyToDelete.id}</b> ein, um die Umfrage zu löschen
                    </div>
                    <input
                      value={idCheck || ''}
                      onChange={(e) => setIdCheck(e.target.value)}
                      className="form-control mt-2"
                      type="text"
                    />
                  </div>
                </div>
                <div className="footer justify-content-between">
                  <Button variant="secondary" onClick={hideModal}>
                    Abbrechen
                  </Button>
                  <Button
                    disabled={idCheck !== surveyToDelete.id.toString()}
                    onClick={handleDeleteSurvey}
                    variant="danger"
                  >
                    Löschen
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </Container>
  )
}
