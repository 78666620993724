import { forwardRef } from 'react'
import { CustomIntro } from './CustomIntro'
import { DefaultIntro } from './DefaultIntro'

export const Intro = forwardRef(({ survey, questionnaire, handleCurrentQuestionaire }, ref) => {
  if (survey?.settings?.intro === 'custom') {
    return (
      <CustomIntro
        survey={survey}
        questionnaire={questionnaire}
        handleCurrentQuestionaire={handleCurrentQuestionaire}
        ref={ref}
      />
    )
  }

  return (
    <DefaultIntro
      survey={survey}
      questionnaire={questionnaire}
      handleCurrentQuestionaire={handleCurrentQuestionaire}
      ref={ref}
    />
  )
})
