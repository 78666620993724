import { Survey } from './survey'
import { Container } from '../../../../components/Container'
import { SurveyEditorStateProvider } from './surveyEditorState'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const EditSurvey = () => {
  return (
    <Container>
      <SurveyEditorStateProvider>
        <DndProvider backend={HTML5Backend}>
          <Survey />
        </DndProvider>
      </SurveyEditorStateProvider>
    </Container>
  )
}
