import React, { useState, useCallback } from 'react'
import { Question } from './question'
import { findLastNonDeleted } from './utils'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import { TabContainer } from './TabContainer'
import { Modal } from '../../../../components/Modal'
import { Button } from 'react-bootstrap'
import { SurveySection } from './Components'
import { IdLabel } from '../../../../components/Labels'

const SinglePage = ({
  page,
  index,
  questionnaireId,
  questionnaireIndex,
  handleCustomCheckboxChange,
  handleInputChange,
  handleTinyMCEChange,
  assetClass,
}) => {
  return (
    <React.Fragment>
      <div className="mt-4">
        <div className="form-group row mb-0">
          <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
          <div className="col-sm-10">
            <IdLabel>ID: {page.id}</IdLabel>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
          <div className="col-sm-10">
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                name="hide_title"
                type="checkbox"
                readOnly
                checked={!page?.hide_title}
              ></input>
              <label
                className="custom-control-label"
                onClick={() => handleCustomCheckboxChange(index, 'hide_title')}
              >
                Titel anzeigen
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Titel</label>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="title"
              onChange={(event) => handleInputChange(index, event)}
              value={page.title || ''}
              disabled={page?.hide_title}
            ></input>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Beschreibung</label>
          <div className="col">
            <TinyMCE
              id={`pageDescription-${questionnaireId}-${page.id}`}
              name={'description'}
              content={page.description}
              inlineMode={true}
              handleTinyMCEChange={(event) => handleTinyMCEChange(index, event)}
              disabled={page?.hide_title}
            />
          </div>
        </div>
      </div>

      <Question
        pageId={page.id}
        questionnaireId={questionnaireId}
        questionnaireIndex={questionnaireIndex}
        pageIndex={index}
        questions={page.questions || []}
        assetClass={assetClass}
      />
    </React.Fragment>
  )
}

export const Page = ({ pages, questionnaireId, title, questionnaireIndex, assetClass }) => {
  const [
    { pageTab },
    { setPageInput, addPage, setPageTab, deletePage, changePageOrder, moveQuestionToPage },
  ] = useSurveyEditorStateStore()
  const [newId, setNewId] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [pageToDelete, setPageToDelete] = useState(null)

  const updateOrder = (pages) => {
    changePageOrder(questionnaireIndex, pages)
  }

  const updateMoveItem = useCallback(
    (question, targetPage) => {
      if (pageTab !== targetPage.hoverIndex) {
        moveQuestionToPage(questionnaireIndex, pageTab, targetPage.hoverIndex, question.index)
      }
    },
    [pageTab, questionnaireIndex, moveQuestionToPage]
  )

  const showDeleteModal = (index) => {
    setShowModal(true)
    setPageToDelete(index)
  }

  const hideDeleteModal = () => {
    setShowModal(false)
    setPageToDelete(null)
  }

  const handleNewPageClick = useCallback(() => {
    const initialProperties = {
      id: newId,
      order: pages.length + 1,
      title: '',
      description: '',
      questions: [],
      riwis_icon: '',
      hide_title: false,
    }
    setNewId(newId - 1)
    addPage(questionnaireIndex, initialProperties)
    setPageTab(pages.length)
  }, [addPage, newId, pages, questionnaireIndex, setPageTab])

  const handleInputChange = useCallback(
    (index, event) => {
      setPageInput(questionnaireIndex, index, event.target.value, event.target.name)
    },
    [questionnaireIndex, setPageInput]
  )

  const handleCustomCheckboxChange = useCallback(
    (index, name) => {
      const value = pages[index].hide_title
      setPageInput(questionnaireIndex, index, !value, name)
    },
    [questionnaireIndex, pages, setPageInput]
  )

  const handleTinyMCEChange = useCallback(
    (index, event) => {
      setPageInput(questionnaireIndex, index, event.value, event.name)
    },
    [questionnaireIndex, setPageInput]
  )

  const handleDeletePageClick = useCallback(() => {
    if (pageTab === pageToDelete) {
      setPageTab(findLastNonDeleted(pageToDelete, pages))
    } else if (pageTab - 1 > 0) {
      setPageTab(pageTab - 1)
    }
    deletePage(questionnaireIndex, pageToDelete)
    hideDeleteModal()
  }, [deletePage, pages, pageTab, pageToDelete, setPageTab, questionnaireIndex])

  return (
    <SurveySection>
      <div className="d-flex align-items-center">
        <h3>Seiten in Fragebogen {questionnaireIndex + 1}</h3>
        <button type="button" className="btn-sm btn-outline-primary ml-5" onClick={handleNewPageClick}>
          <small>Neue Seite</small>
        </button>
      </div>
      <hr></hr>
      <div className="d-flex flex-wrap">
        {pages.length > 0 && (
          <TabContainer
            tabs={pages}
            activeTab={pageTab}
            setActiveTab={setPageTab}
            handleDelete={showDeleteModal}
            tabWidth={'164px'}
            updateOrder={updateOrder}
            updateMoveItem={updateMoveItem}
            moveInto
            type="page"
            itemType="pageTab"
            acceptedItemTypes={['pageTab', 'questionTab']}
          />
        )}
      </div>
      {pages[pageTab] && !pages[pageTab].deleted && (
        <SinglePage
          page={pages[pageTab]}
          index={pageTab}
          questionnaireId={questionnaireId}
          questionnaireIndex={questionnaireIndex}
          handleCustomCheckboxChange={handleCustomCheckboxChange}
          handleInputChange={handleInputChange}
          handleTinyMCEChange={handleTinyMCEChange}
          assetClass={assetClass}
        />
      )}
      {showModal && (
        <Modal onHide={hideDeleteModal} maxWidth={'500px'} minWidth={'450px'}>
          <div className="header">Seite löschen?</div>
          <div className="body">
            <p>
              Seite <b>{pages[pageToDelete].title}</b> wirklich löschen?
            </p>
            <div>Zusätzlich werden folgende Inhalte gelöscht:</div>
            <ul>
              <li>
                <b>Alle Fragen der Seite</b>
              </li>
              <li>
                <b>Antworten zu den Fragen</b>
              </li>
            </ul>
          </div>
          <div className="footer justify-content-between">
            <Button variant="secondary" onClick={hideDeleteModal}>
              Abbrechen
            </Button>
            <Button variant="danger" onClick={handleDeletePageClick}>
              Löschen
            </Button>
          </div>
        </Modal>
      )}
    </SurveySection>
  )
}
