import { Radio } from './QuestionTypes/Radio'
import { RadioInline } from './QuestionTypes/RadioInline'
import { RadioMatrix } from './QuestionTypes/RadioMatrix'
import { Checkbox } from './QuestionTypes/Checkbox'
import { CheckboxMatrix } from './QuestionTypes/CheckboxMatrix'
import { Text } from './QuestionTypes/Text'
import { Textarea } from './QuestionTypes/Textarea'
import { Slider } from './QuestionTypes/Slider'
import { SliderRiwis } from './QuestionTypes/SliderRiwis'
import { RadioMatrixRiwis } from './QuestionTypes/RadioMatrixRiwis'
import { RadioInlineRiwis } from './QuestionTypes/RadioInlineRiwis'
import { Sum } from './QuestionTypes/Sum'
import { InputColumns } from './QuestionTypes/InputColumns'
import { ContactForm } from './QuestionTypes/ContactForm'
import { Dropdown } from './QuestionTypes/Dropdown'

export const QuestionSwitch = ({ question, questionnaireIndex, pageIndex, index, assetClass }) => {
  if (question.type === 'radio') {
    return (
      <Radio
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'radioInline') {
    return (
      <RadioInline
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'radioMatrix') {
    return (
      <RadioMatrix
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'checkbox') {
    return (
      <Checkbox
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'checkboxMatrix') {
    return (
      <CheckboxMatrix
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'text') {
    return <Text />
  } else if (question.type === 'inputColumns') {
    return (
      <InputColumns
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'textarea') {
    return (
      <Textarea
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'slider') {
    return (
      <Slider
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'sum') {
    return (
      <Sum question={question} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} index={index} />
    )
  } else if (question.type === 'sliderRiwis') {
    return (
      <SliderRiwis
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'radioMatrixRiwis') {
    return (
      <RadioMatrixRiwis
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
        assetClass={assetClass}
      />
    )
  } else if (question.type === 'radioInlineRiwis') {
    return (
      <RadioInlineRiwis
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
        assetClass={assetClass}
      />
    )
  } else if (question.type === 'contactForm') {
    return (
      <ContactForm
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  } else if (question.type === 'dropdown') {
    return (
      <Dropdown
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
    )
  }
}
