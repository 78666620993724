import { useCallback, useMemo, useState } from 'react'
import { NoAnswerCheckbox } from '../QuestionnaireBuilder/Components/NoAnswerCheckbox'
import styled from 'styled-components'
import { Input } from '../QuestionnaireBuilder/Components/Input'
import { useSurveyDataStore } from '../dataStore'

const Row = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

export const InputColumns = ({
  id,
  qid,
  dataType,
  questionFields,
  previousAnswer,
  value = {},
  minValue,
  maxValue,
  defaultValue,
  unit,
  onChange,
  withDecimal,
  noAnswer,
  withLineDefinitions,
  inlineLabels = true,
}) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)
  const [{ missingInputs }] = useSurveyDataStore()

  const currentMissingInputs = useMemo(() => {
    const found = missingInputs.find((missingInput) => Number(Object.keys(missingInput)[0]) === id) || {}
    return found[id] || []
  }, [missingInputs, id])

  const formattedQuestionFields = useMemo(() => {
    if (!withLineDefinitions) {
      return [...questionFields.map((field) => [field])]
    }
    // prevent mutation
    const newQuestionFields = questionFields.map((questionField) => ({ ...questionField }))
    return newQuestionFields
      .sort((a, b) => a.line - b.line)
      .reduce((arr, questionField) => {
        // group fields by line
        if (typeof arr[questionField.line - 1] === 'undefined') {
          arr[questionField.line - 1] = [questionField]
        } else {
          arr[questionField.line - 1].push(questionField)
        }
        return arr
      }, [])
  }, [questionFields, withLineDefinitions])

  const handleChange = useCallback(
    (event) => {
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }
      let inputValue = event.target.value

      if (event.target.type === 'checkbox') {
        newValue.noAnswer = event.target.checked
      }

      newValue[event.target.name] = inputValue
      newValue.noAnswer = false

      setLocalPreviousAnswer(null)
      onChange({ question_id: id, value: newValue, qid })
    },
    [id, localPreviousAnswer, onChange, qid, value]
  )

  const inputValue = useMemo(() => {
    let inputValue = { ...value }
    if (!Object.keys(value).length) {
      if (localPreviousAnswer?.noAnswer === true) {
        inputValue = { noAnswer: localPreviousAnswer?.noAnswer || false }
      } else if (localPreviousAnswer && Object.keys(localPreviousAnswer).length) {
        inputValue = { ...localPreviousAnswer }
      }
    }
    return inputValue
  }, [value, localPreviousAnswer])

  const noAnswerValue = useMemo(() => {
    let checked = false
    if (typeof value.noAnswer !== 'undefined') {
      checked = value.noAnswer
    } else if (typeof localPreviousAnswer?.noAnswer !== 'undefined') {
      checked = localPreviousAnswer.noAnswer
    }
    return checked
  }, [value, localPreviousAnswer])

  return (
    <>
      {formattedQuestionFields.map((questionFields, index) => (
        <Row key={index}>
          {questionFields.map((questionField) => (
            <Input
              key={`${id}_${questionField.id}`}
              name={questionField.id}
              id={`${qid}-${questionField.id}`}
              disabled={noAnswerValue}
              onChange={handleChange}
              type="text"
              value={inputValue[questionField.id] || defaultValue || ''}
              unit={questionField.unit || unit}
              dataType={questionField.dataType || dataType}
              decimal={withDecimal}
              min={minValue}
              max={maxValue}
              label={questionField.text}
              hint={questionField.hint}
              inlineLabel={inlineLabels}
              notFullfilled={currentMissingInputs?.includes(questionField.id)}
              required={questionField.required}
              style={questionField.style}
            ></Input>
          ))}
        </Row>
      ))}
      <NoAnswerCheckbox noAnswer={noAnswer} onChange={handleChange} checked={noAnswerValue} questionId={id} />
    </>
  )
}
