import React, { useCallback } from 'react'
import { useSurveyDataStore } from './dataStore'
import { QuestionBuilder } from './QuestionBuilder'

const getQuestionNumber = (questionCount, pageNumber, questionsPerPage) => {
  if (pageNumber === 0) {
    return questionCount
  } else {
    return questionsPerPage[pageNumber - 1].accumulatedQuestions + questionCount
  }
}

export const Question = ({ pageIndex, question, questionIndex, questionNumber, questionsLength }) => {
  const [{ questionsPerPage, missingInputs, selectedCity, survey, pageNumber }, { setAnswer }] =
    useSurveyDataStore()

  const handleAnswerChange = useCallback(
    (answer) => {
      setAnswer(pageIndex, questionIndex, answer)
    },
    [pageIndex, setAnswer, questionIndex]
  )

  return (
    <div className="question">
      <QuestionBuilder
        question={question}
        lastQuestionNo={getQuestionNumber(questionNumber, pageNumber, questionsPerPage)}
        showStar={survey?.settings?.showStar}
        showQuestionNo={survey?.settings?.showQuestionNo}
        missingInputs={missingInputs}
        handleAnswerChange={handleAnswerChange}
        pageIndex={pageIndex}
        selectedCity={selectedCity || null}
        surveyType={survey.type}
        surveyYear={survey.datasource_year}
        surveyQuarter={survey.quarter}
      />
      {questionsLength - 1 !== questionIndex && <hr style={{ marginTop: '2rem' }}></hr>}
    </div>
  )
}
