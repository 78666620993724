import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useSurveyDataStore } from './dataStore'
import { MissingInputWarning } from './missingInputWarning'
import { IconEnum, TextEnum } from './QuestionTypes/RiwisSlider/mapHelper'
import { Modal } from '../../components/Modal'
import { Dismiss } from './ModalWindows/Dismiss'
import { Questions } from './Questions'
import { useTranslation } from 'react-i18next'

//todo
const onScrollY = () => {}

export const Page = ({ page, onHide }) => {
  // const [showScrollIndicatorTop, setShowScrollIndicatorTop] = useState(false)
  const [{ survey, questionnaire, selectedCity, pageNumber, missingInputs }] = useSurveyDataStore()
  const questionsContainerRef = useRef(null)
  const [showDismiss, setShowDismiss] = useState(false)
  const { t } = useTranslation()

  const pageTitle = useMemo(() => {
    if (survey?.settings?.showPageNo) {
      return `${pageNumber + 1}. ${page?.title}`
    }
    return page?.title
  }, [survey, page, pageNumber])

  useEffect(() => {
    if (pageNumber >= 0) {
      window.scrollTo({ top: 60 })
    }
  }, [questionsContainerRef, pageNumber])

  if (!page) return <div></div>
  return (
    <div id={page.pageId} key={page.pageId}>
      {missingInputs.length > 0 && (
        <MissingInputWarning
          error={
            !survey?.settings?.showStar
              ? t('survey.missingInput.warning')
              : t('survey.missingInput.warningAlt')
          }
          position={'sticky'}
        />
      )}
      {/* <div className={(showScrollIndicatorTop ? 'scrollIndicatorTop' : '') + ' pageHead'}> */}
      <div className="pageHead">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              {/* show additional infos for riwis surveys */}
              {questionnaire.asset_class !== null && selectedCity && (
                <div className="d-flex flex-row mb-3 align-items-start">
                  <h4 className="mr-2" style={{ fontSize: '1rem', color: '#212529', fontWeight: 400 }}>
                    {selectedCity.name.replace(' (Stadt)', '')}
                  </h4>
                  <h4 style={{ fontSize: '1rem', color: '#212529', fontWeight: 400 }}>
                    - {TextEnum[questionnaire.asset_class]}
                  </h4>
                  <div className="ml-3">
                    <img width={14} src={IconEnum[questionnaire.asset_class]} alt="" />
                  </div>
                </div>
              )}
              {!page?.hide_title && (
                <div className="d-flex flex-row">
                  <h4 className="pageTitle">{pageTitle}</h4>
                </div>
              )}

              {page?.description?.length > 0 && (
                <p
                  className="mt-3 mb-3 pageDescription"
                  dangerouslySetInnerHTML={{ __html: page.description }}
                ></p>
              )}
            </div>
          </div>
          <div className="crossContainer" onClick={() => setShowDismiss(true)}>
            <div className="crossBarOne">
              <div className="crossBarTwo"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="questionsContainer" onScroll={onScrollY} ref={questionsContainerRef}>
        <Questions questions={page.questions} pageIndex={page.index} />
      </div>
      {showDismiss === true && (
        <Modal onHide={setShowDismiss} minWidth={'500px'}>
          <Dismiss onHide={onHide} />
        </Modal>
      )}
      {/* <div className={showScrollIndicatorBottom ? 'scrollIndicatorBottom' : ''}></div> */}
    </div>
  )
}
