import { WithDecimal } from './Components/WithDecimal'
import { Step } from './Components/Step'
import { Unit } from './Components/Unit'
import { RiwisBox } from './Components/RiwisBox'
import { ShowMap } from './Components/ShowMap'

export const SliderRiwis = ({ question, questionnaireIndex, pageIndex, index }) => {
  return (
    <div>
      <ShowMap
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <WithDecimal
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <Step question={question} index={index} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} />
      <Unit question={question} index={index} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} />
      <RiwisBox
        datasources={question.riwis_datasources || []}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
    </div>
  )
}
