import React, { useEffect, useMemo, useCallback } from 'react'
import { useReportingDataStore } from './dataStore'
import { IconEnum, TextEnum } from '../../Survey/QuestionTypes/RiwisSlider/mapHelper'
import XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

const defaultAssetClasses = ['BÜRO', 'WOHNEN', 'INDUSTRIE', 'EINZELHANDEL']

const getCityName = (cities, gac) => {
  return cities.find((city) => city.gac === gac).name
}

export const Feedback = () => {
  const [{ report, cities }, { fetchCities }] = useReportingDataStore()

  const answersWithGacs = useMemo(() => {
    if (report && report.type === 'riwis') {
      return report.answers.reduce((userList, answer) => {
        const found = userList.find((user) => user.id === answer.userId)
        if (!found) {
          userList.push({
            username: answer.username,
            first_name: answer.first_name,
            last_name: answer.last_name,
            company: answer.company,
            list_as_partner: answer.list_as_partner,
            id: answer.userId,
            answeredGacs: report.answeredGacs
              .filter((answeredGac) => answeredGac.user_id === answer.userId)
              .reduce((assetGroups, assetAnswer) => {
                if (!assetGroups[assetAnswer.generic_area_code]) {
                  assetGroups[assetAnswer.generic_area_code] = []
                }
                assetGroups[assetAnswer.generic_area_code].push(assetAnswer)
                return assetGroups
              }, {}),
          })
        }
        return userList
      }, [])
    }
    return null
  }, [report])

  const answerReport = useMemo(() => {
    if (answersWithGacs && !cities) {
      return []
    }
    if (answersWithGacs) {
      const list = answersWithGacs.reduce((arr, answersByUser) => {
        const userLines = Object.entries(answersByUser.answeredGacs).reduce(
          (line, [gac, assetClasses], index) => {
            const answeredAssetClasses = defaultAssetClasses.reduce((answeredArr, assetClass) => {
              const foundAssetClass = assetClasses.find(
                (aClass) => aClass.title.toLowerCase() === assetClass.toLowerCase()
              )
              const percentage =
                Math.ceil((foundAssetClass?.answer_count / foundAssetClass?.question_count) * 100) || 0
              answeredArr.push(percentage + '%')
              return answeredArr
            }, [])
            line.push([
              index === 0 ? answersByUser.username : '',
              index === 0 ? answersByUser.last_name : '',
              index === 0 ? answersByUser.first_name : '',
              index === 0 ? answersByUser.company : '',
              getCityName(cities, gac),
              ...answeredAssetClasses,
              index === 0 ? (answersByUser.list_as_partner ? 'X' : '') : '',
            ])
            return line
          },
          []
        )
        arr.push(...userLines)
        return arr
      }, [])
      return list
    }
  }, [answersWithGacs, cities])

  const generateXLSX = useCallback(() => {
    if (report && answerReport) {
      const workbook = XLSX.utils.book_new()
      workbook.Props = {
        Title: 'Übersicht_' + report.currentSurvey,
        CreatedDate: new Date(),
      }

      const colHeaders = [
        'E-Mail',
        'Nachname',
        'Vorname',
        'Firma',
        'Stadt',
        ...defaultAssetClasses,
        'Marktexperte',
      ]
      const ws = XLSX.utils.aoa_to_sheet([colHeaders, ...answerReport])
      workbook.SheetNames.push('Report')
      workbook.Sheets['Report'] = ws
      XLSX.writeFile(workbook, 'Übersicht_' + report.currentSurvey + '.xlsx')
    }
  }, [answerReport, report])

  useEffect(() => {
    if (!cities && report?.type === 'riwis') {
      fetchCities()
    }
  }, [cities, fetchCities, report])

  if (report?.type === 'riwis' && !cities) {
    return null
  }

  if (report) {
    if (report.type !== 'riwis') {
      return (
        <table className="table table-sm" id="surveyParticipated">
          <thead className="bg-primary text-light">
            <tr>
              <th scope="col">Teilnehmer ({report.percentageAnswered?.length})</th>
              <th scope="col">Fragen beantwortet</th>
              <th scope="col">Umfrage abgebrochen</th>
            </tr>
          </thead>
          <tbody>
            {report &&
              report.type !== 'riwis' &&
              report.percentageAnswered.map((item) => (
                <tr key={item.username} className={item.percentage < 100 ? 'alert-danger' : 'alert-success'}>
                  <td>{item.username}</td>
                  <td>{item.percentage}%</td>
                  <td>{item.earlyExit && `Seite ${item.earlyExit}`}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )
    } else {
      return (
        cities !== null && (
          <>
            <button className="btn btn-outline-primary" onClick={generateXLSX}>
              <FontAwesomeIcon icon={faFileExcel} className="h3 m-0" />
            </button>
            {answersWithGacs.map((answerPerUser, index) => (
              <div className="report" key={answerPerUser.id}>
                <div className="user">
                  <div className="index">{index + 1}.</div>
                  {answerPerUser.username}
                </div>
                {Object.entries(answerPerUser.answeredGacs).map(([gac, assets]) => (
                  <div key={`${answerPerUser.id}-${gac}`} className="answerRow">
                    <div className="header">{getCityName(cities, gac)}:</div>
                    {Object.keys(TextEnum).map((assetClass) => {
                      const asset = assets.find((asset) => asset.asset_class === assetClass)
                      let progress = asset
                        ? Math.ceil((asset.answer_count / asset.question_count) * 100) || 0
                        : 0
                      return (
                        <div
                          className="answer"
                          style={{ opacity: progress === 0 ? 0.2 : 1 }}
                          key={`${answerPerUser.id}-${gac}-${assetClass}`}
                        >
                          <img width="20" src={IconEnum[assetClass]} alt="icon" />
                          <div className="ml-3">{TextEnum[assetClass]}:</div>
                          <div
                            className="ml-3"
                            style={{
                              fontWeight: progress === 100 ? '700' : '',
                              textDecoration: progress === 100 ? 'underline' : '',
                            }}
                          >
                            {progress}%
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
            ))}
          </>
        )
      )
    }
  }

  return <div></div>
}
