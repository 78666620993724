import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  faChevronUp,
  faChevronDown,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

const AccordionButton = styled.div`
  margin-bottom: 1rem;
  color: #002940;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  max-width: 100px;
`

const JSONIndicator = styled.div``

const Textarea = styled.textarea`
  width: 100%;
  &:focus-visible {
    outline: ${({ isValidJSON }) => (isValidJSON ? '-webkit-focus-ring-color auto 1px' : '#dc3545 auto 1px')};
  }
`

export const LayoutSettingEditor = ({ introStyles, onChange, name, layout = 'default' }) => {
  const [internalLayout, setInternalLayout] = useState(layout)
  const setStringifiedJSONRef = useRef()

  const handleChange = useCallback(
    (event) => {
      const newSettings = { ...introStyles }
      newSettings[layout] = event.target.value
      const newEvent = { target: { name: event.target.name, value: newSettings } }
      onChange(newEvent)
    },
    [introStyles, layout, onChange]
  )

  useEffect(() => {
    if (layout !== internalLayout) {
      setInternalLayout(layout)
      if (typeof setStringifiedJSONRef.current === 'function') {
        setStringifiedJSONRef.current(JSON.stringify(introStyles[layout], null, 4))
      }
    }
  }, [layout, internalLayout, introStyles])

  return (
    <JSONTextarea
      json={introStyles[internalLayout]}
      onChange={handleChange}
      name={name}
      layout={layout}
      setStringifiedJSONRef={setStringifiedJSONRef}
      showWithinAccordion={true}
    />
  )
}

export const JSONTextarea = ({
  json,
  onChange,
  onBlur,
  onKeyDown,
  name,
  id,
  setStringifiedJSONRef,
  showWithinAccordion = false,
  width = '100%',
  rows = 16,
  message = null,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [stringifiedJSON, setStringifiedJSON] = useState(JSON.stringify(json, null, 4))

  const isValidJSON = useMemo(() => {
    try {
      JSON.parse(stringifiedJSON)
      return true
    } catch (e) {
      return false
    }
  }, [stringifiedJSON])

  const handleChange = useCallback(
    (event) => {
      setStringifiedJSON(event.target.value)
      try {
        onChange({
          target: { name: event.target.name, id: event.target.id, value: JSON.parse(event.target.value) },
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    [onChange]
  )

  const onBlurLocal = useCallback(
    (event) => {
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    },
    [onBlur]
  )

  const onKeyDownLocal = useCallback(
    (event) => {
      if (typeof onKeyDown === 'function') {
        onKeyDown(event)
      }
    },
    [onKeyDown]
  )

  useEffect(() => {
    if (setStringifiedJSONRef && !setStringifiedJSONRef.current) {
      setStringifiedJSONRef.current = setStringifiedJSON
    }
  }, [setStringifiedJSONRef])

  const beautify = useCallback(() => {
    const beautifiedJSON = JSON.stringify(JSON.parse(stringifiedJSON), null, 4)
    const event = { target: { id, name, value: beautifiedJSON } }
    handleChange(event)
  }, [stringifiedJSON, name, id, handleChange])

  if (showWithinAccordion) {
    return (
      <div style={{ height: expanded ? 'auto' : '30px', overflow: 'hidden', width }}>
        <AccordionButton className="my-3" onClick={() => setExpanded(!expanded)}>
          <div className="d-flex justify-content-between align-items-center">
            {expanded ? 'Einklappen' : 'Ausklappen'}{' '}
            {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </div>
        </AccordionButton>
        <Textarea
          value={stringifiedJSON}
          name={name}
          id={id}
          onChange={handleChange}
          rows={rows}
          isValidJSON={isValidJSON}
          onBlur={onBlurLocal}
          onKeyDown={onKeyDownLocal}
        ></Textarea>
        <div className="d-flex justify-content-between align-items-center">
          <Button size={'sm'} onClick={beautify}>
            Beautify
          </Button>
          <div
            className={
              message?.type === 'error'
                ? 'text-danger'
                : message?.type === 'warning'
                ? 'text-warning'
                : message?.type === 'info'
                ? 'text-info'
                : ''
            }
          >
            {message && message.text}
          </div>
          <JSONIndicator>
            JSON:{' '}
            {isValidJSON ? (
              <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
            ) : (
              <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" />
            )}
          </JSONIndicator>
        </div>
      </div>
    )
  }

  return (
    <div style={{ width }}>
      <Textarea
        value={stringifiedJSON}
        name={name}
        id={id}
        onChange={handleChange}
        onBlur={onBlurLocal}
        onKeyDown={onKeyDownLocal}
        rows={rows}
        isValidJSON={isValidJSON}
      ></Textarea>
      <div className="d-flex justify-content-between align-items-center">
        <Button size={'sm'} onClick={beautify}>
          Beautify
        </Button>
        <div
          className={
            message?.type === 'error'
              ? 'text-danger'
              : message?.type === 'warning'
              ? 'text-warning'
              : message?.type === 'info'
              ? 'text-info'
              : ''
          }
        >
          {message && message.text}
        </div>
        <JSONIndicator>
          JSON:{' '}
          {isValidJSON ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" />
          )}
        </JSONIndicator>
      </div>
    </div>
  )
}
