import React from 'react'
import { ListBox } from './Components/ListBox'

export const RadioMatrix = ({ question, questionnaireIndex, pageIndex, index }) => {
  return (
    <div>
      <ListBox
        label={'Antworten'}
        name="possible_answers"
        items={question.possible_answers}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />

      <ListBox
        label={'Frage-Feld'}
        name="questions_field"
        items={question.questions_field}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
    </div>
  )
}
