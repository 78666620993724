// polyfills
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './css/custom.css'
import { UserDataProvider } from './stores/userStore'

ReactDOM.render(
  <UserDataProvider>
    <App />
  </UserDataProvider>,
  document.getElementById('root')
)
