import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NoAnswerCheckbox } from '../QuestionnaireBuilder/Components/NoAnswerCheckbox'

export const Textarea = ({
  id,
  qid,
  value = { text: '' },
  noAnswer,
  previousAnswer,
  limit = null,
  onChange,
}) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)
  const [localValue, setLocalValue] = useState(() => {
    let text = ''
    let noAnswer = false
    if (value?.text) {
      text = value?.text
    } else if (previousAnswer && previousAnswer?.text && !previousAnswer.noAnswer) {
      text = previousAnswer.text
    }

    if (typeof value.noAnswer !== 'undefined') {
      noAnswer = value.noAnswer
    } else if (previousAnswer && typeof previousAnswer.noAnswer !== 'undefined') {
      noAnswer = previousAnswer.noAnswer
    }

    return { text, noAnswer }
  })

  const handleChange = useCallback(
    (event) => {
      let newValue = { ...value }
      if (localPreviousAnswer !== null && !Object.keys(newValue).length) {
        newValue = { ...localPreviousAnswer }
      }
      if (event.target.type === 'checkbox') {
        newValue.noAnswer = event.target.checked
      }

      if (event.target.type === 'textarea') {
        newValue.text = event.target.value
      }
      setLocalPreviousAnswer(null)
      setLocalValue(newValue)
      onChange({ question_id: id, value: newValue, qid })
    },
    [onChange, id, qid, value, localPreviousAnswer]
  )

  // converting text to new format
  useEffect(() => {
    if (typeof value === 'string') {
      const newValue = { text: value, noAnswer: false }
      onChange({ question_id: id, value: newValue, qid })
    }
  }, [onChange, id, qid, value])

  return (
    <div className="form-group">
      <div className="row">
        <textarea
          className="form-control col-sm-12 col-md-5 ml-4"
          name={id}
          onChange={handleChange}
          value={localValue.text}
          id=""
          cols="6"
          rows="6"
          disabled={value.no}
          // style={{ resize: 'both', width: '50%' }}
          maxLength={limit}
        ></textarea>
      </div>
      {limit && (
        <div className="mt-2 text-muted">
          {value.text.length} / {limit} Zeichen
        </div>
      )}
      <NoAnswerCheckbox
        noAnswer={noAnswer}
        onChange={handleChange}
        checked={localValue.noAnswer}
        questionId={id}
      />
    </div>
  )
}
