import { useCallback } from 'react'
import { Tab } from './Tab'
import update from 'immutability-helper'

export const TabContainer = ({
  tabs,
  updateOrder,
  updateMoveItem,
  activeTab,
  setActiveTab,
  handleDelete,
  tabWidth,
  flexDirection,
  itemType,
  acceptedItemTypes,
}) => {
  const moveTab = useCallback(
    (dragIndex, hoverIndex) => {
      const dragTab = tabs[dragIndex]
      const newTabs = update(tabs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragTab],
        ],
      })
      // keep track of active tab
      if (dragIndex === activeTab) {
        setActiveTab(hoverIndex)
        // switch place of inactive tab with active tab
      } else if (hoverIndex === activeTab) {
        if (dragIndex < hoverIndex) {
          setActiveTab(hoverIndex - 1)
        } else if (dragIndex > hoverIndex) {
          setActiveTab(hoverIndex + 1)
        }
      } else {
        // move active tab if two inactive tabs switch place and active tab lies in between
        if (dragIndex < hoverIndex && activeTab > dragIndex && activeTab < hoverIndex) {
          setActiveTab(activeTab - 1)
        } else if (dragIndex > hoverIndex && activeTab < dragIndex && activeTab > hoverIndex) {
          setActiveTab(activeTab + 1)
        }
      }
      newTabs.forEach((tab, index) => (tab.order = index + 1))
      updateOrder(newTabs)
    },
    [tabs, updateOrder, activeTab, setActiveTab]
  )

  const renderTab = (tab, index) => {
    return (
      <Tab
        key={tab.id}
        index={index}
        id={tab.id}
        text={tab.label || tab.title || 'Titel'}
        moveTab={moveTab}
        moveInto={updateMoveItem}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleDelete={handleDelete}
        tabWidth={tabWidth}
        tabPills={flexDirection === 'column'}
        itemType={itemType}
        acceptedItemTypes={acceptedItemTypes}
      />
    )
  }

  return (
    <div className="tabContainer d-flex flex-wrap" style={{ flexDirection }}>
      {tabs.map((tab, index) => !tab.deleted && renderTab(tab, index))}
    </div>
  )
}
