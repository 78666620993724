import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

export const WithDecimal = ({ question, index, questionnaireIndex, pageIndex }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const handleChange = useCallback(
    (question) => {
      const withDecimalValue = !question.with_decimal
      const switchDecimal = (currentValue) => {
        if (withDecimalValue) {
          return Number.parseFloat(currentValue).toFixed(1)
        } else {
          return Number.parseInt(currentValue)
        }
      }

      const values = [
        { property: 'with_decimal', value: withDecimalValue },
        { property: 'min_value', value: switchDecimal(question.min_value) },
        { property: 'max_value', value: switchDecimal(question.max_value) },
        { property: 'default_value', value: switchDecimal(question.default_value) },
        { property: 'step', value: switchDecimal(question.step) },
      ]
      setQuestionInput(questionnaireIndex, pageIndex, index, values)
    },
    [pageIndex, questionnaireIndex, index, setQuestionInput]
  )

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
      <div className="col-sm-10">
        <div className="custom-control custom-checkbox">
          <input
            className="custom-control-input"
            name="with_decimal"
            type="checkbox"
            readOnly
            checked={question.with_decimal || false}
          ></input>
          <label className="custom-control-label" onClick={() => handleChange(question)}>
            mit Nachkomma-Stelle
          </label>
        </div>
      </div>
    </div>
  )
}
