import React, { useCallback, useEffect } from 'react'

export const Modal = ({ children, onHide, maxWidth, minWidth, marginX, overflowY, isPreview = false }) => {
  const handleOutsideClick = useCallback(
    (event) => {
      if (event.target.id === 'outside') {
        onHide(false)
      }
    },
    [onHide]
  )

  const onEscapeListener = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onHide(false)
      }
    },
    [onHide]
  )

  useEffect(() => {
    document.addEventListener('keydown', onEscapeListener)
    return () => {
      document.removeEventListener('keydown', onEscapeListener)
    }
  }, [onEscapeListener])

  return (
    <div className="modalWindow" id="outside" onClick={handleOutsideClick}>
      <div
        className={`modalWindowContent ${isPreview && 'previewModal'}`}
        style={{ maxWidth, marginLeft: marginX, marginRight: marginX, minWidth, overflowY }}
      >
        <div className="crossContainer align-self-end" onClick={() => onHide(false)}>
          <div className="crossBarOne">
            <div className="crossBarTwo"></div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
