import { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsOfflineExporting from 'highcharts/modules/offline-exporting'
import highchartsExportData from 'highcharts/modules/export-data'

highchartsExporting(Highcharts)
highchartsOfflineExporting(Highcharts)
highchartsExportData(Highcharts)

const chartOptions = {
  chart: {
    borderColor: 'lightgray',
    borderWidth: 1,
    height: 400,
  },
  plotOptions: {
    series: {
      animation: false,
    },
  },
  credits: {
    enabled: false,
  },
  title: {},
  xAxis: {
    title: {
      text: 'Antworten',
    },
  },
  yAxis: {
    title: {
      text: 'Anzahl',
    },
  },
}

export const StatisticsChart = ({ series, categories, title, chartType, subQuestionIndex }) => {
  const [options, setOptions] = useState(chartOptions)

  useEffect(() => {
    setOptions((prevOptions) => {
      let newSeries = [...series]
      if (subQuestionIndex >= 0) {
        newSeries = newSeries.map((answer) => ({
          name: answer.name,
          data: [answer.data[subQuestionIndex]],
        }))
      }

      const newOptions = { ...prevOptions }
      newOptions.title = { ...newOptions.title }
      newOptions.title.text = title
      newOptions.chart = { ...newOptions.chart }
      newOptions.chart.type = chartType

      newOptions.series =
        chartType === 'pie'
          ? [
              {
                name: 'Anzahl',
                data: [
                  ...newSeries.map((answer) => ({
                    name: `${typeof answer.name === 'number' ? `Antwort: ${answer.name}` : answer.name}`,
                    y: answer.data[0],
                  })),
                ],
              },
            ]
          : [...newSeries]

      if (chartType !== 'pie') {
        newOptions.series = newSeries.map((data) => {
          const newData = { ...data }
          if (typeof data.name === 'number') {
            newData.name = `Antwort "${data.name}"`
          }
          return newData
        })
      }

      newOptions.xAxis = { ...newOptions.xAxis }
      if (categories.length) {
        newOptions.xAxis.categories = [...categories, '']
      } else {
        newOptions.xAxis.categories = ['']
      }
      return newOptions
    })
  }, [series, categories, title, chartType, subQuestionIndex])

  return <HighchartsReact options={options} highcharts={Highcharts} />
}
