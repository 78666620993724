import createStore from '../lib/flux-store'
import { Auth, User, Registration } from '../helper/api'

const initialState = {
  user: null,
  badCredentials: false,
  redirectTo: '',
  saveMessage: { msg: '', type: '', err: '' },
  loginMessage: { msg: '', type: '', err: '' },
  verificationRequestMessage: { msg: '', type: '', err: '' },
  requestResetPasswordMessage: { msg: '', type: '', err: '' },
  resetPasswordMessage: { msg: '', type: '', err: '' },
  showLoginModal: false,
  listAsPartner: null,
  language: 'de',
}

const actions = {
  setShowLoginModal: (show) => ({ show }),
  loginUser: (user) => (dispatch) => {
    Auth.login(user)
      .then((res) => {
        if (res.data.msg === 'failed') {
          if (res.data.error === 'bad credentials') {
            const loginMessage = { msg: 'E-Mail oder Kennwort falsch', type: 'failed', err: res.data.error }
            dispatch({ type: 'setLoginMessage', loginMessage })
          } else if (res.data.error === 'not active') {
            const loginMessage = {
              msg: 'Ihre E-Mail Adresse wurde noch nicht bestätigt',
              type: 'failed',
              err: res.data.error,
            }
            dispatch({ type: 'setLoginMessage', loginMessage })
          } else {
            const loginMessage = { msg: '', type: 'failed', err: res.data.err }
            dispatch({ type: 'setLoginMessage', loginMessage })
          }
        } else {
          dispatch({ type: 'loginUser', payload: res.data })
        }
      })
      .catch((err) => dispatch({ type: 'setLoginMessage', loginMessage: { msg: '', type: 'failed', err } }))
  },
  loginUserToken: (token) => (dispatch) => {
    Auth.token(token)
      .then((res) => {
        if (res.data.msg === 'failed') {
          dispatch({
            type: 'setLoginMessage',
            loginMessage: { msg: '', type: 'failed', err: res.data.error },
          })
        } else {
          dispatch({ type: 'loginUserToken', payload: res.data })
        }
      })
      .catch(() => dispatch({ type: 'setBadCredentials', payload: true }))
  },
  authenticateUser: () => (dispatch) => {
    Auth.authenticate().then((res) => {
      if (res.data.id) {
        dispatch({ type: 'loginUser', payload: res.data })
      }
    })
  },
  logoutUser: () => (dispatch) => {
    Auth.logout().then(() => {
      const redirectTo = '/'
      dispatch({ type: 'setUser', user: null })
      dispatch({ type: 'setRedirectTo', redirectTo })
    })
  },
  setUser: (user) => ({ user }),
  saveUserChanges: (user) => (dispatch) => {
    User.editUser(user)
      .then((res) => {
        if (res.data.msg === 'success') {
          dispatch({ type: 'setUser', user })
          dispatch({
            type: 'setSaveMessage',
            saveMessage: { msg: 'Ihre Änderungen wurden erfolgreich gespeichert', type: 'success', err: '' },
          })
        } else {
          dispatch({
            type: 'setSaveMessage',
            saveMessage: { msg: '', type: 'failed', err: JSON.stringify(res.data.error) },
          })
        }
      })
      .catch(() => {
        dispatch({
          type: 'setSaveMessage',
          saveMessage: { msg: '', type: 'failed', err: 'Änderungen konnten nicht gespeichert werden' },
        })
      })
  },
  requestVerificationMail: (email) => (dispatch) => {
    Registration.requestVerificationMail(email).then((res) => {
      if (res.data.msg === 'failed') {
        dispatch({
          type: 'setVerificationRequestMessage',
          verificationRequestMessage: {
            msg: 'Bestätigungsmail konnte nicht gesendet werden',
            type: 'failed',
            err: res.data.error,
          },
        })
      } else {
        dispatch({
          type: 'setVerificationRequestMessage',
          verificationRequestMessage: {
            msg: '',
            type: 'success',
            err: '',
          },
        })
      }
    })
  },
  requestResetPassword: (email) => (dispatch) => {
    User.requestResetPassword(email).then((res) => {
      if (res.data.msg === 'failed') {
        dispatch({
          type: 'setRequestResetPasswordMessage',
          requestResetPasswordMessage: {
            msg: 'Ihre Anfrage konnte nicht bearbeitet werden.',
            type: 'failed',
            err: res.data.error,
          },
        })
      } else {
        dispatch({
          type: 'setRequestResetPasswordMessage',
          requestResetPasswordMessage: { msg: '', type: 'success', err: '' },
        })
      }
    })
  },
  changePassword: (password, token) => (dispatch) => {
    User.changePassword(password, token).then((res) => {
      if (res.data.msg === 'failed') {
        dispatch({
          type: 'setResetPasswordMessage',
          resetPasswordMessage: {
            msg: 'Ihr Passwort konnte nicht geändert werden',
            type: 'failed',
            err: res.data.error,
          },
        })
      } else {
        dispatch({
          type: 'setResetPasswordMessage',
          resetPasswordMessage: {
            msg: '',
            type: 'success',
            err: '',
          },
        })
      }
    })
  },
  setListAsPartner: (listAsPartner) => (dispatch) => {
    User.setListAsPartner(listAsPartner)
      .then((res) => {
        if (!res.data.error) {
          dispatch({ type: 'setListAsPartner', listAsPartner: res.data.listAsPartner })
        } else {
          console.error(res.data.error)
        }
      })
      .catch((err) => console.error(err))
  },
  setBadCredentials: (bool) => ({ payload: bool }),
  setSaveMessage: (saveMessage) => ({ saveMessage }),
  setLoginMessage: (loginMessage) => ({ loginMessage }),
  setVerificationRequestMessage: (setVerificationRequestMessage) => ({ setVerificationRequestMessage }),
  setRequestResetPasswordMessage: (requestResetPasswordMessage) => ({ requestResetPasswordMessage }),
  setResetPasswordMessage: (resetPasswordMessage) => ({ resetPasswordMessage }),
  setLanguage: (language) => ({ language }),
}

const reducer = {
  setShowLoginModal: (state, { show }) => {
    return { ...state, showLoginModal: show }
  },
  loginUser: (state, { payload }) => {
    return { ...state, user: payload, badCredentials: false }
  },
  loginUserToken: (state, { payload }) => {
    return { ...state, user: payload.user, redirectTo: payload.redirectTo }
  },
  setUser: (state, { user }) => {
    return { ...state, user: user }
  },
  setBadCredentials: (state, { payload }) => {
    return { ...state, badCredentials: payload }
  },
  setSaveMessage: (state, { saveMessage }) => {
    return { ...state, saveMessage }
  },
  setRedirectTo: (state, { redirectTo }) => {
    return { ...state, redirectTo }
  },
  setLoginMessage: (state, { loginMessage }) => {
    return { ...state, loginMessage }
  },
  setVerificationRequestMessage: (state, { verificationRequestMessage }) => {
    return { ...state, verificationRequestMessage }
  },
  setRequestResetPasswordMessage: (state, { requestResetPasswordMessage }) => {
    return { ...state, requestResetPasswordMessage }
  },
  setResetPasswordMessage: (state, { resetPasswordMessage }) => {
    return { ...state, resetPasswordMessage }
  },
  setListAsPartner: (state, { listAsPartner }) => {
    return { ...state, listAsPartner }
  },
  setLanguage: (state, { language }) => ({ ...state, language }),
}

export const [UserDataContext, UserDataProvider, useUserDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'UserDataStore'
)
