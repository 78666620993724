export const Container = ({
  children,
  bgColor,
  surveyType,
  wrapperStyle = {},
  customContainerStyle = {},
}) => {
  return (
    <div className="wrapper" style={{ backgroundColor: bgColor ? bgColor : 'white', ...wrapperStyle }}>
      <div className={(surveyType ? surveyType : '') + ' customContainer'} style={customContainerStyle}>
        {children}
      </div>
    </div>
  )
}
