import React from 'react'
import { User } from '../helper/api'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
export class UserSelectBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      support_contact: props?.support_contact || '',
      resultList: [],
      email: props?.support_contact?.email || '',
      userSelect: false,
      showContactDataBtn: props.showContactDataBtn || true,
      showModal: false,
    }
    this.timer = null
  }

  showModal = () => {
    this.setState({ showModal: true })
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.email !== this.state.email && !this.state.userSelect) {
      this.handleSearch()
    }
  }

  renderContactDetails = () => {
    return (
      <div className="form-group row">
        <div className="col">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="first_name">Vorname</label>
              <input
                disabled
                type="text"
                className="form-control"
                id="first_name"
                placeholder="Vorname"
                value={this.state.support_contact.first_name || ''}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="last_name">Nachname</label>
              <input
                disabled
                type="text"
                className="form-control"
                id="last_name"
                placeholder="Nachname"
                value={this.state.support_contact.last_name || ''}
              ></input>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="street">Straße</label>
              <input
                disabled
                type="text"
                className="form-control"
                id="street"
                placeholder="Straße"
                value={this.state.support_contact.street || ''}
              ></input>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="plz">PLZ</label>
              <input
                disabled
                type="text"
                className="form-control"
                id="plz"
                placeholder="Postleitzahl"
                value={this.state.support_contact.zip || ''}
              ></input>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="city">Stadt</label>
              <input
                disabled
                type="text"
                className="form-control"
                id="city"
                placeholder="Stadt"
                value={this.state.support_contact.city || ''}
              ></input>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">E-Mail</label>
              <input
                disabled
                type="email"
                className="form-control"
                id="email"
                placeholder="E-Mail"
                value={this.state.support_contact.email || ''}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phone">Telefon</label>
              <input
                disabled
                type="tel"
                className="form-control"
                id="phone"
                placeholder="Telefon"
                value={this.state.support_contact.phone || ''}
              ></input>
            </div>
          </div>
        </div>
      </div>
    )
  }

  setEmail = (event) => {
    this.setState({ email: event.target.value, userSelect: false })
  }

  handleSearch = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.fetchUser(this.state.email)
    }, 500)
  }

  fetchUser = (query) => {
    if (query.length >= 3) {
      User.findByName(query).then((res) => this.setState({ resultList: res.data }))
    }
  }

  handleSelect = (event) => {
    this.setState({
      support_contact: this.state.resultList[event.target.dataset.index],
      resultList: [],
      email: this.state.resultList[event.target.dataset.index].email,
      userSelect: true,
    })

    this.props.changeHandler(this.state.resultList[event.target.dataset.index])
  }

  handleClose = () => {
    this.setState({
      resultList: [],
      email: this.state.support_contact.email,
    })
  }

  renderResultList = (items) => {
    return (
      <ul className="userSearchResultList">
        <FontAwesomeIcon icon={faTimesCircle} className="closeButton" onClick={this.handleClose} />
        {items.map((item, index) => (
          <li key={item.id} data-index={index} onClick={this.handleSelect}>
            {item.first_name} {item.last_name}
            {'<'}
            {item.email ? item.email : 'null'}
            {'>'}
          </li>
        ))}
      </ul>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-row">
          <div className={this.state.showContactDataBtn ? 'col-sm-12' : ' col-sm-4'}>
            <input
              type="text"
              className="form-control"
              placeholder="Tippe um zu suchen..."
              value={this.state.email || ''}
              onChange={this.setEmail}
            />
            {this.state.resultList.length > 0 && this.renderResultList(this.state.resultList)}
          </div>
          {this.state.showContactDataBtn === false && (
            <div className="from-gorup col-sm-4">
              <button className="btn btn-primary" onClick={this.showModal}>
                Kontaktdaten anzeigen
              </button>
            </div>
          )}
        </div>

        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Kontaktdaten</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderContactDetails()}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.hideModal}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}
