import { InputValue } from './Components/InputValue'
import { Step } from './Components/Step'
import { Unit } from './Components/Unit'
import { WithDecimal } from './Components/WithDecimal'

export const Slider = ({ question, index, questionnaireIndex, pageIndex }) => {
  return (
    <div>
      <WithDecimal
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <InputValue
        label={'Min. Wert'}
        name={'min_value'}
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <InputValue
        label={'Max. Wert'}
        name={'max_value'}
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <InputValue
        label={'Default Wert'}
        name={'default_value'}
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <Step question={question} index={index} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} />
      <Unit question={question} index={index} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} />
    </div>
  )
}
