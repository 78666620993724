import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSurveyEditorStateStore } from './surveyEditorState'

const createTemplate = (survey) => {
  const keepQuestionIds = false
  // survey.type === 'bfQuarter' || survey.type === 'riwis'
  const template = { ...survey }
  template.title = ''
  template.id = 0
  delete template?.create_date
  if (template.questionnaires) {
    template.questionnaires = [...survey.questionnaires]
  } else {
    template.questionnaires = []
  }
  template.questionnaires = template?.questionnaires.map((questionnaire, questionnaireIndex) => {
    questionnaire = { ...template.questionnaires[questionnaireIndex] }
    questionnaire.id = questionnaireIndex === 0 ? 0 : questionnaireIndex * -1
    delete questionnaire?.survey_id
    delete questionnaire?.create_date
    if (template.questionnaires[questionnaireIndex].pages) {
      questionnaire.pages = [...template?.questionnaires[questionnaireIndex]?.pages]
    } else {
      questionnaire.pages = []
    }
    questionnaire.pages = questionnaire?.pages.map((page, pageIndex) => {
      page = { ...template.questionnaires[questionnaireIndex].pages[pageIndex] }
      delete page?.questionnaire_id
      delete page?.create_date
      page.id = pageIndex === 0 ? 0 : pageIndex * -1
      if (page.questions) {
        page.questions = [...template.questionnaires[questionnaireIndex].pages[pageIndex].questions]
      } else {
        page.questions = []
      }
      page.questions = page?.questions.map((question, questionIndex) => {
        question = {
          ...template.questionnaires[questionnaireIndex].pages[pageIndex].questions[questionIndex],
        }
        if (!keepQuestionIds) {
          question.id = questionIndex === 0 ? 0 : questionIndex * -1
        }
        delete question?.page_id
        delete question?.given_answer
        delete question?.create_date
        return question
      })
      return page
    })
    return questionnaire
  })
  return template
}

export const TemplateSave = ({ showTemplateSave, onHide }) => {
  const [missingTitle, setMissingTitle] = useState(false)
  const [{ survey, templateTitle }, { setTemplateTitle, saveTemplate }] = useSurveyEditorStateStore()

  const handleClose = () => onHide()

  const handleSave = () => {
    const template = createTemplate(survey)
    saveTemplate(templateTitle, template)
    handleClose()
  }

  const handleInputChange = (event) => {
    if (event.target.value) {
      setTemplateTitle(event.target.value)
      setMissingTitle(false)
    } else {
      setMissingTitle(true)
    }
  }

  return (
    <Modal show={showTemplateSave} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Vorlage speichern</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col mb-3">Titel: </div>
        <div className="col">
          <input
            id="templateTitle"
            className="form-control"
            type="text"
            name="templateTitle"
            value={survey.templateTitle}
            onChange={handleInputChange}
          />
        </div>
        {missingTitle === true && <div className="col mt-3 text-danger">Bitte vergeben Sie einen Titel</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Vorlage speichern
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
