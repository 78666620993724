import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertBox } from '../../components/AlertBox'
import { Container } from '../../components/Container'
import { useUserDataStore } from '../../stores/userStore'

export const Account = () => {
  const { t } = useTranslation()
  const [createNewPassword, setCreateNewPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [{ user, saveMessage }, { saveUserChanges, setSaveMessage }] = useUserDataStore()
  const [editedUser, setEditedUser] = useState(null)
  const [passwordLengthErr, setPasswordLengthErr] = useState(false)

  const handleInputChange = (event) => {
    const newUser = { ...editedUser }
    newUser[event.target.name] = event.target.value
    setEditedUser(newUser)
  }
  const handleNewPassword = () => {
    setCreateNewPassword(true)
  }

  const handleSaveUser = () => {
    if (!editedUser.password || editedUser.password.length >= 8) {
      saveUserChanges(editedUser)
      setPasswordLengthErr(false)
    } else {
      setPasswordLengthErr(true)
    }
  }

  useEffect(() => {
    if (user) {
      setEditedUser(user)
    }
  }, [user])

  useEffect(() => {
    if (saveMessage?.msg?.length) {
      setTimeout(() => {
        setSaveMessage({ msg: '', error: '', type: '' })
      }, 3000)
    }
  }, [saveMessage, setSaveMessage])

  return (
    <Container>
      {editedUser && (
        <>
          <div className="sticky-top pt-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
            <div className="d-flex justify-space-between">
              <div className="d-flex">
                <h3 className="mr-5">{t('account.myAccount')}</h3>
                <button onClick={handleSaveUser} className="btn-sm btn-primary">
                  {t('account.save')}
                </button>
              </div>
              <div id="#alert" className="d-flex justify-content-end">
                <AlertBox type={saveMessage.type} message={saveMessage.msg} error={saveMessage.error} />
              </div>
            </div>
            <hr className="mb-5" />
          </div>
          <div className="px-4">
            <div className="form-group row">
              <label htmlFor="username" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.username')}
              </label>
              <div className="col">
                <input
                  onChange={handleInputChange}
                  type="text"
                  name="username"
                  id="username"
                  className="form-control"
                  value={editedUser.username || ''}
                  disabled={editedUser.id}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="password"
                className={
                  (passwordLengthErr ? 'text-danger font-weight-bolder' : '') +
                  ' col-sm-2 col-form-label text-md-right text-sm-left'
                }
              >
                {t('account.password')}
              </label>
              {editedUser.id !== 0 && !createNewPassword && (
                <div className="col-sm-8" id="passwordInputButtonLine">
                  <button className="btn btn-sm btn-primary" type="button" onClick={handleNewPassword}>
                    {t('account.newPassword')}
                  </button>
                </div>
              )}
              {(editedUser.id === 0 || createNewPassword) && (
                <>
                  <div className="col-sm-8">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="form-control"
                      onChange={handleInputChange}
                      value={editedUser.password || ''}
                    />
                    <div className={(passwordLengthErr ? 'text-danger font-weight-bolder' : '') + ' mt-2'}>
                      {t('account.minCharacters')}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="d-flex justify-content-end">
                      <div className="custom-control custom-checkbox">
                        <input
                          onClick={(event) => setShowPassword(event.target.checked)}
                          type="checkbox"
                          className="custom-control-input"
                          id="showPassword"
                        />
                        <label htmlFor="showPassword" className="custom-control-label">
                          {t('account.showPassword')}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="first_name" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.firstname')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="form-control"
                  value={editedUser.first_name || ''}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="last_name" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.lastname')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="last_name"
                  className="form-control"
                  value={editedUser.last_name || ''}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="gender" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.gender')}
              </label>
              <div className="col-md-2 col-sm-12">
                <select
                  className="form-control"
                  name="gender"
                  onChange={handleInputChange}
                  value={editedUser.gender || 'W'}
                >
                  <option value="W">{t('account.female')}</option>
                  <option value="M">{t('account.male')}</option>
                  <option value="D">{t('account.diverse')}</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="company" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.company')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="form-control"
                  value={editedUser.company || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="street" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.street')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="street"
                  id="street"
                  className="form-control"
                  value={editedUser.street || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="zip" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.zipCode')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  className="form-control"
                  value={editedUser.zip || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="city" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.city')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="form-control"
                  value={editedUser.city || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 py-0"></div>
              <div className="col py-0">
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="phone" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.phone')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  value={editedUser.phone || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="mobile" className="col-sm-2 col-form-label text-md-right text-sm-left">
                {t('account.mobile')}
              </label>
              <div className="col">
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  className="form-control"
                  value={editedUser.mobile || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* <div className="form-group row">
              <label htmlFor="email" className="col-sm-2 col-form-label text-md-right text-sm-left">
                E-Mail
              </label>
              <div className="col">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  value={editedUser.email || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
          </div>
        </>
      )}
    </Container>
  )
}
