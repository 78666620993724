import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../../components/Container'

export const Expired = () => {
  return (
    <Container>
      <div className="expiredContainer">
        <div style={{ position: 'relative' }}>
          <div className="text-center background">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div
            style={{ position: 'relative' }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            {/* <h1 className="mb-5">Umfrage abgelaufen</h1> */}
            <div style={{ fontSize: '3rem' }}>Umfrage abgelaufen</div>
            <div style={{ fontSize: '1.5rem' }}>
              <div>Leider ist der Befragungszeitraum für diese Umfrage bereits abgelaufen.</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
