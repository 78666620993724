import { Container } from '../../components/Container'

export const Forbidden = () => {
  return (
    <Container>
      <div className="d-flex flex-column align-items-center">
        <div style={{ fontSize: '6rem' }}>403</div>
        <h2>Leider können Sie nicht an dieser Umfrage teilnehmen</h2>
      </div>
    </Container>
  )
}
