import { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSurveyEditorStateStore } from './surveyEditorState'
export const TemplateLoad = ({ showTemplateLoad, onHide, onLoaded }) => {
  const [{ templateList, template }, { fetchTemplateList, fetchTemplate, setSurvey }] =
    useSurveyEditorStateStore()

  const handleClose = () => {
    onHide()
  }

  const handleSelectChange = (event) => {
    const selectedId = parseInt(event.target.value)
    if (selectedId) {
      fetchTemplate(selectedId)
    }
  }

  const handleApply = () => {
    setSurvey(template.template)
    onLoaded(true)
    onHide()
  }

  const handleEdit = () => {
    setSurvey(template.template, true)
    onLoaded(true)
    onHide()
  }

  useEffect(() => {
    if (showTemplateLoad) {
      fetchTemplateList()
    }
  }, [fetchTemplateList, showTemplateLoad])

  return (
    <Modal show={showTemplateLoad} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Vorlage Laden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col mb-3">Vorlage auswählen: </div>
        <div className="col">
          {templateList !== null && (
            <select
              className="form-control"
              defaultValue={template ? template.id : 0}
              onChange={handleSelectChange}
            >
              <option value="0">... auswählen</option>
              {templateList !== null &&
                templateList.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.title}
                  </option>
                ))}
            </select>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button variant="primary" disabled={!template} onClick={handleEdit}>
          Bearbeiten
        </Button>
        <Button variant="primary" disabled={!template} onClick={handleApply}>
          Anwenden
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
