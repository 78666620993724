import React, { useMemo, useState } from 'react'
import { Radio } from './QuestionTypes/Radio'
import { RadioMatrix } from './QuestionTypes/RadioMatrix'
import { RadioInline } from './QuestionTypes/RadioInline'
import { Checkbox } from './QuestionTypes/Checkbox'
import { CheckboxMatrix } from './QuestionTypes/checkboxMatrix'
import { Slider } from './QuestionTypes/Slider'
import { Text } from './QuestionTypes/Text'
import { Textarea } from './QuestionTypes/Textarea'
import { Sum } from './QuestionTypes/Sum'
import { RiwisSlider } from './QuestionTypes/RiwisSlider'
import { InputColumns } from './QuestionTypes/InputColumns'
import InfoIcon from '../../assets/icons/Chat-Information.svg'
import { Modal } from '../../components/Modal'
import { RiwisRadioMatrix } from './QuestionTypes/RiwisRadioMatrix'
import { RiwisRadioInline } from './QuestionTypes/RiwisRadioInline'
import { findLatestPreviousAnswer } from './QuestionnaireBuilder/helper'
import { Dropdown } from './QuestionTypes/Dropdown'

const QuestionSelector = ({ question, value, previousAnswer, label, pageIndex, handleAnswerChange }) => {
  switch (question.type) {
    case 'radio':
      return (
        <Radio
          id={question.id}
          qid={question.qid}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          previousAnswer={previousAnswer}
          value={value}
          onChange={handleAnswerChange}
        />
      )
    case 'radioInline':
      return (
        <RadioInline
          id={question.id}
          qid={question.qid}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          value={value}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'radioMatrix':
      return (
        <RadioMatrix
          id={question.id}
          qid={question.qid}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          questions={question.questions_field}
          oneAnswer={question.one_answer}
          value={value}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'checkbox':
      return (
        <Checkbox
          id={question.id}
          qid={question.qid}
          limit={question.limit}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          value={value}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'checkboxMatrix':
      return (
        <CheckboxMatrix
          id={question.id}
          qid={question.qid}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          questions={question.questions_field}
          value={value}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'slider':
      return (
        <Slider
          id={question.id}
          qid={question.qid}
          key={question.id}
          label={label}
          options={question}
          value={value}
          noAnswer={question.no_answer}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'text':
      return (
        <Text
          id={question.id}
          qid={question.qid}
          value={value}
          noAnswer={question.no_answer}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'textarea':
      return (
        <Textarea
          id={question.id}
          qid={question.qid}
          key={question.id}
          label={label}
          value={value}
          noAnswer={question.no_answer}
          limit={question.limit}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        ></Textarea>
      )
    case 'sum':
      return (
        <Sum
          id={question.id}
          qid={question.qid}
          key={question.id}
          label={label}
          answers={question.questions_field}
          noAnswer={question.no_answer}
          value={value}
          maxValue={question.max_value}
          unit={question.unit}
          optOut={question.opt_out}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        ></Sum>
      )
    case 'sliderRiwis':
      return (
        <RiwisSlider
          question={question}
          key={question.id}
          value={value}
          previousAnswer={previousAnswer}
          noAnswer={question.no_answer}
          onChange={handleAnswerChange}
          pageIndex={pageIndex}
        />
        // <div>riwis</div>
      )
    case 'radioMatrixRiwis':
      return (
        <RiwisRadioMatrix
          id={question.id}
          qid={question.qid}
          key={question.id}
          label={label}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          riwisDatasources={question.riwis_datasources}
          answerHeader={question.matrix_answer_header}
          questionHeader={question.matrix_question_header}
          questions={question.questions_field}
          oneAnswer={question.one_answer}
          value={value}
          onChange={handleAnswerChange}
        />
      )
    case 'radioInlineRiwis':
      return (
        <RiwisRadioInline
          id={question.id}
          qid={question.qid}
          key={question.id}
          label={label}
          answers={question.possible_answers}
          noAnswer={question.no_answer}
          value={value}
          onChange={handleAnswerChange}
          riwisDatasources={question.riwis_datasources}
        />
      )
    case 'inputColumns':
      return (
        <InputColumns
          id={question.id}
          qid={question.qid}
          dataType={question.data_type}
          questionFields={question.questions_field}
          noAnswer={question.no_answer}
          value={value}
          withDecimal={question.with_decimal}
          minValue={question.min_value}
          maxValue={question.max_value}
          defaultValue={question.default_value}
          unit={question.unit}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'contactForm':
      return (
        <InputColumns
          id={question.id}
          qid={question.qid}
          inlineLabels={false}
          withLineDefinitions={true}
          questionFields={question.questions_field}
          noAnswer={question.no_answer}
          value={value}
          onChange={handleAnswerChange}
          previousAnswer={previousAnswer}
        />
      )
    case 'dropdown':
      return (
        <Dropdown
          id={question.id}
          qid={question.qid}
          key={question.id}
          label={label}
          noAnswer={question.no_answer}
          riwisDatasources={question.riwis_datasources}
          dropdowns={question.questions_field}
          oneAnswer={question.one_answer}
          value={value}
          onChange={handleAnswerChange}
        />
      )
    default:
      return <div></div>
  }
}

export const QuestionBuilder = ({
  question,
  lastQuestionNo,
  showStar,
  showQuestionNo,
  missingInputs,
  handleAnswerChange,
  pageIndex,
  surveyYear,
  surveyQuarter,
  surveyType,
}) => {
  const [showDefinitionModal, setShowDefinitionModal] = useState(false)

  const value = question?.given_answer?.value?.value || undefined
  const previousAnswer = findLatestPreviousAnswer(
    question?.previous_answers,
    surveyType,
    surveyYear,
    surveyQuarter
  )
  const label = (question.is_optional || !showStar ? '' : '*') + question.label
  const warning = missingInputs.some((missingInput) =>
    typeof missingInput === 'number'
      ? missingInput === question.id
      : Number(Object.keys(missingInput)[0]) === question.id
  )

  const showQuestionNumber = useMemo(() => {
    if (typeof question.show_question_number === 'boolean') {
      return question.show_question_number
    }
    return showQuestionNo
  }, [showQuestionNo, question])

  // const Debug = useCallback(() => {
  //   return (
  //     <DebugAnswers
  //       question={question}
  //       value={question?.given_answer?.value?.value || ''}
  //       initExpand={true}
  //       rows={10}
  //       onChange={handleAnswerChange}
  //     />
  //   )
  // }, [question, handleAnswerChange])

  return (
    <>
      <div className="mb-5">
        <div className="d-flex mb-3 align-items-start">
          {showQuestionNumber === true && (
            <h4 className={`${warning ? 'missingInputColor' : ''} questionNumber`}>{lastQuestionNo}. </h4>
          )}
          <div
            style={{ display: 'inline-flex' }}
            className={showQuestionNumber === false ? 'questionIndent noQuestionNo' : ''}
          >
            {question.is_optional || !showStar ? (
              ''
            ) : (
              <span
                style={{
                  verticalAlign: 'top',
                  position: 'relative',
                  left: '-8px',
                  display: 'inline-flex',
                  width: 0,
                }}
                className={(warning ? 'missingInputColor' : '') + ' mr-1 warning star'}
              >
                *
              </span>
            )}
            <h4
              className={`${warning ? 'missingInputColor' : ''} pageTitle`}
              dangerouslySetInnerHTML={{ __html: question.label }}
            ></h4>
            {question?.definition && (
              <span className="cursorPointer" onClick={() => setShowDefinitionModal(true)}>
                <img src={InfoIcon} width="24" height="24" alt="" />
              </span>
            )}
          </div>
        </div>
        {question.description && (
          <h6
            className={`${question.type === 'sliderRiwis' ? 'riwisIndent' : ''} ${
              showQuestionNumber === false ? 'noQuestionNo' : ''
            } pb-3 questionDescription questionIndent`}
            dangerouslySetInnerHTML={{ __html: question.description }}
          ></h6>
        )}
      </div>
      <div
        className={
          (question.type !== 'radioMatrix' && question.type !== 'checkboxMatrix' && question.type !== 'sum'
            ? showQuestionNumber === false
              ? 'questionIndent noQuestionNo'
              : 'questionIndent'
            : showQuestionNumber === false
            ? 'matrixIndent noQuestionNo'
            : 'matrixIndent') +
          (question.type === 'sliderRiwis' || question.type === 'radioMatrixRiwis' ? ' riwisIndent' : '') +
          ' pb-3'
        }
      >
        <QuestionSelector
          question={question}
          value={value}
          previousAnswer={previousAnswer}
          label={label}
          handleAnswerChange={handleAnswerChange}
          pageIndex={pageIndex}
        />
      </div>
      {showDefinitionModal && (
        <Modal onHide={setShowDefinitionModal} maxWidth={'50rem'} marginX={'2rem'}>
          {/* <h3 className="mb-5">Definition</h3> */}
          <div
            style={{ fontSize: '1rem', maxWidth: '50rem' }}
            dangerouslySetInnerHTML={{ __html: question.definition }}
          ></div>
        </Modal>
      )}
    </>
  )
}
