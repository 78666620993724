import { useEffect, useState } from 'react'
import { useReportingDataStore } from './dataStore'
import { useSurveyAdminDataStore } from '../Survey/dataStore'
import { Container } from '../../../components/Container'
import { Link, Redirect } from 'react-router-dom'
import { Dropdown, Button, ButtonGroup, Nav } from 'react-bootstrap'
import { Switch, Route, useLocation } from 'react-router-dom'
import { Feedback } from './Feedback'
import { Results } from './Results'
import { Statistics } from './Statistics'
import { Monitor } from './Monitor'
export const Reporting = () => {
  const [{ surveyList, dataIsLoading }, { fetchSurveyList }] = useSurveyAdminDataStore()
  const [{ report }, { fetchReport }] = useReportingDataStore()
  const [surveyId, setSurveyId] = useState(null)

  const splitRoute = useLocation().pathname.split('/')
  const activeTab = splitRoute[splitRoute.length - 1]

  useEffect(() => {
    fetchSurveyList()
  }, [fetchSurveyList])

  useEffect(() => {
    if (surveyId) {
      fetchReport(surveyId)
    }
  }, [surveyId, fetchReport])

  return (
    <Container>
      {dataIsLoading === false && (
        <>
          <div className="d-flex flex-row justify-content-start align-items-center">
            <div>
              <h3>Auswertung für</h3>
            </div>
            <div className="ml-3">
              <Dropdown as={ButtonGroup}>
                <Button variant="outline-primary">{report ? report.currentSurvey : '...bitte wählen'}</Button>

                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                <Dropdown.Menu align="left" style={{ height: '400px', overflowY: 'auto' }}>
                  {surveyList.map((survey) => (
                    <Dropdown.Item onClick={() => setSurveyId(survey.id)} key={survey.id}>
                      {survey.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <hr />
          <Nav variant="pills" className="mt-5" defaultActiveKey="/admin/reporting/feedback">
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={activeTab === 'feedback'}>
                <Link
                  style={{ color: activeTab === 'feedback' ? 'white' : 'black' }}
                  to="/admin/reporting/feedback"
                >
                  Rückmeldung
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={activeTab === 'results'}>
                <Link
                  style={{ color: activeTab === 'results' ? 'white' : 'black' }}
                  to="/admin/reporting/results"
                >
                  Ergebnisse
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={activeTab === 'statistics'} disabled={true}>
                <Link
                  style={{ color: activeTab === 'statistics' ? 'white' : 'lightgrey' }}
                  to="/admin/reporting/statistics"
                >
                  Statistik
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={'div'} className="rounded-0" active={activeTab === 'monitor'}>
                <Link
                  style={{ color: activeTab === 'monitor' ? 'white' : 'black' }}
                  to="/admin/reporting/monitor"
                >
                  Monitor
                </Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr className="bg-primary m-0 mb-3 p-0" />
          <Switch>
            <Route exact path="/admin/reporting">
              <Redirect to="/admin/reporting/feedback" />
            </Route>
            <Route path="/admin/reporting/feedback" component={Feedback} />
            <Route path="/admin/reporting/results" component={Results} />
            <Route path="/admin/reporting/statistics" component={Statistics} />
            <Route path="/admin/reporting/monitor" component={Monitor} />
          </Switch>
        </>
      )}
    </Container>
  )
}
