import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

const displayNumberValue = (question, field, defaultValue) => {
  if (question.with_decimal) {
    defaultValue = 0.1
    return question.hasOwnProperty(field) ? String(question[field]).replace('.', ',') : defaultValue
  }
  return question.hasOwnProperty(field) ? String(parseInt(question[field])) : defaultValue
}

export const Step = ({ question, pageIndex, questionnaireIndex, index }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const handleNumberInput = useCallback(
    (event, question) => {
      const pattern = question.with_decimal ? /^\d+,{0,1}\d{0,1}$/ : /^\d+$/
      if (event.target.value !== '' && event.target.value.match(pattern) === null) {
        return 0
      }
      const value = event.target.value.replace(',', '.')
      setQuestionInput(questionnaireIndex, pageIndex, index, value, event.target.name)
    },
    [pageIndex, questionnaireIndex, setQuestionInput, index]
  )

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">Schrittweite</label>
      <div className="col-sm-10">
        <input
          className="form-control"
          type="text"
          name="step"
          onChange={(event) => handleNumberInput(event, question)}
          value={displayNumberValue(question, 'step', 1)}
        ></input>
      </div>
    </div>
  )
}
