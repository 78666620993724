import { useEffect, useCallback } from 'react'
import { Container } from '../../../components/Container'
import { Link, useParams } from 'react-router-dom'
import { useUserGroupsAdminDataStore } from './dataStore'
import { AlertBox } from '../../../components/AlertBox'
import XLSX from 'xlsx'
import { useState } from 'react'
import { Button, Dropdown, DropdownButton, Modal, Spinner } from 'react-bootstrap'
import { UserSelectBox } from '../../../components/UserSelectBox'
import { UserGroup as UserGroupApi } from '../../../helper/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusSquare } from '@fortawesome/free-regular-svg-icons'

const fileToTable = (fileAsArrayBuffer) => {
  const workbook = XLSX.read(fileAsArrayBuffer, { type: 'array' })
  const worksheet = workbook.Sheets['Upload'] || null
  return worksheet
}

export const UserGroup = () => {
  const [
    { dataIsLoading, userGroup, saveMessage },
    { fetchUserGroup, saveUserGroup, setSaveMessage, editUserGroup, deleteUsers },
  ] = useUserGroupsAdminDataStore()
  const [showWorksheetError, setShowWorksheetError] = useState(false)
  const [show, setShow] = useState(false)
  const [usersToAdd, setUsersToAdd] = useState([])
  const { userGroupId } = useParams()
  const [usersFetching, setUsersFetching] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])

  const showModal = () => setShow(true)
  const hideModal = () => setShow(false)

  const handleInputChange = (event) => {
    editUserGroup(event.target.value, event.target.name)
  }

  const handleCancelAddToUserList = () => {
    setUsersToAdd([])
    hideModal()
  }

  const handleUserGroupSave = () => {
    setUsersFetching(true)
    saveUserGroup(userGroup, userGroup.id)
  }

  const handleDeleteUsers = () => {
    deleteUsers(userGroup.id, selectedUsers)
  }

  const handleAddUser = useCallback(
    (user) => {
      const newUser = user
      newUser.new = true
      setUsersToAdd([...usersToAdd, newUser])
    },
    [usersToAdd, setUsersToAdd]
  )

  const handleAddToUserList = useCallback(() => {
    const newUserList = usersToAdd.reduce(
      (arr, newUser) => {
        const found = arr.some((user) => newUser.id === user.id)
        if (!found) {
          newUser.new = true
          arr.push(newUser)
        }
        return arr
      },
      [...userGroup.user_list]
    )
    hideModal()
    editUserGroup(newUserList, 'user_list')
  }, [editUserGroup, userGroup, usersToAdd])

  const handleCheckbox = useCallback(
    (event, userId, all) => {
      let newSelectedUsers = [...selectedUsers]
      if (event.target.checked) {
        if (all) {
          newSelectedUsers = userGroup.user_list.map((user) => user.id)
        } else {
          newSelectedUsers.push(userId)
        }
      } else {
        if (all) {
          newSelectedUsers = []
        } else {
          const index = newSelectedUsers.indexOf(userId)
          newSelectedUsers.splice(index, 1)
        }
      }
      setSelectedUsers(newSelectedUsers)
    },
    [selectedUsers, userGroup]
  )

  const handleFileChange = useCallback(
    (event) => {
      if (!event.target.files[0]) return
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = function () {
        const worksheet = fileToTable(reader.result)
        if (!worksheet) {
          setShowWorksheetError(true)
        } else {
          setShowWorksheetError(false)
          const excelUsers = XLSX.utils.sheet_to_json(worksheet)
          setUsersFetching(true)
          UserGroupApi.uploadUsers(excelUsers).then((res) => {
            setUsersFetching(false)
            const newUsers = res.data
            const newUserList = newUsers.reduce(
              (arr, newUser) => {
                const found = arr.find((user) => newUser.id === user.id && newUser.username === user.username)
                if (!found) {
                  newUser.new = true
                  arr.push(newUser)
                } else {
                  // update gender
                  if (!found.gender) {
                    found.gender = newUser.gender
                    found.genderAdded = true
                  }
                  // update company
                  if (!found.company) {
                    found.company = newUser.company
                    found.companyAdded = true
                  }
                  // update topix id
                  if (!found.topix_id) {
                    found.topix_id = newUser.topix_id
                    found.topixIdAdded = true
                  }
                }
                return arr
              },
              [...userGroup.user_list]
            )
            editUserGroup(newUserList, 'user_list')
          })
        }
      }
    },
    [editUserGroup, userGroup]
  )

  useEffect(() => {
    fetchUserGroup(userGroupId)
  }, [fetchUserGroup, userGroupId])

  useEffect(() => {
    if (saveMessage.type) {
      setUsersFetching(false)
      setTimeout(() => {
        setSaveMessage({ msg: '', error: '' })
      }, 5000)
    }
  }, [saveMessage, setSaveMessage])

  return (
    <Container>
      {userGroup && (
        <>
          <div className="sticky-top pt-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <h2 className="mr-5">Benutzer-Gruppe bearbeiten</h2>
                <Button variant="primary" onClick={handleUserGroupSave}>
                  Speichern
                </Button>
              </div>
              <DropdownButton alignRight={true} variant="outline-primary" title="Optionen">
                <Dropdown.Item onClick={handleDeleteUsers}>Auswahl löschen</Dropdown.Item>
              </DropdownButton>
              <AlertBox type={saveMessage.type} message={saveMessage.msg} error={saveMessage.error} />
            </div>
          </div>
          <hr className="mb-5" />
          <div className="form-group row align-items-center">
            <input type="hidden" id="userGroupUserList" name="user_list" value={userGroup.user_list} />
            <label htmlFor="userGroupLabel" className="col-sm-1 h5">
              Label
            </label>
            <div className="col">
              <input
                type="text"
                name="label"
                id="userGroupLabel"
                className="form-control"
                onChange={handleInputChange}
                value={userGroup.label}
              />
            </div>
          </div>
          <div className="row align-items-start mt-5">
            <div className="col-3">
              <h4>User in der Gruppe</h4>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="custom-file">
                    <input
                      type="file"
                      id="customFile"
                      onChange={handleFileChange}
                      className="custom-file-input"
                    />
                    <label htmlFor="customFile" className="custom-file-label">
                      ...aus Excel-Datei laden
                    </label>
                    {showWorksheetError === true && (
                      <small className="form-text text-dagner">
                        Datei muss ein Sheet <i>Upload</i> haben!
                      </small>
                    )}
                    <small className="text-muted">
                      Spalten die gelesen werden:{' '}
                      <b>
                        Vorname | Nachname | Benutzername | Firma | Strasse | PLZ | Ort | Tel | Mobil | E-Mail
                        | Geschlecht {'(M|W)'} | Topix ID
                      </b>
                    </small>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col">
                  <Button variant="primary" onClick={showModal}>
                    ...vorhandenen User hinzufügen
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <table className="table table-sm userGroup">
            <thead className="bg-primary text-light">
              <tr>
                <th scope="col">
                  <input type="checkbox" onClick={(event) => handleCheckbox(event, null, true)} />
                </th>
                <th scope="col"></th>
                <th scope="col">#</th>
                <th scope="col">ID</th>
                <th scope="col">Benutzername</th>
                <th scope="col">Vorname</th>
                <th scope="col">Nachname</th>
                <th scope="col">Firma</th>
                <th scope="col">Geschlecht</th>
                <th>Topix ID</th>
              </tr>
            </thead>
            <tbody>
              {!usersFetching && !dataIsLoading ? (
                userGroup.user_list?.map((user, index) => {
                  const selected = selectedUsers.some((id) => user.id === id)
                  return (
                    user !== 0 && (
                      <tr key={`${user.id}-${user.username}`} className={selected ? 'selected' : ''}>
                        <td valign="middle">
                          <input
                            type="checkbox"
                            checked={selected}
                            onChange={(event) => handleCheckbox(event, user.id)}
                          />
                        </td>
                        <td>
                          {user.new ? (
                            <FontAwesomeIcon icon={faPlusSquare} className="text-primary" />
                          ) : (
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                          )}
                        </td>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/admin/user/${user.id}`}>{user.id}</Link>
                        </td>
                        <td>{user.username}</td>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.company}</td>
                        <td>{user.gender}</td>
                        <td>{user.topix_id}</td>
                      </tr>
                    )
                  )
                })
              ) : (
                <tr>
                  <td colSpan="10" align="middle" className="h2 text-muted">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Modal show={show} onHide={hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Benutzer suchen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UserSelectBox showContactDataBtn={false} changeHandler={handleAddUser} />
              <ul>
                {usersToAdd.map((user) => (
                  <li key={user.id}>{user.username}</li>
                ))}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancelAddToUserList}>
                Abbrechen
              </Button>
              <Button variant="primary" onClick={handleAddToUserList}>
                Hinzufügen
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Container>
  )
}
