import Desktop from '../../../../assets/icons/Desktop.svg'
import Parcel from '../../../../assets/icons/Parcel.svg'
import ShoppingBag from '../../../../assets/icons/ShoppingBag.svg'
import Sofa from '../../../../assets/icons/Sofa.svg'

export const AreaEnum = {
  md_residential_market: 'residentialArea',
  md_office_market: 'officeArea',
  md_retail_market: 'retailArea',
  md_logistics_market: 'logistics',
}

export const TopicToQualityEnum = {
  rent_city: [10],
  rent_city_fringe: [20],
  rent_office_agglomeration: [30],
  rent_suburb: [40, 45],
}

export const FactorEnum = {
  md_office_market: 1.18,
  md_retail_market: 1.19,
  md_logistics_market: 1.16,
}

export const IconEnum = {
  office_market: Desktop,
  residential_market: Sofa,
  logistics_market: Parcel,
  retail_market: ShoppingBag,
}

export const TextEnum = {
  office_market: 'Büro',
  residential_market: 'Wohnen',
  logistics_market: 'Industrie',
  retail_market: 'Handel',
}

export const wohnlagen = {
  101: '#FF8C85',
  101.5: '#FFD1AD',
  102: '#FFFAC2',
  102.5: '#E6F5FE',
  103: '#B9E6F9',
  103.5: '#94D9F8',
  104: '#4E91C6',
}

export const buero_lagen = {
  10: '#FB3232',
  20: '#FFD0A1',
  30: '#FFCF40',
  40: '#FFEDA7',
  45: '#FFFFFF',
}

export const eh_lagen = {
  10: '#6699CC',
  20: '#FF3333',
  30: '#FF9933',
  40: '#660066',
  50: '#807DBA',
  90: '#FFD0A1',
}

export const eh_lagen_name = {
  20: '1A-Lage',
  30: '1B-Lage',
}

export const calculateMultiplier = (value, category) => {
  const factor = FactorEnum[category]
  return parseFloat(((1 / (value * factor)) * 100).toFixed(1))
}

export const numberSeparator = (x, decimalPlaces = 0, shouldHaveDecimal = true) => {
  if (typeof x === 'string') {
    if (x.match('.')) {
      x = parseFloat(x)
    } else {
      x = parseInt(x)
    }
  }
  // check if incoming number already has decimal places
  if (x.toString().split('.').length === 2) {
    if (shouldHaveDecimal) {
      decimalPlaces = 1
    }
  }
  let string = x.toFixed(decimalPlaces).toString()
  const [preDecimal, decimal] = string.split('.')
  return `${preDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${decimal ? ',' + decimal : ''}`
}

export const nthroot = (x, n) => {
  try {
    // eslint-disable-next-line
    let negate = n % 2 == 1 && x < 0
    if (negate) x = -x
    let possible = Math.pow(x, 1 / n)
    n = Math.pow(possible, n)
    // eslint-disable-next-line
    if (Math.abs(x - n) < 1 && x > 0 == n > 0) return negate ? -possible : possible
  } catch (e) {}
}
