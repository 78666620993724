import React, { useCallback, useMemo, useState } from 'react'

export const RadioInline = ({ id, qid, answers, previousAnswer, noAnswer, value = null, onChange }) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)
  const handleChange = useCallback(
    (event) => {
      setLocalPreviousAnswer(null)
      onChange({ question_id: event.target.name, value: event.target.value, qid })
    },
    [onChange, qid]
  )

  const preparedAnswers = useMemo(() => {
    const preparedAnswers = answers.map((answer) => {
      let checked = false
      if (value) {
        checked = parseInt(value) === answer.id
      } else if (!value && localPreviousAnswer) {
        checked = parseInt(localPreviousAnswer) === answer.id
      }
      const elementId = id + '_' + answer.id

      return {
        checked,
        elementId,
        ...answer,
      }
    })

    if (noAnswer && noAnswer.showNoAnswer) {
      let checked = false
      if (value) {
        checked = parseInt(value) === noAnswer.id
      } else if (!value && localPreviousAnswer) {
        checked = parseInt(localPreviousAnswer) === noAnswer.id
      } else {
        checked = false
      }

      const elementId = id + '_' + noAnswer.id

      preparedAnswers.push({
        checked,
        elementId,
        text: noAnswer.title,
        id: noAnswer.id,
        type: 'noAnswer',
      })
    }
    return preparedAnswers
  }, [answers, id, value, noAnswer, localPreviousAnswer])

  return preparedAnswers.map((answer) => (
    <div className="custom-control custom-radio custom-control-inline" key={answer.elementId}>
      <input
        className="custom-control-input"
        type="radio"
        name={id}
        id={answer.elementId}
        value={answer.id}
        onChange={handleChange}
        checked={answer.checked}
      ></input>
      <label
        className="custom-control-label radioLabel"
        htmlFor={answer.elementId}
        dangerouslySetInnerHTML={{ __html: answer.text }}
      />
    </div>
  ))
}

export class RadioInlineOld extends React.Component {
  handleChange = (event) => {
    this.props.onChange({ question_id: event.target.name, value: event.target.value, qid: this.props.qid })
  }

  render() {
    const radios = this.props.answers.map((answer) => {
      const checked = parseInt(this.props.value) === answer.id

      const elementId = this.props.id + '_' + answer.id

      return (
        <div className="custom-control custom-radio custom-control-inline" key={elementId}>
          <input
            className="custom-control-input"
            type="radio"
            name={this.props.id}
            id={elementId}
            value={answer.id}
            onChange={this.handleChange}
            checked={checked}
          ></input>
          <label
            className="custom-control-label radioLabel"
            htmlFor={elementId}
            dangerouslySetInnerHTML={{ __html: answer.text }}
          />
        </div>
      )
    })

    return radios
  }
}
