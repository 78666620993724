import React, { useCallback, useState } from 'react'
import { TinyMCE } from '../Survey/edit/TinyMCE'
import { useTokenAdminDataStore } from './dataStore'
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap'
import { Modal } from '../../../components/Modal'
import { useUserDataStore } from '../../../stores/userStore'
const toolbar =
  'undo redo | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link | code'

const salutationPlaceholder = {
  buttonName: 'salutationPlaceholder',
  pre: '|',
  text: 'Anrede',
  onAction: (editor) =>
    editor.insertContent(
      '{anrede: m="Sehr geehrter Herr" w="Sehr geehrte Frau" d="Sehr geehrte/geehrter Frau/Herr" default="Sehr geehrte/geehrter Frau/Herr"} {nachname},'
    ),
}

const tokenLinkPlaceholder = {
  buttonName: 'tokenLinkPlaceholder',
  pre: ' ',
  text: 'Token-Link',
  onAction: (editor) => editor.insertContent('{link}'),
}

const firstNamePlaceholder = {
  buttonName: 'firstNamePlaceholder',
  pre: ' ',
  text: 'Vorname',
  onAction: (editor) => editor.insertContent('{vorname}'),
}

const lastNamePlaceholder = {
  buttonName: 'lastNamePlaceholder',
  pre: ' ',
  text: 'Nachname',
  onAction: (editor) => editor.insertContent('{nachname}'),
}

// checks tokens for duplicate e-mails, missing user-informations, valid email etc. before sending mails
const checkTokens = (tokens, mail) => {
  const errors = {
    missingUserInformation: [],
    duplicateMailAddresses: [],
    invalidMails: [],
  }
  for (const token of tokens) {
    const user = token.userInfo
    const missingUserInfo = {
      first_name: false,
      last_name: false,
      gender: false,
      token,
    }
    let mailContainsGenderVar = false
    let mailContainsFirstnameVar = false
    let mailContainsLastnameVar = false

    if (mail && mail.mail_content && typeof mail.mail_content === 'string') {
      const mailContent = mail.mail_content
      if (mailContent.match(/{anrede:.*?}/) !== null) {
        mailContainsGenderVar = true
      }
      if (mailContent.match(/{vorname}/g) !== null) {
        mailContainsFirstnameVar = true
      }
      if (mailContent.match(/{nachname}/g) !== null) {
        mailContainsLastnameVar = true
      }
    }
    // check for missing user information
    if (!user?.first_name?.trim() && mailContainsFirstnameVar) {
      missingUserInfo.first_name = true
    }
    if (!user?.last_name?.trim() && mailContainsLastnameVar) {
      missingUserInfo.last_name = true
    }
    if (!user?.gender?.trim() && mailContainsGenderVar) {
      missingUserInfo.gender = true
    }
    if (
      Object.values(missingUserInfo).some((value) => value === true) &&
      !errors.missingUserInformation.some((missing) => missing.token.userInfo.username === user.username)
    ) {
      errors.missingUserInformation.push(missingUserInfo)
    }
    // check for duplicate mail addresses (happens if 2 or more tokens have been generated for one user)
    if (
      tokens.filter((t) => t.userInfo.username === user.username).length > 1 &&
      !errors.duplicateMailAddresses.some((dToken) => user.username === dToken.userInfo.username)
    ) {
      errors.duplicateMailAddresses.push(token)
    }
    // check if username is a valid email address
    if (
      !user.username.match('@') &&
      !errors.invalidMails.some((iToken) => iToken.userInfo.username === user.username)
    ) {
      errors.invalidMails.push(token)
    }
  }
  if (
    errors.missingUserInformation.length ||
    errors.invalidMails.length ||
    errors.duplicateMailAddresses.length
  ) {
    return errors
  }
  return null
}

export const Mail = ({ userGroupId, surveyId, selectedTokens }) => {
  const [{ mail, tokens }, { setMail, saveMail, sendMail }] = useTokenAdminDataStore()
  const [{ user }] = useUserDataStore()
  const [tempTokens, setTempTokens] = useState(null)
  const [errors, setErrors] = useState(null)
  const [showTestDelivery, setShowTestDelivery] = useState(false)
  const [testUser, setTestUser] = useState({
    id: 0,
    username: '',
    first_name: '',
    last_name: '',
    uuid: '00000000-0000-0000-0000-000000000000',
    gender: 'w',
  })

  const handleChange = ({ value }, property) => {
    const newMail = { ...mail }
    newMail[property] = value
    setMail(newMail)
  }
  const handleSave = () => {
    saveMail(userGroupId, surveyId, mail)
  }

  const hideModal = () => {
    setErrors(null)
  }

  const hideTestDeliveryModal = () => {
    setShowTestDelivery(false)
  }

  const showTestDeliveryModal = () => {
    setShowTestDelivery(true)
  }

  const handleInputChange = (event) => {
    const newTestUser = { ...testUser }
    newTestUser[event.target.name] = event.target.value
    setTestUser(newTestUser)
  }

  const handleSendSelected = useCallback(() => {
    const errors = checkTokens(selectedTokens, mail)
    if (errors) {
      setErrors(errors)
      setTempTokens(selectedTokens)
    } else {
      sendMail(userGroupId, surveyId, mail, selectedTokens)
    }
  }, [mail, selectedTokens, sendMail, surveyId, userGroupId])

  const handleSendAll = useCallback(() => {
    const allTokens = tokens.filter((token) => token.id !== 0)
    const errors = checkTokens(allTokens, mail)
    if (errors) {
      setErrors(errors)
      setTempTokens(allTokens)
    } else {
      sendMail(userGroupId, surveyId, mail, allTokens)
    }
  }, [mail, sendMail, surveyId, tokens, userGroupId])

  const handleSendUnsent = useCallback(() => {
    let unsentTokens = []
    if (mail?.token_ids?.length) {
      unsentTokens = tokens.filter((token) => {
        return !mail.token_ids.some((tokenId) => token.id === tokenId) && token.id !== 0
      })
    } else {
      unsentTokens = tokens.filter((token) => token.id !== 0)
    }
    const errors = checkTokens(unsentTokens, mail)
    if (errors) {
      setErrors(errors)
      setTempTokens(unsentTokens)
    } else {
      sendMail(userGroupId, surveyId, mail, unsentTokens)
    }
  }, [mail, sendMail, surveyId, tokens, userGroupId])

  const handleSendUnanswered = useCallback(() => {
    const unansweredTokens = tokens.filter(
      (token) => (token.answer_count === 0 || token.early_exit === true) && token.id !== 0
    )
    const errors = checkTokens(unansweredTokens, mail)
    if (errors) {
      setErrors(errors)
      setTempTokens(unansweredTokens)
    } else {
      sendMail(userGroupId, surveyId, mail, unansweredTokens)
    }
  }, [tokens, mail, surveyId, sendMail, userGroupId])

  const sendAnyway = useCallback(() => {
    sendMail(userGroupId, surveyId, mail, tempTokens)
    setTempTokens(null)
    hideModal()
  }, [mail, sendMail, surveyId, tempTokens, userGroupId])

  const sendTestMail = useCallback(() => {
    const testToken = {
      id: 0,
      uuid: testUser.uuid,
      userInfo: testUser,
    }
    sendMail(userGroupId, surveyId, mail, [testToken])
    hideTestDeliveryModal()
  }, [mail, sendMail, surveyId, testUser, userGroupId])

  return (
    <>
      <h5>Absender:</h5>
      <Form.Group>
        <Form.Control
          as="select"
          defaultValue={'survey-desk@bulwiengesa.de'}
          value={mail.from}
          onChange={(evt) => handleChange(evt.target, 'from')}
        >
          <option>survey-desk@bulwiengesa.de</option>
          {user?.mail_send_as &&
            user.mail_send_as.map((mail) => (
              <option key={mail} value={mail}>
                {mail}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
      <h5>Betreff:</h5>
      <input
        type="text"
        className="form-control my-2"
        id="subject"
        name="subject"
        style={{ borderRadius: 0 }}
        onChange={(event) => handleChange(event.target, 'subject')}
        value={mail.subject}
      ></input>
      <TinyMCE
        toolbar={toolbar}
        content={mail.mail_content}
        handleTinyMCEChange={(value) => handleChange(value, 'mail_content')}
        customButtons={[
          salutationPlaceholder,
          tokenLinkPlaceholder,
          firstNamePlaceholder,
          lastNamePlaceholder,
        ]}
      />
      <div
        className="d-flex justify-content-end align-items-center mt-4 mb-5 pb-4"
        style={{ borderBottom: '1px solid lightgray' }}
      >
        <Button className="mr-3" onClick={handleSave} disabled={!mail.mail_content.length}>
          Speichern
        </Button>
        <DropdownButton
          disabled={!mail.mail_content.length}
          variant="outline-primary"
          alignRight={true}
          id="dropdown-basic-button"
          title="E-Mail Versand"
        >
          <Dropdown.Item onClick={showTestDeliveryModal}>Testversand</Dropdown.Item>
          <Dropdown.Item disabled={!selectedTokens.length} onClick={handleSendSelected}>
            Ausgewählte
          </Dropdown.Item>
          <Dropdown.Item onClick={handleSendAll}>Alle</Dropdown.Item>
          <Dropdown.Item onClick={handleSendUnsent}>Unversendet</Dropdown.Item>
          <Dropdown.Item onClick={handleSendUnanswered}>Unbeantwortet</Dropdown.Item>
        </DropdownButton>
      </div>
      {errors !== null && (
        <Modal onHide={hideModal} maxWidth={'750px'} minWidth={'600px'}>
          <div>
            <div className="header">Benutzer-Überprüfung</div>
            <div className="body">
              <p>Bei folgenden Nutzern fehlen Informationen die für den E-Mail Text benötigt werden:</p>
              {errors.missingUserInformation.length > 0 && (
                <div>
                  <div className="font-weight-bold">Fehlende Benutzer-Informationen:</div>
                  <ul>
                    {errors.missingUserInformation.map((error) => (
                      <li key={error.token.id}>
                        {error.token.userInfo.username}:{' '}
                        {error.first_name === true
                          ? error.last_name === true
                            ? 'Vorname, '
                            : 'Vorname'
                          : ''}
                        {error.last_name === true ? (error.gender === true ? 'Nachname, ' : 'Nachname') : ''}
                        {error.gender === true && 'Geschlecht'}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {errors.duplicateMailAddresses.length > 0 && (
                <div>
                  <div className="font-weight-bold">E-Mail ist mehrmals im Versand:</div>
                  <ul>
                    {errors.duplicateMailAddresses.map((token) => (
                      <li key={token.id}>{token.userInfo.username}</li>
                    ))}
                  </ul>
                </div>
              )}

              {errors.invalidMails.length > 0 && (
                <div>
                  <div className="font-weight-bold">Keine gültige E-Mail Adresse:</div>
                  <ul>
                    {errors.invalidMails.map((token) => (
                      <li key={token.id}>{token.userInfo.username}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="footer justify-content-between">
              <Button variant="primary" onClick={hideModal}>
                Abbrechen
              </Button>
              <Button variant="danger" onClick={sendAnyway}>
                Trotzdem versenden
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showTestDelivery === true && (
        <Modal onHide={hideTestDeliveryModal} minWidth={'650px'}>
          <div>
            <div className="header">Testversand</div>
            <div className="body">
              <div className="form-group row col-sm-11">
                <label className="col-sm-2 form-group-label text-right">E-Mail:</label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="text"
                    name="username"
                    onChange={(event) => handleInputChange(event)}
                    value={testUser.username}
                  ></input>
                </div>
              </div>

              <div className="form-group row col-sm-11">
                <label className="col-sm-2 form-group-label text-right">Vorname:</label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="text"
                    name="first_name"
                    onChange={(event) => handleInputChange(event)}
                    value={testUser.first_name}
                  ></input>
                </div>
              </div>

              <div className="form-group row col-sm-11">
                <label className="col-sm-2 form-group-label text-right">Nachname:</label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="text"
                    name="last_name"
                    onChange={(event) => handleInputChange(event)}
                    value={testUser.last_name}
                  ></input>
                </div>
              </div>

              <div className="form-group row col-sm-11">
                <label className="col-sm-2 form-group-label text-right">Geschlecht:</label>
                <div className="col-sm-4">
                  <select
                    name="gender"
                    value={testUser.gender}
                    onChange={(event) => handleInputChange(event)}
                    className="form-control"
                  >
                    <option value="w">weiblich</option>
                    <option value="m">männlich</option>
                    <option value="d">divers</option>
                  </select>
                </div>
              </div>

              <div className="form-group row col-sm-11">
                <label className="col-sm-2 form-group-label text-right">UUID:</label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="text"
                    name="uuid"
                    onChange={(event) => handleInputChange(event)}
                    value={testUser.uuid}
                  ></input>
                </div>
              </div>
            </div>
            <div className="footer justify-content-between">
              <Button variant="secondary" onClick={hideTestDeliveryModal}>
                Abbrechen
              </Button>
              <Button onClick={sendTestMail}>Versenden</Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
