import { memo, useCallback, useMemo, useState } from 'react'
import { useReportingDataStore } from './dataStore'
import XLSX from 'xlsx'
import { TextEnum } from '../../Survey/QuestionTypes/RiwisSlider/mapHelper'
import { stripString, decodeSpecialChars } from '../Survey/edit/utils'
import { commentCategories } from '../Survey/edit/QuestionTypes/RiwisCategories'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { Cell, Column, ColumnGroup, HeaderCell, Table } from 'rsuite-table'
import 'rsuite-table/dist/css/rsuite-table.css'

const userInfosCols = [
  'topix_id',
  'username',
  'is_riwis_user',
  'first_name',
  'last_name',
  'company',
  'generic_area_code',
]

const findCommentHeader = (fileMakerName) => {
  const commentHeaders = Object.entries(commentCategories)
  for (let [commentHeader, values] of commentHeaders) {
    if (values.some((value) => value === fileMakerName)) {
      return commentHeader
    }
  }
}

const getFilemakerNames = (questions) => {
  const names = []
  questions.forEach((question) => {
    question.riwis_datasources.forEach((datasource) => {
      const commentHeaders = Object.entries(commentCategories)
      let commentHeader = null
      commentHeaders.forEach(([header, value]) => {
        if (value[value.length - 1] === datasource.fileMaker) {
          commentHeader = header
        }
      })
      names.push(datasource.fileMaker)
      if (commentHeader !== null) {
        names.push(commentHeader)
      }
    })
  })
  return names
}

const answersOfUsers = (colHeaders, riwisReport, assetClass) => {
  const answers = []
  if (!riwisReport[assetClass]) {
    return answers
  }
  Object.values(riwisReport[assetClass]).forEach((gacGroup) => {
    Object.values(gacGroup).forEach((userAnswersOfGac) => {
      const row = []
      let notes = ''
      let currentCommentHeader = ''
      let edit_date = null
      let create_date = null
      const typeIndex = colHeaders.indexOf('typ')
      const uniqueId = `${userAnswersOfGac[0].userId}${userAnswersOfGac[0].questionnaire_id}${userAnswersOfGac[0].generic_area_code}`
      const uniqueIdIndex = colHeaders.indexOf('id')
      row[uniqueIdIndex] = uniqueId
      row[typeIndex] = TextEnum[assetClass]
      userAnswersOfGac.forEach((userAnswer) => {
        const answer_create_date = new Date(userAnswer.create_date).getTime()
        const answer_updated_at = userAnswer.updated_at ? new Date(userAnswer.updated_at).getTime() : null
        if (create_date === null || create_date > answer_create_date) {
          const index = colHeaders.indexOf('Erstellungsdatum')
          create_date = answer_create_date
          row[index] = new Date(create_date)
        }
        if (
          (edit_date === null && answer_updated_at !== null) ||
          edit_date < answer_create_date ||
          edit_date < answer_updated_at
        ) {
          const index = colHeaders.indexOf('Bearbeitungsdatum')
          if ((edit_date === null && answer_updated_at !== null) || edit_date < answer_updated_at) {
            edit_date = answer_updated_at
            row[index] = new Date(edit_date)
          } else if (edit_date !== null && edit_date !== answer_create_date) {
            edit_date = answer_create_date
            row[index] = new Date(edit_date)
          }
        }
        userInfosCols.forEach((userInfo) => {
          const index = colHeaders.indexOf(userInfo)
          row[index] = userAnswer[userInfo]
        })
        if (userAnswer.questionType === 'radioInlineRiwis') {
          const index = colHeaders.indexOf(userAnswer.value.value.fileMaker)
          const answer = userAnswer.possible_answers.find(
            (answer) => answer.id === parseInt(userAnswer.value.value.value)
          )
          if (answer) {
            row[index] = answer.text
          }
          if (userAnswer.value.value.note) {
            const commentHeader = findCommentHeader(userAnswer.riwis_datasources[0].fileMaker)
            const index = colHeaders.indexOf(commentHeader)
            row[index] = row[index] || ''
            row[index] += decodeSpecialChars(stripString(userAnswer.label)) + ': '
            row[index] += userAnswer.value.value.note
            row[index] = row[index].replace(/\n/g, ' ')
          }
        } else if (userAnswer.questionType === 'radioMatrixRiwis') {
          const index = colHeaders.indexOf(userAnswer.value.value.fileMaker)
          const [yId, xId] = Object.entries(userAnswer.value.value)[0]
          const y = userAnswer.questions_field.findIndex((question) => question.id === parseInt(yId)) + 1
          const x = userAnswer.possible_answers.findIndex((answer) => answer.id === parseInt(xId)) + 1
          // if values have been found
          if (x > 0 && y > 0) {
            row[index] = `(${x},${y})`
          }
          if (userAnswer.value.value.note) {
            const commentHeader = findCommentHeader(userAnswer.riwis_datasources[0].fileMaker)
            const index = colHeaders.indexOf(commentHeader)
            row[index] = row[index] || ''
            row[index] += decodeSpecialChars(stripString(userAnswer.label)) + ': '
            row[index] += userAnswer.value.value.note
            row[index] = row[index].replace(/\n/g, ' ')
          }
        } else {
          Object.entries(userAnswer.value.value).forEach(([key, value]) => {
            if (key === 'note') {
              const commentHeader = findCommentHeader(userAnswer.riwis_datasources[0].fileMaker)
              if (currentCommentHeader !== commentHeader) {
                currentCommentHeader = commentHeader
                notes = ''
              }
              if (!commentHeader) {
                console.error('No header found for', userAnswer.riwis_datasources[0].fileMaker)
              }
              const index = colHeaders.indexOf(findCommentHeader(userAnswer.riwis_datasources[0].fileMaker))
              notes += decodeSpecialChars(stripString(userAnswer.label)) + ': '
              notes += value
              row[index] = notes.replace(/\n/g, ' ')
            } else {
              const index = colHeaders.indexOf(value.fileMaker)
              row[index] = value.userValue
            }
          })
        }
      })
      answers.push(row)
    })
  })
  return answers
}

export const Results = () => {
  const [{ report }] = useReportingDataStore()
  const [resolveAnswerIds, setResolveAnswerIds] = useState(false)

  const riwisReport = useMemo(() => {
    if (!report || report.type !== 'riwis') return null
    const groupByAssetClassAndGac = report.answers.reduce((obj, answer) => {
      if (
        obj.hasOwnProperty(answer.asset_class) &&
        obj[answer.asset_class].hasOwnProperty(answer.generic_area_code) &&
        obj[answer.asset_class][answer.generic_area_code].hasOwnProperty(answer.username)
      ) {
        obj[answer.asset_class][answer.generic_area_code][answer.username].push(answer)
      } else {
        if (obj.hasOwnProperty(answer.asset_class)) {
          if (obj[answer.asset_class].hasOwnProperty(answer.generic_area_code)) {
            if (obj[answer.asset_class][answer.generic_area_code].hasOwnProperty(answer.username)) {
              obj[answer.asset_class][answer.generic_area_code][answer.username].push(answer)
            } else {
              // asset class and gac exists, user missing
              obj[answer.asset_class][answer.generic_area_code][answer.username] = [answer]
            }
          } else {
            // asset class exists, gac and user missing
            obj[answer.asset_class][answer.generic_area_code] = {}
            obj[answer.asset_class][answer.generic_area_code][answer.username] = [answer]
          }
        } else {
          // asset class does not exist yet
          obj[answer.asset_class] = {}
          obj[answer.asset_class][answer.generic_area_code] = {}
          obj[answer.asset_class][answer.generic_area_code][answer.username] = [answer]
        }
      }
      return obj
    }, {})
    return groupByAssetClassAndGac
  }, [report])

  const generateMarketDataXLSX = useCallback(() => {
    if (report) {
      const questionGroups = report.questions.reduce((obj, question) => {
        if (Object.prototype.hasOwnProperty.call(obj, question.asset_class)) {
          obj[question.asset_class].push(question)
        } else {
          obj[question.asset_class] = [question]
        }
        return obj
      }, {})
      const workbook = XLSX.utils.book_new()
      workbook.Props = {
        Title: report.currentSurvey,
        CreatedDate: new Date(),
      }

      Object.entries(TextEnum).forEach(([key, value]) => {
        const colHeaders = [
          'id',
          'Erstellungsdatum',
          'Bearbeitungsdatum',
          'typ',
          ...userInfosCols,
          ...getFilemakerNames(questionGroups[key]),
        ]
        const answers = answersOfUsers(colHeaders, riwisReport, key)
        const ws = XLSX.utils.aoa_to_sheet([colHeaders, ...answers])
        const index = workbook.SheetNames.push(value)
        workbook.Sheets[workbook.SheetNames[index - 1]] = ws
      })
      XLSX.writeFile(workbook, 'results_' + report.currentSurvey + '.xlsx')
    }
  }, [report, riwisReport])

  const generateXLSX = useCallback(() => {
    if (report) {
      const workbook = XLSX.utils.book_new()
      workbook.Props = {
        Title: report.currentSurvey,
        CreatedDate: new Date(),
      }
      // const answers = answersOfUsers(colHeaders, riwisReport, key)
      const newAnswers = report.answers.map((answersOfUser) => {
        const resolvedAnswersOfUser = {
          userId: answersOfUser.userId,
          'E-Mail': answersOfUser.username,
          Erstellungsdatum: new Date(answersOfUser.createDate),
          Bearbeitungsdatum: new Date(answersOfUser.updatedAt),
        }
        report.questions.forEach((question, qIndex) => {
          const questionTitle = stripString(question.question) || question.question_id + ' - kein Titel'
          const answer = { ...answersOfUser[question.question_qid] }
          // add resolved question to answer
          if (answer?.value) {
            // answersOfUser[question.question_id] = null
            if (question.questions_field.length) {
              question.questions_field.forEach((questionField, qfIndex) => {
                resolvedAnswersOfUser[
                  `Frage${qIndex + 1}_${qfIndex + 1}-${questionField.text}-${questionTitle}`
                ] = getValueByQuestionType(
                  answer.value,
                  answer.questionType,
                  questionField.id,
                  question.possible_answers,
                  questionField,
                  resolveAnswerIds
                )
              })
            } else {
              resolvedAnswersOfUser[`Frage${qIndex + 1}-${questionTitle}`] = getValueByQuestionType(
                answer.value,
                answer.questionType,
                null,
                question.possible_answers,
                [],
                resolveAnswerIds
              )
            }
          } else {
            resolvedAnswersOfUser[`Frage${qIndex + 1}-${questionTitle}`] = ''
          }
        })
        return resolvedAnswersOfUser
      })

      const newHeader = ['userId', 'E-Mail', 'Erstellungsdatum', 'Bearbeitungsdatum']

      const ws = XLSX.utils.json_to_sheet(newAnswers, {
        header: [...newHeader],
      })
      XLSX.utils.book_append_sheet(workbook, ws, 'Data')
      XLSX.writeFile(workbook, 'results_' + report.currentSurvey + '.xlsx')
    }
  }, [report, resolveAnswerIds])

  return (
    report &&
    (report.type === 'riwis' ? (
      <div className="d-flex justify-content-center mt-5">
        <button className="btn btn-primary" onClick={generateMarketDataXLSX}>
          <div className="d-flex flex-column">
            <FontAwesomeIcon icon={faFileExcel} className="h3 m-0" style={{ fontSize: '3rem' }} />
            <div style={{ fontSize: '1rem' }} className="mt-3">
              Excel Report
            </div>
          </div>
        </button>
      </div>
    ) : (
      <div>
        <button className="btn btn-outline-primary" onClick={generateXLSX}>
          <FontAwesomeIcon icon={faFileExcel} className="h3 m-0" />
        </button>
        <div className="custom-control custom-checkbox mb-2 mt-5">
          <input
            className="custom-control-input"
            type="checkbox"
            name={'resolveAnswerIds'}
            id={'resolveAnswerIds'}
            onChange={(e) => setResolveAnswerIds(e.target.checked)}
            checked={resolveAnswerIds}
          ></input>
          <label
            className="custom-control-label"
            // style={{ fontWeight: 'normal', fontSize: '14px' }}
            htmlFor={'resolveAnswerIds'}
          >
            Zeige Antworten anstelle von IDs
          </label>
        </div>
        <TableView report={report} resolveAnswerIds={resolveAnswerIds} />
      </div>
    ))
  )
}

const TableView = memo(({ report, resolveAnswerIds }) => {
  return (
    <Table bordered cellBordered height={420} data={report.answers} headerHeight={75} virtualized>
      <Column align="center" fullText>
        <HeaderCell>id</HeaderCell>
        <Cell dataKey="userId" />
      </Column>
      <Column align="center" fullText>
        <HeaderCell>E-Mail</HeaderCell>
        <Cell dataKey="username" />
      </Column>
      <Column align="center" width={150} fullText>
        <HeaderCell>Datum</HeaderCell>
        <RenderDateCell dataKey="createDate" />
      </Column>
      {report.questions.map((question) => {
        if (question.questions_field?.length) {
          return (
            <ColumnGroup
              align="center"
              width={10}
              key={question.question_id}
              header={stripString(question.question)}
              fullText
            >
              {question.questions_field.map((questionField) => (
                <Column align="center" key={`${question.question_id}_${questionField.id}`} fullText>
                  <HeaderCell>{questionField.text}</HeaderCell>
                  <RenderCell
                    subId={questionField.id}
                    dataKey={question.question_qid.toString()}
                    possibleAnswers={question.possible_answers}
                    resolveAnswerIds={resolveAnswerIds}
                    questionField={questionField}
                  />
                </Column>
              ))}
            </ColumnGroup>
          )
        }
        return (
          <Column align="center" key={question.question_id} fullText>
            <HeaderCell>{stripString(question.question)}</HeaderCell>
            <RenderCell
              dataKey={question.question_qid.toString()}
              possibleAnswers={question.possible_answers}
              resolveAnswerIds={resolveAnswerIds}
            />
          </Column>
        )
      })}
    </Table>
  )
})

const RenderDateCell = ({ rowData, dataKey, ...props }) => {
  if (!rowData[dataKey]) {
    return <Cell {...props}></Cell>
  }
  const date = new Date(rowData[dataKey])
  return <Cell {...props}>{date.toLocaleString('de-DE')}</Cell>
}

const RenderCell = memo(
  ({ rowData, dataKey, subId, possibleAnswers, resolveAnswerIds, questionField, ...props }) => {
    if (!rowData[dataKey]) {
      return <Cell {...props}></Cell>
    }
    const { questionType, value } = rowData[dataKey]

    const displayValue = getValueByQuestionType(
      value,
      questionType,
      subId,
      possibleAnswers,
      questionField,
      resolveAnswerIds
    )

    return <Cell {...props}>{displayValue}</Cell>
  }
)

const getValueByQuestionType = (
  value,
  questionType,
  subId,
  possibleAnswers,
  questionField,
  resolveAnswerIds
) => {
  let displayValue = ''
  const getTextOfAnswerId = (possibleAnswers, id) =>
    possibleAnswers.find((possibleAnswers) => Number(possibleAnswers.id) === Number(id))?.text ||
    'noTextFound'

  if (questionType === 'radio' || questionType === 'radioInline') {
    displayValue = value.value < 0 ? 'k.A.' : value.value
    if (resolveAnswerIds && Number(displayValue) >= 0) {
      displayValue = getTextOfAnswerId(possibleAnswers, value.value)
    }
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
  }

  if (questionType === 'checkbox') {
    displayValue = value?.value?.[0] < 0 ? 'k.A.' : value?.value?.sort((a, b) => a - b).join('|') || ''
    if (resolveAnswerIds && Number(displayValue[0]) >= 0) {
      displayValue = value?.value?.map((id) => getTextOfAnswerId(possibleAnswers, id)).join('|')
    }
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
  }

  if (questionType === 'checkboxMatrix') {
    displayValue =
      value?.value?.[subId]?.[0] < 0 ? 'k.A' : value?.value?.[subId]?.sort((a, b) => a - b).join('|') || ''
    if (resolveAnswerIds && Number(value?.value?.[subId]?.[0]) >= 0) {
      displayValue = value.value[subId]?.map((id) => getTextOfAnswerId(possibleAnswers, id)).join('|')
    }
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
  }

  if (questionType === 'radioMatrix') {
    displayValue = value?.value?.[subId] < 0 ? 'k.A.' : value?.value?.[subId]
    if (resolveAnswerIds && Number(value?.value[subId] >= 0)) {
      displayValue = getTextOfAnswerId(possibleAnswers, value.value[subId])
    }
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
  }

  if (questionType === 'text' || questionType === 'textarea') {
    displayValue = value?.value?.noAnswer
      ? 'k.A.'
      : value?.value?.text ?? value?.value?.text ?? value?.value ?? ''
  }

  if (questionType === 'inputColumns' || questionType === 'contactForm') {
    displayValue = value?.value?.noAnswer ? 'k.A.' : value?.value?.[subId]
  }
  if (questionType === 'slider') {
    displayValue = value?.value?.noAnswer ? 'k.A' : value?.value?.sliderValue ?? ''
    // fallback for old answers
    if (
      (typeof value?.value?.sliderValue === 'undefined' && value.value && typeof value.value === 'string') ||
      typeof value.value === 'number'
    ) {
      displayValue = value.value
    }
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
  }

  if (questionType === 'sum') {
    displayValue = value?.value?.noAnswer ? 'k.A' : value?.value[subId]
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
  }

  if (questionType === 'dropdown') {
    displayValue = value?.value?.noAnswer ? 'k.A' : value?.value[subId]
    displayValue = isNaN(Number(displayValue)) ? displayValue : Number(displayValue)
    if (resolveAnswerIds && Number(value?.value[subId] >= 0)) {
      displayValue = getTextOfAnswerId(questionField.items, value.value[subId])
    }
  }
  if (typeof displayValue === 'object') {
    displayValue = JSON.stringify(displayValue)
  }
  return displayValue
}
