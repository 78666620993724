import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import '../../../css/autosuggest.css'
import Cross from '../../../assets/icons/Cross-UI.svg'
import { IconEnum } from '../QuestionTypes/RiwisSlider/mapHelper'
import bgagColors from '../../../config/bgag-colors.json'
import { LocationPin } from '../../../components/LocationPin'
import { useSurveyDataStore } from '../dataStore'

const colorList = ['bgag-blue', 'bgag-yellow', 'bgag-lightgreen', 'bgag-violet', 'bgag-darkgreen']

const splitRgbValue = (rgbValue) => {
  const parsed = rgbValue.replace(/[rgb()]/g, '').split(',')
  return `${parsed[0]}, ${parsed[1]}, ${parsed[2]}`
}

const getMappedAnsweredCities = (riwisCities, answeredCities) => {
  return riwisCities.filter((city) => {
    return answeredCities.find((answeredCity) => answeredCity.generic_area_code === city.gac)
  })
}

const getUnansweredCities = (riwisCities, answeredCities) => {
  return riwisCities.reduce((arr, city) => {
    const found = answeredCities.find((answeredCity) => city.gac === answeredCity.generic_area_code)
    if (!found) {
      arr.push(city)
    }
    return arr
  }, [])
}

const getSuggestions = (value, riwisCities, answeredCities) => {
  if (!riwisCities) return []
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length
  const regex = new RegExp(value.toLowerCase(), 'g')
  // create array of all answered cities
  const answeredCitiesResolved = getMappedAnsweredCities(riwisCities, answeredCities)
  //  remove answered cities from riwisCities
  const unansweredCities = getUnansweredCities(riwisCities, answeredCities)

  const suggestions = []
  if (answeredCitiesResolved.length < 5) {
    suggestions.push({ title: 'Unbeantwortet', cities: unansweredCities })
  }
  suggestions.push({ title: 'Beantwortet', cities: answeredCitiesResolved })

  return suggestions
    .map((section) => {
      return {
        title: section.title,
        cities:
          inputLength === 0
            ? section.cities
            : section.cities.filter((city) => city.name.toLowerCase().match(regex)),
      }
    })
    .filter((section) => section.cities.length > 0)
}

const getSectionSuggestions = (section) => section.cities
const renderSectionTitle = (section) => section.title

const renderSuggestion = (suggestion, { query }) => {
  const regex = new RegExp(query, 'i')
  const match = suggestion.name.match(regex)
  const result = suggestion.name.replace(match, `<span style="font-weight: 500;">${match}</span>`)
  return <div dangerouslySetInnerHTML={{ __html: result }}></div>
}

const shouldRenderSuggestions = () => {
  return true
}

export const CitySelectWidget = ({ survey, riwisCities, answeredCities }) => {
  const [{ selectedCity }, { setSelectedCity }] = useSurveyDataStore()
  const [userInput, setUserInput] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [currentCity, setCurrentCity] = useState(() => {
    if (selectedCity) {
      return selectedCity
    }
    return null
  })

  const answerdCitiesWithNames = useMemo(() => {
    if (riwisCities && answeredCities) {
      return answeredCities
        .map((city) => {
          const found = riwisCities.find((rCity) => rCity.gac === city.generic_area_code)
          return {
            ...city,
            name: found.name,
            marketCategory: found.market_category,
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  }, [answeredCities, riwisCities])

  const onChange = useCallback((event, { newValue, method }) => {
    setUserInput(newValue)
  }, [])

  const inputProps = useMemo(() => {
    return {
      placeholder: 'Stadt finden...',
      value: userInput,
      onChange,
    }
  }, [userInput, onChange])

  const onSuggestionSelected = (event, { suggestion }) => {
    setCurrentCity({ gac: suggestion.gac, name: suggestion.name, marketCategory: suggestion.market_category })
  }

  const onSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      setSuggestions(getSuggestions(value, riwisCities, answeredCities))
    },
    [riwisCities, answeredCities]
  )

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([])
  }, [])

  const getSuggestionValue = (suggestion) => {
    return suggestion.name
  }

  const handleAnsweredCityClick = useCallback((city) => {
    setUserInput('')
    setCurrentCity({ gac: city.generic_area_code, name: city.name, marketCategory: city.marketCategory })
  }, [])

  const handleDeselect = useCallback(() => {
    setUserInput('')
    setCurrentCity(null)
  }, [setUserInput])

  const AnsweredCityList = useCallback(() => {
    return (
      <div>
        <div className="my-4 header">Beantwortet</div>
        {answerdCitiesWithNames.map((city, index) => {
          const selected = currentCity && city.generic_area_code === currentCity.gac
          const color = bgagColors[colorList[index]].main
          return (
            <div
              className="mb-5 cursorPointer"
              onClick={() => handleAnsweredCityClick(city)}
              key={city.generic_area_code}
            >
              <div
                className="cityWidget cursorPointer flex-1"
                style={{
                  backgroundColor: selected ? `rgba(${splitRgbValue(color)}, 0.1)` : 'white',
                }}
              >
                <div className="cityText d-flex align-items-center flex-1" style={{ minHeight: '1.5rem' }}>
                  <LocationPin selected={selected} color={color} />
                  {city.name}
                </div>
              </div>
              <div
                className="cityStats"
                style={{
                  backgroundColor: selected ? `rgba(${splitRgbValue(color)}, 0.3)` : 'rgba(255,255,255, 0.5)',
                }}
              >
                {survey.questionnaires.map((questionnaire) => {
                  // find matching questionnaire stats
                  const questionnaireProps = city.questionnaires.find((q) => q.id === questionnaire.id)

                  let progress = questionnaireProps
                    ? Math.ceil((questionnaireProps.answerCount / questionnaireProps.questionCount) * 100)
                    : 0

                  if (isNaN(progress)) {
                    progress = 0
                  }

                  return (
                    <div className="item" key={questionnaire.id}>
                      {<img width="14" src={IconEnum[questionnaire.asset_class]} alt="asset_class" />}:
                      <div className="percentage">{progress}%</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }, [answerdCitiesWithNames, handleAnsweredCityClick, currentCity, survey])

  useEffect(() => {
    if (currentCity) {
      setSelectedCity(currentCity.gac, currentCity.name, currentCity.marketCategory)
    } else {
      setSelectedCity(null)
    }
  }, [currentCity, setSelectedCity])

  return (
    <div className="riwisCityFilter">
      <div
        className="citySelectWidget"
        style={{ height: answeredCities.length < 5 ? '5.75rem' : '7.875rem' }}
      >
        {answeredCities.length >= 5 ? (
          <div className="d-flex flex-column maximumCitiesText">
            <div className="mb-3 header">5 Städte bearbeitet</div>
            <div>
              Sie haben bereits die maximal zulässige Zahl von 5 Städten bearbeitet. Vielen Dank dafür!
            </div>
            <div>Sollten Sie Interesse an weiteren Städten haben, wenden Sie sich bitte an:</div>
            <div>
              <a href="mailto:riwis@bulwiengesa.de">riwis@bulwiengesa.de</a>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-3 header">Stadt auswählen:</div>
            {!selectedCity ||
            (answerdCitiesWithNames.some((city) => city.name === selectedCity.name) &&
              selectedCity.name !== userInput) ? (
              <div className="riwisAutoSuggest">
                <Autosuggest
                  multiSection={true}
                  suggestions={suggestions}
                  shouldRenderSuggestions={shouldRenderSuggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  onSuggestionSelected={onSuggestionSelected}
                  getSectionSuggestions={getSectionSuggestions}
                  renderSuggestion={renderSuggestion}
                  renderSectionTitle={renderSectionTitle}
                  inputProps={inputProps}
                />
              </div>
            ) : (
              <div
                className="cityWidget"
                style={{ backgroundColor: `rgba(${splitRgbValue(bgagColors['bgag-cyan'].main)}, 0.1)` }}
              >
                <div className="cityName" style={{ minHeight: '1.5rem' }}>
                  <div className="d-flex align-items-center">
                    <LocationPin selected={true} color={bgagColors['bgag-cyan'].main} />
                    {selectedCity.name}
                  </div>
                  <div onClick={handleDeselect} className="cursorPointer">
                    <img width="24" height="24" src={Cross} alt="remove" />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {answerdCitiesWithNames.length > 0 && <AnsweredCityList />}
    </div>
  )
}
