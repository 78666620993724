import createStore from '../../../lib/flux-store'
import { Survey, UserGroup } from '../../../helper/api'

const initialState = {
  dataIsLoading: true,
  surveyList: null,
  survey: null,
  userGroups: null,
  editMode: false,
  surveyListMessage: { msg: '', type: '', err: '' },
}

const actions = {
  fetchSurveyList: () => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Survey.getAll().then((res) => {
      dispatch({ type: 'setSurveyList', payload: res.data })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchSurveyAndUserGroups: (surveyId) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Promise.all([Survey.getById(surveyId), UserGroup.getAll()]).then((res) => {
      const [surveyData, userGroupsData] = res
      const payload = {
        survey: surveyData.data.survey,
        userGroups: userGroupsData.data.userGroups,
      }
      dispatch({ type: 'setSurvey', payload })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchUserGroups: () => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    UserGroup.getAll().then((res) => {
      dispatch({ type: 'setUserGroups', payload: res.data.userGroups })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  deleteSurvey: (surveyId) => (dispatch) => {
    Survey.deleteById(surveyId).then((res) => {
      if (res.data.error) {
        dispatch({
          type: 'setSurveyListMessage',
          surveyListMessage: { msg: '', err: JSON.stringify(res.data.err), type: 'failed' },
        })
      } else {
        dispatch({ type: 'setSurveyList', payload: res.data })
        dispatch({
          type: 'setSurveyListMessage',
          surveyListMessage: { msg: 'Umfrage gelöscht', err: '', type: 'success' },
        })
      }
    })
  },
  updateStatus: (surveyId, status) => (dispatch) => {
    Survey.updateStatus(surveyId, status).then((res) => {
      dispatch({ type: 'setSurveyList', payload: res.data })
    })
  },
  setSurveyListMessage: (surveyListMessage) => ({ surveyListMessage }),
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setSurveyList: (state, { payload }) => {
    return { ...state, surveyList: payload }
  },
  setSurvey: (state, { payload }) => {
    return { ...state, survey: payload.survey, editMode: payload.editMode, userGroups: payload.userGroups }
  },
  setUserGroups: (state, { payload }) => {
    return { ...state, userGroups: payload }
  },
  setSurveyListMessage: (state, { surveyListMessage }) => {
    return { ...state, surveyListMessage }
  },
}

export const [SurveyAdminDataContext, SurveyAdminDataProvider, useSurveyAdminDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'SurveyAdminDataStore'
)
