import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../surveyEditorState'
import { Limit } from './Components/Limit'

export const Textarea = ({ question, questionnaireIndex, pageIndex, index }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()
  const handleInputChange = useCallback(
    (event) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, event.target.value, event.target.name)
    },
    [pageIndex, questionnaireIndex, setQuestionInput, index]
  )

  return <Limit limit={question.limit} handleInputChange={handleInputChange} />
}
