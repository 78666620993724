import { useCallback, useMemo } from 'react'
import { NoAnswerCheckbox } from '../QuestionnaireBuilder/Components/NoAnswerCheckbox'
import { useTranslation } from 'react-i18next'
import { useSurveyDataStore } from '../dataStore'

export const Dropdown = ({ id, qid, label, noAnswer, riwisDatasources, dropdowns, value = {}, onChange }) => {
  const { t } = useTranslation()
  const [{ missingInputs }] = useSurveyDataStore()

  const currentMissingInputs = useMemo(() => {
    const found = missingInputs.find((missingInput) => Number(Object.keys(missingInput)[0]) === id) || {}
    return found[id] || []
  }, [missingInputs, id])

  const localOnChange = useCallback(
    (evt) => {
      const dropdownId = evt.target.name
      const givenAnswer = evt.target.value
      const newValue = { ...value }
      if (evt.target.type === 'checkbox') {
        newValue.noAnswer = evt.target.checked
      } else {
        newValue[dropdownId] = givenAnswer
      }
      onChange({ question_id: id, value: newValue, qid })
    },
    [id, onChange, qid, value]
  )

  const noAnswerValue = useMemo(() => {
    let checked = false
    if (typeof value?.noAnswer !== 'undefined') {
      checked = value.noAnswer
    }
    return checked
  }, [value])
  return (
    <>
      <div style={{ display: 'flex', columnGap: '.5rem', rowGap: '1rem', flexWrap: 'wrap' }}>
        {dropdowns.map(({ id, text, items, style, requires, required, noSelectionText }) => (
          <div key={id} style={{ display: 'flex', flexDirection: 'column', ...style }}>
            <label
              className={(currentMissingInputs?.includes(id) ? 'missingInputColor' : '') + ' mr-1 warning'}
              htmlFor={`${qid}-${id}`}
            >
              {text}
              {required === true && (
                <span
                  style={{ fontSize: '0.6125rem', verticalAlign: 'top' }}
                  className={`star ${currentMissingInputs?.includes(id) ? 'missingInputColor' : ''}`}
                >
                  *
                </span>
              )}
            </label>
            <select
              id={`${qid}-${id}`}
              disabled={noAnswerValue || (requires !== null && typeof value?.[requires] === 'undefined')}
              className="form-control"
              name={id}
              value={value?.[id]}
              onChange={localOnChange}
            >
              <option disabled={value?.[id]}>
                {noSelectionText || t('survey.questions.dropdown.noSelection')}
              </option>
              {items.map(({ id, text, disabled }) => (
                <option key={id} value={id} disabled={disabled}>
                  {text}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      <NoAnswerCheckbox
        noAnswer={noAnswer}
        onChange={localOnChange}
        checked={noAnswerValue}
        questionId={id}
      />
    </>
  )
}
