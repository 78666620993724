import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import { Modal, Button } from 'react-bootstrap'
const makeSelectedItems = (selectedItems) => selectedItems.filter((item) => item.selected)

export class ModalSelectBox extends React.Component {
  constructor(props) {
    super(props)

    this.idPrefix = 'selectBox_'

    const selectItems = props.items || []
    const selectedIds = props.selectedItemIds || []

    const isSelected = (item) => selectedIds.find((selectedId) => item.id === selectedId)
    selectItems.forEach((item) => (isSelected(item) ? (item.selected = true) : (item.selected = false)))

    this.state = {
      items: selectItems,
      items_old: [],
      selectedItems: props.items.filter((item) => item.selected),
      showModal: false,
      value: '',
      suggestions: [],
    }

    // this.renderSingleItem = this.renderSingleItem.bind(this)
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }
  handleShow = () => {
    this.setState({ showModal: true })
  }

  //           console.log(data.value.selected)
  //           // Modify Results Item Style
  //           element.style = 'display: flex; justify-content:flex-start'
  //           element.className = 'custom-control custom-checkbox'
  //           //create input
  //           const inputElement = document.createElement('input')
  //           inputElement.checked = data.value.selected
  //           inputElement.type = 'checkbox'
  //           inputElement.className = 'custom-control-input'
  //           inputElement.name = 'search' + data.value.id
  //           inputElement.id = 'search' + data.value.id
  //           //create label
  //           const labelElement = document.createElement('label')
  //           labelElement.className = 'custom-control-label'
  //           labelElement.innerHTML = ''
  //           // labelElement.htmlFor = 'search' + data.value.id
  //           const childElement = document.createElement('div')
  //           childElement.appendChild(inputElement)
  //           childElement.appendChild(labelElement)
  //           element.insertBefore(childElement, element.firstChild)

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <div className="custom-control custom-checkbox d-flex justify-content-start">
        <input
          type="checkbox"
          className="custom-control-input"
          name={`search-${suggestion.id}`}
          id={`search-${suggestion.id}`}
          defaultChecked={suggestion.selected}
        />
        <label className="custom-control-label" htmlFor={`search-${suggestion.id}`}>
          {suggestion.label}
        </label>
      </div>
    )
  }

  getSuggestionValue = (suggestion) => {
    let items = this.state.items
    items.find((item) => item.id === suggestion.id).selected = !suggestion.selected
    const selectedItems = this.state.items.filter((item) => item.selected === true)
    this.props.changeHandler(selectedItems)
    return this.state.value
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    return inputLength === 0
      ? []
      : this.props.items.filter((group) => group.label.toLowerCase().slice(0, inputLength) === inputValue)
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.showModal === false && this.state.showModal === true) {
  //     this.autoCompleteJS = new autoComplete({
  //       name: 'User-Gruppen',
  //       data: {
  //         src: this.props.items,
  //         key: ['label', 'id'],
  //       },
  //       selector: '#groupSearch',
  //       trigger: {
  //         event: ['input', 'focus'],
  //       },
  //       placeHolder: 'Suche nach Gruppen',
  //       searchEngine: 'strict',
  //       highlight: true,
  //       resultsList: {
  //         destination: '#groupList',
  //         position: 'afterbegin',
  //         element: 'div',
  //         className: 'groupResults',
  //         navigation: (event, input, resListElement, onSelection, resListData) => {
  //           console.log('navigation', event)
  //           console.log('onselection', onSelection)
  //         },
  //       },
  //       resultItem: {
  //         content: (data, element) => {
  //           console.log(data.value.selected)
  //           // Modify Results Item Style
  //           element.style = 'display: flex; justify-content:flex-start'
  //           element.className = 'custom-control custom-checkbox'
  //           //create input
  //           const inputElement = document.createElement('input')
  //           inputElement.checked = data.value.selected
  //           inputElement.type = 'checkbox'
  //           inputElement.className = 'custom-control-input'
  //           inputElement.name = 'search' + data.value.id
  //           inputElement.id = 'search' + data.value.id
  //           //create label
  //           const labelElement = document.createElement('label')
  //           labelElement.className = 'custom-control-label'
  //           labelElement.innerHTML = ''
  //           // labelElement.htmlFor = 'search' + data.value.id
  //           const childElement = document.createElement('div')
  //           childElement.appendChild(inputElement)
  //           childElement.appendChild(labelElement)
  //           element.insertBefore(childElement, element.firstChild)
  //         },
  //       },
  //       noResults: (dataFeedback, generateList) => {
  //         // Generate autoComplete List
  //         generateList(this.autoCompleteJS, dataFeedback, dataFeedback.results)
  //         // No Results List Item
  //         const result = document.createElement('div')
  //         result.setAttribute('class', 'no_result')
  //         result.setAttribute('tabindex', '1')
  //         result.innerHTML = `<span style="display: flex; align-items: center; font-weight: 100; color: rgba(0,0,0,1);">Found No Results for "${dataFeedback.query}"</span>`
  //         document.querySelector(`#${this.autoCompleteJS.resultsList.idName}`).appendChild(result)
  //       },
  //       onSelection: (feedback) => {
  //         document.querySelector('#groupSearch').focus()
  //         // Prepare User's Selected Value
  //         console.log(feedback)
  //         let items = this.state.items
  //         const selection = feedback.selection.value
  //         items.find((item) => item.id === selection.id).selected = !selection.selected
  //         const selectedItems = this.state.items.filter((item) => item.selected === true)
  //         this.props.changeHandler(selectedItems)
  //       },
  //     })
  //   }
  // }

  componentDidMount() {}

  handleConfirmClick = () => {
    this.setState({ showModal: false, selectedItems: makeSelectedItems(this.state.items) })

    if (this.props.changeHandler) {
      const selectedItems = this.state.items.filter((item) => item.selected === true)

      this.props.changeHandler(selectedItems)
    }
    // todo
    // $('#selectBoxModal').modal('hide')
  }

  openModal = (event) => {
    // todo
    // $('#selectBoxModal').modal('show')
    this.setState({ showModal: true, items_old: this.state.items.map((a) => ({ ...a })) })
  }
  handleAbortClick = () => {
    this.setState({ showModal: true, items: this.state.items_old })
  }

  handleSelectedItemsTextChanged = () => {
    console.log('ModalSelectBox::handleSelectedItemsTextChanged: called')
  }

  handleChange = (event) => {
    let items = [...this.state.items]

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    items.find((item) => item.id === parseInt(event.target.name)).selected = value

    this.setState({ items: items })
  }

  handleDelete = (event) => {
    let id = null
    const items = this.state.items

    if (!event.target.id) {
      id = parseInt(event.target.closest('.itemBadgeDelete').id)
    } else {
      id = parseInt(event.target.id)
    }
    items.find((item) => item.id === id).selected = false
    const selectedItems = items.filter((item) => item.selected === true)

    this.setState({ selectedItems: makeSelectedItems(selectedItems) })
    this.props.changeHandler(selectedItems)
  }

  renderSingleItem = (item) => {
    return (
      <div className="custom-control custom-checkbox" key={this.idPrefix + item.id}>
        <input
          className="custom-control-input"
          type="checkbox"
          name={item.id}
          onChange={this.handleChange}
          checked={item.selected}
          id={this.idPrefix + item.id}
        ></input>
        <label className="custom-control-label" htmlFor={this.idPrefix + item.id}>
          {item.label}
        </label>
      </div>
    )
  }

  renderItemBadge = (item) => {
    return (
      <div className="itemBadge" key={item.id}>
        {item.label}
        <div className="itemBadgeDelete" id={item.id} onClick={this.handleDelete}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
      </div>
    )
  }

  render() {
    // const filteredItems = this.state.selectedItems
    //   .filter((item) => item.selected === true)
    //   .map((item) => this.renderItemBadge(item))
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: 'Gruppen-Name',
      value,
      onChange: this.onChange,
    }

    return (
      <div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <button className="btn btn-primary " type="button" onClick={this.handleShow}>
              Gruppe hinzufügen
            </button>
          </div>
          <div className="d-flex flex-row align-items-center itemBadgeContainer">
            {this.state.selectedItems.length
              ? this.state.selectedItems.map((item) => this.renderItemBadge(item))
              : 'Keine Gruppen hinzugefügt'}
          </div>
        </div>
        {/* <div className="input-group mb-3 d-flex flex-row"></div> */}
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title" id="selectBoxLabel">
                User-Gruppen
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
          <Modal.Body>{this.state.items.map(this.renderSingleItem)}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleAbortClick}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={this.handleConfirmClick}>
              Übernhemen
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <div
          className="modal fade"
          id="selectBoxModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="selectBoxLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="selectBoxLabel">
                  User-Gruppen
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <input type="text" id="groupSearch" className="mx-4 mt-4" />
              <div className="modal-body" id="groupList">
                {this.state.items.map(this.renderSingleItem)}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.handleAbortClick}
                >
                  Abbrechen
                </button>
                <button type="button" className="btn btn-primary" onClick={this.handleConfirmClick}>
                  Übernehmen
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
