import createStore from '../../../lib/flux-store'
import { Reporting, Riwis } from '../../../helper/api'

const initialState = {
  dataIsLoading: true,
  report: null,
  chartType: 'column',
  currentQuestionId: null,
  cities: null,
}

const actions = {
  fetchReport: (surveyId) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Reporting.getReport(surveyId).then((res) => {
      dispatch({ type: 'setReport', payload: res.data })
      dispatch({ type: 'setDataIsLoading', payload: true })
    })
  },
  fetchCities: () => (dispatch) => {
    Riwis.getCities().then((res) => {
      dispatch({ type: 'setCities', cities: res.data.riwisCities })
    })
  },
  setChartType: (chartType) => ({ chartType }),
  setCurrentQuestionId: (questionId) => ({ questionId }),
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setReport: (state, { payload }) => {
    return { ...state, report: payload }
  },
  setChartType: (state, { chartType }) => {
    return { ...state, chartType }
  },
  setCurrentQuestionId: (state, { questionId }) => {
    return { ...state, currentQuestionId: parseInt(questionId) }
  },
  setCities: (state, { cities }) => {
    return { ...state, cities }
  },
}

export const [ReportingDataContext, ReportingDataProvider, useReportingDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'ReportingDataStore'
)
