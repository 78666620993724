import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const BackgroundImage = styled.div(({ children, backgroundImage, ...rest }) => {
  return {
    backgroundImage: `url(${backgroundImage})`,
    ...rest,
  }
})

const SurveyTitle = styled.h1(({ children, id, ...rest }) => {
  return {
    ...rest,
  }
})

const Introduction = styled.div(({ children, id, ...rest }) => {
  return { ...rest }
})

const SurveySubtitle = styled.h2(({ children, id, ...rest }) => {
  return { ...rest }
})

const SurveyDescription = styled.div(({ children, className, id, ...rest }) => {
  return { ...rest }
})

SurveyDescription.Text = styled.div(({ children, className, id, ...rest }) => ({ ...rest }))

const Button = styled('div')(({ theme, children, 'data-id': dataId, id, location, ...rest }) => ({
  cursor: 'pointer',
  ...rest,
}))

const SurveyLogoWrapper = styled.div(({ children, className, id, ...rest }) => ({ ...rest }))
const SurveyLogo = styled('img')(({ children, className, id, ...rest }) => ({ ...rest }))

const defaultIntroductionStyle = {
  background: 'linear-gradient(60deg, #003e71 5%, rgba(0, 62, 113, 0) 100%)',
  opacity: '1',
  color: 'white',
  fontWeight: 300,
  padding: '1.5rem',
  WebkitBackdropFilter: 'blur(2px)',
  backdropFilter: 'blur(2px)',
  fontSize: '0.875rem',
}

export const CustomIntro = forwardRef(({ survey, questionnaire = null, handleCurrentQuestionaire }, ref) => {
  const { t } = useTranslation()
  let {
    backgroundImageStyle,
    introductionStyle,
    surveyTitleStyle,
    surveySubtitleStyle,
    surveyDescriptionStyle,
    surveyDescriptionText,
    surveyStartButton,
    surveyLogoWrapper,
    surveyLogo,
  } = survey.settings.introStyles.custom

  const buttonLocation = surveyStartButton?.location || 'standalone'

  if (backgroundImageStyle && Object.keys(backgroundImageStyle).length) {
    backgroundImageStyle = { ...backgroundImageStyle, backgroundImage: `url(${survey.background_image})` }
  }

  introductionStyle = Object.keys(introductionStyle).length ? introductionStyle : defaultIntroductionStyle

  return (
    <BackgroundImage id="backgroundImage" {...backgroundImageStyle} backgroundImage={survey.background_image}>
      <Introduction id="introduction" {...introductionStyle}>
        <SurveyTitle id="surveyTitle" {...surveyTitleStyle}>
          {survey.title}
        </SurveyTitle>
        <SurveySubtitle id="surveySubtitle" {...surveySubtitleStyle}>
          {survey.subtitle}
        </SurveySubtitle>
        {survey.logo && (
          <SurveyLogoWrapper {...surveyLogoWrapper} id="surveyLogoWrapper">
            <SurveyLogo id="surveyLogo" {...surveyLogo} src={survey.logo} alt="logo" />
          </SurveyLogoWrapper>
        )}
        {survey.description && (
          <SurveyDescription id="surveyDescription" {...surveyDescriptionStyle} className="openerDescription">
            <SurveyDescription.Text
              id="surveyDescriptionText"
              {...surveyDescriptionText}
              dangerouslySetInnerHTML={{ __html: survey.description }}
            />
            {questionnaire !== null && buttonLocation === 'SurveyDescription' && (
              <Button
                id="start"
                data-id="surveyStartButton"
                {...surveyStartButton}
                onClick={() => handleCurrentQuestionaire(questionnaire.id)}
                ref={ref}
              >
                {t('survey.opener.answer')}
              </Button>
            )}
          </SurveyDescription>
        )}
        {questionnaire !== null && buttonLocation === 'standalone' && (
          <Button
            id="start"
            data-id="surveyStartButton"
            {...surveyStartButton}
            onClick={() => handleCurrentQuestionaire(questionnaire.id)}
            ref={ref}
          >
            {t('survey.opener.answer')}
          </Button>
        )}
      </Introduction>
    </BackgroundImage>
  )
})
