import React, { useCallback, useMemo, useState } from 'react'

export const Radio = ({ id, qid, answers, previousAnswer, noAnswer, value = null, onChange }) => {
  const [localPreviousAnswer, setLocalPreviousAnswer] = useState(previousAnswer)
  const handleChange = useCallback(
    (event) => {
      setLocalPreviousAnswer(null)
      onChange({ question_id: event.target.name, value: event.target.value, qid })
    },
    [qid, onChange]
  )

  const preparedAnswers = useMemo(() => {
    const preparedAnswers = answers.map((answer) => {
      let checked = false
      if (value) {
        checked = parseInt(value) === answer.id
      } else if (!value && localPreviousAnswer) {
        checked = parseInt(localPreviousAnswer) === answer.id
      }
      const elementId = id + '_' + answer.id

      return {
        checked,
        elementId,
        ...answer,
      }
    })

    if (noAnswer && noAnswer.showNoAnswer) {
      let checked = false
      if (value) {
        checked = parseInt(value) === noAnswer.id
      } else if (!value && localPreviousAnswer) {
        checked = parseInt(localPreviousAnswer) === noAnswer.id
      } else {
        checked = false
      }

      const elementId = id + '_' + noAnswer.id

      preparedAnswers.push({
        checked,
        elementId,
        text: noAnswer.title,
        id: noAnswer.id,
        type: 'noAnswer',
      })
    }
    return preparedAnswers
  }, [answers, id, value, noAnswer, localPreviousAnswer])

  return preparedAnswers.map((answer) => (
    <div key={answer.elementId} className={`custom-control custom-radio mb-3 ${answer?.type === 'noAnswer'}`}>
      <input
        className="custom-control-input"
        type="radio"
        name={id}
        id={answer.elementId}
        value={answer.id}
        onChange={handleChange}
        checked={answer.checked}
      ></input>
      <label
        className="custom-control-label radioLabel"
        dangerouslySetInnerHTML={{ __html: answer.text }}
        htmlFor={answer.elementId}
      />
    </div>
  ))
}
