export const Limit = ({ limit, handleInputChange }) => {
  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">Zeichenlimit</label>
      <div className="col-sm-10">
        <input
          className="form-control"
          type="number"
          name="limit"
          onChange={(event) => handleInputChange(event)}
          value={limit || 0}
        ></input>
      </div>
    </div>
  )
}
