import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { stripString, decodeSpecialChars } from './utils'

const style = {
  padding: '0.5rem 1rem',
  // cursor: 'move',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '36px',
}
const labelStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  width: '100%',
}

export const Tab = ({
  index,
  id,
  text,
  moveTab,
  moveInto,
  activeTab,
  setActiveTab,
  handleDelete,
  tabWidth,
  tabPills,
  itemType,
  acceptedItemTypes,
}) => {
  const ref = useRef(null)
  const [dragCounter, setDragCounter] = useState(-1)

  const onDragEnter = useCallback(
    (event) => {
      event.stopPropagation()
      setDragCounter(dragCounter + 1)
      if (!event.currentTarget.classList.contains('dropAnimation')) {
        event.currentTarget.classList.add('dropAnimation')
      }
    },
    [dragCounter]
  )

  const onDragLeave = useCallback(
    (event) => {
      event.stopPropagation()
      setDragCounter(dragCounter - 1)
      if (dragCounter === 0 && event.currentTarget.classList.contains('dropAnimation')) {
        event.currentTarget.classList.remove('dropAnimation')
      }
    },
    [dragCounter]
  )

  const onDrop = useCallback((event) => {
    setDragCounter(-1)
    if (event.currentTarget.classList.contains('dropAnimation')) {
      event.currentTarget.classList.remove('dropAnimation')
    }
  }, [])

  const [{ hanlderId }, drop] = useDrop({
    accept: acceptedItemTypes,
    collect(monitor) {
      return {
        hanlderId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (itemType === item.type) {
        moveTab(dragIndex, hoverIndex)
        item.index = hoverIndex
      } else {
        if (!ref.current.classList.contains('active')) {
          // ref.current.classList.toggle('dropAnimation')
        }
      }
    },
    drop(item, monitor) {
      if (!ref.current) {
        return
      }
      const hoverIndex = index

      if (itemType !== item.type && typeof moveInto === 'function') {
        const target = { itemType, hoverIndex }
        moveInto(item, target)
      }
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: () => {
      return { id, index, type: itemType }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))
  return (
    <div className={(activeTab === index ? 'active' : '') + (!tabPills ? ' tabWrapper' : 'tabPillWrapper')}>
      <div
        className={
          (activeTab === index ? 'active' : 'inactive') + (tabPills ? ' tabPills' : '') + ' tab ' + itemType
        }
        onClick={() => setActiveTab(index)}
        ref={ref}
        data-hanlder-id={hanlderId}
        style={{ ...style, opacity, width: tabWidth }}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div style={{ ...labelStyle }}>
          {index + 1 + '. '}
          {decodeSpecialChars(stripString(text))}
        </div>
        <FontAwesomeIcon
          icon={faTrashAlt}
          size="sm"
          className="ml-3 trashCan"
          onClick={(e) => {
            e.stopPropagation()
            handleDelete(index)
          }}
        />
      </div>
    </div>
  )
}
