import { useEffect, useState, useMemo, useCallback } from 'react'
export const CookieAlert = () => {
  const [showCookie, setShowCookie] = useState(false)

  const cookieStorage = useMemo(() => {
    return {
      getItem: (key) => {
        const cookies = document.cookie
          .split(';')
          .map((cookie) => cookie.split('='))
          .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {})

        return cookies[key]
      },
      setItem: (key, value) => {
        document.cookie = `${key}=${value}`
      },
    }
  }, [])

  const acceptCookies = useCallback(() => {
    const maxAge = 60 * 60 * 24 * 365 * 3 // 3 years
    cookieStorage.setItem('surveyDesk_consent', `accepted; max-age=${maxAge}`)
    setShowCookie(false)
  }, [cookieStorage])

  useEffect(() => {
    if (!cookieStorage.getItem('surveyDesk_consent')) {
      setShowCookie(true)
    }
  }, [cookieStorage])

  return (
    showCookie === true && (
      <div
        className="customContainer alert alert-secondary text-center mb-5 h6"
        id="cookieBanner"
        role="alert"
      >
        Cookies erleichtern die Bereitstellung unserer Dienste. Dazu speichert diese Website Cookies. <br />
        Mit der weiteren Nutzung erklären Sie sich mit der Verwendung von Cookies einverstanden.
        <hr />
        <a
          href="https://www.bulwiengesa.de/de/datenschutzerklaerung"
          target="_self"
          rel="noopener noreferrer"
        >
          Weitere Informationen
        </a>
        <button
          onClick={acceptCookies}
          className="btn btn-primary btn-sm ml-3"
          id="btnCookieOk"
          type="button"
        >
          OK
        </button>
      </div>
    )
  )
}
