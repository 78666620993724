import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const MissingInputWarning = ({ error }) => {
  return (
    <div className="missingInputBox">
      <FontAwesomeIcon icon={faExclamation} className="mr-3 icon warning" />
      {error}
    </div>
  )
}
