import styled from 'styled-components'

export const IdLabel = styled('div')({
  color: 'lightgrey',
  fontWeight: '500',
  textAlign: 'right',
})

export const PropertyLabel = styled('div')({
  fontSize: '0.6rem',
  color: 'lightgrey',
  fontWeight: '400',
  marginRight: '0.5rem',
})
